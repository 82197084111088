import React from "react";
import ExcelJS from "exceljs";
import { FaDownload } from "react-icons/fa";

const DownloadExcel = ({ data, fileName, sheetName }) => {
  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(sheetName || "Sheet1");

    data.forEach((entry) => {
      const { month, dates, days, projects } = entry;

      // Calculate working days (excluding Sat, Sun, and "HOL")
      const workingDaysCount = entry.days.filter(
        (day, dayIndex) =>
          day !== "Sat" &&
          day !== "Sun" &&
          entry.projects.every((project) =>
            project.employees.every(
              (employee) => employee.hours[dayIndex] !== "HOL"
            )
          )
      ).length;

      // Add the month, dates in the same row
      const headerRow = worksheet.addRow([month, "Date", ...dates]);

      // Merge cells for "Working Days"
      const workingDaysCell = headerRow.getCell(dates.length + 3);
      headerRow.getCell(dates.length + 3).value = "Working Days:";
      worksheet.mergeCells(
        headerRow.number,
        workingDaysCell.col,
        headerRow.number,
        workingDaysCell.col + 2
      ); // Merge three cells
      workingDaysCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
      }; // Yellow for Working Days
      workingDaysCell.alignment = { horizontal: "center", vertical: "middle" }; // Center alignment for merged cell

      // Set the value of the merged cell to workingDaysCount
      headerRow.getCell(
        dates.length + 3
      ).value = `Working Days: ${workingDaysCount}`;

      // Align and set all cells in the header row to bold and center
      headerRow.eachCell((cell) => {
        cell.alignment = { horizontal: "center", vertical: "middle" };
        cell.font = { bold: true }; // Set header row to bold
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        }; // Add border to header cells
      });

      // Add the headers row with 'Leave', 'Present', and 'Total Hours'
      const headersRow = worksheet.addRow([
        "Project",
        "Day",
        ...days,
        "Leave",
        "Present",
        "Total Hours",
      ]);

      // Style headers: Leave in red, Present in green, Total Hours in yellow
      headersRow.getCell(days.length + 3).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF0000" },
      }; // Red for Leave
      headersRow.getCell(days.length + 3).font = {
        color: { argb: "000000" },
        bold: true,
      }; // Set font color to black and bold

      headersRow.getCell(days.length + 4).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "00FF00" },
      }; // Green for Present
      headersRow.getCell(days.length + 4).font = {
        color: { argb: "000000" },
        bold: true,
      }; // Set font color to black and bold

      headersRow.getCell(days.length + 5).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "00ebff" },
      }; // Yellow for Total Hours
      headersRow.getCell(days.length + 5).font = {
        color: { argb: "000000" },
        bold: true,
      }; // Set font color to black and bold

      // Align and set all cells in the headers row to bold and center
      headersRow.eachCell((cell) => {
        cell.alignment = { horizontal: "center", vertical: "middle" };
        cell.font = { bold: true }; // Set headers row to bold
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        }; // Add border to header cells
      });

      // Add project and employee data
      projects.forEach((project) => {
        project.employees.forEach((employee) => {
          let leaveCount = 0;
          let presentCount = 0;
          let totalHours = 0;

          // Loop through each day to calculate totals
          const row = [
            project.projectName,
            employee.name,
            ...employee.hours.map((hour, dayIndex) => {
              // Display "Sat" and "Sun" explicitly
              if (days[dayIndex] === "Sat" || days[dayIndex] === "Sun") {
                return days[dayIndex]; // Return 'Sat' or 'Sun' for those days
              }

              // Count leave and present hours
              if (
                hour === "E" ||
                hour === "EH" ||
                hour === "S" ||
                hour === "SH" ||
                hour === "C" ||
                hour === "CH" ||
                hour === "BL" ||
                hour === "PL" ||
                hour === "LP" ||
                hour === "CL"
              ) {
                if (
                  hour === "E" ||
                  hour === "S" ||
                  hour === "C" ||
                  hour === "BL" ||
                  hour === "PL" ||
                  hour === "CL" ||
                  hour === "LP"
                )
                  leaveCount += 1;
                if (hour === "EH" || hour === "SH" || hour === "CH") {
                  leaveCount += 0.5; // Count half-day leave as 0.5
                  totalHours += 4; // Add 4 hours for half-day leave
                }
              } else if (typeof hour === "number" && hour > 0) {
                presentCount += 1;
                totalHours += hour; // Accumulate total hours
              }

              return hour; // Return the hour value
            }),
            leaveCount || "0",
            presentCount || "0",
            `${totalHours || 0} Hrs`, // Include the total hours in the row
          ];

          const employeeRow = worksheet.addRow(row);

          // Align all cells in the employee row to the center
          employeeRow.eachCell((cell) => {
            cell.alignment = { horizontal: "center", vertical: "middle" };
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            }; // Add border to employee cells
          });

          // Apply background colors to values
          employee.hours.forEach((hour, dayIndex) => {
            const cell = employeeRow.getCell(dayIndex + 3); // Adjust index to match ExcelJS (1-based index)
            let bgColor;

            if (days[dayIndex] === "Sat" || days[dayIndex] === "Sun") {
              bgColor = "D3D3D3"; // Light gray for weekends
            } else if (hour === "C" || hour === "CH") {
              bgColor = "FFDAB9"; // Peachpuff for Casual leave
            } else if (hour === "S" || hour === "SH") {
              bgColor = "FF0000"; // Red for Sick leave
            } else if (hour === "E" || hour === "EH") {
              bgColor = "ADD8E6"; // Light blue for Earned leave
            } else if (hour === "HOL") {
              bgColor = "F8A53A"; // Orange for holidays
            } else if (hour === "BL") {
              bgColor = "e67300";
            } else if (hour === "LP") {
              bgColor = "yellow";
            } else if (hour === "PL") {
              bgColor = "0066cc";
            } else if (hour === "CL") {
              bgColor = "a3a375";
            } else {
              bgColor = "FFFFFF"; // Default white background
            }

            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: bgColor },
            };
            cell.alignment = { horizontal: "center", vertical: "middle" }; // Center each cell
          });

          // Adjust column width based on the content of employee name
          const nameColumnIndex = 2; // The index of the 'employee.name' column (1-based index)
          const currentWidth = worksheet.getColumn(nameColumnIndex).width || 10; // Default width if not set
          worksheet.getColumn(nameColumnIndex).width = Math.max(
            currentWidth,
            employee.name.length + 2
          ); // Add some padding
        });
      });
    });

    // Save the workbook as an Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName || "Report"}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button
      onClick={exportToExcel}
      style={{ background: "none", border: "none", cursor: "pointer" }}
    >
      <FaDownload size={20} color="green" />
    </button>
  );
};

export default DownloadExcel;
