import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { Menubar } from 'primereact/menubar';
import logo from '../../../../assets/IGS.png';
import { TbArrowBack } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

const NotOnBench = () => {
  const [data, setData] = useState(null);
  const [selectedProject, setSelectedProject] = useState('All');
  const navigate = useNavigate();

  // Fetch the data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/billableAndNonBillable`);
        const result = await response.json();
        setData(result.report);
      } catch (error) {
        console.error('Error fetching the data:', error);
      }
    };
    fetchData();
  }, []);

  // Prepare project options for the dropdown
  const projectOptions = [
    { label: 'All', value: 'All' },
    ...(data?.nonBillable.map(item => ({ label: item.project, value: item.project })) || []),
  ];

  // Filter the data based on the selected project
  const filteredData = selectedProject === 'All'
    ? data?.nonBillable.flatMap(item => item.employees) || []
    : data?.nonBillable.find(item => item.project === selectedProject)?.employees || [];

  // Initialize the chart
  useEffect(() => {
    if (data) {
      const chartDom = document.getElementById('not-on-bench-chart');
      const myChart = echarts.init(chartDom);

      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: (params) => {
            return `
              ${params[0].name}<br/>
              <strong>Total Hours: </strong>${params[0].value}
            `;
          },
        },
        legend: {
          bottom: '10px',
          textStyle: {
            color: '#555',
            fontSize: 14,
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          axisLabel: {
            color: '#666',
            fontSize: 12,
            formatter: '{value}', // Display natural number counts
          },
          splitLine: {
            lineStyle: {
              color: '#f0f0f0',
            },
          },
        },
        yAxis: {
          type: 'category',
          data: filteredData.map((item) => item.employeeName), // Employee names
          axisLabel: {
            color: '#666',
            fontSize: 14,
            fontWeight: 'bold',
            margin: 15,
          },
        },
        series: [
          {
            name: 'Total Hours',
            type: 'bar',
            data: filteredData.map((item) => item.totalHours), // Total hours
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                { offset: 1, color: '#993300' },
                { offset: 0, color: '#000066' },
              ]),
            },
            label: {
              show: true,
              position: 'inside',
              formatter: '{c} Hours', // Display natural number counts
              color: '#fff',
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(0, 255, 0, 0.3)',
              },
            },
            animationEasing: 'elasticOut',
            animationDelay: (idx) => idx * 50,
          },
        ],
        backgroundColor: '#f5f5f5',
        animationDuration: 1500,
      };

      myChart.setOption(option);

      // Cleanup the chart on component unmount
      return () => {
        myChart.dispose();
      };
    }
  }, [filteredData]);

  const start = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <button className="rounded-full m-2 hover:bg-gray-200" style={{ height: '40px', width: '40px' }} onClick={() => navigate(-1)}>
        <TbArrowBack />
      </button>
      <div style={{ color: '#00000080' }}>
        <h2>Non-Billable Status By Employees</h2>
      </div>
    </div>
  );

  const end = (
    <div style={{ paddingRight: '15px' }}>
      <Image src={logo} height="60px" width="60px" />
    </div>
  );

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        <Menubar start={start} end={end} />
      </div>

      {/* Dropdown to select project */}
      <div style={{ marginBottom: '20px', width: '300px' }}>
        <Dropdown
          value={selectedProject}
          options={projectOptions}
          onChange={(e) => setSelectedProject(e.value)}
          placeholder="Select Project"
          style={{width:"12vw"}}
        />
      </div>

      {/* Chart container */}
      <div id="not-on-bench-chart" style={{ width: '100%', height: '700px', backgroundColor: '#fff', borderRadius: '10px', padding: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}></div>
    </div>
  );
};

export default NotOnBench;
