// import React, { useState } from "react";
// import { nanoid } from "nanoid"; // Import nanoid

// const AddSubProject = ({ projectId,onSubProjectAdded  }) => {
//   const [subProjectName, setSubProjectName] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const handleAddSubProject = async () => {
//     if (!subProjectName) {
//       setError("Sub-project name is required");
//       return;
//     }

//     setLoading(true);
//     setError(null); // Clear previous errors

//     const subProjectId = nanoid(6); // Generate a unique ID using nanoid (6 characters long)

//     const subProjectData = {
//       project_id: projectId, // Use the projectId from props
//       sub_project: {
//         _id: subProjectId,
//         name: subProjectName,
//       },
//     };

//     try {
//       const response = await fetch(
//         "http://192.168.29.195:5004/api/v1/admin/subproject/addSubProject",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(subProjectData), // Convert subProjectData to JSON string
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to add sub-project. Please try again.");
//       }

//       const data = await response.json();
//       console.log("Sub-project added successfully:", data);
//       // Update the project data in the parent component (ProjectDataTable)
//       onSubProjectAdded(subProjectData.sub_project);

//       // Optionally, you can clear the input and provide feedback
//       setSubProjectName("");
//       alert("Sub-project added successfully");
//     } catch (error) {
//       setError(error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div>

//       <input
//         type="text"
//         value={subProjectName}
//         placeholder="Enter sub-project name"
//         onChange={(e) => setSubProjectName(e.target.value)}
//         disabled={loading}
//       />
//       <button onClick={handleAddSubProject} disabled={loading}>
//         {loading ? "Adding..." : "Add Sub-Project"}
//       </button>
//       {error && <p style={{ color: "red" }}>{error}</p>}
//     </div>
//   );
// };

// export default AddSubProject;


import React, { useState } from "react";
import { nanoid } from "nanoid";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast"; // Import Toast

const AddSubProject = ({ projectId, onSubProjectAdded, visible, onHide }) => {
  const [subProjectName, setSubProjectName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const toastRef = React.useRef(null); // Create a ref for Toast

  const handleAddSubProject = async () => {
    if (!subProjectName) {
      setError("Sub-project name is required");
      return;
    }

    setLoading(true);
    setError(null); // Clear previous errors

    const subProjectId = nanoid(6); // Generate a unique ID using nanoid (6 characters long)

    const subProjectData = {
      project_id: projectId, // Use the projectId from props
      sub_project: {
        _id: subProjectId,
        name: subProjectName,
      },
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/subproject/addSubProject`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(subProjectData), // Convert subProjectData to JSON string
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add sub-project. Please try again.");
      }

      const data = await response.json();
      console.log("Sub-project added successfully:", data);
      // Update the project data in the parent component (ProjectDataTable)
      onSubProjectAdded(subProjectData.sub_project);

      // Clear the input
      setSubProjectName("");

      // Show success toast
      toastRef.current.show({ severity: 'success', summary: 'Success', detail: 'Sub-project added successfully', life: 1000 });

      // Close the modal after a delay
      setTimeout(() => {
        onHide();
      }, 1000);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Toast ref={toastRef} /> {/* Add the Toast component */}
      <Dialog
        style={{ width: '30vw' }}
        header="Add Sub-Project"
        visible={visible}
        onHide={onHide}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
          <div className="p-field">
            <InputText
              placeholder="Enter sub project name"
              value={subProjectName}
              onChange={(e) => setSubProjectName(e.target.value)}
              required
              style={{ width: '17vw' }}
            />
            <div>
              {error && <p style={{ color: "red" }}>{error}</p>}
            </div>
          </div>
          <div className="p-field">
            <Button
              style={{ backgroundColor: '#182452' }}
              label="Add"
              onClick={handleAddSubProject}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AddSubProject;

