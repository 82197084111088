// // src/App.js
// import React, { useState } from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import "./App.css"
// //Components
// import Sidebar from './components/Sidebar/Sidebar';
// import Projects from './pages/Projects/Projects';
// import TimeSheets from './pages/TimeSheets/TimeSheets';
// import Reports from './pages/Reports/Reports';
// import Tasks from './pages/Tasks/Tasks';
// import Users from './pages/Users/Users';
// import Leaves from './pages/Leaves/Leaves';
// import CreateProject from './components/CreateProject/CreateProject';
// // import Feedback from './components/Feedback/Feedback';
// import AddUsers from './components/AddUsers/AddUsers.js';
// import Login from './pages/Login/Login';
// import MasterTask from './pages/MasterTask/MasterTask';
// // import EarMark from './pages/EarMark/EarMark.js';


// // src/App.js or src/index.js
// import 'primereact/resources/themes/saga-blue/theme.css'; // Choose your theme
// import 'primereact/resources/primereact.min.css'; // Core PrimeReact CSS
// import 'primeicons/primeicons.css'; // PrimeIcons
// import 'primeflex/primeflex.css'; // PrimeFlex for utility classes
// import MasterTasks from './pages/MasterTask/MasterTasks.js';
// // import MasterTaskss from './pages/MasterTask/MasterTaskss.js';
// import TimeSheetSummary from './pages/TimeSheets/TimeSheetSummary.js';
// import EarMark from './pages/EarMark/EarMark.js';
// import AddNew from './pages/EarMark/AddNew.jsx';
// import NewProject from "./pages/EarMark/NewProject.jsx";
// function App() {

//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [ isuserauthenticated, setIsUserAuthenticated] = useState(false)

//   // const [showFeedback, setShowFeedback] = useState(false);

//   // const handleRelease = () => {
//   //   console.log('Released');
//   //   setShowFeedback(false);
//   // };

//   // const handleCancel = () => {
//   //   setShowFeedback(false);
//   // };

//   const handleLoginSuccess = () => {
//     setIsAuthenticated(true);
//   };

//   return (

//     <Router>
//         <div className="app" style={{ display: 'flex' }}>
//           <Sidebar />
//           <main style={{ flex: 1, padding: '20px' }}>
//             <Routes>
//               <Route path="/projects" element={<Projects />} />
//               <Route path="/timesheets" element={<TimeSheetSummary />} />
//               <Route path='/submittimesheet/:id' element={<TimeSheets />} />
//               <Route path="/reports" element={<Reports />} />
//               <Route path="/createtasks" element={<MasterTasks />} />
//               <Route path="/earmark/new" element={<AddNew />} />
//               <Route path="/earmark/NewProject" element={<NewProject />} />
//               <Route path="/earmark" element={<EarMark />} />

//               <Route path="/tasks" element={<Tasks />} />
//               <Route path="/users" element={<Users />} />
//               <Route path="/leaves" element={<Leaves />} />
//               <Route path="/createproject" element={<CreateProject />} />
//               {/* <Route path="/feedback" element={<Feedback userName="User Name" endDate="22/05/2023" onRelease={handleRelease} onCancel={handleCancel} />} /> */}
//               <Route path="/addusers" element={<AddUsers />} />
//               <Route path="*" element={<Navigate to="/projects" />} />
//             </Routes>
//           </main>
//         </div>
//     </Router>

//     // <Router>
//     //   {isAuthenticated ? (
//     //     <div className="app" style={{ display: 'flex' }}>
//     //       <Sidebar />
//     //       <main style={{ flex: 1, padding: '20px' }}>
//     //         <Routes>
//     //           <Route path="/projects" element={<Projects />} />
//     //           <Route path="/timesheets" element={<TimeSheets />} />
//     //           <Route path="/reports" element={<Reports />} />
//     //           <Route path="/createtasks" element={<MasterTask />} />
//     //           <Route path="/tasks" element={<Tasks />} />
//     //           <Route path="/users" element={<Users />} />
//     //           <Route path="/leaves" element={<Leaves />} />
//     //           <Route path="/createproject" element={<CreateProject />} />
//     //           {/* <Route path="/feedback" element={<Feedback userName="User Name" endDate="22/05/2023" onRelease={handleRelease} onCancel={handleCancel} />} /> */}
//     //           <Route path="/addusers" element={<AddUsers />} />
//     //           <Route path="*" element={<Navigate to="/projects" />} />
//     //         </Routes>
//     //       </main>
//     //     </div>
//     //   ) : (
//     //     <Routes>
//     //       <Route path="" element={<Navigate to="/login" />}  />
//     //       <Route path="/login" element={<Login onLoginSuccess={handleLoginSuccess} />} />
//     //       <Route path="*" element={<Navigate to="/login" />} />
//     //     </Routes>
//     //   )}
//     // </Router>
//   );
// }

// export default App;




// import React, { useState, useEffect } from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate,
//   useLocation,
// } from "react-router-dom";
// import Sidebar from "./components/Sidebar/Sidebar";
// import Projects from "./pages/Projects/Projects";
// import TimeSheets from "./pages/TimeSheets/TimeSheets";
// import Reports from "./pages/Reports/Reports";
// import Tasks from "./pages/Tasks/Tasks";
// import Users from "./pages/Users/Users";
// import Leaves from "./pages/Leaves/Leaves";
// import CreateProject from "./components/CreateProject/CreateProject";
// import Login from "./pages/Login/Login";
// import MasterTasks from "./pages/MasterTask/MasterTasks.js";
// import EarMark from "./pages/EarMark/EarMark.js";
// import AddNew from "./pages/EarMark/AddNew.jsx";
// import "primereact/resources/themes/saga-blue/theme.css";
// import "primereact/resources/primereact.min.css";
// import "primeicons/primeicons.css";
// import "primeflex/primeflex.css";
// import NewProject from "./pages/EarMark/NewProject.jsx";
// import RejectedTimesheet from "./pages/Users/RejectedTimesheet/RejectedTimesheet.js"
// import ApprovedTimesheet from "./pages/Users/ApprovedTimesheet/ApprovedTimesheet.js"
// import PendingTimesheet from "./pages/Users/PendingTimesheet/PendingTimesheet.js"
// import AddUsers from './components/AddUsers/AddUsers.js';

// function MainContent({ isAuthenticated, onLoginSuccess }) {
//   const location = useLocation();
//   const shouldApplyPadding = location.pathname !== "/login";

//   return (
//     <main style={{ flex: 1, padding: shouldApplyPadding ? "20px" : "0px" }}>
//       <Routes>
//         <Route
//           path="/login"
//           element={
//             isAuthenticated ? (
//               <Navigate to="/timesheets" />
//             ) : (
//               <Login onLoginSuccess={onLoginSuccess} />
//             )
//           }
//         />
//         {isAuthenticated ? (
//           <>
//             <Route path="/timesheets" element={<TimeSheets />} />
//             <Route path="/projects" element={<Projects />} />
//             <Route path="/earmark" element={<EarMark />} />
//             <Route path="/reports" element={<Reports />} />
//             <Route path="/earmark/new" element={<AddNew />} />
//             <Route path="/earmark/NewProject" element={<NewProject />} />
//             <Route path="/createtasks" element={<MasterTasks />} />
//             <Route path="/tasks" element={<Tasks />} />
//             <Route path="/users" element={<Users />} />
//             <Route path="/addusers" element={<AddUsers />} />

//             <Route path="/timesheet/rejectedtimesheet" element={<RejectedTimesheet />} />
//             <Route path="/timesheet/pendingtimesheet" element={<PendingTimesheet />} />
//             <Route path="/timesheet/Approvedtimesheet" element={<ApprovedTimesheet />} />

//             <Route path="/leaves" element={<Leaves />} />
//             <Route path="/createproject" element={<CreateProject />} />
//             <Route path="*" element={<Navigate to="/projects" />} />
//           </>
//         ) : (
//           <Route path="*" element={<Navigate to="/login" />} />
//         )}
//       </Routes>
//     </main>
//   );
// }

// function App() {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   useEffect(() => {
//     // Check if the user is authenticated on page load
//     const user = localStorage.getItem("user");
//     if (user) {
//       setIsAuthenticated(true);
//     }
//   }, []);

//   const handleLoginSuccess = () => {
//     setIsAuthenticated(true);
//   };

//   const handleLogout = () => {
//     // Remove user data from localStorage
//     localStorage.removeItem("user");
//     // Set authentication state to false
//     setIsAuthenticated(false);
//   };

//   return (
//     <Router>
//       <div className="app" style={{ display: "flex" }}>
//         {isAuthenticated && <Sidebar onLogout={handleLogout} />}
//         <MainContent
//           isAuthenticated={isAuthenticated}
//           onLoginSuccess={handleLoginSuccess}
//         />
//       </div>
//     </Router>
//   );
// }

// export default App;





import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import Projects from "./pages/Projects/Projects";
import ProjectTab from "./pages/Projects/ProjectTab.js";
import TimeSheets from "./pages/TimeSheets/TimeSheets";
import Reports from "./pages/Reports/Reports";
import Tasks from "./pages/Tasks/Tasks";
import Users from "./pages/Users/Users";
// import Leaves from "./pages/Leaves/Leaves";
import Leaves from "./pages/Leaves/Leaves.js"
import CreateProject from "./components/CreateProject/CreateProject";
import Login from "./pages/Login/Login";
import MasterTasks from "./pages/MasterTask/MasterTasks.js";
import EarMark from "./pages/EarMark/EarMark.js";
import AddNew from "./pages/EarMark/AddNew.jsx";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import NewProject from "./pages/EarMark/NewProject.jsx";
import RejectedTimesheet from "./pages/Users/RejectedTimesheet/RejectedTimesheet.js"
import ApprovedTimesheet from "./pages/Users/ApprovedTimesheet/ApprovedTimesheet.js"
import PendingTimesheet from "./pages/Users/PendingTimesheet/PendingTimesheet.js"
import AddUsers from './components/AddUsers/AddUsers.js';
import { UserContext } from "./context/UserContext.js";
import TimesheetTab from "./pages/TimeSheets/TimesheetTab.js";
import MasterTaskss from "./pages/MasterTask/MasterTaskss.js";
import Task from "./pages/Tasks/Task.js";
import EmployeeDetails from "./pages/Users/employeeHistory/EmployeeDetails.js";
import ApprovedHistory from "./pages/Users/ApprovedTimesheet/ApprovedEmployee/ApprovedHistory.js";
import UserReport from "./pages/Reports/UserReport/UserReport.js";
import RejectHistory from "./pages/Users/RejectedTimesheet/RejectHistory/RejectHistory.js"
import ProjectLog from "./pages/Projects/ProjectLog/projectLog.js";
import Bench from "./pages/Reports/admin/Bench.js";
import NotOnBench from "./pages/Reports/admin/NotOnBench.js";
import ReportByMonth from "./pages/Reports/User/ReportByMonth.js";
import ReportByWeek from "./pages/Reports/User/ReportByWeek.js";
import TimesheetAnysis from "./pages/Reports/User/TimesheetAnysis.js";
import Reminder from "./pages/Reports/User/Reminder.js";
import UserReminder from "./pages/Reports/User/UserReminder.js";
import ProjectsTask from "./pages/Projects/ProjectsTasks/ProjectsTask.js";
import ProjectTasks from "./pages/Projects/ProjectsTasks/ProjectTasks.js";
import BenchReport from "./pages/Reports/BenchReport/BenchReport.js";
import AllocationReport from "./pages/Reports/admin/Allocation/AllocationReport.js";
import ZeroToTwentyFive from "./pages/Reports/admin/Allocation/ZeroToTwentyFive.js";
import Fifty from "./pages/Reports/admin/Allocation/Fifty.js"
import Seventy from "./pages/Reports/admin/Allocation/SeventyFive.js"
import Hundred from "./pages/Reports/admin/Allocation/hundred.js"
import BenchStatusChart from "./pages/Reports/admin/Billable/Billable.js";
import BillableReport from "./pages/Reports/admin/Billable/BillableReport.js";
import NonBillableReport from "./pages/Reports/admin/Billable/NonBillableReport.js";
import Organization from "./pages/Reports/admin/Organization.js";
import BillingReport from "./pages/Reports/admin/BillingRport/BillingReport.js";
import BillingModal from "./pages/Reports/admin/BillingRport/BillingModal.js"
function MainContent({ isAuthenticated, onLoginSuccess, userHkey }) {
  const location = useLocation();
  const shouldApplyPadding = location.pathname !== "/login";

  return (
    <main style={{ flex: 1, padding: shouldApplyPadding ? "20px" : "0px" }}>
      <Routes>
        <Route
          path="/login"
          element={
            isAuthenticated ? (
              <Navigate to="/timesheets" />
            ) : (
              <Login onLoginSuccess={onLoginSuccess} />
            )
          }
        />
        {isAuthenticated ? (
          <>
            <Route
              path="/timesheets"
              element={([ "110", "111", 110, 111].includes(userHkey) ? <Users /> : <TimesheetTab />)}
            />
            <Route path="/reports" element={([ "110", "111",  110, 111].includes(userHkey) ? <Reports /> : <UserReport />)} />
            <Route path="/reports/bench" element={<Bench />} />
            <Route path="/reports/billing" element={<BillingReport />} />
            <Route path="/reports/Attendence" element={<BillingModal />} />
            <Route path="/reports/allocation" element={<AllocationReport />} />
            <Route path="/reports/utilization" element={<BenchStatusChart />} />
            <Route path="/reports/organization" element={<Organization />} />
            <Route path="/billable/report" element={<BillableReport />}/>
            <Route path="/nonbillable/report" element={<NonBillableReport />}/>
            <Route path="/0-25%" element={<ZeroToTwentyFive />} />
            <Route path="/25-50%" element={<Fifty />} />
            <Route path="/50-75%" element={<Seventy />} />
            <Route path="/75-100%" element={<Hundred />} />
            <Route path="/reports/notonbench" element={<NotOnBench />} />
            <Route path="/report/benchlist"  element= {<BenchReport />} />
            <Route path="/reports/week" element={<ReportByWeek />} />
            <Route path="/reports/month" element={<ReportByMonth />} />
            <Route path="/reports/timesheetanalysis" element={<TimesheetAnysis />} />
            <Route path="/reports/reportanalysis" element={<Reminder />} />
            <Route path="/reports/reminder" element={<UserReminder />} />
            {/* <Route path="/projectstasks" element={<ProjectsTask />} /> */}
            <Route path="/projectstasks" element={<ProjectTasks />} />
            
            <Route path="/timesheets/approve" element={<ApprovedTimesheet />} />
            <Route path="/timesheets/pending" element={<TimeSheets />} />

            <Route path="/projects" element={<ProjectTab />} />
            <Route path="/projectlog" element={<ProjectLog />} />

            <Route path="/earmark" element={<EarMark />} />
            <Route path="/earmark/new" element={<AddNew />} />
            <Route path="/earmark/NewProject" element={<NewProject />} />
            <Route path="/mastertasks" element={<MasterTaskss />} />
            {/* <Route path="/tasks" element={<Task />} /> */}
            <Route path="/users" element={<Users />} />
            <Route path="/addusers" element={<AddUsers />} />

            <Route path="/timesheet/rejectedtimesheet" element={<RejectedTimesheet />} />
            <Route path="/timesheet/pendingtimesheet" element={<PendingTimesheet />} />
            <Route path="/timesheet/Approvedtimesheet" element={<ApprovedTimesheet />} />
            <Route
              path="/timesheets/employee/:employeeName/:weekRange/:selectedProject"
              element={<EmployeeDetails />}
            />

            <Route path="/timesheets/approvedemployee/:employeeName/:weekRange/:selectedProject"
              element={<ApprovedHistory />} />

            <Route path="/timesheets/rejectemployee/:employeeName/:weekRange/:selectedProject"
              element={<RejectHistory />} />

            <Route path="/leaves" element={<Leaves />} />
            <Route path="/createproject" element={<CreateProject />} />
            <Route path="*" element={<Navigate to="/projects" />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/login" />} />
        )}
      </Routes>
    </main>
  );
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    // Check if the user is authenticated on page load
    const user = localStorage.getItem("user");
    if (user) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    // Remove user data from localStorage
    localStorage.removeItem("user");
    // Set authentication state to false
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <div className="app" style={{ display: "flex" }}>
        {isAuthenticated && <Sidebar onLogout={handleLogout} />}
        <MainContent
          isAuthenticated={isAuthenticated}
          onLoginSuccess={handleLoginSuccess}
          userHkey={user?.Data?.hkey}
        />
      </div>
    </Router>
  );
}

export default App;