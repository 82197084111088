// import React from "react";
// import { Image } from "primereact/image";
// import logo from "../../../assets/IGS.png";
// import { Menubar } from "primereact/menubar";
// // import ReminderChart from "../UserReport/charts/TimesheetApprovalChart";
// import ReminderCharts from "../User/ReminderCharts"
// export default function UserReport() {
//   const start = (
//     <div>
//       <p
//         style={{
//           color: "#00000080",
//           fontWeight: "bold",
//           fontSize: "22px",
//           margin: "0px",
//           marginBottom: "5PX",
//         }}
//       >
//         Reports
//       </p>
//     </div>
//   );
//   const end = (
//     <div style={{ paddingRight: "15px" }}>
//       <Image src={logo} height="60px" width="60px" />
//     </div>
//   );
//   return (
//     <div>
//       <div className="card">
//         <Menubar start={start} end={end} />
//       </div>
//       <div
//         style={{
//           backgroundColor: "#f8f9fa",
//           height: 'calc(100vh - 132px)',
//           boxShadow: "0 10px 10px -5px rgba(0, 0, 0, 0.3)",
//           border: "1px solid rgba(128, 128, 128, 0.211)",
//           marginTop: "10px",
//           overflow: "scroll",
//           position: "relative",
//         }}>
//         <div 
//          style={{
//           display: "flex",
//           justifyContent: "space-around",
//           alignItems: "center",
//           margin: "5px",
//           gap:'10px'
//         }}>
//         <div
//             style={{
//               width: "50%",
//               boxSizing: "border-box",
//               paddingBottom: "20px",
//             }}
//           >
//           </div>
//           <div
//             style={{
//               width: "50%",
//               boxSizing: "border-box",
//               paddingBottom: "20px",
//             }}
//           >
//             <ReminderCharts style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }} />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }



// import React, { useEffect, useRef } from 'react';
// import * as echarts from 'echarts';
// import { Image } from 'primereact/image';
// import logo from "../../../assets/IGS.png";
// import { Menubar } from 'primereact/menubar';
// const ProjectChart = () => {
//     const chartRef = useRef(null);  // Ref to hold the chart DOM element

//     useEffect(() => {
//         // Fetch data from the API
//         const fetchData = async () => {
//             try {
//                 const response = await fetch('http://192.168.29.195:5004/api/v1/admin/reports/fetchProjectsByEmpId/E01550');
//                 const data = await response.json();

//                 if (data.status === 200) {
//                     // Transform the API data to match the chart format
//                     const chartData = data.projects.map(project => ({
//                         name: project.projectName,
//                         value: project.totalHours
//                     }));

//                     // Initialize the chart
//                     const chartInstance = echarts.init(chartRef.current);

//                     // Chart configuration
//                     const option = {
//                         tooltip: {
//                             trigger: 'item',
//                             formatter: '{b}: {c} hours',  // Customize tooltip to show project name and total hours
//                         },
//                         legend: {
//                             top: 'bottom'
//                         },
//                         toolbox: {
//                             show: true,
//                             feature: {
//                                 mark: { show: true },
//                                 dataView: { show: true, readOnly: false },
//                                 restore: { show: true },
//                                 saveAsImage: { show: true }
//                             }
//                         },
//                         series: [
//                             {
//                                 name: 'Project Hours',
//                                 type: 'pie',
//                                 radius: '80%',
//                                 center: ['50%', '50%'],
//                                 roseType: 'area',
//                                 itemStyle: {
//                                     borderRadius: 8
//                                 },
//                                 label: {
//                                     show: true,  // Display label
//                                     position: 'inside',  // Position the label inside the slice
//                                     formatter: '{c} hours',  // Format the label with project name and total hours
//                                     fontSize: 12,  // Customize the font size
//                                     color: '#fff',  // Set the label color to white for better contrast
//                                 },
//                                 data: chartData  // Set the transformed data
//                             }
//                         ]
//                     };

//                     // Set the options to the chart instance
//                     chartInstance.setOption(option);

//                     // Cleanup on component unmount
//                     return () => {
//                         chartInstance.dispose();
//                     };
//                 }
//             } catch (error) {
//                 console.error('Error fetching project data:', error);
//             }
//         };

//         fetchData();
//     }, []);


//     const start = (
//         <div>
//             <p
//                 style={{
//                     color: "#00000080",
//                     fontWeight: "bold",
//                     fontSize: "22px",
//                     margin: "0px",
//                     marginBottom: "5PX",
//                 }}
//             >
//                 Project Analysis
//             </p>
//         </div>
//     );
//     const end = (
//         <div style={{ paddingRight: "15px" }}>
//             <Image src={logo} height="60px" width="60px" />
//         </div>
//     );

//     return (
//         <div>
//             <div className='card'>
//                 <Menubar start={start} end={end} />
//             </div>
//             <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 132px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
//                 <div>
//                     <div
//                         ref={chartRef}
//                         style={{ width: '100%', height: '500px' }}  // Set the chart container size
//                     />
//                 </div>

//             </div>

//         </div>
//         // The chart will render inside this div

//     );
// };

// export default ProjectChart;


// import React, { useEffect, useRef } from 'react';
// import * as echarts from 'echarts';
// import { Image } from 'primereact/image';
// import logo from "../../../assets/IGS.png";
// import { Menubar } from 'primereact/menubar';

// const ProjectChart = () => {
//     const chartRef = useRef(null);  // Ref to hold the chart DOM element

//     useEffect(() => {
//         // Fetch data from the API
//         const fetchData = async () => {
//             try {
//                 const response = await fetch('http://192.168.29.195:5004/api/v1/admin/reports/fetchProjectsByEmpId/E01550');
//                 const data = await response.json();

//                 if (data.status === 200) {
//                     // Transform the API data to match the chart format
//                     const chartData = data.projects.map(project => ({
//                         name: project.projectName,
//                         value: project.totalHours
//                     }));

//                     // Initialize the chart
//                     const chartInstance = echarts.init(chartRef.current);

//                     // Chart configuration
//                     const option = {
//                         tooltip: {
//                             trigger: 'item',
//                             formatter: '{b}: {c} hours',  // Customize tooltip to show project name and total hours
//                         },
//                         legend: {
//                             top: 'bottom'
//                         },
//                         toolbox: {
//                             show: true,
//                             feature: {
//                                 mark: { show: true },
//                                 dataView: { show: true, readOnly: false },
//                                 restore: { show: true },
//                                 saveAsImage: { show: true }
//                             }
//                         },
//                         series: [
//                             {
//                                 name: 'Project Hours',
//                                 type: 'pie',
//                                 radius: ['40%', '70%'],
//                                 center: ['50%', '50%'],
//                                 roseType: 'area',
//                                 itemStyle: {
//                                     borderRadius: 8
//                                 },
//                                 label: {
//                                     show: true,
//                                     position: 'inside',  // Display inside label
//                                     formatter: '{c} hours',  // Show total hours inside the slice
//                                     fontSize: 12,
//                                     color: '#fff',  // Set the label color for better contrast
//                                 },
//                                 labelLine: {
//                                     show: true,  // Enable the label line
//                                     length: 10,  // Length of the first segment
//                                     length2: 15,  // Length of the second segment
//                                     smooth: true,  // Smooth out the label line
//                                 },
//                                 data: chartData  // Set the transformed data
//                             },
//                             {
//                                 name: 'Project Hours Outside',
//                                 type: 'pie',
//                                 radius: [50,200],
//                                 center: ['50%', '50%'],
//                                 roseType: 'area',
//                                 label: {
//                                     show: true,
//                                     position: 'outside',  // Display outside label
//                                     formatter: '{b}',  // Show project name outside
//                                     fontSize: 12,
//                                     color: '#000',  // Set the label color
//                                 },
//                                 labelLine: {
//                                     show: true,  // Enable the label line
//                                     length: 10,  // Length of the first segment
//                                     length2: 15,  // Length of the second segment
//                                     smooth: true,  // Smooth out the label line
//                                 },
//                                 data: chartData  // Set the transformed data
//                             }
//                         ]
//                     };

//                     // Set the options to the chart instance
//                     chartInstance.setOption(option);

//                     // Cleanup on component unmount
//                     return () => {
//                         chartInstance.dispose();
//                     };
//                 }
//             } catch (error) {
//                 console.error('Error fetching project data:', error);
//             }
//         };

//         fetchData();
//     }, []);

//     const start = (
//         <div>
//             <p
//                 style={{
//                     color: "#00000080",
//                     fontWeight: "bold",
//                     fontSize: "22px",
//                     margin: "0px",
//                     marginBottom: "5PX",
//                 }}
//             >
//                 Project Analysis
//             </p>
//         </div>
//     );
//     const end = (
//         <div style={{ paddingRight: "15px" }}>
//             <Image src={logo} height="60px" width="60px" />
//         </div>
//     );

//     return (
//         <div>
//             <div className='card'>
//                 <Menubar start={start} end={end} />
//             </div>
//             <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 132px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
//                 <div>
//                     <div
//                         ref={chartRef}
//                         style={{ width: '100%', height: '500px' }}  // Set the chart container size
//                     />
//                 </div>

//             </div>

//         </div>
//     );
// };

// export default ProjectChart;


// import React, { useEffect, useRef, useContext } from 'react';
// import * as echarts from 'echarts';
// import { Image } from 'primereact/image';
// import logo from "../../../assets/IGS.png";
// import { Menubar } from 'primereact/menubar';
// import { UserContext } from '../../../context/UserContext';

// const ProjectChart = () => {
//     const { user } = useContext(UserContext)

//     const chartRef = useRef(null);  // Ref to hold the chart DOM element

//     useEffect(() => {
//         // Fetch data from the API
//         const fetchData = async () => {
//             try {
//                 const response = await fetch(
//                     // 'http://192.168.29.195:5004/api/v1/admin/reports/fetchProjectsByEmpId/E01550'
//                     `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/fetchProjectsByEmpId/${user.Data.empId}`
//                 );
//                 const data = await response.json();

//                 if (data.status === 200) {
//                     // Transform the API data to match the chart format
//                     const chartData = data.projects.map(project => ({
//                         name: project.projectName,
//                         value: project.totalHours
//                     }));

//                     // Initialize the chart
//                     const chartInstance = echarts.init(chartRef.current);

//                     // Chart configuration
//                     const option = {
//                         tooltip: {
//                             trigger: 'item',
//                             formatter: '{b}: {c} hours',  // Customize tooltip to show project name and total hours
//                         },
//                         legend: {
//                             show: false,  // Hide the legend
//                         },
//                         toolbox: {
//                             show: true,
//                             feature: {
//                                 mark: { show: true },
//                                 dataView: { show: true, readOnly: false },
//                                 restore: { show: true },
//                                 saveAsImage: { show: true }
//                             }
//                         },
//                         series: [
//                             {
//                                 name: 'Project Hours',
//                                 type: 'pie',
//                                 radius: [40, 200],
//                                 center: ['50%', '50%'],
//                                 roseType: 'area',
//                                 itemStyle: {
//                                     borderRadius: 8
//                                 },
//                                 label: {
//                                     show: true,
//                                     position: 'inside',  // Display inside label
//                                     formatter: '{c} hours',  // Show total hours inside the slice
//                                     fontSize: 12,
//                                     color: '#fff',  // Set the label color for better contrast
//                                 },
//                                 labelLine: {
//                                     show: true,  // Enable the label line
//                                     length: 10,  // Length of the first segment
//                                     length2: 15,  // Length of the second segment
//                                     smooth: true,  // Smooth out the label line
//                                 },
//                                 data: chartData  // Set the transformed data
//                             },
//                             {
//                                 name: 'Project Hours Outside',
//                                 type: 'pie',
//                                 radius: [60, 220],  // Adjust radius to ensure it's outside
//                                 center: ['50%', '50%'],
//                                 roseType: 'area',
//                                 label: {
//                                     show: true,
//                                     position: 'outside',  // Display outside label
//                                     formatter: '{b}',  // Show project name outside
//                                     fontSize: 12,
//                                     color: '#000',  // Set the label color
//                                 },
//                                 labelLine: {
//                                     show: true,  // Enable the label line
//                                     length: 10,  // Length of the first segment
//                                     length2: 15,  // Length of the second segment
//                                     smooth: true,  // Smooth out the label line
//                                 },
//                                 data: chartData  // Set the transformed data
//                             }
//                         ]
//                     };

//                     // Set the options to the chart instance
//                     chartInstance.setOption(option);

//                     // Cleanup on component unmount
//                     return () => {
//                         chartInstance.dispose();
//                     };
//                 }
//             } catch (error) {
//                 console.error('Error fetching project data:', error);
//             }
//         };

//         fetchData();
//     }, []);

//     const start = (
//         <div>
//             <p
//                 style={{
//                     color: "#00000080",
//                     fontWeight: "bold",
//                     fontSize: "22px",
//                     margin: "0px",
//                     marginBottom: "5PX",
//                 }}
//             >
//                 Project Analysis
//             </p>
//         </div>
//     );
//     const end = (
//         <div style={{ paddingRight: "15px" }}>
//             <Image src={logo} height="60px" width="60px" />
//         </div>
//     );

//     return (
//         <div>
//             <div className='card'>
//                 <Menubar start={start} end={end} />
//             </div>
//             <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 132px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
//                 <div>
//                     <div
//                         ref={chartRef}
//                         style={{ width: '100%', height: '500px' }}  // Set the chart container size
//                     />
//                 </div>

//             </div>

//         </div>
//     );
// };

// export default ProjectChart;

// import React, { useEffect, useRef, useState, useContext } from 'react';
// import * as echarts from 'echarts';
// import { Image } from 'primereact/image';
// import logo from "../../../assets/IGS.png";
// import { Menubar } from 'primereact/menubar';
// import { UserContext } from '../../../context/UserContext';

// const ProjectChart = () => {
//     const { user } = useContext(UserContext);
//     const chartRef = useRef(null);  // Ref to hold the chart DOM element
//     const [chartData, setChartData] = useState([]);  // State to hold the chart data

//     useEffect(() => {
//         // Fetch data from the API
//         const fetchData = async () => {
//             try {
//                 const response = await fetch(
//                     `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/fetchProjectsByEmpId/${user.Data.empId}`
//                 );
//                 const data = await response.json();

//                 if (data.status === 200) {
//                     // Transform the API data to match the chart format
//                     const chartData = data.projects.map(project => ({
//                         name: project.projectName,
//                         value: project.totalHours
//                     }));
                    
//                     // Set chart data to state
//                     setChartData(chartData);

//                     if (chartData.length > 0) {
//                         // Initialize the chart if there is data
//                         const chartInstance = echarts.init(chartRef.current);

//                         // Chart configuration
//                         const option = {
//                             tooltip: {
//                                 trigger: 'item',
//                                 formatter: '{b}: {c} hours',  // Customize tooltip to show project name and total hours
//                             },
//                             legend: {
//                                 show: false,  // Hide the legend
//                             },
//                             toolbox: {
//                                 show: true,
//                                 feature: {
//                                     mark: { show: true },
//                                     dataView: { show: true, readOnly: false },
//                                     restore: { show: true },
//                                     saveAsImage: { show: true }
//                                 }
//                             },
//                             series: [
//                                 {
//                                     name: 'Project Hours',
//                                     type: 'pie',
//                                     radius: [40, 200],
//                                     center: ['50%', '50%'],
//                                     roseType: 'area',
//                                     itemStyle: {
//                                         borderRadius: 8
//                                     },
//                                     label: {
//                                         show: true,
//                                         position: 'inside',  // Display inside label
//                                         formatter: '{c} hours',  // Show total hours inside the slice
//                                         fontSize: 12,
//                                         color: '#fff',  // Set the label color for better contrast
//                                     },
//                                     labelLine: {
//                                         show: true,  // Enable the label line
//                                         length: 10,  // Length of the first segment
//                                         length2: 15,  // Length of the second segment
//                                         smooth: true,  // Smooth out the label line
//                                     },
//                                     data: chartData  // Set the transformed data
//                                 },
//                                 {
//                                     name: 'Project Hours Outside',
//                                     type: 'pie',
//                                     radius: [60, 220],  // Adjust radius to ensure it's outside
//                                     center: ['50%', '50%'],
//                                     roseType: 'area',
//                                     label: {
//                                         show: true,
//                                         position: 'outside',  // Display outside label
//                                         formatter: '{b}',  // Show project name outside
//                                         fontSize: 12,
//                                         color: '#000',  // Set the label color
//                                     },
//                                     labelLine: {
//                                         show: true,  // Enable the label line
//                                         length: 10,  // Length of the first segment
//                                         length2: 15,  // Length of the second segment
//                                         smooth: true,  // Smooth out the label line
//                                     },
//                                     data: chartData  // Set the transformed data
//                                 }
//                             ]
//                         };

//                         // Set the options to the chart instance
//                         chartInstance.setOption(option);

//                         // Cleanup on component unmount
//                         return () => {
//                             chartInstance.dispose();
//                         };
//                     }
//                 }
//             } catch (error) {
//                 console.error('Error fetching project data:', error);
//             }
//         };

//         fetchData();
//     }, [user.Data.empId]);

//     const start = (
//         <div>
//             <p
//                 style={{
//                     color: "#00000080",
//                     fontWeight: "bold",
//                     fontSize: "22px",
//                     margin: "0px",
//                     marginBottom: "5PX",
//                 }}
//             >
//                 Project Analysis
//             </p>
//         </div>
//     );
//     const end = (
//         <div style={{ paddingRight: "15px" }}>
//             <Image src={logo} height="60px" width="60px" />
//         </div>
//     );

//     // Determine if there is any chart data
//     const hasData = chartData && chartData.length > 0;

//     return (
//         <div>
//             <div className='card'>
//                 <Menubar start={start} end={end} />
//             </div>
//             <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 132px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
//                 {/* Conditional Rendering */}
//                 {hasData ? (
//                     <div
//                         ref={chartRef}
//                         style={{ width: '100%', height: '500px' }}  // Set the chart container size
//                     />
//                 ) : (
//                     <div style={{ height: 'calc(100vh - 260px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                     <p style={{ color: '#00000080', fontWeight: 'bold', fontSize: '24px' }}>No Project Report</p>
//                   </div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default ProjectChart;

import React, { useEffect, useRef, useState, useContext } from 'react';
import * as echarts from 'echarts';
import { Image } from 'primereact/image';
import logo from "../../../assets/IGS.png";
import { Menubar } from 'primereact/menubar';
import { UserContext } from '../../../context/UserContext';

const ProjectChart = () => {
    const { user } = useContext(UserContext);
    const chartRef = useRef(null);  // Ref to hold the chart DOM element
    const [chartData, setChartData] = useState([]);  // State to hold the chart data
    const chartInstanceRef = useRef(null); // To keep track of chart instance

    useEffect(() => {
        // Fetch data from the API
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/fetchProjectsByEmpId/${user.Data.empId}`
                );
                const data = await response.json();

                if (data.status === 200) {
                    // Transform the API data to match the chart format
                    const chartData = data.projects.map(project => ({
                        name: project.projectName,
                        value: project.totalHours
                    }));
                    
                    // Set chart data to state
                    setChartData(chartData);
                }
            } catch (error) {
                console.error('Error fetching project data:', error);
            }
        };

        fetchData();
    }, [user.Data.empId]);

    useEffect(() => {
        if (chartData.length > 0 && chartRef.current) {
            // Initialize the chart if the chartRef is available and chartData is present
            const chartInstance = echarts.init(chartRef.current);

            // Save the chart instance in ref to dispose of it later
            chartInstanceRef.current = chartInstance;

            // Chart configuration
            const option = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}: {c} hours',  // Customize tooltip to show project name and total hours
                },
                legend: {
                    show: false,  // Hide the legend
                },
                toolbox: {
                    show: true,
                    feature: {
                        mark: { show: true },
                        dataView: { show: true, readOnly: false },
                        restore: { show: true },
                        saveAsImage: { show: true }
                    }
                },
                series: [
                    {
                        name: 'Project Hours',
                        type: 'pie',
                        radius: [40, 200],
                        center: ['50%', '50%'],
                        roseType: 'area',
                        itemStyle: {
                            borderRadius: 8
                        },
                        label: {
                            show: true,
                            position: 'inside',  // Display inside label
                            formatter: '{c} hours',  // Show total hours inside the slice
                            fontSize: 12,
                            color: '#fff',  // Set the label color for better contrast
                        },
                        labelLine: {
                            show: true,  // Enable the label line
                            length: 10,  // Length of the first segment
                            length2: 15,  // Length of the second segment
                            smooth: true,  // Smooth out the label line
                        },
                        data: chartData  // Set the transformed data
                    },
                    {
                        name: 'Project Hours Outside',
                        type: 'pie',
                        radius: [60, 220],  // Adjust radius to ensure it's outside
                        center: ['50%', '50%'],
                        roseType: 'area',
                        label: {
                            show: true,
                            position: 'outside',  // Display outside label
                            formatter: '{b}',  // Show project name outside
                            fontSize: 12,
                            color: '#000',  // Set the label color
                        },
                        labelLine: {
                            show: true,  // Enable the label line
                            length: 10,  // Length of the first segment
                            length2: 15,  // Length of the second segment
                            smooth: true,  // Smooth out the label line
                        },
                        data: chartData  // Set the transformed data
                    }
                ]
            };

            // Set the options to the chart instance
            chartInstance.setOption(option);
        }

        // Cleanup on component unmount
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.dispose();
            }
        };
    }, [chartData]);

    const start = (
        <div>
            <p
                style={{
                    color: "#00000080",
                    fontWeight: "bold",
                    fontSize: "22px",
                    margin: "0px",
                    marginBottom: "5PX",
                }}
            >
                Project Analysis
            </p>
        </div>
    );
    const end = (
        <div style={{ paddingRight: "15px" }}>
            <Image src={logo} height="60px" width="60px" />
        </div>
    );

    // Determine if there is any chart data
    const hasData = chartData && chartData.length > 0;

    return (
        <div>
            <div className='card'>
                <Menubar start={start} end={end} />
            </div>
            <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 132px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
                {/* Conditional Rendering */}
                {hasData ? (
                    <div
                        ref={chartRef}
                        style={{ width: '100%', height: '500px' }}  // Set the chart container size
                    />
                ) : (
                    <div style={{ height: 'calc(100vh - 260px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <p style={{ color: '#00000080', fontWeight: 'bold', fontSize: '24px' }}>No Project Report</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProjectChart;
