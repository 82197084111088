
import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Image } from "primereact/image";
import logo from "../../assets/IGS.png";
import { Menubar } from "primereact/menubar";

const ProjectTable = () => {
  const [data, setData] = useState([]); // State to hold API data
  const [selectedProject, setSelectedProject] = useState(null);
  const navigate = useNavigate();

  // Fetch data from API
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_EARMARK_URL}${process.env.REACT_APP_EARMARK_ENDPOINT}/earmark/employees`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setSelectedProject(data[0]); // Set initial selected project
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const projectOptions = data.map((project) => ({
    label: project.project_name,
    value: project.project_name,
  }));

  const handleProjectChange = (e) => {
    setSelectedProject(
      data.find((project) => project.project_name === e.value)
    );
  };

  const getAllEmployees = (project) => {
    // Flatten the nested employee structure
    return project.employees.flatMap((group) => group.employees);
  };

  const start = (
    <div>
      <p style={{ color: "#00000080", fontWeight: "bold", fontSize: "22px", margin: "0px", marginBottom: '5PX' }}>
        EarMarked Projects
      </p>
    </div>
  )
  const end = (
    <div style={{ paddingRight: '15px' }}>
      <Image src={logo} height='60px' width='60px' />
    </div>
  )

  return (
    <div>
      <div className="card">
        <Menubar start={start} end={end} />
      </div>

      <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 132px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px' }}>
        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", gap: "30px", padding: "10px", alignItems: 'center' }}>
          <div>
            <Button style={{ background: "#29295FFF", border: "1px solid #29295FFF" }}
              onClick={() => navigate("/earmark/NewProject")}
              label="Add Project"
            />
          </div>
          <div>
            <Button style={{ background: "#29295FFF", border: "1px solid #29295FFF" }}
              onClick={() => navigate("/earmark/new")}
              label="Employees Management"
            />
          </div>
        </div>

        {data.length === 0 ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 300px)' }}>
            <h1 style={{ textAlign: 'center', color: '#888' }}>No Earmarked Found</h1>
          </div>
        ) : (
          selectedProject && (
            <div
              style={{
                marginTop: "20px",
                border: "1px solid #EBEBEB",
                padding: "10px",
                borderRadius: "5px",
                boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.2)",
                margin: '10px'
              }}
            >
              <div style={{ display: "flex", justifyContent: "flex-start", gap: "70px", alignItems: "center" }}>
                <div>
                  Select Project:
                  <span style={{ color: "#EFB034FF", paddingLeft: '10px' }}>
                    <Dropdown
                      value={selectedProject?.project_name}
                      options={projectOptions}
                      onChange={handleProjectChange}
                      placeholder="Select a Project"
                      style={{ width: "300px" }}
                    />
                  </span>
                </div>
                <p style={{ fontWeight: "bold", color: "#00000080", fontSize: "16px" }}>
                  Project Cost: {selectedProject.cost}
                </p>
                <p style={{ fontWeight: "bold", color: "#00000080", fontSize: "16px" }}>
                  Start Date: {selectedProject.startDate}
                </p>
                <p style={{ fontWeight: "bold", color: "#00000080", fontSize: "16px" }}>
                  End Date: {selectedProject.endDate}
                </p>
              </div>
              <div
                style={{
                  borderRadius: "5px",
                  overflow: "hidden",
                  boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.2)",
                  marginTop: "20px",
                }}
              >
                <DataTable value={getAllEmployees(selectedProject)}>
                  <Column field="name" header="Name" />
                  <Column field="role" header="Role" />
                  <Column field="startDate" header="Start Date" />
                  <Column field="endDate" header="End Date" />
                  <Column field="salary" header="Salary" />
                  <Column field="margin" header="Margin" />
                </DataTable>
              </div>
            </div>
          )
        )}
      </div>

    </div>
  );
};

export default ProjectTable;