// import React, { useEffect, useRef, useState } from "react";
// import { Panel } from "primereact/panel";
// import { Chart } from "chart.js/auto";

// export default function TimesheetChartByWeek() {
//   const chartRef = useRef(null);
//   const [chartData, setChartData] = useState({
//     labels: [], // Week ranges
//     datasets: [
//       {
//         label: "Rejected Timesheets",
//         data: [], // Rejected timesheet counts
//         backgroundColor: "#FF6384", // Color for rejected timesheets
//       },
//     ],
//   });
//   const [tooltips, setTooltips] = useState([]); // Tooltips to show project and task names

//   useEffect(() => {
//     const fetchTimesheetData = async () => {
//       try {
//         const response = await fetch(
//           "http://192.168.1.20:5004/api/v1/admin/reports/fetchEmployeeTimesheetsByWeek/E04105"
//         );
//         const result = await response.json();

//         const weekLabels = [];
//         const rejectedData = [];
//         const projectTooltips = [];

//         // Process the rejected timesheets for each week
//         result.data.forEach((weekData) => {
//           weekLabels.push(weekData.week); // Add week range as label
//           let rejectedCount = 0; // Initialize rejected count for each week
//           let tooltipTextArray = []; // Array to store tooltips for each task

//           // Iterate over rejected timesheets in the current week
//           weekData.rejected.forEach((rejected) => {
//             rejectedCount++; // Increment rejected timesheet count
//             const tooltipText = `${rejected.project}: ${rejected.task}`; // Combine project and task name
//             tooltipTextArray.push(tooltipText); // Store each rejected timesheet's project/task name
//           });

//           rejectedData.push(rejectedCount); // Add total rejected timesheet count for the week
//           projectTooltips.push(tooltipTextArray.join(', ')); // Store combined tooltips for the week
//         });

//         setChartData({
//           labels: weekLabels, // Set week ranges as labels
//           datasets: [
//             {
//               label: "Rejected Timesheets",
//               data: rejectedData, // Total rejected timesheet counts
//               backgroundColor: "#FF6384",
//             },
//           ],
//         });

//         setTooltips(projectTooltips); // Set tooltips with project and task names for each week
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchTimesheetData();
//   }, []);

//   useEffect(() => {
//     const ctx = chartRef.current.getContext("2d");

//     const myChart = new Chart(ctx, {
//       type: "bar", // Bar chart for rejected timesheets
//       data: chartData,
//       options: {
//         responsive: true,
//         plugins: {
//           tooltip: {
//             callbacks: {
//               // Custom tooltip to show project name and task name for each week
//               label: function (context) {
//                 return tooltips[context.dataIndex]; // Display corresponding tooltip text for that week
//               },
//             },
//           },
//           legend: {
//             position: "top",
//           },
//         },
//         scales: {
//           x: {
//             beginAtZero: true,
//           },
//           y: {
//             beginAtZero: true,
//             stepSize: 1, // Adjust step size for clearer counts
//           },
//         },
//       },
//     });

//     return () => {
//       myChart.destroy();
//     };
//   }, [chartData, tooltips]);

//   return (
//     <Panel
//       header="Rejected Timesheets by Week and Project"
//       style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}
//     >
//       <canvas ref={chartRef} />
//     </Panel>
//   );
// }


// // import React, { useEffect, useRef, useState } from "react";
// // import { Panel } from "primereact/panel";
// // import { Chart } from "chart.js/auto";

// // export default function TimesheetChartByWeek() {
// //   const chartRef = useRef(null);
// //   const [chartData, setChartData] = useState({
// //     labels: [], // Week ranges
// //     datasets: [
// //       {
// //         label: "Rejected Timesheets",
// //         data: [], // Rejected timesheet counts
// //         backgroundColor: "#FF6384", // Color for rejected timesheets
// //       },
// //     ],
// //   });
// //   const [tooltips, setTooltips] = useState([]); // Tooltips to show project and task names

// //   useEffect(() => {
// //     const fetchTimesheetData = async () => {
// //       try {
// //         const response = await fetch(
// //           "http://localhost:5004/api/v1/admin/reports/fetchEmployeeTimesheetsByWeek/E04105"
// //         );
// //         const result = await response.json();

// //         const weekLabels = [];
// //         const rejectedData = [];
// //         const projectTooltips = [];

// //         // Process the rejected timesheets for each week
// //         result.data.forEach((weekData) => {
// //           weekLabels.push(weekData.week); // Add week range as label
// //           let rejectedCount = 0; // Initialize rejected count for each week

// //           // Iterate over rejected timesheets in the current week
// //           weekData.rejected.forEach((rejected) => {
// //             rejectedCount++; // Increment rejected timesheet count
// //             const tooltipText = `${rejected.project}: ${rejected.task}`; // Combine project and task name
// //             projectTooltips.push(tooltipText); // Add tooltip text
// //           });

// //           rejectedData.push(rejectedCount); // Add total rejected timesheet count for the week
// //         });

// //         setChartData({
// //           labels: weekLabels, // Set week ranges as labels
// //           datasets: [
// //             {
// //               label: "Rejected Timesheets",
// //               data: rejectedData, // Total rejected timesheet counts
// //               backgroundColor: "#FF6384",
// //             },
// //           ],
// //         });

// //         setTooltips(projectTooltips); // Set tooltips with project and task names
// //       } catch (error) {
// //         console.error("Error fetching data:", error);
// //       }
// //     };

// //     fetchTimesheetData();
// //   }, []);

// //   useEffect(() => {
// //     const ctx = chartRef.current.getContext("2d");

// //     const myChart = new Chart(ctx, {
// //       type: "bar", // Bar chart for rejected timesheets
// //       data: chartData,
// //       options: {
// //         responsive: true,
// //         plugins: {
// //           tooltip: {
// //             callbacks: {
// //               // Custom tooltip to show project name and task name
// //               label: function (context) {
// //                 return tooltips[context.dataIndex]; // Display corresponding tooltip text
// //               },
// //             },
// //           },
// //           legend: {
// //             position: "top",
// //           },
// //         },
// //         scales: {
// //           x: {
// //             beginAtZero: true,
// //           },
// //           y: {
// //             beginAtZero: true,
// //           },
// //         },
// //       },
// //     });

// //     return () => {
// //       myChart.destroy();
// //     };
// //   }, [chartData, tooltips]);

// //   return (
// //     <Panel
// //       header="Rejected Timesheets by Week and Project"
// //       style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}
// //     >
// //       <canvas ref={chartRef} />
// //     </Panel>
// //   );
// // }




// import React, { useState, useEffect, useRef } from 'react';
// import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
// import { Panel } from 'primereact/panel';
// import { Dropdown } from 'primereact/dropdown';

// // Register Chart.js components
// ChartJS.register(Title, Tooltip, Legend, ArcElement);

// const TimesheetChart = () => {
//   const [data, setData] = useState([]);
//   const [selectedWeek, setSelectedWeek] = useState(null);
//   const chartRef = useRef(null);
//   const chartInstanceRef = useRef(null);

//   useEffect(() => {
//     // Fetch the data from the API
//     fetch('http://192.168.29.195:5004/api/v1/admin/reports/fetchEmployeeTimesheetsByWeek/E04105')
//       .then(response => response.json())
//       .then(jsonData => {
//         setData(jsonData.data);
//         if (jsonData.data.length > 0) {
//           setSelectedWeek(jsonData.data[0].week); // Set the default selected week
//         }
//       });
//   }, []);

//   useEffect(() => {
//     if (data.length > 0 && selectedWeek) {
//       const selectedData = data.find(d => d.week === selectedWeek);
//       if (selectedData) {
//         const { counts } = selectedData;

//         // Destroy previous chart instance if it exists
//         if (chartInstanceRef.current) {
//           chartInstanceRef.current.destroy();
//         }

//         // Create the chart
//         const ctx = chartRef.current.getContext('2d');
//         chartInstanceRef.current = new ChartJS(ctx, {
//           type: 'pie',
//           data: {
//             labels: ['Pending', 'Submitted', 'Approved', 'Rejected'],
//             datasets: [{
//               data: [
//                 counts.pending,
//                 counts.submitted,
//                 counts.approved,
//                 counts.rejected
//               ],
//               backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF5722'],
//             }]
//           },
//           options: {
//             responsive: true,
//             plugins: {
//               legend: {
//                 position: 'top',
//               },
//               tooltip: {
//                 callbacks: {
//                   label: function(context) {
//                     let label = context.label || '';
//                     if (context.raw !== null) {
//                       label += `: ${context.raw}`;
//                     }
//                     return label;
//                   }
//                 }
//               }
//             }
//           }
//         });
//       }
//     }
//   }, [data, selectedWeek]);

//   return (
//     <Panel header="Timesheet Analytics by Week"
//     style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}>
//       <div style={{ marginBottom: '20px' }}>
//         <Dropdown
//           value={selectedWeek}
//           options={data.map(d => ({ label: d.week, value: d.week }))}
//           onChange={(e) => setSelectedWeek(e.value)}
//           placeholder="Select a week"
//         />
//       </div>
//       <div style={{ width: "300px", height: "300px", margin: "0 auto" }}>
//           <canvas ref={chartRef} style={{ width: "100%", height: "100%" }}></canvas>
//         </div>
//     </Panel>
//   );
// };

// export default TimesheetChart;

import React, { useState, useEffect, useRef, useContext } from 'react';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Panel } from 'primereact/panel';
import { Dropdown } from 'primereact/dropdown';
import { UserContext } from "../../../../context/UserContext";


// Register Chart.js components
ChartJS.register(Title, Tooltip, Legend, ArcElement);

const TimesheetChart = () => {
  const { user } = useContext(UserContext)
  const [data, setData] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    // Fetch the data from the API
    fetch(
      // 'http://192.168.29.195:5004/api/v1/admin/reports/fetchEmployeeTimesheetsByWeek/E04105'
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/fetchEmployeeTimesheetsByWeek/${user.Data.empId}`
    )
      .then(response => response.json())
      .then(jsonData => {
        if (jsonData.data && jsonData.data.length > 0) {
          setData(jsonData.data);
          setSelectedWeek(jsonData.data[0].week); // Set the default selected week
        } else {
          setData([]); // Set an empty array if no data is found
        }
      })
      .catch(error => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    if (data.length > 0 && selectedWeek) {
      const selectedData = data.find(d => d.week === selectedWeek);
      if (selectedData) {
        const { counts } = selectedData;

        // Destroy previous chart instance if it exists
        if (chartInstanceRef.current) {
          chartInstanceRef.current.destroy();
        }

        // Create the chart
        const ctx = chartRef.current.getContext('2d');
        chartInstanceRef.current = new ChartJS(ctx, {
          type: 'pie',
          data: {
            labels: ['Pending', 'Submitted', 'Approved', 'Rejected'],
            datasets: [{
              data: [
                counts.pending,
                counts.submitted,
                counts.approved,
                counts.rejected
              ],
              backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF5722'],
            }]
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              tooltip: {
                callbacks: {
                  label: function(context) {
                    let label = context.label || '';
                    if (context.raw !== null) {
                      label += `: ${context.raw}`;
                    }
                    return label;
                  }
                }
              }
            }
          }
        });
      }
    }
  }, [data, selectedWeek]);

  return (
    <Panel header="Timesheet Analytics by Week"
    style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}>
      <div style={{ marginBottom: '20px' }}>
        {data.length > 0 ? (
          <Dropdown
            value={selectedWeek}
            options={data.map(d => ({ label: d.week, value: d.week }))}
            onChange={(e) => setSelectedWeek(e.value)}
            placeholder="Select a week"
          />
        ) : (
          <p>No data available</p> // Show a message when there is no data
        )}
      </div>
      <div style={{ width: "300px", height: "300px", margin: "0 auto" }}>
          <canvas ref={chartRef} style={{ width: "100%", height: "100%" }}></canvas>
      </div>
    </Panel>
  );
};

export default TimesheetChart;
