import React from 'react'
import { TabPanel, TabView } from "primereact/tabview";
import { Image } from 'primereact/image';
import logo from "../../assets/IGS.png"
import { Menubar } from 'primereact/menubar';
import Projects from "./Projects"
import FinishedProject from './FinishedProject';
export default function ProjectTab() {

  const start = (
    <div>
      <p style={{ color: "#00000080", fontWeight: "bold", fontSize: "22px", margin: "0px", marginBottom: '5PX' }}>
        Projects
      </p>
    </div>
  )
  const end = (
    <div style={{ paddingRight: '15px' }}>
      <Image src={logo} height='60px' width='60px' />
    </div>
  )
  return (
    <div className="w-full">
      <div style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <div className="card">
          <Menubar start={start} end={end} />
          </div>
          <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 140px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
          <TabView activeIndex={0}>
            <TabPanel header="Current Project">
              <Projects />
            </TabPanel>
            <TabPanel header="FInished Project">
              <FinishedProject />
            </TabPanel>

          </TabView>
          </div>
      
        </div>
      </div>
  )
}
