
// import React, { useEffect, useState } from 'react';
// import styles from './ProjectsList.module.css';
// import { NavLink, useNavigate } from 'react-router-dom';
// import { getAllProjects } from '../../services/createProjectServices/Services';
// import { Button } from 'primereact/button';
// import { Image } from 'primereact/image';
// import logo from "../../assets/IGS.png"
// import { Menubar } from 'primereact/menubar';
// import { InputText } from 'primereact/inputtext';

// const ProjectsList = () => {
//   const [projects, setProjects] = useState([]);
//   const navigate = useNavigate();
//   const [searchTerm, setSearchTerm] = useState('');


//   useEffect(() => {
//     getAllProjects().then((data) => {
//       if (data != null) {
//         const sortedProjects = data.sort((a, b) => {
//           const currentDate = new Date();
//           const endDateA = new Date(a.project_details.end_date);
//           const endDateB = new Date(b.project_details.end_date);
//           // If both projects are active or both are expired, sort by start_date
//           if ((currentDate < endDateA && currentDate < endDateB) || (currentDate > endDateA && currentDate > endDateB)) {
//             return new Date(b.project_details.start_date) - new Date(a.project_details.start_date);
//           }
//           // If one project is active and the other is expired, push the expired one down
//           return currentDate > endDateA ? 1 : -1;
//         });
//         setProjects(sortedProjects);
//       }
//     });
//   }, []);


//   function handleEditClick(index) {
//     console.log(index);
//     navigate('/createProject', { state: projects[index] });
//   }

//   function handleAddUsersClick(index) {
//     navigate('/addusers', { state: projects[index] });
//   }

//   // Function to format date from yyyy-mm-dd to dd-mm-yyyy
//   function formatDate(dateString) {
//     const date = new Date(dateString);
//     return date.toLocaleDateString('en-GB');
//   }

//   // Function to determine the color of the status indicator
//   function getStatusColor(startDate, endDate) {
//     const currentDate = new Date();
//     const projectStartDate = new Date(startDate);
//     const projectEndDate = new Date(endDate);

//     // If the current date is past the end date, return red color
//     if (currentDate > projectEndDate) {
//       return "#FF0000"; // Red color
//     }

//     // Calculate the total duration of the project in milliseconds
//     const totalDuration = projectEndDate - projectStartDate;

//     // Calculate the remaining duration of the project in milliseconds
//     const remainingDuration = projectEndDate - currentDate;

//     // Check if the remaining duration is less than or equal to 20% of the total duration
//     if (remainingDuration <= 0.2 * totalDuration) {
//       return "#FFBF00"; // Amber color
//     }

//     // Otherwise, return green color
//     return "green"; // Green color
//   }


//   const filteredProjects = projects.filter((project) => {
//     const projectName = project?.project_details?.name?.toLowerCase() || '';
//     const deliveryManagerName = project?.project_details?.delivery_manager?.name?.toLowerCase() || '';
//     return (
//       projectName.includes(searchTerm.toLowerCase()) ||
//       deliveryManagerName.includes(searchTerm.toLowerCase())
//     );
//   });


//   const start = (
//     <div>
//       <p style={{ color: "#00000080", fontWeight: "bold", fontSize: "22px", margin: "0px", marginBottom: '5PX' }}>
//         Projects
//       </p>
//     </div>
//   )
//   const end = (
//     <div style={{ paddingRight: '15px' }}>
//       <Image src={logo} height='60px' width='60px' />
//     </div>
//   )

//   const handleProjectClick = (index) => {
//     navigate(`/projectlog`, { state: { project: projects[index] } });
//   };
//   const handleProjectTask = (index) => {
//     navigate('/projectstasks', {state: {projects: projects[index]}})
//   }
//   return (
//     <div className={styles.container}>
//       <div className="card">
//         <Menubar start={start} end={end} />
//       </div>
//       <main className={styles.main}>
//         <header className={styles.header}>
//           <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '30px', padding: '10px' }}>
//             <div>
//             <InputText
//               type='search'
//              className="w-full md:w-14rem"
//               placeholder='Search by project'
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//             />
//             </div>

//             <NavLink to="/createproject">
//               <button className={styles.createButton}>+ Create new</button>
//             </NavLink>
//           </div>
//         </header>

//         {filteredProjects.length === 0 ? (
//           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 300px)' }}>
//             <h1 style={{ textAlign: 'center', color: '#888' }}>No Project Found</h1>
//           </div>
//         ) : (
//           <div className={styles.projectList}>
//             {filteredProjects.map((project, index) => (
//               <div
//                 key={index}
//                 className={styles.project}
//                 style={{ cursor: 'pointer' }}
//               >
//                 <div className={styles.projectNumber} onClick={(e) => {
//                   e.stopPropagation(); // Prevent navigation to projectLog
//                   handleProjectTask(index); // Execute add users function
//                 }}>
//                   {project.project_details.name}
//                 </div>
//                 <div className={styles.projectName}>
//                   <span>Delivery Manager: {project.project_details.delivery_manager?.name || 'No delivery manager'}</span>
//                 </div>
//                 <div className={styles.projectName1}>
//                   <span>Client: {project.project_details.client_name}</span>
//                 </div>
//                 <div className={styles.projectDates}>
//                   <span>Start: {formatDate(project.project_details.start_date)}</span>
//                   <span>End: {formatDate(project.project_details.end_date)}</span>
//                 </div>
//                 <div className={styles.projectUsers}>
//                   Team: {project.project_members ? project.project_members.length : `0`}/{project.project_details.team_size}
//                 </div>
//                 <div className={styles.projectActions}>
//                   <Button
//                     icon="pi pi-save"
//                     className={styles.icon}
//                     onClick={() => handleProjectClick(index)}
//                   />
//                   <Button
//                     icon="pi pi-pencil"
//                     className={styles.icon}
//                     onClick={(e) => {
//                       e.stopPropagation(); // Prevent navigation to projectLog
//                       handleEditClick(index); // Execute edit function
//                     }}
//                   />
//                   <Button
//                     icon="pi pi-user-plus"
//                     className={styles.icon}
//                     onClick={(e) => {
//                       e.stopPropagation(); // Prevent navigation to projectLog
//                       handleAddUsersClick(index); // Execute add users function
//                     }}
//                   />
//                 </div>
//                 <div className={styles.projectStatus}>
//                   <div
//                     className={styles.statusIndicator}
//                     style={{
//                       backgroundColor: getStatusColor(
//                         project.project_details.start_date,
//                         project.project_details.end_date
//                       ),
//                     }}
//                   ></div>
//                 </div>
//                 <div>
//                   <div   style={{backgroundColor:'#ebebeb'}}>
//                     <Button 
//                      className={styles.icon}
//                      icon="pi pi-ellipsis-v"
//                      style={{backgroundColor:'#ebebeb', border:'none'}}
//                     />

//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}
//       </main>
//     </div>
//   );
// };

// export default ProjectsList;

import React, { useEffect, useState, useRef, useContext } from 'react';
import styles from './ProjectsList.module.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { getAllProjects } from '../../services/createProjectServices/Services';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel'; // Import OverlayPanel
import { UserContext } from '../../context/UserContext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';

import { ProgressSpinner } from 'primereact/progressspinner';
        
const ProjectsList = () => {
  const [projects, setProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProject, setSelectedProject] = useState(null); // To store selected project
  const [reason, setReason] = useState(''); // Reason for closing project
  const [showDialog, setShowDialog] = useState(false); // Control dialog visibility
  const overlayPanelRef = useRef(null); // Ref for the OverlayPanel
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const toast = useRef(null); // Ref for Toast
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getAllProjects().then((data) => {
      if (data != null) {
        const sortedProjects = data.sort((a, b) => {
          const currentDate = new Date();
          const endDateA = new Date(a.project_details.end_date);
          const endDateB = new Date(b.project_details.end_date);
          if ((currentDate < endDateA && currentDate < endDateB) || (currentDate > endDateA && currentDate > endDateB)) {
            return new Date(b.project_details.start_date) - new Date(a.project_details.start_date);
          }
          return currentDate > endDateA ? 1 : -1;
        });
        setProjects(sortedProjects);
      }
    });
  }, []);

  const handleEditClick = (index) => {
    navigate('/createProject', { state: projects[index] });
  };

  const handleAddUsersClick = (index) => {
    navigate('/addusers', { state: projects[index] });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB');
  };

  const getStatusColor = (startDate, endDate) => {
    const currentDate = new Date();
    const projectStartDate = new Date(startDate);
    const projectEndDate = new Date(endDate);

    if (currentDate > projectEndDate) {
      return "#FF0000"; // Red
    }

    const totalDuration = projectEndDate - projectStartDate;
    const remainingDuration = projectEndDate - currentDate;

    if (remainingDuration <= 0.2 * totalDuration) {
      return "#FFBF00"; // Amber
    }

    return "green"; // Green
  };

  const filteredProjects = projects.filter((project) => {
    const projectName = project?.project_details?.name?.toLowerCase() || '';
    const deliveryManagerName = project?.project_details?.delivery_manager?.name?.toLowerCase() || '';

    const matchesSearchTerm = projectName.includes(searchTerm.toLowerCase()) || deliveryManagerName.includes(searchTerm.toLowerCase());

    const statusColor = getStatusColor(project.project_details.start_date, project.project_details.end_date);
    const isNotExpired = statusColor !== '#FF0000'; // Exclude red status projects

    return matchesSearchTerm && isNotExpired;
  });


  const handleProjectClick = (index) => {
    navigate(`/projectlog`, { state: { project: projects[index] } });
  };

  const handleProjectTask = (index) => {
    navigate('/projectstasks', { state: { projects: projects[index] } });
  };

  const handleEllipsisClick = (e, project) => {
    setSelectedProject(project); // Set the clicked project
    overlayPanelRef.current.toggle(e); // Toggle OverlayPanel
  };
  const handleOpenCloseDialog = () => {
    setShowDialog(true); // Open the dialog
  };

  // Close the dialog
  const handleCloseDialog = () => {
    setShowDialog(false); // Close the dialog
    setReason(''); // Reset reason
  };
  // const handleCloseClick = () => {
  //   // Handle project closure logic here
  //   console.log(`Closing project: ${selectedProject.project_details.name}`);
  // };
  // Handle project closure with reason
  const handleProjectClose = async () => {
    if (!reason) {
      toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please provide a reason for closing the project', life: 3000 });
      return;
    }

    const payload = {
      reason: reason,
      performedBy: {
        empId: user?.Data?.empId,
        name: user?.Data?.name,
        email: user?.Data?.email,
      },
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/project/endEarly/${selectedProject?.project_details.project_id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to close project');
      }

      // Close the dialog and reset state
      setShowDialog(false);
      setReason('');

      // Show a success toast message
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Project closed successfully!', life: 3000 });

      // Refresh the project list after a short delay
      setTimeout(() => {
        // You can either refetch projects or apply the filter again
        const updatedProjects = projects.filter((project) => project.project_details.project_id !== selectedProject?.project_details.project_id);
        setProjects(updatedProjects);
      }, 1000);

    } catch (error) {
      console.error('Error closing project:', error);
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error closing the project. Please try again.', life: 3000 });
    }
  };
  useEffect(() => {
    // Simulate a loading delay for demo purposes
    const loadProjects = setTimeout(() => {
      setLoading(false);
    }, 1000); // Set to your actual loading time

    return () => clearTimeout(loadProjects);
  }, []);

  return (
    <div className={styles.container}>
      <Toast ref={toast} />
      <main className={styles.main}>
        <header className={styles.header}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '30px', padding: '10px' }}>
            <div>
              <InputText type='search' className="w-full md:w-14rem" placeholder='Search by project' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            </div>
            <NavLink to="/createproject">
              <button className={styles.createButton}>+ Create new</button>
            </NavLink>
          </div>
        </header>

        {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 300px)' }}>
          <ProgressSpinner />
        </div>
      ) : (
        filteredProjects.length === 0 ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 300px)' }}>
            <h1 style={{ textAlign: 'center', color: '#888' }}>No Project Found</h1>
          </div>
        ) : (
          <div className={styles.projectList}>
            {filteredProjects.map((project, index) => (
              <div key={index} className={styles.project}>
                <div className={styles.projectNumber} onClick={(e) => {
                  e.stopPropagation();
                  handleProjectTask(index);
                }}>
                  {project.project_details.name}
                </div>
                <div className={styles.projectName}>
                  <span>Delivery Manager: {project.project_details.delivery_manager?.name || 'No delivery manager'}</span>
                </div>
                <div className={styles.projectName1}>
                  <span>Client: {project.project_details.client_name}</span>
                </div>
                <div className={styles.projectDates}>
                  <span>Start: {formatDate(project.project_details.start_date)}</span>
                  <span>End: {formatDate(project.project_details.end_date)}</span>
                </div>
                <div className={styles.projectUsers}>
                  Team: {project.project_members ? project.project_members.length : `0`}/{project.project_details.team_size}
                </div>
                <div className={styles.projectActions}>
                  <Button icon="pi pi-save" className={styles.icon} onClick={() => handleProjectClick(index)} />
                  <Button icon="pi pi-pencil" className={styles.icon} onClick={(e) => {
                    e.stopPropagation();
                    handleEditClick(index);
                  }} />
                  <Button icon="pi pi-user-plus" className={styles.icon} onClick={(e) => {
                    e.stopPropagation();
                    handleAddUsersClick(index);
                  }} />
                </div>
                <div className={styles.projectStatus}>
                  <div className={styles.statusIndicator} style={{ backgroundColor: getStatusColor(project.project_details.start_date, project.project_details.end_date) }}></div>
                </div>
                <div>
                  <div style={{ backgroundColor: '#ebebeb' }}>
                    <Button
                      icon="pi pi-ellipsis-v"
                      className={styles.icon}
                      style={{ backgroundColor: '#ebebeb', border: 'none' }}
                      onClick={(e) => handleEllipsisClick(e, project)} // Pass the project to the handler
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )
      )}
        <Dialog
          style={{ width: '40vw' }}
          header={`Are you sure you want to close this project -${selectedProject?.project_details.name}`}
          visible={showDialog}
          onHide={handleCloseDialog}
          footer={
            <div>
              <Button label="Cancel"  onClick={handleCloseDialog} className="p-button-success" />
              <Button label="Submit"  onClick={handleProjectClose} autoFocus className="p-button-secondary"/>
            </div>
          }
        >
          <div >
            <h4 style={{ width: '100%' }}>Reason for Closing Project</h4>
            <InputTextarea
              style={{ width: '100%' }}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              rows={5}
              cols={30}
              placeholder="Enter reason here..."
              autoResize
            />
          </div>
        </Dialog>
        <OverlayPanel ref={overlayPanelRef} className={styles.overlayPanel}>
          <div>
            <Button
              style={{ backgroundColor: '#e7e7e7', color: 'black' }}
              label={`Close Project -  ${selectedProject?.project_details.name}`}
              onClick={handleOpenCloseDialog} />
          </div>
        </OverlayPanel>
      </main>
    </div>
  );
};

export default ProjectsList;
