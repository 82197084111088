import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { Panel } from "primereact/panel";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import styles from "./AddUsers.module.css";
import BenchPool from "./BenchPool/BenchPoll";
import ProjectDetailsCard from "./ProjectDetailsCard/ProjectDetailsCard";
import { Image } from "primereact/image";
import logo from "../../assets/IGS.png";
import { TbArrowBack } from "react-icons/tb";
import { Menubar } from "primereact/menubar";
import { Divider } from "primereact/divider";

const AddUsers = () => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const projectData = location.state;
  const [projectMembers, setProjectMembers] = useState(
    projectData.project_members || []
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [displayDialog, setDisplayDialog] = useState(false);
  const [selectedUserToRemove, setSelectedUserToRemove] = useState(null);
  const [endDateInput, setEndDateInput] = useState("");
  const [feedbackInput, setFeedbackInput] = useState("");
  const [hierarchyData, setHierarchyData] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  const showSuccess = (message) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };
  const [hasDataSaved, setHasDataSaved] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVICE_URL}${process.env.REACT_APP_SERVICE_LAYER_ENDPOINT}/serviceLayerHierarchy`
        );
        const data = await response.json();
        console.log("Fetched data:", data);

        if (data.length > 0 && data[0].Hierarchy_Progression) {
          const flatHierarchy = data[0].Hierarchy_Progression.reduce(
            (acc, item) => {
              if (Array.isArray(item.Progression)) {
                item.Progression.forEach((progress) => {
                  Object.keys(progress).forEach((key) => {
                    if (key !== "name") {
                      acc.push({ designation: progress[key], hkey: key });
                    }
                  });
                });
              } else {
                Object.keys(item.Progression).forEach((key) => {
                  if (key !== "name") {
                    acc.push({ designation: item.Progression[key], hkey: key });
                  }
                });
              }
              return acc;
            },
            []
          );
          setHierarchyData(flatHierarchy);
        }
      } catch (error) {
        console.error("Error fetching hierarchy data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleHierarchyChange = async (key) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVICE_URL}${process.env.REACT_APP_SERVICE_LAYER_ENDPOINT}/serviceLayerAllFilter/${key}`
      );
      const data = await response.json();
      console.log("Fetched users data:", data);

      setFilteredUsers(data || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleUserClick = async (user) => {
    const isUserAlreadyAdded = projectMembers.some(
      (member) => member.empId === user.empId || member.email === user.email
    );

    if (isUserAlreadyAdded) {
      toast.current.show({
        severity: "warn",
        summary: "Duplicate User",
        detail: `${user.name} is already added to the project.`,
        life: 3000,
      });
      return;
    }
    // Count billable members only
    const billableMembersCount = projectMembers.filter(
      (member) => member.billable
    ).length;

    if (billableMembersCount >= projectData.project_details.team_size) {
      // Add the user as non-billable if the team size limit is reached
      const newUser = {
        ...user,
        name: `${user.name}`,
        empId: `${user.empId}`,
        email: `${user.email}`,
        start_date: user.start_date || projectData.project_details.start_date,
        end_date: user.end_date || projectData.project_details.end_date,
        billable: false, // Set as non-billable
        allocation: "100%",
        salary: `${user.salary}`,
        margin: "0.00", // Margin can be calculated even for non-billable
        designation: "", // Designation can be set later
        billing_rate: "", // Hide role price for non-billable members
      };

      setProjectMembers((prevMembers) => [...prevMembers, newUser]);

      // Ensure prevUserData (filteredUsers) is an array before filtering
      setFilteredUsers((prevUserData) =>
        Array.isArray(prevUserData)
          ? prevUserData.filter((u) => u !== user)
          : []
      );

      toast.current.show({
        severity: "warn",
        summary: "Non-Billable Member Added",
        detail: `${user.name} was added as a non-billable member because the team size limit was reached.`,
        life: 3000,
      });
      return;
    }
    // Validate based on the available roles
    let assignedRole = null;
    for (let i = 0; i < projectData.project_details.team_roles.length; i++) {
      const role = projectData.project_details.team_roles[i];
      const currentCount = projectMembers.filter(
        (member) => member.roleIndex === i && member.billable
      ).length;

      // If the current designation is filled, move to the next one
      if (currentCount < role.count) {
        assignedRole = { ...role, roleIndex: i }; // Include index to track uniqueness
        break;
      }
    }

    if (!assignedRole) {
      toast.current.show({
        severity: "warn",
        summary: "No Available Billable Roles",
        detail:
          "All billable designations have been filled for this project. The user will be added as non-billable.",
        life: 3000,
      });

      // Add the user as non-billable because no role is available
      const newUser = {
        ...user,
        name: `${user.name}`,
        empId: `${user.empId}`,
        email: `${user.email}`,
        start_date: user.start_date || projectData.project_details.start_date,
        end_date: user.end_date || projectData.project_details.end_date,
        billable: false, // Set as non-billable
        allocation: "100%",
        salary: `${user.salary}`,
        margin: "0.00", // Margin can be calculated even for non-billable
        designation: "", // Allow changing designation
        billing_rate: "", // Hide role price for non-billable members
      };

      setProjectMembers((prevMembers) => [...prevMembers, newUser]);

      // Ensure prevUserData (filteredUsers) is an array before filtering
      setFilteredUsers((prevUserData) =>
        Array.isArray(prevUserData)
          ? prevUserData.filter((u) => u !== user)
          : []
      );
      return;
    }

    // Call API to fetch employee allocation details
    try {
      const allocationResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/notification/allocation/${user.empId}`
      );

      const allocationData = await allocationResponse.json();

      if (allocationData.success) {
        // Join the allocation messages into a single string with newlines or another separator
        const allMessages = allocationData.data.join("\n"); // '\n' for new lines

        toast.current.show({
          severity: "info",
          summary: "Allocation Details",
          detail: allMessages, // Display all messages in one toast
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error fetching allocation data:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch allocation details.",
        life: 3000,
      });
    }

    // Calculate margin dynamically for billable users
    const rolePrice = parseFloat(assignedRole.billing_rate) || 0;
    const salary = parseFloat(user.salary) || 0;
    const margin = rolePrice > 0 ? ((rolePrice - salary) / rolePrice) * 100 : 0;

    const newUser = {
      ...user,
      name: `${user.name}`,
      empId: `${user.empId}`,
      email: `${user.email}`,
      start_date: user.start_date || projectData.project_details.start_date,
      end_date: user.end_date || projectData.project_details.end_date,
      billable: true, // Set as billable
      allocation: "100%",
      salary: `${user.salary}`,
      margin: margin.toFixed(2), // Dynamically calculated margin
      designation: assignedRole.name.value,
      billing_rate: assignedRole.billing_rate || "",
      roleIndex: assignedRole.roleIndex, // Track which role index this user belongs to
    };

    setProjectMembers((prevMembers) => [...prevMembers, newUser]);

    // Ensure prevUserData (filteredUsers) is an array before filtering
    setFilteredUsers((prevUserData) =>
      Array.isArray(prevUserData) ? prevUserData.filter((u) => u !== user) : []
    );
  };

  const handleRemoveUser = (user) => {
    setProjectMembers((prevMembers) =>
      prevMembers.filter((member) => member !== user)
    );

    // Ensure prevUserData is an array before updating the state
    setFilteredUsers((prevUserData) => {
      // If prevUserData is not an array, initialize it as an empty array
      if (!Array.isArray(prevUserData)) {
        return [user];
      }

      return [...prevUserData, user];
    });
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB");
  }
  const confirmRemoveUser = () => {
    setProjectMembers((prevMembers) =>
      prevMembers.filter((member) => member !== selectedUserToRemove)
    );
    setFilteredUsers((prevUserData) => [...prevUserData, selectedUserToRemove]);
    setDisplayDialog(false);
  };

  useEffect(() => {
    const updatedMembers = projectMembers.map((member) => {
      const selectedRole = projectData.project_details.team_roles.find(
        (role) => role.name.value === member.designation
      );

      // Calculate margin if the member is billable
      if (member.billable && selectedRole) {
        const rolePrice = parseFloat(selectedRole.billing_rate) || 0;
        const salary = parseFloat(member.salary) || 0;
        const margin =
          rolePrice > 0 ? ((rolePrice - salary) / rolePrice) * 100 : 0;

        return {
          ...member,
          billing_rate: selectedRole.billing_rate,
          margin: margin.toFixed(2),
        };
      }

      return { ...member, billing_rate: "0", margin: "0.00" };
    });

    setProjectMembers(updatedMembers);
  }, []); // Empty dependency array ensures this runs only once on component mount

  const handleSave = async () => {
    // Validate if all required fields are filled
    const invalidMembers = projectMembers.filter(
      (member) => !member.start_date || !member.end_date
    );
    const invalidDesignation = projectMembers.filter(
      (member) => !member.designation
    );
    if (invalidMembers.length > 0) {
      // Show a toast warning for missing required fields
      toast.current.show({
        severity: "warn",
        summary: "Validation Error",
        detail: "Start Date and End Date are required for all members.",
        life: 2000,
      });
      return; // Exit the function if validation fails
    }

    const payload = {
      project_details: projectData.project_details,
      project_members: projectMembers.map((member) => ({
        empId: member.empId, // Include empId
        email: member.email, // Include email
        designation: member.designation,
        name: member.name,
        start_date: member.start_date,
        end_date: member.end_date,
        release_feedback: "",
        billable: member.billable,
        allocation: member.allocation,
        billing_rate: member.billing_rate,
        salary: member.salary,
        margin: member.margin,
      })),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/project/allocateUsers`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );

      const responseBody = await response.text();

      if (!response.ok) {
        console.error("API Error:", {
          status: response.status,
          statusText: response.statusText,
          body: responseBody,
        });
        throw new Error("Network response was not ok");
      }

      const data = JSON.parse(responseBody);

      // Show success toast
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Data saved successfully!",
        life: 1000,
      });

      // Set a timeout to navigate after the toast
      setTimeout(() => {
        navigate("/projects");
      }, 1000); // Delay for 3 seconds (3000 ms) to match the toast's life

      console.log("Save successful:", data);
    } catch (error) {
      console.error("Error saving project data:", error);
    }
  };
  const handleInputChange = (index, field, value) => {
    setProjectMembers((prevMembers) =>
      prevMembers.map((member, i) => {
        if (i === index) {
          const updatedMember = { ...member, [field]: value };

          if (field === "designation") {
            const selectedRole = projectData.project_details.team_roles.find(
              (role) => role.name.value === value
            );

            if (member.billable) {
              const designationCount = prevMembers.filter(
                (member) => member.designation === value && member.billable
              ).length;
            }

            updatedMember.billing_rate = selectedRole
              ? selectedRole.billing_rate
              : "";
          }

          if (field === "billable") {
            if (!value) {
              updatedMember.billing_rate = "0";
              updatedMember.margin = "0.00";
            } else {
              const selectedRole = projectData.project_details.team_roles.find(
                (role) => role.name.value === updatedMember.designation
              );
              if (selectedRole) {
                const rolePrice = parseFloat(selectedRole.billing_rate) || 0;
                const salary = parseFloat(updatedMember.salary) || 0;
                const margin =
                  rolePrice > 0 ? ((rolePrice - salary) / rolePrice) * 100 : 0;

                updatedMember.billing_rate = selectedRole.billing_rate;
                updatedMember.margin = margin.toFixed(2);
              }
            }
          }

          if (field === "end_date") {
            const projectEndDate = new Date(
              projectData.project_details.end_date
            );
            const selectedEndDate = new Date(value);
            const startDate = new Date(member.start_date);

            if (selectedEndDate < startDate) {
              toast.current.show({
                severity: "error",
                summary: "Invalid End Date",
                detail: `End date cannot be before the start date (${member.start_date}).`,
                life: 3000,
              });
              return member;
            }

            if (selectedEndDate > projectEndDate) {
              toast.current.show({
                severity: "warn",
                summary: "Warning",
                detail: `You are selecting a date beyond the project deadline for ${member.name}.`,
                life: 3000,
              });
            }
          }

          return updatedMember;
        }
        return member;
      })
    );
  };

  const formatNumber = (number) => {
    if (!number) return "";
    return number
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      .replace(/(\d+)(\d{2},)/, "$1,$2");
  };

  const formatCurrency = (number) => {
    return `₹ ${formatNumber(number)}`;
  };
  const formatSalary = (salary) => {
    // Round the salary to the nearest whole number
    salary = Math.round(salary);

    // Split the whole part and format it with commas
    let wholePart = salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return wholePart; // Return only the whole part without decimals
  };
  const handleCancel = () => {
    navigate("/projects");
  };
  const start = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <button
        className="rounded-full m-2 hover:bg-gray-200"
        style={{ height: "40px", width: "40px" }}
        onClick={() => navigate(-1)}
      >
        <TbArrowBack />
      </button>
      <div>
        <div
          style={{
            fontSize: "20px",
            fontFamily: "sans-serif",
            fontWeight: "bold",
            color: "#00000080",
          }}
        >
          User Allocation
        </div>
      </div>
    </div>
  );
  const end = (
    <div style={{ paddingRight: "15px" }}>
      <Image src={logo} height="60px" width="60px" />
    </div>
  );

  const formatDateToDDMMYYYY = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JS
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div>
      <div className="card">
        <Menubar start={start} end={end} />
      </div>
      <div
        style={{
          boxShadow: "0 10px 10px -5px rgba(0, 0, 0, 0.3)",
          border: "1px solid rgba(128, 128, 128, 0.211)",
          backgroundColor: "#f8f9fa",
          height: "calc(100vh - 140px)",
          marginTop: "10px",
          overflow: "scroll",
          position: "relative",
        }}
      >
        <Panel
          header={
            <span className="text-md">
              <span style={{ marginRight: "40px" }}>
                Project: {projectData.project_details.name}
              </span>

              <span style={{ marginRight: "40px" }}>
                Delivery Manager:{" "}
                {projectData.project_details.delivery_manager.name}
              </span>
              <span style={{ marginRight: "40px" }}>
                Start Date: {formatDate(projectData.project_details.start_date)}
              </span>
              <span style={{ marginRight: "40px" }}>
                End Date: {formatDate(projectData.project_details.end_date)}
              </span>
              <span style={{ marginRight: "40px" }}>
                Billing Type: {projectData.project_details.billing_type}
              </span>
            </span>
          }
          toggleable
          collapsed={true}
          // className="w-full"
        >
          <div className="flex justify-content-between">
            <div
              style={{
                width: "50%",
                paddingRight: "40px",
                paddingLeft: "30px",
              }}
            >
              <div className="flex justify-content-between">
                <div style={{ fontWeight: "500" }}>
                  <p>
                    Start Date:{" "}
                    {formatDate(projectData.project_details.start_date)}
                  </p>
                </div>
                <div style={{ fontWeight: "500" }}>
                  <p>
                    End Date: {formatDate(projectData.project_details.end_date)}
                  </p>
                </div>
              </div>
              <div className="flex justify-content-between">
                <div style={{ fontWeight: "500" }}>
                  <p>Client Name: {projectData.project_details.client_name}</p>
                </div>
                <div style={{ fontWeight: "500" }}>
                  <p>
                    Delivery Manager:{" "}
                    {projectData.project_details.delivery_manager.name}
                  </p>
                </div>
              </div>
              <div className="flex justify-content-between">
                <div style={{ fontWeight: "500" }}>
                  <p>Team Size: {projectData.project_details.team_size}</p>
                </div>
              </div>
            </div>
            <Divider layout="vertical" />
            <ProjectDetailsCard projectData={projectData} />
          </div>
        </Panel>
        <div className="w-full flex">
          <div style={{ marginLeft: "10px" }}>
            <BenchPool
              hierarchyData={hierarchyData}
              projectData={projectData}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              filteredUser={filteredUsers}
              handleUserClick={handleUserClick}
              onHierarchyChange={handleHierarchyChange}
            />
          </div>
          <div className="w-full ">
            {/* <h4 style={{color:'#495057'}}>Project members</h4> */}
            <div style={{ marginRight: "10px" }}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Resource Nmae</th>
                    <th>Designation</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Billable</th>
                    <th>Allocation</th>
                    <th>Bill/Sal/Margin</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {projectMembers.map((member, index) => {
                    const rolePrice = parseFloat(member.billing_rate) || 0;
                    const salary =
                      typeof member.salary === "string"
                        ? parseFloat(member.salary.replace(/,/g, "")) || 0
                        : parseFloat(member.salary) || 0;

                    const margin =
                      rolePrice !== 0
                        ? ((rolePrice - salary) / rolePrice) * 100
                        : 0;

                    console.log(
                      `Role Price: ${rolePrice}, Salary: ${salary}, margin%: ${margin}`
                    );
                    const projectStartDate = formatDateToDDMMYYYY(
                      projectData.project_details.start_date
                    );
                    const projectEndDate = formatDateToDDMMYYYY(
                      projectData.project_details.end_date
                    );

                    return (
                      <tr key={member.email}>
                        <td style={{ width: "18%" }}>{member.name}</td>
                        <td style={{ textAlign: "center", width: "12%" }}>
                          <select
                            value={member.designation}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "designation",
                                e.target.value
                              )
                            }
                          >
                            {projectData.project_details.team_roles.map(
                              (role, idx) => (
                                <option key={idx} value={role.name.value}>
                                  {role.name.value}
                                </option>
                              )
                            )}
                          </select>
                        </td>
                        <td style={{ textAlign: "center", width: "13%" }}>
                          <input
                            required
                            type="date"
                            onFocus={(e) => e.target.showPicker()}
                            value={
                              member.start_date
                                ? member.start_date
                                : formatDateToDDMMYYYY(
                                    projectData.project_details.start_date
                                  )
                            }
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "start_date",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "13%" }}>
                          <input
                            required
                            type="date"
                            onFocus={(e) => e.target.showPicker()}
                            value={
                              member.end_date
                                ? member.end_date
                                : formatDateToDDMMYYYY(
                                    projectData.project_details.end_date
                                  )
                            }
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "end_date",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td style={{ textAlign: "center", width: "8%" }}>
                          <input
                            style={{ width: "100%" }}
                            required
                            type="checkbox"
                            checked={member.billable}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "billable",
                                e.target.checked
                              )
                            }
                          />
                        </td>
                        <td style={{ width: "8%", textAlign: "center" }}>
                          <input
                            required
                            type="text"
                            value={member.allocation}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "allocation",
                                e.target.value
                              )
                            }
                            style={{ width: "50%", margin: "0 auto" }}
                          />
                        </td>
                        <td style={{ width: "16%" }}>
                          {member.billable ? (
                            <>
                              <div className={styles["flex-justify-between"]}>
                                <span>Billing:</span>
                                <select
                                  style={{ width: "70%" }}
                                  value={member.billing_rate}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "billing_rate",
                                      e.target.value
                                    )
                                  }
                                >
                                  {projectData.project_details.team_roles
                                    .filter(
                                      (role) =>
                                        role.name.value === member.designation
                                    )
                                    .map((role, idx) => (
                                      <option
                                        key={idx}
                                        value={role.billing_rate}
                                      >
                                        {formatCurrency(role.billing_rate)}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </>
                          ) : (
                            <div className={styles["flex-justify-between"]}>
                              <span>Billing:</span>
                              <span className="text-green-500">₹ 0</span>
                            </div>
                          )}
                          <div className={styles["flex-justify-between"]}>
                            <span>Salary:</span>
                            <span className="text-green-500">
                              ₹ {formatSalary(member.salary)}
                            </span>
                          </div>
                          <div className={styles["flex-justify-between"]}>
                            <span>Margin:</span>
                            <span className="text-blue-500">
                              {Math.round(margin)}%
                            </span>{" "}
                            {/* Correct margin calculation */}
                          </div>
                        </td>

                        <td style={{ width: "3%", textAlign: "center" }}>
                          <Button
                            icon="pi pi-times"
                            className="p-button-rounded p-button-danger"
                            onClick={() => handleRemoveUser(member)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
            right: "2%",
          }}
        >
          <Toast ref={toast} />
          <Button
            style={{ marginRight: "10px" }}
            label="Save"
            className="p-button-success"
            onClick={handleSave}
          />
          <Button
            label="Cancel"
            className="p-button-secondary"
            onClick={handleCancel}
          />
        </div>
        <Dialog
          visible={displayDialog}
          style={{ width: "450px" }}
          onHide={() => setDisplayDialog(false)}
          header="Confirm Remove User"
          footer={
            <div>
              <Button
                label="No"
                icon="pi pi-times"
                onClick={() => setDisplayDialog(false)}
                className="p-button-text"
              />
              <Button
                label="Yes"
                icon="pi pi-check"
                onClick={confirmRemoveUser}
                className="p-button-text"
              />
            </div>
          }
        >
          <div className="p-fluid">
            <div className="p-field">
              <label htmlFor="endDate">End Date:</label>
              <InputText
                id="endDate"
                value={endDateInput}
                onChange={(e) => setEndDateInput(e.target.value)}
              />
            </div>
            <div className="p-field">
              <label htmlFor="feedback">Feedback:</label>
              <InputText
                id="feedback"
                value={feedbackInput}
                onChange={(e) => setFeedbackInput(e.target.value)}
              />
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default AddUsers;
