import React, { useState, useEffect,} from 'react';
import { Image } from 'primereact/image';
import { Menubar } from 'primereact/menubar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import logo from '../../../assets/IGS.png';
import { TbArrowBack } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { Paginator } from 'primereact/paginator';
import styles from "../ProjectsList.module.css";
import { useLocation } from 'react-router-dom';

const ProjectLog = () => {
    const [selectedName, setSelectedName] = useState('All'); // Default to 'All'
    const [projectData, setProjectData] = useState([]);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0); // To handle pagination
    const navigate = useNavigate();
    const location = useLocation();
    const project = location.state?.project;

    function formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB');
      }
    useEffect(() => {
        if (project) {
            fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/project/projectlog/${project.project_details.project_id}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    setProjectData(data);
                })
                .catch(error => {
                    console.error('Error fetching project data:', error);
                });
        }
    }, [project]);

    if (!project) {
        return <div>No project data available</div>;
    }

    const uniqueNames = ['All', ...new Set(projectData.map(item => item.modified_by.name))];

    const allModifications = projectData.flatMap(item => ({
        ...item.modification,
        modified_by: item.modified_by.name,
        reason: item.reason,
        timestamp: new Date(item.timestamp.$date).toLocaleString(),
    }));

    // Sort the modifications by timestamp (latest first)
    const sortedModifications = allModifications.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

    const filteredModifications = selectedName === 'All'
        ? sortedModifications
        : sortedModifications.filter(mod => mod.modified_by === selectedName);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const paginatedData = filteredModifications.slice(first, first + rows);

    const start = (
        <div style={{ display: "flex", alignItems: 'center' }}>
            <button className="rounded-full m-2 hover:bg-gray-200" style={{ height: '40px', width: '40px' }} onClick={() => navigate(-1)}>
                <TbArrowBack />
            </button>
            <div style={{color: "#00000080"}}>
                <h2 > {project.project_details.name} Project Log</h2>
            </div>
        </div>
    );

    const end = (
        <div style={{ paddingRight: '15px' }}>
            <Image src={logo} height="60px" width="60px" />
        </div>
    );

    return (
        <div>
            <Menubar start={start} end={end} />
            <div style={{ padding: '20px', backgroundColor: '#f8f9fa', height: 'calc(100vh - 140px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }} >
               
                <div className="p-d-flex p-jc-between p-ai-center p-mb-4">
                    <Dropdown
                        value={selectedName}
                        options={uniqueNames.map(name => ({ label: name, value: name }))}
                        onChange={(e) => setSelectedName(e.value)}
                        placeholder="Select Modified By"
                        style={{ width: '250px', marginLeft: '13px' }}
                    />
                </div>
                <DataTable value={paginatedData} responsiveLayout="scroll" style={{
                    padding: '20px',
                    margin: '10px',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    overflow: 'hidden'
                }}>
                    <Column field="field" header="Field" />
                    <Column
                        header="Old Value"
                        body={(rowData) => {
                            if (Array.isArray(rowData.old_value)) {
                                return rowData.old_value.map((val) => val.name).join(', ');
                            } else if (typeof rowData.old_value === 'object' && rowData.old_value !== null) {
                                return rowData.old_value.value || JSON.stringify(rowData.old_value);
                            } else {
                                return rowData.old_value;
                            }
                        }}
                    />
                    <Column
                        header="New Value"
                        body={(rowData) => {
                            if (Array.isArray(rowData.new_value)) {
                                return rowData.new_value.map((val) => val.name).join(', ');
                            } else if (typeof rowData.new_value === 'object' && rowData.new_value !== null) {
                                return rowData.new_value.value || JSON.stringify(rowData.new_value);
                            } else {
                                return rowData.new_value;
                            }
                        }}
                    />
                    <Column field="modified_by" header="Modified By" />
                    <Column field="reason" header="Reason" />
                    <Column 
                        field="timestamp" 
                        header="Timestamp" 
                        body={(rowData) => formatDate(rowData.timestamp)} // Format the timestamp
                    />
                    {paginatedData.length === 0 && (
                        <Column body={<div style={{ textAlign: 'center', padding: '1rem' }}>No data available</div>} />
                    )}
                </DataTable>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Paginator
                        first={first}
                        rows={rows}
                        totalRecords={filteredModifications.length}
                        onPageChange={onPageChange}
                        style={{ position: 'relative', top: '88%' }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProjectLog;




// import React, { useState, useEffect } from 'react';
// import { Image } from 'primereact/image';
// import { Menubar } from 'primereact/menubar';
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';
// import { Dropdown } from 'primereact/dropdown';
// import logo from '../../../assets/IGS.png';
// import { TbArrowBack } from 'react-icons/tb';
// import { useNavigate } from 'react-router-dom';
// import { Card } from 'primereact/card';
// import { useLocation } from 'react-router-dom';
// import styles from "../ProjectsList.module.css"
// import { Paginator } from 'primereact/paginator';

// const ProjectLog = () => {
//     const [selectedName, setSelectedName] = useState(''); // Initial state set to empty string
//     const [projectData, setProjectData] = useState([]);
//     const [first, setFirst] = useState(0); // Current page
//     const [rows, setRows] = useState(10); // Items per page
//     const [totalRecords, setTotalRecords] = useState(0); // Total number of records
//     const navigate = useNavigate();
//     const location = useLocation();
//     const project = location.state?.project;
//     // Log the location and project data to the console for debugging
//     console.log('Location State:', location.state);
//     console.log('Project:', project);

//     const formatDate = (dateString) => {
//         const options = { year: 'numeric', month: 'long', day: 'numeric' };
//         return new Date(dateString).toLocaleDateString(undefined, options);
//     };


//     useEffect(() => {
//         if (project) {
//             fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/project/projectlog/${project.project_details.project_id}`)
//                 .then(response => {
//                     if (!response.ok) {
//                         console.error('Response Error:', response.status, response.statusText);
//                         throw new Error('Network response was not ok');
//                     }
//                     return response.json();
//                 })
//                 .then(data => {
//                     setProjectData(data);
//                 })
//                 .catch(error => {
//                     console.error('Error fetching project data:', error);
//                 });
//         }
//     }, [project]);

//     // Check if there is a project
//     if (!project) {
//         return <div>No project data available</div>;
//     }

//     // Extract unique names for the dropdown, including "All"
//     const uniqueNames = ['All', ...new Set(projectData.map(item => item.modified_by.name))];

//     // Combine all modifications into a single array
//     const allModifications = projectData.flatMap(item => ({
//         ...item.modification,
//         modified_by: item.modified_by.name,
//         reason: item.reason,
//         timestamp: new Date(item.timestamp.$date).toLocaleString()
//     }));

//     // Filter modifications based on "Modified By"
//     const filteredModifications = selectedName === 'All' || selectedName === ''
//         ? allModifications
//         : allModifications.filter(mod => mod.modified_by === selectedName);

//     // Pagination handler
//     const onPageChange = (event) => {
//         setFirst(event.first);
//         setRows(event.rows);
//     };

//     // Slice the data for the current page
//     const paginatedData = filteredModifications.slice(first, first + rows);

//     // Determine if data should be displayed or "No data available" message shown
//     const shouldDisplayData = selectedName !== '';
//     const start = (
//         <div style={{ display: "flex", alignItems: 'center' }}>
//             <button className="rounded-full m-2 hover:bg-gray-200" style={{ height: '40px', width: '40px' }} onClick={() => navigate(-1)}>
//                 <TbArrowBack />
//             </button>
//             <div>
//                 <h2>Project Log</h2>
//             </div>
//         </div>

//     );
//     const end = (
//         <div style={{ paddingRight: '15px' }}>
//             <Image src={logo} height="60px" width="60px" />
//         </div>
//     );

//     return (
//         <div>
//             <Menubar start={start} end={end} />
//             <div style={{ padding: '20px', backgroundColor: '#f8f9fa', height: 'calc(100vh - 140px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }} >
//                 <div>
//                     <div className={styles.projectList}>
//                         <div className={styles.project} style={{ cursor: 'pointer' }}>
//                             <div className={styles.projectNumber}>
//                                 {project.project_details.name}
//                             </div>
//                             <div className={styles.projectName}>
//                                 <span>Delivery Manager: {project.project_details.delivery_manager.name}</span>
//                             </div>
//                             <div className={styles.projectName1}>
//                                 <span>Client: {project.project_details.client_name}</span>
//                             </div>
//                             <div className={styles.projectDates}>
//                                 <span>Start: {formatDate(project.project_details.start_date)}</span>
//                                 {/* <span>End: {formatDate(project.project_details.end_date)}</span> */}
//                             </div>
//                             <div className={styles.projectDates}>
//                                 {/* <span>Start: {formatDate(project.project_details.start_date)}</span> */}
//                                 <span>End: {formatDate(project.project_details.end_date)}</span>
//                             </div>
//                             <div className={styles.projectUsers}>
//                                 Users: {project.project_members ? project.project_members.length : `0`}/{project.project_details.team_size}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="p-d-flex p-jc-between p-ai-center p-mb-4" style={{ marginTop: '30px' }}>
//                     <Dropdown
//                         value={selectedName}
//                         options={uniqueNames.map(name => ({ label: name, value: name }))}
//                         onChange={(e) => setSelectedName(e.value)}
//                         placeholder="Select Modified By"
//                         style={{ width: '290px', marginLeft: '13px' }}
//                     />
//                 </div>
//                 <DataTable value={shouldDisplayData ? paginatedData : []} responsiveLayout="scroll" style={{
//                     padding: '20px',
//                     margin: '10px',
//                     border: '1px solid #ddd',
//                     borderRadius: '4px',
//                     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//                     overflow: 'hidden'
//                 }}>
//                     <Column field="field" header="Field" />
//                     <Column
//                         header="Old Value"
//                         body={(rowData) => {
//                             if (Array.isArray(rowData.old_value)) {
//                                 // If old_value is an array, join values
//                                 return rowData.old_value.map((val) => val.name).join(', ');
//                             } else if (typeof rowData.old_value === 'object' && rowData.old_value !== null) {
//                                 // If old_value is an object, display its 'value' field
//                                 return rowData.old_value.value || JSON.stringify(rowData.old_value);
//                             } else {
//                                 // If old_value is a string or number, return as is
//                                 return rowData.old_value;
//                             }
//                         }}
//                     />

//                     <Column
//                         header="New Value"
//                         body={(rowData) => {
//                             if (Array.isArray(rowData.new_value)) {
//                                 // If new_value is an array, join values
//                                 return rowData.new_value.map((val) => val.name).join(', ');
//                             } else if (typeof rowData.new_value === 'object' && rowData.new_value !== null) {
//                                 // If new_value is an object, display its 'value' field
//                                 return rowData.new_value.value || JSON.stringify(rowData.new_value);
//                             } else {
//                                 // If new_value is a string or number, return as is
//                                 return rowData.new_value;
//                             }
//                         }}
//                     />

//                     <Column field="modified_by" header="Modified By" />
//                     <Column field="reason" header="Reason" />
//                     <Column field="timestamp" header="Timestamp" />
//                     {(!shouldDisplayData || paginatedData.length === 0) && (
//                         <Column body={<div style={{ textAlign: 'center', padding: '1rem' }}>No data available</div>} />
//                     )}
//                 </DataTable>

//                 <div style={{ display: 'flex', justifyContent: 'center' }}>
//                     <Paginator
//                         first={first}
//                         rows={rows}
//                         totalRecords={totalRecords}
//                         onPageChange={onPageChange}
//                         style={{ position: 'relative', top: '88%', }}
//                     />
//                 </div>

//             </div>
//         </div>
//     );
// };

// export default ProjectLog;


