import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import { Image } from 'primereact/image';
import { Menubar } from 'primereact/menubar';
import logo from '../../../assets/IGS.png';
import { TbArrowBack } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

const NotOnBench = () => {
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  // Fetch the data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getOnBenchByDesignation`);
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching the data:", error);
      }
    };

    fetchData();
  }, []);

  // Initialize the chart
  useEffect(() => {
    if (data) {
      const chartDom = document.getElementById('bench-details-chart');
      const myChart = echarts.init(chartDom);

      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: (params) => {
            return `
              ${params[0].name}<br/>
              <strong>On Bench: </strong>${params[0].value}
            `;
          },
        },
        legend: {
          bottom: '10px',
          textStyle: {
            color: '#555',
            fontSize: 14,
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          axisLabel: {
            color: '#666',
            fontSize: 12,
            formatter: '{value}', // Display natural number counts
          },
          splitLine: {
            lineStyle: {
              color: '#f0f0f0',
            },
          },
        },
        yAxis: {
          type: 'category',
          data: data.map((item) => item.designation), // Map designations from the data
          axisLabel: {
            color: '#666',
            fontSize: 14,
            fontWeight: 'bold',
          },
        },
        series: [
          {
            name: 'On Bench',
            type: 'bar',
            data: data.map((item) => item.onBench), // Map onBench counts from the data
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                { offset: 1, color: '#009900' },
                { offset: 0, color: '#003399' },
              ]),
            },
            label: {
              show: true,
              position: 'inside',
              formatter: '{c} Employee', // Display natural number counts
              color: '#fff',
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(255, 0, 0, 0.3)',
              },
            },
            animationEasing: 'elasticOut',
            animationDelay: (idx) => idx * 50,
          },
        ],
        backgroundColor: '#f5f5f5',
        animationDuration: 1500,
      };

      myChart.setOption(option);

      // Cleanup the chart on component unmount
      return () => {
        myChart.dispose();
      };
    }
  }, [data]);

  const start = (
    <div style={{ display: "flex", alignItems: 'center' }}>
      <button className="rounded-full m-2 hover:bg-gray-200" style={{ height: '40px', width: '40px' }} onClick={() => navigate(-1)}>
        <TbArrowBack />
      </button>
      <div style={{ color: "#00000080" }}>
        <h2>Bench Status By Designation</h2>
      </div>
    </div>
  );

  const end = (
    <div style={{ paddingRight: '15px' }}>
      <Image src={logo} height="100px" width="60px" />
    </div>
  );

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        <Menubar start={start} end={end} />
      </div>
      <div id="bench-details-chart" style={{ width: '100%', height: '600px', backgroundColor: '#fff', borderRadius: '10px', padding: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}></div>
    </div>
  );
};

export default NotOnBench;

// import React, { useEffect, useState } from 'react';
// import * as echarts from 'echarts';
// import { useNavigate } from 'react-router-dom'; // Assuming you are using React Router for navigation

// const BenchStatusChart = () => {
//   const [data, setData] = useState(null);
//   const navigate = useNavigate(); // To navigate to the new page

//   // Fetch the data
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch('http://192.168.29.195:5004/api/v1/admin/bench-status'); // Replace with your actual API endpoint
//         const result = await response.json();
//         setData(result);
//       } catch (error) {
//         console.error("Error fetching the data:", error);
//       }
//     };
//     fetchData();
//   }, []);

//   // Initialize the chart
//  useEffect(() => {
//   if (data) {
//     const chartDom = document.getElementById('bench-status-chart');
//     const myChart = echarts.init(chartDom);
    
//     // Define gradient colors
//     const gradientColors = [
//       {
//         type: 'linear',
//         x: 0,
//         y: 0,
//         x2: 0,
//         y2: 1,
//         colorStops: [
//           { offset: 0, color: '#f1948a' }, // Start color (e.g., Coral)
//           { offset: 1, color: '#cd6155' }, // End color (e.g., Tomato)
//         ],
//       },
//       {
//         type: 'linear',
//         x: 0,
//         y: 0,
//         x2: 0,
//         y2: 1,
//         colorStops: [
//           { offset: 0, color: '#87cefa' }, // Start color (e.g., LightSkyBlue)
//           { offset: 1, color: '#4682b4' }, // End color (e.g., SteelBlue)
//         ],
//       },
//     ];

//     const option = {
//       tooltip: {
//         trigger: 'item',
//       },
//       series: [
//         {
//           name: 'Bench Status',
//           type: 'pie',
//           radius: '90%',
//           data: [
//             { value: data.onBench.count, name: 'On Bench', itemStyle: { color: gradientColors[0] } },
//             { value: data.notOnBench.count, name: 'Not On Bench', itemStyle: { color: gradientColors[1] } },
//           ],
//           emphasis: {
//             itemStyle: {
//               shadowBlur: 10,
//               shadowOffsetX: 0,
//               shadowColor: 'rgba(0, 0, 0, 0.5)',
//             },
//           },
//           label: {
//             show: true,
//             position: 'outside',
//             formatter: '{b}',
//             color: '#000', // Adjust color for visibility
//           },
//           labelLine: {
//             show: true,
//             length: 10,
//             length2: 10,
//             smooth: true,
//           },
//           itemStyle: {
//             borderColor: '#fff',
//             borderWidth: 2,
//           },
//         },
//         {
//           name: 'Bench Status Counts',
//           type: 'pie',
//           radius: ['60%', '60%'],
//           data: [
//             { value: data.onBench.count, name: '' },
//             { value: data.notOnBench.count, name: '' },
//           ],
//           label: {
//             show: true,
//             position: 'inside',
//             formatter: '{c}',
//             color: (params) => {
//               return params.value > 0 ? '#fff' : '#000'; // Dynamic color
//             },
//             fontSize: '16px',   // Set font size
//             fontWeight: '400',  // Set font weight
//           },
//           emphasis: {
//             itemStyle: {
//               shadowBlur: 10,
//               shadowOffsetX: 0,
//               shadowColor: 'rgba(0, 0, 0, 0.5)',
//             },
//           },
//         },
//       ],
//     };
//     myChart.setOption(option);
//     // Handle click event
//     myChart.on('click', function (params) {
//       navigate('/reports/notonbench'); // Navigate to the details page when chart is clicked
//     });

//     // Cleanup the chart on component unmount
//     return () => {
//       myChart.dispose();
//     };
//   }
// }, [data, navigate]);

//   return (
//     <div>
//       <div style={{ display: 'flex', justifyContent: 'center',height: 'calc(100vh - 180px)', backgroundColor:'white', border: '1px solid rgba(128, 128, 128, 0.211)' }}>
//         <div style={{ width: '100%' }}> 
//           <h2 style={{ textAlign: 'center', marginBottom:'40px' }}>Employee Bench Status Chart</h2>
//           <div id="bench-status-chart" style={{ width: '100%', height: '400px' }}></div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BenchStatusChart;