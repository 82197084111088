

// import React, { useState, useEffect } from 'react';
// import * as echarts from 'echarts';
// import { Dropdown } from 'primereact/dropdown';
// import { Image } from 'primereact/image';
// import logo from "../../../assets/IGS.png";
// import { Menubar } from 'primereact/menubar';

// const ApprovedTimesheetsChart = () => {
//     const [weeks, setWeeks] = useState([]); // Store the available weeks
//     const [selectedWeek, setSelectedWeek] = useState(''); // Store the selected week
//     const [chartData, setChartData] = useState([]); // Store the data for the pie chart

//     // Fetch weeks when component mounts
//     useEffect(() => {
//         fetchWeeks();
//     }, []);

//     // Fetch timesheet data when selectedWeek changes
//     useEffect(() => {
//         if (selectedWeek) {
//             fetchData(selectedWeek);
//         }
//     }, [selectedWeek]);

//     // Function to fetch the available weeks from the API
//     const fetchWeeks = async () => {
//         try {
//             const response = await fetch('http://192.168.1.21:5004/api/v1/admin/reports/getApprovedTimesheetsByEmployeeByWeek/E01550');
//             const data = await response.json();

//             // Check the response structure
//             console.log('Fetched data:', data);

//             // Extract weeks from the API response
//             const weeksData = data.approvedTimesheets?.groupedByWeek; // Access directly from approvedTimesheets
//             console.log('Weeks data:', weeksData); // Log weeks data to verify

//             // Populate available weeks in dropdown
//             if (weeksData) {
//                 // Sort weeks in descending order by date
//                 const sortedWeeks = weeksData.sort((a, b) => new Date(b.week) - new Date(a.week));
//                 setWeeks(sortedWeeks.map((weekItem) => ({
//                     label: weekItem.week,
//                     value: weekItem.week
//                 })));

//                 // Set the latest week as the default selected value
//                 const latestWeek = sortedWeeks[0].week;
//                 setSelectedWeek(latestWeek);
//                 localStorage.setItem('selectedWeek', latestWeek); // Persist the latest week in local storage
//             } else {
//                 console.error('groupedByWeek is undefined or empty');
//             }
//         } catch (error) {
//             console.error('Error fetching weeks:', error);
//         }
//     };

//     // Function to fetch timesheet data based on selected week
//     const fetchData = async (week) => {
//         try {
//             const response = await fetch('http://192.168.1.21:5004/api/v1/admin/reports/getApprovedTimesheetsByEmployeeByWeek/E01550');
//             const data = await response.json();
//             const weeksData = data.approvedTimesheets?.groupedByWeek; // Access directly from approvedTimesheets

//             // Find the data for the selected week
//             const selectedWeekData = weeksData?.find((weekItem) => weekItem.week === week);
//             if (selectedWeekData) {
//                 const aggregatedData = aggregateTaskHours(selectedWeekData.projects);
//                 setChartData(aggregatedData); // Set the chart data
//                 renderChart(aggregatedData); // Render the chart
//             }
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };

//     // Function to aggregate task hours by task name and accumulate projects for each task
//     const aggregateTaskHours = (projects) => {
//         const taskMap = {};

//         projects.forEach((project) => {
//             project.tasks.forEach((task) => {
//                 if (taskMap[task.task]) {
//                     // If task exists, update the total hours and add the project to the list
//                     taskMap[task.task].value += task.totalTaskHours;
//                     taskMap[task.task].projects.push(project.project);
//                 } else {
//                     // If task doesn't exist, create a new entry
//                     taskMap[task.task] = {
//                         name: task.task,
//                         value: task.totalTaskHours,
//                         projects: [project.project]
//                     };
//                 }
//             });
//         });
//         // Transform into array format for pie chart and prepare tooltip format
//         return Object.keys(taskMap).map((taskName) => ({
//             name: `${taskName} (${taskMap[taskName].projects.join(', ')})`,
//             value: taskMap[taskName].value,
//         }));
//     };

//     const renderChart = (data) => {
//         const chartDom = document.getElementById('timesheetChart');
//         const myChart = echarts.init(chartDom);
    
//         const option = {
//             tooltip: {
//                 trigger: 'item',
//                 formatter: '{b}: {c} hours', // Tooltip showing task and total hours
//             },
//             legend: {
//                 show: false, // Hide the legend
//             },
//             series: [
//                 {
//                     name: 'Tasks',
//                     type: 'pie',
//                     radius: '70%', // Outer radius of the pie chart
//                     data: data,
//                     label: {
//                         show: true,
//                         position: 'inside', // Display hours inside the pie slices
//                         formatter: '{c} hours', // Show hours inside the pie slices
//                         fontSize: 12, // Adjust font size as needed
//                         color: '#fff', // Set text color to white for better contrast
//                         overflow: 'break', // Ensure text does not overflow
//                     },
//                     labelLine: {
//                         show: true, // Display the lines (arrows) to outside labels
//                         length: 20, // Length of the line from the pie slice
//                         length2: 10, // Length of the line to the label
//                         smooth: true, // Smooth the line
//                     },
//                     emphasis: {
//                         itemStyle: {
//                             shadowBlur: 10,
//                             shadowOffsetX: 0,
//                             shadowColor: 'rgba(0, 0, 0, 0.5)',
//                         },
//                     },
//                 },
//                 {
//                     name: 'Task Names',
//                     type: 'pie',
//                     radius: ['60%', '70%'], // Inner radius to create space for labels outside
//                     data: data,
//                     label: {
//                         show: true,
//                         position: 'outside', // Display task names outside the pie slices
//                         formatter: '{b}', // Show task names outside
//                         fontSize: 12, // Adjust font size
//                         color: '#000', // Set text color for visibility
//                     },
//                     labelLine: {
//                         show: true, // Display lines (arrows) connecting the labels outside
//                         length: 20, // Length of the line from the pie slice
//                         length2: 10, // Length of the line to the label
//                         smooth: true, // Smooth the line
//                     },
//                     emphasis: {
//                         itemStyle: {
//                             shadowBlur: 10,
//                             shadowOffsetX: 0,
//                             shadowColor: 'rgba(0, 0, 0, 0.5)',
//                         },
//                     },
//                 },
//             ],
//         };
    
//         option && myChart.setOption(option);
//     };
    

//     // Handle week selection from dropdown
//     const handleWeekChange = (e) => {
//         setSelectedWeek(e.value);
//         localStorage.setItem('selectedWeek', e.value); // Persist the selected week in local storage
//     };

//     // Initialize selected week from local storage if available
//     useEffect(() => {
//         const storedWeek = localStorage.getItem('selectedWeek');
//         if (storedWeek) {
//             setSelectedWeek(storedWeek);
//         }
//     }, []);

//     const start = (
//         <div>
//             <p
//                 style={{
//                     color: "#00000080",
//                     fontWeight: "bold",
//                     fontSize: "22px",
//                     margin: "0px",
//                     marginBottom: "5PX",
//                 }}
//             >
//                 Task Analytics by week
//             </p>
//         </div>
//     );
//     const end = (
//         <div style={{ paddingRight: "15px" }}>
//             <Image src={logo} height="60px" width="60px" />
//         </div>
//     );

//     return (
//         <div>
//             <div className='card'>
//                 <Menubar start={start} end={end} />
//             </div>
//             <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 132px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
//                 {/* Week Selection Dropdown */}
//                 <div>
//                     <Dropdown
//                         value={selectedWeek}
//                         options={weeks}
//                         onChange={handleWeekChange}
//                         optionLabel="label"
//                         placeholder="Select a week"
//                         style={{ width: '200px', margin: '10px' }}
//                     />
//                 </div>

//                 <div style={{ margin: '10px' }}>
//                     <div id="timesheetChart" style={{ height: '500px' }}></div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default ApprovedTimesheetsChart;

// import React, { useState, useEffect, useContext } from 'react';
// import * as echarts from 'echarts';
// import { Dropdown } from 'primereact/dropdown';
// import { Image } from 'primereact/image';
// import logo from "../../../assets/IGS.png";
// import { Menubar } from 'primereact/menubar';
// import { UserContext } from '../../../context/UserContext';

// const ApprovedTimesheetsChart = () => {
//     const { user } = useContext(UserContext)
//     const [weeks, setWeeks] = useState([]); // Store the available weeks
//     const [projects, setProjects] = useState([]); // Store the available projects
//     const [selectedWeek, setSelectedWeek] = useState(''); // Store the selected week
//     const [selectedProject, setSelectedProject] = useState(''); // Store the selected project
//     const [chartData, setChartData] = useState([]); // Store the data for the pie chart

//     // Fetch weeks when component mounts
//     useEffect(() => {
//         fetchWeeks();
//     }, []);

//     // Fetch timesheet data when selectedWeek changes
//     useEffect(() => {
//         if (selectedWeek) {
//             fetchData(selectedWeek);
//         }
//     }, [selectedWeek]);

//     // Fetch filtered timesheet data when the project changes
//     useEffect(() => {
//         if (selectedWeek && selectedProject) {
//             fetchData(selectedWeek, selectedProject);
//         }
//     }, [selectedProject]);

//     // Function to fetch the available weeks from the API
//     const fetchWeeks = async () => {
//         try {
//             const response = await fetch(
//                 // 'http://192.168.29.195:5004/api/v1/admin/reports/getApprovedTimesheetsByEmployeeByWeek/E01550'
//                 `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getApprovedTimesheetsByEmployeeByWeek/${user.Data.empId}`
//             );
//             const data = await response.json();

//             // Extract weeks from the API response
//             const weeksData = data.approvedTimesheets?.groupedByWeek;
//             console.log('Weeks data:', weeksData);

//             if (weeksData) {
//                 // Sort weeks in descending order by date
//                 const sortedWeeks = weeksData.sort((a, b) => new Date(b.week) - new Date(a.week));
//                 setWeeks(sortedWeeks.map((weekItem) => ({
//                     label: weekItem.week,
//                     value: weekItem.week
//                 })));

//                 // Set the latest week as the default selected value
//                 const latestWeek = sortedWeeks[0].week;
//                 setSelectedWeek(latestWeek);
//                 localStorage.setItem('selectedWeek', latestWeek);
//             } else {
//                 console.error('groupedByWeek is undefined or empty');
//             }
//         } catch (error) {
//             console.error('Error fetching weeks:', error);
//         }
//     };

//     // Function to fetch timesheet data based on selected week and optionally filter by project
//     const fetchData = async (week, project = '') => {
//         try {
//             const response = await fetch(
//                 // 'http://192.168.29.195:5004/api/v1/admin/reports/getApprovedTimesheetsByEmployeeByWeek/E01550'
//                  `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getApprovedTimesheetsByEmployeeByWeek/${user.Data.empId}`
//             );
//             const data = await response.json();
//             const weeksData = data.approvedTimesheets?.groupedByWeek;

//             // Find the data for the selected week
//             const selectedWeekData = weeksData?.find((weekItem) => weekItem.week === week);
//             if (selectedWeekData) {
//                 const availableProjects = selectedWeekData.projects.map((proj) => ({
//                     label: proj.project,
//                     value: proj.project,
//                 }));
//                 setProjects(availableProjects);

//                 // Filter by project if a project is selected
//                 const filteredProjects = project
//                     ? selectedWeekData.projects.filter((proj) => proj.project === project)
//                     : selectedWeekData.projects;

//                 const aggregatedData = aggregateTaskHours(filteredProjects);
//                 setChartData(aggregatedData); // Set the chart data
//                 renderChart(aggregatedData); // Render the chart
//             }
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };

//     // Function to aggregate task hours by task name and accumulate projects for each task
//     const aggregateTaskHours = (projects) => {
//         const taskMap = {};

//         projects.forEach((project) => {
//             project.tasks.forEach((task) => {
//                 if (taskMap[task.task]) {
//                     taskMap[task.task].value += task.totalTaskHours;
//                     taskMap[task.task].projects.push(project.project);
//                 } else {
//                     taskMap[task.task] = {
//                         name: task.task,
//                         value: task.totalTaskHours,
//                         projects: [project.project]
//                     };
//                 }
//             });
//         });

//         return Object.keys(taskMap).map((taskName) => ({
//             name: `${taskName} (${taskMap[taskName].projects.join(', ')})`,
//             value: taskMap[taskName].value,
//         }));
//     };

//     const renderChart = (data) => {
//         const chartDom = document.getElementById('timesheetChart');
//         const myChart = echarts.init(chartDom);
    
//         const option = {
//             tooltip: {
//                 trigger: 'item',
//                 formatter: '{b}: {c} hours',
//             },
//             legend: {
//                 show: false,
//             },
//             series: [
//                 {
//                     name: 'Tasks',
//                     type: 'pie',
//                     radius: '70%',
//                     data: data,
//                     label: {
//                         show: true,
//                         position: 'inside',
//                         formatter: '{c} hours',
//                         fontSize: 12,
//                         color: '#fff',
//                         overflow: 'break',
//                     },
//                     labelLine: {
//                         show: true,
//                         length: 20,
//                         length2: 10,
//                         smooth: true,
//                     },
//                     emphasis: {
//                         itemStyle: {
//                             shadowBlur: 10,
//                             shadowOffsetX: 0,
//                             shadowColor: 'rgba(0, 0, 0, 0.5)',
//                         },
//                     },
//                 },
//                 {
//                     name: 'Task Names',
//                     type: 'pie',
//                     radius: ['60%', '70%'],
//                     data: data,
//                     label: {
//                         show: true,
//                         position: 'outside',
//                         formatter: '{b}',
//                         fontSize: 12,
//                         color: '#000',
//                     },
//                     labelLine: {
//                         show: true,
//                         length: 20,
//                         length2: 10,
//                         smooth: true,
//                     },
//                     emphasis: {
//                         itemStyle: {
//                             shadowBlur: 10,
//                             shadowOffsetX: 0,
//                             shadowColor: 'rgba(0, 0, 0, 0.5)',
//                         },
//                     },
//                 },
//             ],
//         };
    
//         option && myChart.setOption(option);
//     };

//     // Handle week selection from dropdown
//     const handleWeekChange = (e) => {
//         setSelectedWeek(e.value);
//         localStorage.setItem('selectedWeek', e.value);
//         setSelectedProject(''); // Reset project selection when week changes
//     };

//     // Handle project selection from dropdown
//     const handleProjectChange = (e) => {
//         setSelectedProject(e.value);
//     };

//     // Initialize selected week from local storage if available
//     useEffect(() => {
//         const storedWeek = localStorage.getItem('selectedWeek');
//         if (storedWeek) {
//             setSelectedWeek(storedWeek);
//         }
//     }, []);

//     const start = (
//         <div>
//             <p
//                 style={{
//                     color: "#00000080",
//                     fontWeight: "bold",
//                     fontSize: "22px",
//                     margin: "0px",
//                     marginBottom: "5PX",
//                 }}
//             >
//                 Task Analytics by week
//             </p>
//         </div>
//     );
//     const end = (
//         <div style={{ paddingRight: "15px" }}>
//             <Image src={logo} height="60px" width="60px" />
//         </div>
//     );

//     return (
//         <div>
//             <div className='card'>
//                 <Menubar start={start} end={end} />
//             </div>
//             <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 132px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
//                 {/* Week Selection Dropdown */}
//                 <div style={{display:'flex', justifyContent:'flex-start', gap:'20px'}}>
//                 <div>
//                     <Dropdown
//                         value={selectedWeek}
//                         options={weeks}
//                         onChange={handleWeekChange}
//                         optionLabel="label"
//                         placeholder="Select a week"
//                         style={{ width: '200px', margin: '10px' }}
//                     />
//                 </div>

//                 {/* Project Selection Dropdown */}
//                 <div>
//                     <Dropdown
//                         value={selectedProject}
//                         options={projects}
//                         onChange={handleProjectChange}
//                         optionLabel="label"
//                         placeholder="Select a project"
//                         style={{ width: '200px', margin: '10px' }}
//                         disabled={!projects.length} // Disable if no projects are available
//                     />
//                 </div>
//                 </div>
              

//                 <div style={{ margin: '10px' }}>
//                     <div id="timesheetChart" style={{ width: '100%', height: '500px' }}></div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default ApprovedTimesheetsChart;

// import React, { useState, useEffect, useContext } from 'react';
// import * as echarts from 'echarts';
// import { Dropdown } from 'primereact/dropdown';
// import { Image } from 'primereact/image';
// import logo from "../../../assets/IGS.png";
// import { Menubar } from 'primereact/menubar';
// import { UserContext } from '../../../context/UserContext';

// const ApprovedTimesheetsChart = () => {
//     const { user } = useContext(UserContext);
//     const [weeks, setWeeks] = useState([]); // Store the available weeks
//     const [projects, setProjects] = useState([]); // Store the available projects
//     const [selectedWeek, setSelectedWeek] = useState(''); // Store the selected week
//     const [selectedProject, setSelectedProject] = useState('All'); // Store the selected project
//     const [chartData, setChartData] = useState([]); // Store the data for the pie chart

//     // Fetch weeks when component mounts
//     useEffect(() => {
//         fetchWeeks();
//     }, []);

//     // Fetch timesheet data when selectedWeek or selectedProject changes
//     useEffect(() => {
//         if (selectedWeek) {
//             fetchData(selectedWeek, selectedProject);
//         }
//     }, [selectedWeek, selectedProject]);

//     // Function to fetch the available weeks from the API
//     const fetchWeeks = async () => {
//         try {
//             const response = await fetch(
//                 `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getApprovedTimesheetsByEmployeeByWeek/${user.Data.empId}`
//             );
//             const data = await response.json();

//             // Extract weeks from the API response
//             const weeksData = data.approvedTimesheets?.groupedByWeek;
//             console.log('Weeks data:', weeksData);

//             if (weeksData) {
//                 // Sort weeks in descending order by date
//                 const sortedWeeks = weeksData.sort((a, b) => new Date(b.week) - new Date(a.week));
//                 setWeeks(sortedWeeks.map((weekItem) => ({
//                     label: weekItem.week,
//                     value: weekItem.week
//                 })));

//                 // Set the latest week as the default selected value
//                 const latestWeek = sortedWeeks[0].week;
//                 setSelectedWeek(latestWeek);
//                 localStorage.setItem('selectedWeek', latestWeek);
//                 localStorage.setItem('selectedProject', 'All'); // Set "All" as default
//             } else {
//                 console.error('groupedByWeek is undefined or empty');
//             }
//         } catch (error) {
//             console.error('Error fetching weeks:', error);
//         }
//     };

//     // Function to fetch timesheet data based on selected week and optionally filter by project
//     const fetchData = async (week, project = 'All') => {
//         try {
//             const response = await fetch(
//                 `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getApprovedTimesheetsByEmployeeByWeek/${user.Data.empId}`
//             );
//             const data = await response.json();
//             const weeksData = data.approvedTimesheets?.groupedByWeek;

//             // Find the data for the selected week
//             const selectedWeekData = weeksData?.find((weekItem) => weekItem.week === week);
//             if (selectedWeekData) {
//                 const availableProjects = [{ label: 'All', value: 'All' }, ...selectedWeekData.projects.map((proj) => ({
//                     label: proj.project,
//                     value: proj.project,
//                 }))];
//                 setProjects(availableProjects);

//                 // Filter by project if a project is selected
//                 const filteredProjects = project === 'All'
//                     ? selectedWeekData.projects
//                     : selectedWeekData.projects.filter((proj) => proj.project === project);

//                 const aggregatedData = aggregateTaskHours(filteredProjects);
//                 setChartData(aggregatedData); // Set the chart data
//                 renderChart(aggregatedData); // Render the chart
//             }
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };

//     // Function to aggregate task hours by task name and accumulate projects for each task
//     const aggregateTaskHours = (projects) => {
//         const taskMap = {};

//         projects.forEach((project) => {
//             project.tasks.forEach((task) => {
//                 if (taskMap[task.task]) {
//                     taskMap[task.task].value += task.totalTaskHours;
//                     taskMap[task.task].projects.push(project.project);
//                 } else {
//                     taskMap[task.task] = {
//                         name: task.task,
//                         value: task.totalTaskHours,
//                         projects: [project.project]
//                     };
//                 }
//             });
//         });

//         return Object.keys(taskMap).map((taskName) => ({
//             name: `${taskName} (${taskMap[taskName].projects.join(', ')})`,
//             value: taskMap[taskName].value,
//         }));
//     };

//     const renderChart = (data) => {
//         const chartDom = document.getElementById('timesheetChart');
//         const myChart = echarts.init(chartDom);
    
//         const option = {
//             tooltip: {
//                 trigger: 'item',
//                 formatter: '{b}: {c} hours',
//             },
//             legend: {
//                 show: false,
//             },
//             series: [
//                 {
//                     name: 'Tasks',
//                     type: 'pie',
//                     radius: '70%',
//                     data: data,
//                     label: {
//                         show: true,
//                         position: 'inside',
//                         formatter: '{c} hours',
//                         fontSize: 12,
//                         color: '#fff',
//                         overflow: 'break',
//                     },
//                     labelLine: {
//                         show: true,
//                         length: 20,
//                         length2: 10,
//                         smooth: true,
//                     },
//                     emphasis: {
//                         itemStyle: {
//                             shadowBlur: 10,
//                             shadowOffsetX: 0,
//                             shadowColor: 'rgba(0, 0, 0, 0.5)',
//                         },
//                     },
//                 },
//                 {
//                     name: 'Task Names',
//                     type: 'pie',
//                     radius: ['60%', '70%'],
//                     data: data,
//                     label: {
//                         show: true,
//                         position: 'outside',
//                         formatter: '{b}',
//                         fontSize: 12,
//                         color: '#000',
//                     },
//                     labelLine: {
//                         show: true,
//                         length: 20,
//                         length2: 10,
//                         smooth: true,
//                     },
//                     emphasis: {
//                         itemStyle: {
//                             shadowBlur: 10,
//                             shadowOffsetX: 0,
//                             shadowColor: 'rgba(0, 0, 0, 0.5)',
//                         },
//                     },
//                 },
//             ],
//         };
    
//         option && myChart.setOption(option);
//     };

//     // Handle week selection from dropdown
//     const handleWeekChange = (e) => {
//         setSelectedWeek(e.value);
//         localStorage.setItem('selectedWeek', e.value);
//         setSelectedProject('All'); // Reset project selection to "All" when week changes
//     };

//     // Handle project selection from dropdown
//     const handleProjectChange = (e) => {
//         setSelectedProject(e.value);
//     };

//     // Initialize selected week and project from local storage if available
//     useEffect(() => {
//         const storedWeek = localStorage.getItem('selectedWeek');
//         const storedProject = localStorage.getItem('selectedProject') || 'All';
//         if (storedWeek) {
//             setSelectedWeek(storedWeek);
//         }
//         setSelectedProject(storedProject);
//     }, []);

//     const start = (
//         <div>
//             <p
//                 style={{
//                     color: "#00000080",
//                     fontWeight: "bold",
//                     fontSize: "22px",
//                     margin: "0px",
//                     marginBottom: "5PX",
//                 }}
//             >
//                 Task Analytics by week
//             </p>
//         </div>
//     );
//     const end = (
//         <div style={{ paddingRight: "15px" }}>
//             <Image src={logo} height="60px" width="60px" />
//         </div>
//     );

//     return (
//         <div>
//             <div className='card'>
//                 <Menubar start={start} end={end} />
//             </div>
//             <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 132px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
//                 {/* Week Selection Dropdown */}
//                 <div style={{display:'flex', justifyContent:'flex-start', gap:'15px'}}>
//                     <Dropdown
//                         value={selectedWeek}
//                         options={weeks}
//                         onChange={handleWeekChange}
//                         optionLabel="label"
//                         placeholder="Select a week"
//                         style={{ width: '250px', margin: '10px' }}
//                     />
//                     <Dropdown
//                         value={selectedProject}
//                         options={projects}
//                         onChange={handleProjectChange}
//                         optionLabel="label"
//                         placeholder="Select a project"
//                         style={{ width: '150px', margin: '10px' }}
//                     />
//                 </div>
//                 <div id="timesheetChart" style={{ width: '100%', height: 'calc(100vh - 240px)' }}></div>
//             </div>
//         </div>
//     );
// };

// export default ApprovedTimesheetsChart;

import React, { useState, useEffect, useContext } from 'react';
import * as echarts from 'echarts';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import logo from "../../../assets/IGS.png";
import { Menubar } from 'primereact/menubar';
import { UserContext } from '../../../context/UserContext';

const ApprovedTimesheetsChart = () => {
    const { user } = useContext(UserContext);
    const [weeks, setWeeks] = useState([]); // Store the available weeks
    const [projects, setProjects] = useState([]); // Store the available projects
    const [selectedWeek, setSelectedWeek] = useState(''); // Store the selected week
    const [selectedProject, setSelectedProject] = useState('All'); // Store the selected project
    const [chartData, setChartData] = useState([]); // Store the data for the pie chart

    // Fetch weeks when component mounts
    useEffect(() => {
        fetchWeeks();
    }, []);

    // Fetch timesheet data when selectedWeek or selectedProject changes
    useEffect(() => {
        if (selectedWeek) {
            fetchData(selectedWeek, selectedProject);
        }
    }, [selectedWeek, selectedProject]);

    // Function to fetch the available weeks from the API
    const fetchWeeks = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getApprovedTimesheetsByEmployeeByWeek/${user.Data.empId}`
            );
            const data = await response.json();

            // Extract weeks from the API response
            const weeksData = data.approvedTimesheets?.groupedByWeek;
            console.log('Weeks data:', weeksData);

            if (weeksData) {
                // Sort weeks in descending order by date
                const sortedWeeks = weeksData.sort((a, b) => new Date(b.week) - new Date(a.week));
                setWeeks(sortedWeeks.map((weekItem) => ({
                    label: weekItem.week,
                    value: weekItem.week
                })));

                // Set the latest week as the default selected value
                const latestWeek = sortedWeeks[0].week;
                setSelectedWeek(latestWeek);
                localStorage.setItem('selectedWeek', latestWeek);
                localStorage.setItem('selectedProject', 'All'); // Set "All" as default
            } else {
                console.error('groupedByWeek is undefined or empty');
            }
        } catch (error) {
            console.error('Error fetching weeks:', error);
        }
    };

    // Function to fetch timesheet data based on selected week and optionally filter by project
    const fetchData = async (week, project = 'All') => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getApprovedTimesheetsByEmployeeByWeek/${user.Data.empId}`
            );
            const data = await response.json();
            const weeksData = data.approvedTimesheets?.groupedByWeek;

            // Find the data for the selected week
            const selectedWeekData = weeksData?.find((weekItem) => weekItem.week === week);
            if (selectedWeekData) {
                const availableProjects = [{ label: 'All', value: 'All' }, ...selectedWeekData.projects.map((proj) => ({
                    label: proj.project,
                    value: proj.project,
                }))];
                setProjects(availableProjects);

                // Filter by project if a project is selected
                const filteredProjects = project === 'All'
                    ? selectedWeekData.projects
                    : selectedWeekData.projects.filter((proj) => proj.project === project);

                const aggregatedData = aggregateTaskHours(filteredProjects);
                setChartData(aggregatedData); // Set the chart data
                // renderChart(aggregatedData); // Render the chart
            } else {
                setChartData([]); // No data for the selected week
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Function to aggregate task hours by task name and accumulate projects for each task
    const aggregateTaskHours = (projects) => {
        const taskMap = {};

        projects.forEach((project) => {
            project.tasks.forEach((task) => {
                if (taskMap[task.task]) {
                    taskMap[task.task].value += task.totalTaskHours;
                    taskMap[task.task].projects.push(project.project);
                } else {
                    taskMap[task.task] = {
                        name: task.task,
                        value: task.totalTaskHours,
                        projects: [project.project]
                    };
                }
            });
        });

        return Object.keys(taskMap).map((taskName) => ({
            name: `${taskName} (${taskMap[taskName].projects.join(', ')})`,
            value: taskMap[taskName].value,
        }));
    };

    const renderChart = (data) => {
        const chartDom = document.getElementById('timesheetChart');
        const myChart = echarts.init(chartDom);
    
        const option = {
            tooltip: {
                trigger: 'item',
                formatter: '{b}: {c} hours',
            },
            legend: {
                show: false,
            },
            series: [
                {
                    name: 'Tasks',
                    type: 'pie',
                    radius: '70%',
                    data: data,
                    label: {
                        show: true,
                        position: 'inside',
                        formatter: '{c} hours',
                        fontSize: 12,
                        color: '#fff',
                        overflow: 'break',
                    },
                    labelLine: {
                        show: true,
                        length: 20,
                        length2: 10,
                        smooth: true,
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                },
                {
                    name: 'Task Names',
                    type: 'pie',
                    radius: ['60%', '70%'],
                    data: data,
                    label: {
                        show: true,
                        position: 'outside',
                        formatter: '{b}',
                        fontSize: 12,
                        color: '#000',
                    },
                    labelLine: {
                        show: true,
                        length: 20,
                        length2: 10,
                        smooth: true,
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                },
            ],
        };
    
        option && myChart.setOption(option);
    };
    useEffect(() => {
        if (chartData.length > 0) {
            renderChart(chartData);
        }
    }, [chartData]);
    // Handle week selection from dropdown
    const handleWeekChange = (e) => {
        setSelectedWeek(e.value);
        localStorage.setItem('selectedWeek', e.value);
        setSelectedProject('All'); // Reset project selection to "All" when week changes
    };

    // Handle project selection from dropdown
    const handleProjectChange = (e) => {
        setSelectedProject(e.value);
    };

    // Initialize selected week and project from local storage if available
    useEffect(() => {
        const storedWeek = localStorage.getItem('selectedWeek');
        const storedProject = localStorage.getItem('selectedProject') || 'All';
        if (storedWeek) {
            setSelectedWeek(storedWeek);
        }
        setSelectedProject(storedProject);
    }, []);

    const start = (
        <div>
            <p
                style={{
                    color: "#00000080",
                    fontWeight: "bold",
                    fontSize: "22px",
                    margin: "0px",
                    marginBottom: "5PX",
                }}
            >
                Task Analytics by week
            </p>
        </div>
    );
    const end = (
        <div style={{ paddingRight: "15px" }}>
            <Image src={logo} height="60px" width="60px" />
        </div>
    );

    // Determine if there is any chart data
    const hasData = chartData && chartData.length > 0;

    return (
        <div>
            <div className='card'>
                <Menubar start={start} end={end} />
            </div>
            <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 132px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
                {/* Week Selection Dropdown */}
                <div style={{display:'flex', justifyContent:'flex-start', gap:'15px'}}>
                 <Dropdown
                        value={selectedWeek}
                        options={weeks}
                        onChange={handleWeekChange}
                        optionLabel="label"
                        placeholder="Select a week"
                        style={{ width: '250px', margin: '10px' }}
                    />
                    <Dropdown
                        value={selectedProject}
                        options={projects}
                        onChange={handleProjectChange}
                        optionLabel="label"
                        placeholder="Select a project"
                        style={{ width: '150px', margin: '10px' }}
                    />
                </div>
                {/* Conditional Rendering */}
                {hasData ? (
                    <div id="timesheetChart" style={{ width: '100%', height: 'calc(100vh - 300px)' }}></div>
                ) : (
                    <div style={{ height: 'calc(100vh - 260px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <p style={{ color: '#00000080', fontWeight: 'bold', fontSize: '24px' }}>No Weekly Report</p>
                  </div>
                )}
            </div>
        </div>
    );
};

export default ApprovedTimesheetsChart;
