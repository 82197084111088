

// import React, { useState, useEffect } from 'react';
// import * as echarts from 'echarts';
// import { Dropdown } from 'primereact/dropdown';
// import 'primereact/resources/themes/saga-blue/theme.css';  // or your chosen PrimeReact theme
// import 'primereact/resources/primereact.min.css';  // core styles
// import 'primeicons/primeicons.css';  // icons

// const ChartComponent = () => {
//   const [data, setData] = useState(null);
//   const [selectedMonth, setSelectedMonth] = useState(null);

//   useEffect(() => {
//     // Fetch data from API
//     const fetchData = async () => {
//       try {
//         const response = await fetch('http://192.168.29.195:5004/api/v1/admin/reports/getApprovedTimesheetForParticularEmployeeByMonth/E01550');
//         const result = await response.json();
//         setData(result);

//         // Set default selected month if data is available
//         if (result && result.approvedTimesheets && result.approvedTimesheets.approvedTimesheets && result.approvedTimesheets.approvedTimesheets.groupedByMonth.length > 0) {
//           setSelectedMonth(result.approvedTimesheets.approvedTimesheets.groupedByMonth[0].month);
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (data && selectedMonth) {
//       const monthData = data.approvedTimesheets.approvedTimesheets.groupedByMonth.find(
//         (month) => month.month === selectedMonth
//       );

//       if (monthData) {
//         const weeks = monthData.weeks.map((week) => week.week);
//         const series = [];
//         const colors = ['#ff9999', '#66b3ff', '#99ff99', '#ffcc99', '#c2c2f0', '#ffb3e6', '#c2f0c2']; // Array of colors

//         monthData.weeks.forEach((week) => {
//           week.projects.forEach((project) => {
//             project.tasks.forEach((task, taskIndex) => {
//               const taskSeries = {
//                 name: task.task,
//                 type: 'bar',
//                 stack: project.project,
//                 itemStyle: {
//                   color: colors[taskIndex % colors.length]
//                 },
//                 data: monthData.weeks.map(weekData => {
//                   const projectData = weekData.projects.find(p => p.project === project.project);
//                   if (projectData) {
//                     const taskData = projectData.tasks.find(t => t.task === task.task);
//                     return taskData ? taskData.totalTaskHours : 0;
//                   }
//                   return 0;
//                 })
//               };
//               series.push(taskSeries);
//             });
//           });
//         });

//         const chartDom = document.getElementById('main');
//         const myChart = echarts.init(chartDom);

//         const option = {
//           tooltip: {
//             trigger: 'item',
//             formatter: function (params) {
//               // Find the task series and get the corresponding data
//               const taskSeries = series.find(seriesItem => seriesItem.name === params.seriesName);
//               if (taskSeries) {
//                 const index = taskSeries.data.findIndex((data, idx) => idx === params.dataIndex);
//                 if (index !== -1) {
//                   const task = monthData.weeks.flatMap(week => week.projects.flatMap(project => project.tasks))
//                     .find(task => task.task === params.seriesName);
//                   return `<strong>${params.seriesName}</strong><br/>${task ? `${task.task}: ${task.totalTaskHours} hours` : 'No Data'}`;
//                 }
//               }
//               return 'No Data';
//             }
//           },
//           legend: {
//             data: monthData.weeks.flatMap(week => week.projects.flatMap(project => project.tasks.map(task => task.task))),
//             selectedMode: false
//           },
//           grid: {
//             left: 100,
//             right: 100,
//             top: 50,
//             bottom: 50
//           },
//           yAxis: {
//             type: 'value'
//           },
//           xAxis: {
//             type: 'category',
//             data: weeks
//           },
//           series
//         };

//         myChart.setOption(option);
//       }
//     }
//   }, [data, selectedMonth]);

//   const handleMonthChange = (e) => {
//     setSelectedMonth(e.value);
//   };

//   const monthOptions = data && data.approvedTimesheets && data.approvedTimesheets.approvedTimesheets
//     ? data.approvedTimesheets.approvedTimesheets.groupedByMonth.map((month) => ({
//         label: month.month,
//         value: month.month
//       }))
//     : [];

//   return (
//     <div>
//       <h1>Timesheet Data</h1>
//       <Dropdown
//         value={selectedMonth}
//         options={monthOptions}
//         onChange={handleMonthChange}
//         placeholder="Select a Month"
//       />
//       <div id="main" style={{ width: '100%', height: '500px' }}></div>
//     </div>
//   );
// };

// export default ChartComponent;


// import React, { useState, useEffect } from 'react';
// import * as echarts from 'echarts';
// import { Dropdown } from 'primereact/dropdown';
// import 'primereact/resources/themes/saga-blue/theme.css';  // or your chosen PrimeReact theme
// import 'primereact/resources/primereact.min.css';  // core styles
// import 'primeicons/primeicons.css';  // icons
// import { Image } from 'primereact/image';
// import logo from "../../../assets/IGS.png";
// import { Menubar } from 'primereact/menubar';

// const ChartComponent = () => {
//   const [data, setData] = useState(null);
//   const [selectedMonth, setSelectedMonth] = useState(null);

//   useEffect(() => {
//     // Fetch data from API
//     const fetchData = async () => {
//       try {
//         const response = await fetch('http://192.168.1.14:5004/api/v1/admin/reports/getApprovedTimesheetForParticularEmployeeByMonth/E01550');
//         const result = await response.json();
//         setData(result);

//         // Set default selected month if data is available
//         if (result && result.approvedTimesheets && result.approvedTimesheets.approvedTimesheets && result.approvedTimesheets.approvedTimesheets.groupedByMonth.length > 0) {
//           setSelectedMonth(result.approvedTimesheets.approvedTimesheets.groupedByMonth[0].month);
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (data && selectedMonth) {
//       const monthData = data.approvedTimesheets.approvedTimesheets.groupedByMonth.find(
//         (month) => month.month === selectedMonth
//       );

//       if (monthData) {
//         const weeks = monthData.weeks.map((week) => week.week);
//         const taskMap = {};
//         const totalHoursByWeek = {};

//         monthData.weeks.forEach((week) => {
//           let weekTotal = 0;
//           week.projects.forEach((project) => {
//             project.tasks.forEach((task) => {
//               const taskKey = task.task;
//               if (!taskMap[taskKey]) {
//                 taskMap[taskKey] = {
//                   name: taskKey,
//                   totalHours: Array(weeks.length).fill(0),
//                   projects: []
//                 };
//               }

//               const weekIndex = weeks.indexOf(week.week);
//               taskMap[taskKey].totalHours[weekIndex] += task.totalTaskHours;
//               if (!taskMap[taskKey].projects.includes(project.project)) {
//                 taskMap[taskKey].projects.push(project.project);
//               }
//               weekTotal += task.totalTaskHours;
//             });
//           });
//           totalHoursByWeek[week.week] = weekTotal;
//         });

//         const series = Object.values(taskMap).map((task) => ({
//           name: task.name,
//           type: 'bar',
//           stack: 'total',
//           itemStyle: {
//             color: getRandomColor()
//           },
//           label: {
//             show: false,
//             position: 'top',
//             formatter: '{c} hours'
//           },
//           data: task.totalHours
//         }));

//         const chartDom = document.getElementById('main');
//         const myChart = echarts.init(chartDom);

//         const option = {
//           tooltip: {
//             trigger: 'item',
//             formatter: function (params) {
//               const task = taskMap[params.seriesName];
//               if (task) {
//                 return `<strong>${params.seriesName}</strong><br/>${task.projects.join(', ')}: ${task.totalHours[params.dataIndex]} hours`;
//               }
//               return 'No Data';
//             }
//           },
//           legend: {
//             data: Object.keys(taskMap),
//             selectedMode: false
//           },
//           grid: {
//             left: 50,
//             right: 50,
//             top: 100,
//             bottom: 40,
//           },
//           yAxis: {
//             type: 'value'
//           },
//           xAxis: {
//             type: 'category',
//             data: weeks
//           },
//           series: Object.values(taskMap).map((task) => ({
//             name: task.name,
//             type: 'bar',
//             stack: 'total',
//             itemStyle: {
//               color: getRandomColor()
//             },
//             label: {
//               show: true, // Show the label
//               position: 'inside', // Position the label inside the bar
//               formatter: '{c} hours', // Format the label to show hours
//               color: '#fff' // Set the text color to white for better visibility inside the bars
//             },
//             data: task.totalHours
//           })),
//           dataset: {
//             source: weeks.map(week => ([week, totalHoursByWeek[week]]))
//           },
//         };

//         myChart.setOption(option);
//       }
//     }
//   }, [data, selectedMonth]);

//   const handleMonthChange = (e) => {
//     setSelectedMonth(e.value);
//   };

//   const monthOptions = data && data.approvedTimesheets && data.approvedTimesheets.approvedTimesheets
//     ? data.approvedTimesheets.approvedTimesheets.groupedByMonth.map((month) => ({
//       label: month.month,
//       value: month.month
//     }))
//     : [];

//   const getRandomColor = () => {
//     // Generate a random color
//     const letters = '0123456789ABCDEF';
//     let color = '#';
//     for (let i = 0; i < 6; i++) {
//       color += letters[Math.floor(Math.random() * 16)];
//     }
//     return color;
//   };

//   const start = (
//     <div>
//       <p
//         style={{
//           color: "#00000080",
//           fontWeight: "bold",
//           fontSize: "22px",
//           margin: "0px",
//           marginBottom: "5PX",
//         }}
//       >
//         Task Analytics by Month
//       </p>
//     </div>
//   );
//   const end = (
//     <div style={{ paddingRight: "15px" }}>
//       <Image src={logo} height="60px" width="60px" />
//     </div>
//   );


//   return (
//     <div>
//       <div className='card'>
//         <Menubar start={start} end={end} />
//       </div>
//       <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 132px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
//         <div>
//           <Dropdown
//             value={selectedMonth}
//             options={monthOptions}
//             onChange={handleMonthChange}
//             placeholder="Select a Month"
//             style={{ width: '200px', margin: '10px' }}
//           />
//         </div>

//         <div style={{ height: 'calc(100vh - 260px)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
//           <div style={{ width: '98%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}>
//             <div id="main" style={{ width: '100%', height: '100%', padding: '50px', display:'flex', justifyContent:'center', alignItems:'center' }}></div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ChartComponent;




// import React, { useState, useEffect } from 'react';
// import * as echarts from 'echarts';
// import { Dropdown } from 'primereact/dropdown';
// import 'primereact/resources/themes/saga-blue/theme.css';  // or your chosen PrimeReact theme
// import 'primereact/resources/primereact.min.css';  // core styles
// import 'primeicons/primeicons.css';  // icons
// import { Image } from 'primereact/image';
// import logo from "../../../assets/IGS.png";
// import { Menubar } from 'primereact/menubar';

// const ChartComponent = () => {
//   const [data, setData] = useState(null);
//   const [selectedMonth, setSelectedMonth] = useState(null);

//   useEffect(() => {
//     // Fetch data from API
//     const fetchData = async () => {
//       try {
//         const response = await fetch('http://192.168.1.21:5004/api/v1/admin/reports/getApprovedTimesheetForParticularEmployeeByMonth/E01550');
//         const result = await response.json();
//         setData(result);

//         // Set default selected month if data is available
//         if (result && result.approvedTimesheets && result.approvedTimesheets.approvedTimesheets && result.approvedTimesheets.approvedTimesheets.groupedByMonth.length > 0) {
//           setSelectedMonth(result.approvedTimesheets.approvedTimesheets.groupedByMonth[0].month);
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (data && selectedMonth) {
//       const monthData = data.approvedTimesheets.approvedTimesheets.groupedByMonth.find(
//         (month) => month.month === selectedMonth
//       );

//       if (monthData) {
//         const weeks = monthData.weeks.map((week) => week.week);
//         const taskMap = {};
//         const totalHoursByWeek = {};

//         monthData.weeks.forEach((week) => {
//           let weekTotal = 0;
//           week.projects.forEach((project) => {
//             project.tasks.forEach((task) => {
//               const taskKey = task.task;
//               if (!taskMap[taskKey]) {
//                 taskMap[taskKey] = {
//                   name: taskKey,
//                   totalHours: Array(weeks.length).fill(0),
//                   projects: []
//                 };
//               }

//               const weekIndex = weeks.indexOf(week.week);
//               taskMap[taskKey].totalHours[weekIndex] += task.totalTaskHours;
//               if (!taskMap[taskKey].projects.includes(project.project)) {
//                 taskMap[taskKey].projects.push(project.project);
//               }
//               weekTotal += task.totalTaskHours;
//             });
//           });
//           totalHoursByWeek[week.week] = weekTotal;
//         });

//         // Filter out tasks with all zero hours
//         const filteredTaskMap = Object.fromEntries(
//           Object.entries(taskMap).filter(([taskKey, task]) => task.totalHours.some(hours => hours > 0))
//         );

//         const series = Object.values(filteredTaskMap).map((task) => ({
//           name: task.name,
//           type: 'bar',
//           stack: 'total',
//           itemStyle: {
//             color: getRandomColor()
//           },
//           label: {
//             show: true, // Show the label
//             position: 'inside', // Position the label inside the bar
//             align: 'center', // Center-align the label horizontally
//             verticalAlign: 'middle', // Center-align the label vertically
//             formatter: function (params) {
//               // Only display label if hours > 0
//               return params.value > 0 ? `${params.value} hours` : '';
//             },
//             color: '#fff' // Set the text color to white for better visibility inside the bars
//           },
//           data: task.totalHours
//         }));

//         const chartDom = document.getElementById('main');
//         const myChart = echarts.init(chartDom);

//         const option = {
//           tooltip: {
//             trigger: 'item',
//             formatter: function (params) {
//               const task = filteredTaskMap[params.seriesName];
//               if (task) {
//                 return `<strong>${params.seriesName}</strong><br/>${task.projects.join(', ')}: ${task.totalHours[params.dataIndex]} hours`;
//               }
//               return 'No Data';
//             }
//           },
//           legend: {
//             data: Object.keys(filteredTaskMap),
//             selectedMode: false
//           },
//           grid: {
//             left: 50,
//             right: 50,
//             top: 100,
//             bottom: 40,
//           },
//           yAxis: {
//             type: 'value'
//           },
//           xAxis: {
//             type: 'category',
//             data: weeks
//           },
//           series: series,
//           dataset: {
//             source: weeks.map(week => ([week, totalHoursByWeek[week]]))
//           },
//         };

//         myChart.setOption(option);
//       }
//     }
//   }, [data, selectedMonth]);

//   const handleMonthChange = (e) => {
//     setSelectedMonth(e.value);
//   };

//   const monthOptions = data && data.approvedTimesheets && data.approvedTimesheets.approvedTimesheets
//     ? data.approvedTimesheets.approvedTimesheets.groupedByMonth.map((month) => ({
//       label: month.month,
//       value: month.month
//     }))
//     : [];

//   const getRandomColor = () => {
//     // Generate a random color
//     const letters = '0123456789ABCDEF';
//     let color = '#';
//     for (let i = 0; i < 6; i++) {
//       color += letters[Math.floor(Math.random() * 16)];
//     }
//     return color;
//   };

//   const start = (
//     <div>
//       <p
//         style={{
//           color: "#00000080",
//           fontWeight: "bold",
//           fontSize: "22px",
//           margin: "0px",
//           marginBottom: "5PX",
//         }}
//       >
//         Task Analytics by Month
//       </p>
//     </div>
//   );
//   const end = (
//     <div style={{ paddingRight: "15px" }}>
//       <Image src={logo} height="60px" width="60px" />
//     </div>
//   );

//   return (
//     <div>
//       <div className='card'>
//         <Menubar start={start} end={end} />
//       </div>
//       <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 132px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
//         <div>
//           <Dropdown
//             value={selectedMonth}
//             options={monthOptions}
//             onChange={handleMonthChange}
//             placeholder="Select a Month"
//             style={{ width: '200px', margin: '10px' }}
//           />
//         </div>

//         <div style={{ height: 'calc(100vh - 260px)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
//           <div style={{ width: '98%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}>
//             <div id="main" style={{ width: '100%', height: '100%', padding: '50px', display:'flex', justifyContent:'center', alignItems:'center' }}></div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ChartComponent;

// import React, { useState, useEffect, useContext } from 'react';
// import * as echarts from 'echarts';
// import { Dropdown } from 'primereact/dropdown';
// import 'primereact/resources/themes/saga-blue/theme.css';  // PrimeReact theme
// import 'primereact/resources/primereact.min.css';  // core styles
// import 'primeicons/primeicons.css';  // icons
// import { Image } from 'primereact/image';
// import logo from "../../../assets/IGS.png";
// import { Menubar } from 'primereact/menubar';
// import { UserContext } from '../../../context/UserContext';

// const ChartComponent = () => {
//   const { user } = useContext(UserContext)

//   const [data, setData] = useState(null);
//   const [selectedMonth, setSelectedMonth] = useState(null);
//   const [selectedProject, setSelectedProject] = useState(null);
//   const [projectOptions, setProjectOptions] = useState([]);

//   useEffect(() => {
//     // Fetch data from API
//     const fetchData = async () => {
//       try {
//         const response = await fetch(
//           // 'http://192.168.29.195:5004/api/v1/admin/reports/getApprovedTimesheetForParticularEmployeeByMonth/E01550'
//           `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getApprovedTimesheetForParticularEmployeeByMonth/${user.Data.empId}`
//         );
//         const result = await response.json();
//         setData(result);

//         // Set default selected month if data is available
//         if (result && result.approvedTimesheets && result.approvedTimesheets.approvedTimesheets && result.approvedTimesheets.approvedTimesheets.groupedByMonth.length > 0) {
//           setSelectedMonth(result.approvedTimesheets.approvedTimesheets.groupedByMonth[0].month);
//         }

//         // Prepare project options
//         const projects = result.approvedTimesheets.approvedTimesheets.groupedByMonth.flatMap(month =>
//           month.weeks.flatMap(week => week.projects.map(project => project.project))
//         );
//         const uniqueProjects = [...new Set(projects)]; // Remove duplicates
//         setProjectOptions(uniqueProjects.map(project => ({ label: project, value: project })));
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   // useEffect(() => {
//   //   if (data && selectedMonth) {
//   //     const monthData = data.approvedTimesheets.approvedTimesheets.groupedByMonth.find(
//   //       (month) => month.month === selectedMonth
//   //     );

//   //     if (monthData) {
//   //       const weeks = monthData.weeks.map((week) => week.week);
//   //       const taskMap = {};
//   //       const totalHoursByWeek = {};

//   //       monthData.weeks.forEach((week) => {
//   //         let weekTotal = 0;
//   //         week.projects.forEach((project) => {
//   //           if (!selectedProject || selectedProject === project.project) {
//   //             project.tasks.forEach((task) => {
//   //               const taskKey = task.task;

//   //               if (!taskMap[taskKey]) {
//   //                 taskMap[taskKey] = {
//   //                   name: taskKey,
//   //                   totalHours: Array(weeks.length).fill(0),
//   //                   projects: []
//   //                 };
//   //               }

//   //               const weekIndex = weeks.indexOf(week.week);
//   //               taskMap[taskKey].totalHours[weekIndex] += task.totalTaskHours;
//   //               if (!taskMap[taskKey].projects.includes(project.project)) {
//   //                 taskMap[taskKey].projects.push(project.project);
//   //               }
//   //               weekTotal += task.totalTaskHours;
//   //             });
//   //           }
//   //         });
//   //         totalHoursByWeek[week.week] = weekTotal;
//   //       });

//   //       // Filter out tasks with no valid data
//   //       const filteredTaskMap = Object.fromEntries(
//   //         Object.entries(taskMap).filter(([taskKey, task]) => task.totalHours.some(hours => hours > 0))
//   //       );

//   //       // Construct the series based only on tasks present in the JSON response
//   //       const series = Object.values(filteredTaskMap).map((task) => ({
//   //         name: task.name,
//   //         type: 'bar',
//   //         stack: 'total',
//   //         itemStyle: {
//   //           color: getRandomColor()
//   //         },
//   //         label: {
//   //           show: true,
//   //           position: 'inside',
//   //           align: 'center',
//   //           verticalAlign: 'middle',
//   //           formatter: function (params) {
//   //             return params.value > 0 ? `${params.value} hours` : '';
//   //           },
//   //           color: '#fff'
//   //         },
//   //         data: task.totalHours
//   //       }));

//   //       const chartDom = document.getElementById('main');
//   //       const myChart = echarts.init(chartDom);

//   //       const option = {
//   //         tooltip: {
//   //           trigger: 'item',
//   //           formatter: function (params) {
//   //             const task = filteredTaskMap[params.seriesName];
//   //             if (task) {
//   //               return `<strong>${params.seriesName}</strong><br/>${task.projects.join(', ')}: ${task.totalHours[params.dataIndex]} hours`;
//   //             }
//   //             return '';
//   //           }
//   //         },
//   //         legend: {
//   //           data: Object.keys(filteredTaskMap),
//   //           selectedMode: false
//   //         },
//   //         grid: {
//   //           left: 50,
//   //           right: 50,
//   //           top: 100,
//   //           bottom: 40,
//   //         },
//   //         yAxis: {
//   //           type: 'value'
//   //         },
//   //         xAxis: {
//   //           type: 'category',
//   //           data: weeks
//   //         },
//   //         series: series,
//   //         dataset: {
//   //           source: weeks.map(week => ([week, totalHoursByWeek[week]]))
//   //         },
//   //       };

//   //       myChart.setOption(option);
//   //     }
//   //   }
//   // }, [data, selectedMonth, selectedProject]);

//   useEffect(() => {
//     if (data && selectedMonth) {
//       // Find the month data based on selectedMonth
//       const monthData = data.approvedTimesheets.approvedTimesheets.groupedByMonth.find(
//         (month) => month.month === selectedMonth
//       );

//       if (monthData) {
//         const weeks = monthData.weeks.map((week) => week.week);
//         const taskMap = {};
//         const totalHoursByWeek = {};

//         // Iterate through each week in the selected month
//         monthData.weeks.forEach((week) => {
//           let weekTotal = 0;

//           // Iterate through each project in the week
//           week.projects.forEach((project) => {
//             // If a project is selected, filter by selectedProject
//             if (!selectedProject || selectedProject === project.project) {
//               project.tasks.forEach((task) => {
//                 const taskKey = task.task;

//                 // Initialize taskMap for each task
//                 if (!taskMap[taskKey]) {
//                   taskMap[taskKey] = {
//                     name: taskKey,
//                     totalHours: Array(weeks.length).fill(0),
//                   };
//                 }

//                 const weekIndex = weeks.indexOf(week.week);
//                 taskMap[taskKey].totalHours[weekIndex] += task.totalTaskHours;
//                 weekTotal += task.totalTaskHours;
//               });
//             }
//           });

//           totalHoursByWeek[week.week] = weekTotal;
//         });

//         // Filter out tasks with no valid data
//         const filteredTaskMap = Object.fromEntries(
//           Object.entries(taskMap).filter(([taskKey, task]) => task.totalHours.some((hours) => hours > 0))
//         );

//         // Construct the series based on tasks present in the JSON response
//         const series = Object.values(filteredTaskMap).map((task) => ({
//           name: task.name,
//           type: 'bar',
//           stack: 'total',
//           itemStyle: {
//             color: getRandomColor(),
//           },
//           label: {
//             show: true,
//             position: 'inside',
//             align: 'center',
//             verticalAlign: 'middle',
//             formatter: function (params) {
//               return params.value > 0 ? `${params.value} hours` : '';
//             },
//             color: '#fff',
//           },
//           data: task.totalHours,
//         }));

//         // Set up the chart configuration
//         const chartDom = document.getElementById('main');
//         const myChart = echarts.init(chartDom);

//         const option = {
//           tooltip: {
//             trigger: 'item',
//             formatter: function (params) {
//               const task = filteredTaskMap[params.seriesName];
//               if (task) {
//                 return `<strong>${params.seriesName}</strong><br/>${params.value} hours`;
//               }
//               return '';
//             },
//           },
//           legend: {
//             data: Object.keys(filteredTaskMap),
//             selectedMode: false,
//           },
//           grid: {
//             left: 50,
//             right: 50,
//             top: 100,
//             bottom: 40,
//           },
//           yAxis: {
//             type: 'value',
//           },
//           xAxis: {
//             type: 'category',
//             data: weeks,
//           },
//           series: series,
//           dataset: {
//             source: weeks.map((week) => [week, totalHoursByWeek[week]]),
//           },
//         };

//         myChart.setOption(option);
//       }
//     }
//   }, [data, selectedMonth, selectedProject]);

//   const handleMonthChange = (e) => {
//     setSelectedMonth(e.value);
//   };

//   const handleProjectChange = (e) => {
//     setSelectedProject(e.value);
//   };

//   const monthOptions = data && data.approvedTimesheets && data.approvedTimesheets.approvedTimesheets
//     ? data.approvedTimesheets.approvedTimesheets.groupedByMonth.map((month) => ({
//       label: month.month,
//       value: month.month
//     }))
//     : [];

//   const getRandomColor = () => {
//     const letters = '0123456789ABCDEF';
//     let color = '#';
//     for (let i = 0; i < 6; i++) {
//       color += letters[Math.floor(Math.random() * 16)];
//     }
//     return color;
//   };

//   const start = (
//     <div>
//       <p
//         style={{
//           color: "#00000080",
//           fontWeight: "bold",
//           fontSize: "22px",
//           margin: "0px",
//           marginBottom: "5PX",
//         }}
//       >
//         Task Analytics by Month and Project
//       </p>
//     </div>
//   );
//   const end = (
//     <div style={{ paddingRight: "15px" }}>
//       <Image src={logo} height="60px" width="60px" />
//     </div>
//   );

//   return (
//     <div>
//       <div className='card'>
//         <Menubar start={start} end={end} />
//       </div>
//       <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 132px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
//         <div>
//           <Dropdown
//             value={selectedMonth}
//             options={monthOptions}
//             onChange={handleMonthChange}
//             placeholder="Select a Month"
//             style={{ width: '200px', margin: '10px' }}
//           />
//           <Dropdown
//             value={selectedProject}
//             options={projectOptions}
//             onChange={handleProjectChange}
//             placeholder="Select a Project"
//             style={{ width: '200px', margin: '10px' }}
//           />
//         </div>

//         <div style={{ height: 'calc(100vh - 260px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//           <div style={{ width: '98%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}>
//             <div id="main" style={{ width: '100%', height: '100%', padding: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ChartComponent;


// import React, { useState, useEffect, useContext } from 'react';
// import * as echarts from 'echarts';
// import { Dropdown } from 'primereact/dropdown';
// import 'primereact/resources/themes/saga-blue/theme.css';  // PrimeReact theme
// import 'primereact/resources/primereact.min.css';  // core styles
// import 'primeicons/primeicons.css';  // icons
// import { Image } from 'primereact/image';
// import logo from "../../../assets/IGS.png";
// import { Menubar } from 'primereact/menubar';
// import { UserContext } from '../../../context/UserContext';

// const ChartComponent = () => {
//   const { user } = useContext(UserContext);

//   const [data, setData] = useState(null);
//   const [selectedMonth, setSelectedMonth] = useState(null);
//   const [selectedProject, setSelectedProject] = useState('All'); // Set default to 'All'
//   const [projectOptions, setProjectOptions] = useState([]);
//   const [monthOptions, setMonthOptions] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getApprovedTimesheetForParticularEmployeeByMonth/${user.Data.empId}`
//         );
//         const result = await response.json();
//         setData(result);

//         // Prepare month options and set default selected month if available
//         if (result && result.approvedTimesheets && result.approvedTimesheets.approvedTimesheets) {
//           const months = result.approvedTimesheets.approvedTimesheets.groupedByMonth;
//           setMonthOptions(months.map(month => ({ label: month.month, value: month.month })));
//           setSelectedMonth(months[0].month); // Set default selected month

//           // Prepare project options including "All"
//           const projects = ['All', ...months.flatMap(month =>
//             month.weeks.flatMap(week => week.projects.map(project => project.project))
//           )];
//           const uniqueProjects = [...new Set(projects)]; // Remove duplicates
//           setProjectOptions(uniqueProjects.map(project => ({ label: project, value: project })));
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, [user.Data.empId]);

//   useEffect(() => {
//     if (data && selectedMonth) {
//       const monthData = data.approvedTimesheets.approvedTimesheets.groupedByMonth.find(
//         (month) => month.month === selectedMonth
//       );

//       if (monthData) {
//         const weeks = monthData.weeks.map((week) => week.week);
//         const taskMap = {};
//         const totalHoursByWeek = {};

//         monthData.weeks.forEach((week) => {
//           let weekTotal = 0;

//           week.projects.forEach((project) => {
//             if (!selectedProject || selectedProject === 'All' || selectedProject === project.project) {
//               project.tasks.forEach((task) => {
//                 const taskKey = task.task;

//                 if (!taskMap[taskKey]) {
//                   taskMap[taskKey] = {
//                     name: taskKey,
//                     totalHours: Array(weeks.length).fill(0),
//                   };
//                 }

//                 const weekIndex = weeks.indexOf(week.week);
//                 taskMap[taskKey].totalHours[weekIndex] += task.totalTaskHours;
//                 weekTotal += task.totalTaskHours;
//               });
//             }
//           });

//           totalHoursByWeek[week.week] = weekTotal;
//         });

//         const filteredTaskMap = Object.fromEntries(
//           Object.entries(taskMap).filter(([taskKey, task]) => task.totalHours.some((hours) => hours > 0))
//         );

//         const series = Object.values(filteredTaskMap).map((task) => ({
//           name: task.name,
//           type: 'bar',
//           stack: 'total',
//           itemStyle: {
//             color: getRandomColor(),
//           },
//           label: {
//             show: true,
//             position: 'inside',
//             align: 'center',
//             verticalAlign: 'middle',
//             formatter: function (params) {
//               return params.value > 0 ? `${params.value} hours` : '';
//             },
//             color: '#fff',
//           },
//           data: task.totalHours,
//         }));

//         const chartDom = document.getElementById('main');
//         const myChart = echarts.init(chartDom);

//         const option = {
//           tooltip: {
//             trigger: 'item',
//             formatter: function (params) {
//               const task = filteredTaskMap[params.seriesName];
//               if (task) {
//                 return `<strong>${params.seriesName}</strong><br/>${params.value} hours`;
//               }
//               return '';
//             },
//           },
//           legend: {
//             data: Object.keys(filteredTaskMap),
//             selectedMode: false,
//           },
//           grid: {
//             left: 50,
//             right: 50,
//             top: 100,
//             bottom: 40,
//           },
//           yAxis: {
//             type: 'value',
//           },
//           xAxis: {
//             type: 'category',
//             data: weeks,
//           },
//           series: series,
//           dataset: {
//             source: weeks.map((week) => [week, totalHoursByWeek[week]]),
//           },
//         };

//         myChart.setOption(option);
//       }
//     }
//   }, [data, selectedMonth, selectedProject]);

//   const handleMonthChange = (e) => {
//     setSelectedMonth(e.value);
//     setSelectedProject('All'); // Reset to "All" when month changes
//   };

//   const handleProjectChange = (e) => {
//     setSelectedProject(e.value);
//   };

//   const getRandomColor = () => {
//     const letters = '0123456789ABCDEF';
//     let color = '#';
//     for (let i = 0; i < 6; i++) {
//       color += letters[Math.floor(Math.random() * 16)];
//     }
//     return color;
//   };

//   const start = (
//     <div>
//       <p
//         style={{
//           color: "#00000080",
//           fontWeight: "bold",
//           fontSize: "22px",
//           margin: "0px",
//           marginBottom: "5PX",
//         }}
//       >
//         Task Analytics by Month and Project
//       </p>
//     </div>
//   );
//   const end = (
//     <div style={{ paddingRight: "15px" }}>
//       <Image src={logo} height="60px" width="60px" />
//     </div>
//   );

//   return (
//     <div>
//       <div className='card'>
//         <Menubar start={start} end={end} />
//       </div>
//       <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 132px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
//         <div>
//           <Dropdown
//             value={selectedMonth}
//             options={monthOptions}
//             onChange={handleMonthChange}
//             placeholder="Select a Month"
//             style={{ width: '200px', margin: '10px' }}
//           />
//           <Dropdown
//             value={selectedProject}
//             options={projectOptions}
//             onChange={handleProjectChange}
//             placeholder="Select a Project"
//             style={{ width: '200px', margin: '10px' }}
//           />
//         </div>

//         <div style={{ height: 'calc(100vh - 260px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//           <div style={{ width: '98%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}>
//             <div id="main" style={{ width: '100%', height: '100%', padding: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ChartComponent;



import React, { useState, useEffect, useContext } from 'react';
import * as echarts from 'echarts';
import { Dropdown } from 'primereact/dropdown';
import 'primereact/resources/themes/saga-blue/theme.css';  // PrimeReact theme
import 'primereact/resources/primereact.min.css';  // core styles
import 'primeicons/primeicons.css';  // icons
import { Image } from 'primereact/image';
import logo from "../../../assets/IGS.png";
import { Menubar } from 'primereact/menubar';
import { UserContext } from '../../../context/UserContext';

const ChartComponent = () => {
  const { user } = useContext(UserContext);

  const [data, setData] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedProject, setSelectedProject] = useState('All'); // Set default to 'All'
  const [projectOptions, setProjectOptions] = useState([]);
  const [monthOptions, setMonthOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getApprovedTimesheetForParticularEmployeeByMonth/${user.Data.empId}`
        );
        const result = await response.json();
        setData(result);

        // Prepare month options and set default selected month if available
        if (result && result.approvedTimesheets && result.approvedTimesheets.approvedTimesheets) {
          const months = result.approvedTimesheets.approvedTimesheets.groupedByMonth;
          setMonthOptions(months.map(month => ({ label: month.month, value: month.month })));
          setSelectedMonth(months[0].month); // Set default selected month

          // Prepare project options including "All"
          const projects = ['All', ...months.flatMap(month =>
            month.weeks.flatMap(week => week.projects.map(project => project.project))
          )];
          const uniqueProjects = [...new Set(projects)]; // Remove duplicates
          setProjectOptions(uniqueProjects.map(project => ({ label: project, value: project })));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [user.Data.empId]);

// Define a fixed set of colors to be used consistently

const colorPalette = [
  '#2874a6', '#33FF57', '#3357FF', '#FF33A1', '#33FFF6',
  '#FF8C33', '#A133FF', '#33FF8C', '#FF5733', '#FF3380'
];

// Hash function to generate a consistent color index based on task name
const getColorForTask = (taskName) => {
  let hash = 0;
  for (let i = 0; i < taskName.length; i++) {
    hash = taskName.charCodeAt(i) + ((hash << 5) - hash);
  }
  return colorPalette[Math.abs(hash) % colorPalette.length];
};

useEffect(() => {
  if (data && selectedMonth) {
    const monthData = data.approvedTimesheets.approvedTimesheets.groupedByMonth.find(
      (month) => month.month === selectedMonth
    );

    if (monthData) {
      const weeks = monthData.weeks.map((week) => week.week);
      const taskMap = {};
      const totalHoursByWeek = {};

      monthData.weeks.forEach((week) => {
        let weekTotal = 0;

        week.projects.forEach((project) => {
          if (!selectedProject || selectedProject === 'All' || selectedProject === project.project) {
            project.tasks.forEach((task) => {
              const taskKey = task.task;

              if (!taskMap[taskKey]) {
                taskMap[taskKey] = {
                  name: taskKey,
                  totalHours: Array(weeks.length).fill(0),
                };
              }

              const weekIndex = weeks.indexOf(week.week);
              taskMap[taskKey].totalHours[weekIndex] += task.totalTaskHours;
              weekTotal += task.totalTaskHours;
            });
          }
        });

        totalHoursByWeek[week.week] = weekTotal;
      });

      const filteredTaskMap = Object.fromEntries(
        Object.entries(taskMap).filter(([taskKey, task]) => task.totalHours.some((hours) => hours > 0))
      );

      const series = Object.values(filteredTaskMap).map((task) => ({
        name: task.name,
        type: 'bar',
        stack: 'total',
        itemStyle: {
          color: getColorForTask(task.name), // Use the fixed color function
        },
        label: {
          show: true,
          position: 'inside',
          align: 'center',
          verticalAlign: 'middle',
          formatter: function (params) {
            return params.value > 0 ? `${params.value} hours` : '';
          },
          color: '#fff',
        },
        data: task.totalHours,
      }));

      const chartDom = document.getElementById('main');
      const myChart = echarts.init(chartDom);

      const option = {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            const task = filteredTaskMap[params.seriesName];
            if (task) {
              return `<strong>${params.seriesName}</strong><br/>${params.value} hours`;
            }
            return '';
          },
        },
        legend: {
          data: Object.keys(filteredTaskMap),
          selectedMode: false,
        },
        grid: {
          left: 50,
          right: 50,
          top: 100,
          bottom: 40,
        },
        yAxis: {
          type: 'value',
        },
        xAxis: {
          type: 'category',
          data: weeks,
        },
        series: series,
        dataset: {
          source: weeks.map((week) => [week, totalHoursByWeek[week]]),
        },
      };

      myChart.setOption(option);
    }
  }
}, [data, selectedMonth, selectedProject]);
  const handleMonthChange = (e) => {
    setSelectedMonth(e.value);
    setSelectedProject('All'); // Reset to "All" when month changes
  };

  const handleProjectChange = (e) => {
    setSelectedProject(e.value);
  };

  // const getRandomColor = () => {
  //   const letters = '0123456789ABCDEF';
  //   let color = '#';
  //   for (let i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //   }
  //   return color;
  // };

  const start = (
    <div>
      <p
        style={{
          color: "#00000080",
          fontWeight: "bold",
          fontSize: "22px",
          margin: "0px",
          marginBottom: "5PX",
        }}
      >
        Task Analytics by Month and Project
      </p>
    </div>
  );
  const end = (
    <div style={{ paddingRight: "15px" }}>
      <Image src={logo} height="60px" width="60px" />
    </div>
  );

  return (
    <div>
      <div className='card'>
        <Menubar start={start} end={end} />
      </div>
      <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 132px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
        <div>
          <Dropdown
            value={selectedMonth}
            options={monthOptions}
            onChange={handleMonthChange}
            placeholder="Select a Month"
            style={{ width: '200px', margin: '10px' }}
          />
          <Dropdown
            value={selectedProject}
            options={projectOptions}
            onChange={handleProjectChange}
            placeholder="Select a Project"
            style={{ width: '200px', margin: '10px' }}
          />
        </div>

        {data && data.approvedTimesheets && data.approvedTimesheets.approvedTimesheets && data.approvedTimesheets.approvedTimesheets.groupedByMonth.length > 0 ? (
          <div style={{ height: 'calc(100vh - 260px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ width: '98%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}>
              <div id="main" style={{ width: '100%', height: '100%', padding: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
            </div>
          </div>
        ) : (
          <div style={{ height: 'calc(100vh - 260px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <p style={{ color: '#00000080', fontWeight: 'bold', fontSize: '24px' }}>No Monthly Report</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChartComponent;



