import React from "react";
import { Image } from "primereact/image";
import logo from "../../../assets/IGS.png";
import { Menubar } from "primereact/menubar";
import TimesheetApprovalChart from "./charts/TimesheetApprovalChart";
import RejectedTimesheetChart from "./charts/RejectedTimesheetChar";
import TimesheetChartByWeek from "./charts/TimesheetChartByWeek";
import TimesheetChartByMonth from "./charts/TimesheetChartByMonth";
export default function UserReport() {
  const start = (
    <div>
      <p
        style={{
          color: "#00000080",
          fontWeight: "bold",
          fontSize: "22px",
          margin: "0px",
          marginBottom: "5PX",
        }}
      >
        Reports
      </p>
    </div>
  );
  const end = (
    <div style={{ paddingRight: "15px" }}>
      <Image src={logo} height="60px" width="60px" />
    </div>
  );
  return (
    <div>
      <div className="card">
        <Menubar start={start} end={end} />
      </div>
      <div
        style={{
          backgroundColor: "#f8f9fa",
          height: 'calc(100vh - 132px)',
          boxShadow: "0 10px 10px -5px rgba(0, 0, 0, 0.3)",
          border: "1px solid rgba(128, 128, 128, 0.211)",
          marginTop: "10px",
          overflow: "scroll",
          position: "relative",
        }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            margin: "5px",
            gap:'10px'
          }}
        >
          <div
            style={{
              width: "50%",
              boxSizing: "border-box",
              paddingBottom: "20px",
            }}
          >
            <TimesheetChartByWeek
              style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}
            />
          </div>
          <div
            style={{
              width: "50%",
              boxSizing: "border-box",
              paddingBottom: "20px",
            }}
          >
            <TimesheetChartByMonth
              style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}
            />
          </div>
        </div>
        <div 
         style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          margin: "5px",
          gap:'10px'
        }}>
        <div
            style={{
              width: "50%",
              boxSizing: "border-box",
              paddingBottom: "20px",
            }}
          >
            <RejectedTimesheetChart style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }} />
          </div>
          <div
            style={{
              width: "50%",
              boxSizing: "border-box",
              paddingBottom: "20px",
            }}
          >
            <TimesheetApprovalChart style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }} />
          </div>
        </div>
      </div>
    </div>
  );
}
