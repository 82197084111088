import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const WeekCalendarHeader = ({ weekRange, setWeekRange }) => {

  const calculateInitialWeekRange = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const sunday = new Date(today);
    sunday.setDate(today.getDate() - dayOfWeek); // Set to Sunday
    const saturday = new Date(sunday);
    saturday.setDate(sunday.getDate() + 6); // Set to Saturday
    return { start: sunday, end: saturday };
  };

  useEffect(() => {
    setWeekRange(calculateInitialWeekRange());
  }, [setWeekRange]);

  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };



  return (
    <div
      className="flex justify-between items-center py-2 px-4 bg-white border-b border-gray-200"
      style={{ alignItems: "center", gap: "10px" }}
    >
      <div className="flex-grow flex justify-center items-center">
        <span className="font-semibold">
          {formatDate(weekRange.start)} - {formatDate(weekRange.end)}
        </span>
      </div>
   
    </div>
  );
};

export default WeekCalendarHeader;