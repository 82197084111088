

// we are add data done only comes after refresh
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddSubProject from "./Button/AddSubProject";
import AddData from "./Button/AddData";
import AddCategory from "./Button/AddCategory";
import { Image } from 'primereact/image';
import logo from "../../../assets/IGS.png"
import { Menubar } from 'primereact/menubar';
import { TbArrowBack } from "react-icons/tb";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from "primereact/dialog";
const ProjectDataTable = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const project = location.state?.projects;
  const projectId = project?.project_details?.project_id;
  const projectName = project?.project_details?.name;
  const [projectData, setProjectData] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(""); // Project or SubProject selected
  const [categoryName, setCategoryName] = useState("All"); // Selected category
  const [searchTerm, setSearchTerm] = useState("");
  const [categories, setCategories] = useState([]); // Dynamically added categories
  const [showAddSubProject, setShowAddSubProject] = useState(false);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [showAddData, setShowAddData] = useState(false);
  // Fetch project and sub-project data
  // Ensure that projectData is defined and has the necessary properties
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/subProject/fetchSubProjectData/${projectId}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setProjectData(data);
          if (!selectedEntity && data.project_details?.name) {
            setSelectedEntity(data.project_details.name); // default to project name
          }
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [projectId, selectedEntity]);

  // Get categories from masterTask and subProjectTask
  const getCategories = (masterTask, subProjectTask) => {
    const masterCategories = masterTask
      ? masterTask.categories.map((category) => category.category_name)
      : [];
    const subProjectCategories = subProjectTask
      ? subProjectTask.categories.map((category) => category.category_name)
      : [];
    // Add new categories dynamically
    return [
      ...new Set([...masterCategories, ...subProjectCategories, ...categories]),
    ];
  };

  // Get category data (master and sub-project level)
  const getCategoryData = (masterTask, subProjectTask) => {
    if (!masterTask && !subProjectTask) return [];

    if (categoryName === "All") {
      const masterData = masterTask
        ? masterTask.categories.map((category) => category.data).flat()
        : [];
      const subProjectData = subProjectTask
        ? subProjectTask.categories.map((category) => category.data).flat()
        : [];
      return { masterData, subProjectData };
    } else {
      const masterCategory = masterTask
        ? masterTask.categories.find(
          (cat) => cat.category_name === categoryName
        )
        : null;
      const subProjectCategory = subProjectTask
        ? subProjectTask.categories.find(
          (cat) => cat.category_name === categoryName
        )
        : null;
      const masterData = masterCategory ? masterCategory.data : [];
      const subProjectData = subProjectCategory ? subProjectCategory.data : [];
      return { masterData, subProjectData };
    }
  };

  // Get master and sub-project tasks based on selected entity
  const getMasterAndSubProjectTask = () => {
    if (!projectData || !projectData.sub_projects) {
      return { masterTask: null, subProjectTask: null };
    }

    const subProject = projectData.sub_projects.find((sub) => sub.name === selectedEntity);

    if (subProject) {
      return {
        masterTask: subProject.masterTask,
        subProjectTask: subProject.subProjectTask || null,
      };
    } else if (projectData.project_details?.name === selectedEntity) {
      return {
        masterTask: projectData.project_details.masterTask,
        subProjectTask: null,
      };
    }

    return { masterTask: null, subProjectTask: null };
  };


  // Get the list of entity names (project and sub-project)
  const getEntityNames = () => {
    if (!projectData || !projectData.project_details) return [];

    const projectNames = [projectData.project_details.name];
    const subProjectNames = projectData.sub_projects
      ? projectData.sub_projects.map((subProject) => subProject.name)
      : [];
    return [...projectNames, ...subProjectNames];
  };

  // Filter data based on search term
  // const getFilteredData = (categoryData) => {
  //   return categoryData.filter((task) => {
  //     const searchString = `${task.ToDS_Ref || task["ToDS Ref"]} ${task.Type} ${task.Task_Description || task["Task Description"]
  //       } ${task.Test_Units || task["Test Units"]} ${task.Unit_of_Measure || task["Unit of Measure"]
  //       } ${task.Category}`;
  //     return searchString.toLowerCase().includes(searchTerm.toLowerCase());
  //   });
  // };

  // Filter data based on search term
  const getFilteredData = (categoryData) => {
    if (!categoryData || !Array.isArray(categoryData)) {
      return []; // Return an empty array if categoryData is undefined or not an array
    }
    return categoryData.filter((task) => {
      // Ensure the task is defined and is an object
      if (!task || typeof task !== 'object') {
        return false; // Exclude undefined or non-object tasks
      }
      // Destructure properties safely
      const {
        ToDS_Ref,
        Type,
        Task_Description,
        Test_Units,
        Unit_of_Measure,
        Category
      } = task;
      // Create a search string from the available properties
      const searchString = `${ToDS_Ref || task["ToDS Ref"] || ''} ${Type || ''} ${Task_Description || task["Task Description"] || ''} ${Test_Units || ''} ${Unit_of_Measure || task["Unit of Measure"] || ''} ${Category || ''}`;
      // Check if the search string includes the search term
      return searchString.toLowerCase().includes(searchTerm.toLowerCase());
    });
  };


  // Handle sub-project addition
  const handleSubProjectAdded = (newSubProject) => {
    setProjectData((prevData) => ({
      ...prevData,
      sub_projects: [...prevData.sub_projects, newSubProject],
    }));
    fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/fetchSubProjectData/${projectId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch updated data');
        }
        return response.json();
      })
      .then((data) => {
        setProjectData(data); // Update the state with the latest data
      })
      .catch((error) => console.error("Error fetching updated data:", error));
  };

  // Handle category addition
  const handleCategoryAdded = (newCategory) => {
    setCategories((prevCategories) => [...prevCategories, newCategory]);
  };


  const handleDataAdded = (newData) => {
    const { sub_project_name, categories } = newData;

    setProjectData((prevData) => {
      const updatedSubProjects = prevData.sub_projects.map((subProject) => {
        if (subProject.name === sub_project_name) {
          // Check if the subproject already has categories
          const updatedCategories = subProject.subProjectTask.categories.map((cat) => {
            if (cat.category_name === categories[0].category_name) {
              return {
                ...cat,
                data: [...cat.data, ...categories[0].data], // Merge the new data with existing data
              };
            }
            return cat;
          });

          return {
            ...subProject,
            subProjectTask: {
              ...subProject.subProjectTask,
              categories: updatedCategories.length > 0 ? updatedCategories : categories, // Add categories if they don't exist
            },
          };
        }
        return subProject;
      });

      return {
        ...prevData,
        sub_projects: updatedSubProjects,
      };
    });

    // Trigger a re-fetch of data after adding new data
    fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/subProject/fetchSubProjectData/${projectId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch updated data');
        }
        return response.json();
      })
      .then((data) => {
        setProjectData(data); // Update the state with the latest data
      })
      .catch((error) => console.error("Error fetching updated data:", error));
  };



  if (!projectData) {
    return <div>Loading...</div>;
  }

  const { masterTask, subProjectTask } = getMasterAndSubProjectTask();
  const { masterData, subProjectData } = getCategoryData(
    masterTask,
    subProjectTask
  );
  const filteredMasterData = getFilteredData(masterData);
  const filteredSubProjectData = getFilteredData(subProjectData);

  const isProjectSelected = projectData && selectedEntity === projectData.project_details?.name;
  const isSubProjectSelected = !isProjectSelected;
  const isCategoryAll = categoryName === "All"; // Category is "All"
  const isSpecificCategorySelected = categoryName !== "All"; // A specific category is selected
  const combinedData = [...filteredMasterData, ...filteredSubProjectData];

  const start = (
    <div style={{ display: "flex", alignItems: 'center' }}>
      <button className="rounded-full m-2 hover:bg-gray-200" style={{ height: '40px', width: '40px' }} onClick={() => navigate(-1)}>
        <TbArrowBack />
      </button>
      <div style={{ color: "#00000080" }}>
        <h3>{project.project_details.name} Tasks</h3>
      </div>
    </div>
  );

  const end = (
    <div style={{ paddingRight: '15px' }}>
      <Image src={logo} height='60px' width='60px' />
    </div>
  );
  return (
    <div>
      <div className="card">
        <Menubar start={start} end={end} />
      </div>
      <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 140px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
        <div style={{ margin: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ gap: '10px', display: 'flex', justifyContent: 'flex-start' }}>
              <div>
                <Dropdown
                  value={selectedEntity}
                  options={
                    getEntityNames().length > 0
                      ? getEntityNames().map((name) => ({ label: name, value: name }))
                      : [{ label: 'No Data Available', value: '' }]
                  }
                  onChange={(e) => setSelectedEntity(e.value)}
                  placeholder="Select Project or Sub Project"
                  style={{ width: '12vw' }}
                  disabled={getEntityNames().length === 0}
                />
              </div>
              <div>
                <Dropdown
                  value={categoryName}
                  options={[
                    { label: 'All', value: 'All' },
                    ...getCategories(masterTask, subProjectTask).map((cat) => ({ label: cat, value: cat }))
                  ]}
                  onChange={(e) => setCategoryName(e.value)}
                  placeholder="Select Category"
                  style={{ width: '12vw' }}
                />
              </div>
              <div>
                <InputText
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ width: '12vw' }}
                />
              </div>
            </div>
            <div style={{ gap: '20px' }}>
              <div>
                {isProjectSelected && (
                  <Button
                    label="Sub Project"
                    icon="pi pi-plus"
                    onClick={() => setShowAddSubProject(true)}
                    style={{ backgroundColor: '#182452' }}
                  />
                )}
              </div>
              <div>
                {isSubProjectSelected && (
                  <>
                    <div style={{ display: 'flex', gap: '20px' }}>
                      <div>
                        {isCategoryAll && (
                          <div>
                            <Button
                              label="Sub Project"
                              icon="pi pi-plus"
                              onClick={() => setShowAddSubProject(true)}
                              style={{ backgroundColor: '#182452' }}
                            />
                          </div>

                        )}
                      </div>
                      <div style={{ display: 'flex', gap: '20px' }}>
                        <div>
                          <Button
                            label="Category"
                            icon="pi pi-plus"
                            onClick={() => setShowAddCategory(true)}
                            style={{ backgroundColor: '#182452' }}
                          />
                        </div>
                        <div>
                          {isSubProjectSelected && isSpecificCategorySelected && (
                            <Button
                              label="Task"
                              icon="pi pi-plus"
                              onClick={() => setShowAddData(true)}
                              style={{ backgroundColor: '#182452' }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

          </div>
          <div style={{ minHeight: '400px', position: 'relative', marginTop: '20px' }}>
            {combinedData.length === 0 ? (
              // Display this when no task data is found
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <h2>No Task Found</h2>
              </div>
            ) : (
              // Render DataTable when there is task data
              <DataTable
                value={combinedData}
                paginator
                rows={10}
                emptyMessage={<div style={{ height: '40px' }}></div>} // Empty space for missing rows
                style={{ height: '100%', paddingBottom: '50px' }} // Space for paginator
              >
                <Column
                  field="ToDS_Ref"
                  header="ToDS Ref"
                  body={(data) => data.ToDS_Ref || data['ToDS Ref']}
                  style={{ border: '1px solid #ccc' }}
                />
                <Column field="Type" header="Type" style={{ border: '1px solid #ccc' }} />
                <Column
                  field="Task_Description"
                  header="Task Description"
                  body={(data) => data.Task_Description || data['Task Description']}
                  style={{ border: '1px solid #ccc' }}
                />
                <Column
                  field="Test_Units"
                  header="Test Units"
                  body={(data) => data.Test_Units || data['Test Units']}
                  style={{ border: '1px solid #ccc' }}
                />
                <Column
                  field="Unit_of_Measure"
                  header="Unit of Measure"
                  body={(data) => data.Unit_of_Measure || data['Unit of Measure']}
                  style={{ border: '1px solid #ccc' }}
                />
                <Column field="Category" header="Category" style={{ border: '1px solid #ccc' }} />
              </DataTable>
            )}
          </div>


        </div>


      </div>



      {/* Dialog for AddSubProject */}
      <Dialog
        visible={showAddSubProject}
        onHide={() => setShowAddSubProject(false)}
      >
        <AddSubProject
          projectId={projectId}
          onSubProjectAdded={handleSubProjectAdded}
          visible={showAddSubProject}
          onHide={() => setShowAddSubProject(false)}
        />
      </Dialog>

      {/* Dialog for AddCategory */}
      <Dialog
        visible={showAddCategory}
        onHide={() => setShowAddCategory(false)}
      >
        <AddCategory
          projectName={projectName}
          subProjectName={selectedEntity}
          onCategoryAdded={handleCategoryAdded}
          visible={showAddCategory}
          onHide={() => setShowAddCategory(false)}
        />
      </Dialog>

      {/* Dialog for AddData */}
      <Dialog
        visible={showAddData}
        onHide={() => setShowAddData(false)}
      >
        <AddData
          projectName={projectName}
          subProjectName={selectedEntity}
          categoryName={categoryName}
          onDataAdded={handleDataAdded}
          visible={showAddData}
          onHide={() => setShowAddData(false)}
        />
      </Dialog>
    </div>
  );
};

export default ProjectDataTable;
