import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { Image } from "primereact/image";
import { Menubar } from "primereact/menubar";
import logo from "../../../../assets/IGS.png";

const AllocationChart = () => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState([]);
  const [drilldownData, setDrilldownData] = useState(null); // For handling drilldown
  const navigate = useNavigate(); // Initialize navigate

  // Fetching the data from your API
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getAllocationChart`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setChartData(data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Initialize the chart after data is fetched
  useEffect(() => {
    if (chartData.length > 0 || drilldownData) {
      const chartInstance = echarts.init(chartRef.current);

      // Base chart options (before drilldown)
      const option = {
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          type: 'category',
          name: 'Allocation Percentage',
          nameLocation: 'middle',
          nameGap: 30,
          nameTextStyle: {
            fontSize: 16, // Set font size for x-axis name
            fontWeight: 'bold', // Set font weight for x-axis name
          },
          data: (drilldownData || chartData).map(item => item.allocation_range),
          axisLabel: {
            fontSize: 14, // Set font size for x-axis labels
            fontWeight: 'bold', // Set font weight for x-axis labels
          },
        },
        yAxis: {
          type: 'value',
          name: 'Number of Employees',
          nameLocation: 'middle',
          nameRotate: 90,
          nameGap: 50,
          nameTextStyle: {
            fontSize: 16, // Set font size for y-axis name
            fontWeight: 'bold', // Set font weight for y-axis name
          },
          axisLabel: {
            fontSize: 14, // Set font size for y-axis labels
            fontWeight: 'bold', // Set font weight for y-axis labels
          },
        },
        series: [
          {
            name: 'Employee Count:',
            data: (drilldownData || chartData).map(item => item.employee_count),
            type: 'bar',
            barWidth: '50%',
            itemStyle: {
              color: '#000066', // Set the bar color to #000066
            },
            label: {
              show: true, // Show labels on top of each bar
              position: 'top', // Position the labels at the top of the bars
              formatter: '{c} Employee', // Use {c} to display the data value (employee count)
              color: '#000', // Set the label text color to black for visibility
            },
          },
        ],
      };
      
      

      chartInstance.setOption(option);

      // Add click functionality to navigate on bar click
      chartInstance.on('click', function (params) {
        const selectedCategory = params.name;

        // Check if the clicked category is '0-25%'
        if (selectedCategory === '0-25%') {
          navigate('/0-25%'); // Navigate to '/0-25%' route
        }
        if (selectedCategory === '25-50%') {
            navigate('/25-50%'); // Navigate to '/0-25%' route
          } 
          if (selectedCategory === '50-75%') {
            navigate('/50-75%'); // Navigate to '/0-25%' route
          } 
          if (selectedCategory === '75-100%') {
            navigate('/75-100%'); // Navigate to '/0-25%' route
          } else if (drilldownData) {
          // Handle drilldown data as per your logic
          const sanitizedCategory = selectedCategory.replace(/\s/g, '').replace('%', ''); // Sanitize category for URL
          navigate(`/${sanitizedCategory}`); // Example of navigating for other categories
        }
      });

      // Cleanup the chart on component unmount
      return () => {
        chartInstance.dispose();
      };
    }
  }, [chartData, drilldownData, navigate]);

  const start = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ color: '#00000080' }}>
        <h2>Allocation Report</h2>
      </div>
    </div>
  );

  const end = (
    <div style={{ paddingRight: '15px' }}>
      <Image src={logo} height="60px" width="60px" />
    </div>
  );

  return (
    <div>
       <div style={{ marginBottom: '20px' }}>
        <Menubar start={start} end={end} />
      </div>
      <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 150px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
      <div style={{ height: 'calc(100vh - 300px)', display: 'flex', alignItems: 'center', justifyContent: 'center', margin:'20px' }}>
      <div ref={chartRef} style={{ width: '99%', height: '100%' }} />
      {/* Add a back button to return from drilldown */}
      {drilldownData && (
        <button onClick={() => setDrilldownData(null)}>Back</button>
      )}
      </div>
      </div>
   
     
    </div>
  );
};

export default AllocationChart;
