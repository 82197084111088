// src/components/ProjectDetailsCard.js
import React from 'react';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import logo from "../../../assets/IGS.png"


const ProjectDetailsCard = ({ projectData }) => {
  return (
    <div style={{ width: '60%' }}>
      <div>
      <div>
        <p className="text-md font-bold">Project Managers:</p>
        <div className="flex justify-start items-center mr-4">
          <div className="grid grid-cols-5 gap-2">
            {projectData.project_details.project_managers.map((manager, index) => (
              <div key={index} className="pl-2">{manager.name}</div>
            ))}
          </div>
        
        </div>
      </div>
      <p className="text-md font-bold">Team Roles:</p>
      <div className="flex">
        <div className="grid grid-cols-5 gap-2">
          {projectData.project_details.team_roles.map((role, index) => (
            <div key={index} className="w-40 h-8 bg-gray-300 rounded-lg flex justify-center items-center mr-2">
              {role.name.value}
            </div>
          ))}
        </div>
      </div>
      </div>
  
    </div>
  );
};

export default ProjectDetailsCard;
