// import React from 'react'

// export default function CalculateHour(daySum = []) {
//     const totalSum = Array.isArray(daySum) 
//     ? daySum.reduce((accumulator, current) => {
//         return accumulator + (current?.total || 0);
//       }, 0)
//     : 0;
//   return (
//     <div
//     style={{
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "space-between",
//     }}
//   >
//     <div
//       style={{
//         marginTop: "10px",
//         background: "#D0CEC7FF",
//         width: "92.75%",
//         borderRadius: "5px",
//         padding: "10px",
//         boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
//         display: "flex",
//       }}
//     >
//       <div
//         style={{
//           fontSize: "22px",
//           color: "171A1FFF",
//           width: "45%",
//           fontWeight: "500",
//         }}
//       >
//         Total
//       </div>
//       <div
//         style={{
//           width: "7.85%",
//           display: "flex",
//           justifyContent: "center",
//           fontSize: "18px",
//           fontWeight: "bold",
//           color: "#29295FFF",
//         }}
//       >
//         {daySum[0]?.total}hr
//       </div>

//       <div
//         style={{
//           width: "7.85%",
//           display: "flex",
//           justifyContent: "center",
//           fontSize: "18px",
//           fontWeight: "bold",
//           color: "#29295FFF",
//         }}
//       >
//         {daySum[1]?.total}hr
//       </div>
//       <div
//         style={{
//           width: "7.85%",
//           display: "flex",
//           justifyContent: "center",
//           fontSize: "18px",
//           fontWeight: "bold",
//           color: "#29295FFF",
//         }}
//       >
//         {daySum[2]?.total}hr
//       </div>
//       <div
//         style={{
//           width: "7.85%",
//           display: "flex",
//           justifyContent: "center",
//           fontSize: "18px",
//           fontWeight: "bold",
//           color: "#29295FFF",
//         }}
//       >
//         {daySum[3]?.total}hr
//       </div>
//       <div
//         style={{
//           width: "7.85%",
//           display: "flex",
//           justifyContent: "center",
//           fontSize: "18px",
//           fontWeight: "bold",
//           color: "#29295FFF",
//         }}
//       >
//         {daySum[4]?.total}hr
//       </div>
//       <div
//         style={{
//           width: "7.85%",
//           display: "flex",
//           justifyContent: "center",
//           fontSize: "18px",
//           fontWeight: "bold",
//           color: "#29295FFF",
//         }}
//       >
//         {daySum[5]?.total}hr
//       </div>
//       <div
//         style={{
//           width: "7.85%",
//           display: "flex",
//           justifyContent: "center",
//           fontSize: "18px",
//           fontWeight: "bold",
//           color: "#29295FFF",
//         }}
//       >
//         {daySum[6]?.total}hr
//       </div>
//     </div>

//     <div
//       style={{
//         width: "6.85%",
//         background: "#DEE1E6FF",
//         borderRadius: "5px",
//         padding: "10px",
//         boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
//         marginTop: "10px",
//         fontSize: "22px",
//         fontWeight: "bold",
//         color: "#29295FFF",
//         display: "flex",
//         justifyContent: "center",
//       }}
//     >
//       {totalSum}hr
//     </div>
//   </div>
//   )
// }


import React from 'react';

const CalculateHour = ({ daySum }) => {
    const totalHours = daySum.reduce((accumulator, current) => {
        return accumulator + (current?.total || 0);
    }, 0);

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            <div
                style={{
                    marginTop: "10px",
                    background: "#D0CEC7FF",
                    width: "92.75%",
                    borderRadius: "5px",
                    padding: "10px",
                    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
                    display: "flex",
                }}
            >
                <div
                    style={{
                        fontSize: "22px",
                        color: "171A1FFF",
                        width: "45%",
                        fontWeight: "500",
                    }}
                >
                    Total
                </div>
                {["sun", "mon", "tue", "wed", "thu", "fri", "sat"].map((day, index) => (
                    <div
                        key={day}
                        style={{
                            width: "7.85%",
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "18px",
                            fontWeight: "bold",
                            color: "#29295FFF",
                        }}
                    >
                        {daySum[index]?.total || 0}hr
                    </div>
                ))}
            </div>

            <div
                style={{
                    width: "6.85%",
                    background: "#DEE1E6FF",
                    borderRadius: "5px",
                    padding: "10px",
                    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
                    marginTop: "10px",
                    fontSize: "22px",
                    fontWeight: "bold",
                    color: "#29295FFF",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                {totalHours}hr
            </div>
        </div>
    );
};


export default CalculateHour;
