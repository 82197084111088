// import React, { useState, useEffect, useContext } from 'react';
// import { Dropdown } from 'primereact/dropdown';
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';
// import { format, parse } from 'date-fns'; // For date handling
// import { UserContext } from "../../../context/UserContext";
// import { Image } from 'primereact/image';
// import logo from "../../../assets/IGS.png";
// import { Menubar } from 'primereact/menubar';

// const TimesheetReport = () => {
//     const { user } = useContext(UserContext)
//     const [selectedStatus, setSelectedStatus] = useState(null);
//     const [tableData, setTableData] = useState([]);
//     const [totalCount, setTotalCount] = useState(0);
//     const [months, setMonths] = useState([]);
//     const [selectedMonth, setSelectedMonth] = useState(null);

//     const statusOptions = [
//         { label: 'Approved', value: 'approved' },
//         { label: 'Rejected', value: 'rejected' }
//     ];

//     useEffect(() => {
//         if (selectedStatus) {
//             // Determine which API to call based on the selected status
//             const apiUrl = selectedStatus === 'approved'
//                 ? `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getApprovedTimesheetForEmployee/${user.Data.empId}`
//                 : `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getRejectedTimesheetsForEmployee/${user.Data.empId}`;

//             fetch(apiUrl)
//                 .then(response => response.json())
//                 .then(data => {
//                     // Adjust for both approved and rejected API responses
//                     const timesheets = selectedStatus === 'approved'
//                         ? data.approvedTimesheets.approvedTimesheets
//                         : data.rejectedTimesheets.rejectedTimesheets;

//                     const totalCount = selectedStatus === 'approved'
//                         ? data.approvedTimesheets.Totalapprovedcount
//                         : data.rejectedTimesheets.TotalRejectedcount;

//                     setTableData(timesheets);
//                     setTotalCount(totalCount);

//                     // Extract unique months and years from the weeks (parsed in 'dd/MM/yyyy' format)
//                     const uniqueMonths = [...new Set(timesheets.map(item => {
//                         const [startDate] = item.week.split(' - ');
//                         const parsedDate = parse(startDate, 'dd/MM/yyyy', new Date());
//                         return format(parsedDate, 'MMMM yyyy'); // Get month and year (e.g., September 2024)
//                     }))];
//                     const monthOptions = uniqueMonths.map(month => ({ label: month, value: month }));
//                     setMonths(monthOptions);
//                 })
//                 .catch(error => console.error("Error fetching data:", error));
//         }
//     }, [selectedStatus]);

//     const handleStatusChange = (e) => {
//         setSelectedStatus(e.value);
//         setSelectedMonth(null); // Reset selected month when status changes
//     };

//     const handleMonthChange = (e) => {
//         setSelectedMonth(e.value);
//     };

//     // Function to style the status
//     const statusBodyTemplate = (rowData) => {
//         const color = rowData.status === 'rejected' ? 'red' : 'green'; // Red for rejected, green for approved
//         return (
//             <span style={{ color }}>
//                 {rowData.status}
//             </span>
//         );
//     };

//     const start = (
//         <div>
//             <p
//                 style={{
//                     color: "#00000080",
//                     fontWeight: "bold",
//                     fontSize: "22px",
//                     margin: "0px",
//                     marginBottom: "5PX",
//                 }}
//             >
//                 Task Analytics by Approved and Reject
//             </p>
//         </div>
//     );
//     const end = (
//         <div style={{ paddingRight: "15px" }}>
//             <Image src={logo} height="60px" width="60px" />
//         </div>
//     );

//     return (
//         <div>
//             <div className='card'>
//                 <Menubar start={start} end={end} />
//             </div>
//             <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 132px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
//                 <div
//                     style={{
//                         margin: '20px',
//                         padding: '20px',
//                         border: '1px solid #ccc',
//                         boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//                         borderRadius: '8px',
//                         height: '45vh', // Set height relative to viewport
//                         display: 'flex',
//                         flexDirection: 'column',
//                         justifyContent: 'space-between'
//                     }}
//                 >
//                     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
//                         <Dropdown
//                             value={selectedStatus}
//                             options={statusOptions}
//                             onChange={handleStatusChange}
//                             placeholder="Select Status"
//                         />
//                         {selectedStatus && (
//                             <Dropdown
//                                 value={selectedMonth}
//                                 options={months}
//                                 onChange={handleMonthChange}
//                                 placeholder="Select Month"
//                                 style={{ marginLeft: '10px' }}
//                             />
//                         )}
//                         <div style={{ fontWeight: 'bold' }}>
//                             Total {selectedStatus === 'approved' ? 'Approved' : 'Rejected'}: {totalCount}
//                         </div>
//                     </div>

//                     {/* Display the table only when a month is selected */}
//                     <div>
//                         {selectedMonth && (
//                             <DataTable value={tableData.filter(item => {
//                                 const [startDate] = item.week.split(' - ');
//                                 const parsedDate = parse(startDate, 'dd/MM/yyyy', new Date());
//                                 const monthYear = format(parsedDate, 'MMMM yyyy');
//                                 return monthYear === selectedMonth;
//                             })}
//                                 style={{ border: '1px solid #ddd', borderRadius: '8px', overflowY: 'auto' }} // Responsive height for DataTable
//                                 emptyMessage="No records found">
//                                 <Column field="week" header="Week" ></Column>
//                                 <Column field="totalTimesheetCount" header="No. of TImesheet" ></Column>
//                                 <Column field="status" header="Status" body={statusBodyTemplate} ></Column>
//                             </DataTable>
//                         )}
//                     </div>
//                 </div>
//             </div>

//         </div>




//     );
// };

// export default TimesheetReport;

import React, { useState, useEffect, useContext } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { format, parse } from 'date-fns'; // For date handling
import { UserContext } from "../../../context/UserContext";
import { Image } from 'primereact/image';
import logo from "../../../assets/IGS.png";
import { Menubar } from 'primereact/menubar';

const TimesheetReport = () => {
    const { user } = useContext(UserContext)
    const [selectedStatus, setSelectedStatus] = useState('approved'); // Default status is approved
    const [tableData, setTableData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [months, setMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);

    const statusOptions = [
        { label: 'Approved', value: 'approved' },
        { label: 'Rejected', value: 'rejected' }
    ];

    useEffect(() => {
        if (selectedStatus) {
            const apiUrl = selectedStatus === 'approved'
                ? `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getApprovedTimesheetForEmployee/${user.Data.empId}`
                : `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getRejectedTimesheetsForEmployee/${user.Data.empId}`;

            fetch(apiUrl)
                .then(response => response.json())
                .then(data => {
                    const timesheets = selectedStatus === 'approved'
                        ? data.approvedTimesheets.approvedTimesheets
                        : data.rejectedTimesheets.rejectedTimesheets;

                    const totalCount = selectedStatus === 'approved'
                        ? data.approvedTimesheets.Totalapprovedcount
                        : data.rejectedTimesheets.TotalRejectedcount;

                    setTableData(timesheets);
                    setTotalCount(totalCount);

                    // Extract unique months and years from the weeks
                    const uniqueMonths = [...new Set(timesheets.map(item => {
                        const [startDate] = item.week.split(' - ');
                        const parsedDate = parse(startDate, 'dd/MM/yyyy', new Date());
                        return format(parsedDate, 'MMMM yyyy'); // e.g., September 2024
                    }))];
                    const monthOptions = uniqueMonths.map(month => ({ label: month, value: month }));
                    setMonths(monthOptions);
                })
                .catch(error => console.error("Error fetching data:", error));
        }
    }, [selectedStatus]);

    const handleStatusChange = (e) => {
        setSelectedStatus(e.value);
        setSelectedMonth(null); // Reset selected month when status changes
    };

    const handleMonthChange = (e) => {
        setSelectedMonth(e.value);
    };

    const statusBodyTemplate = (rowData) => {
        const color = rowData.status === 'rejected' ? 'red' : 'green';
        return (
            <span style={{ color }}>
                {rowData.status}
            </span>
        );
    };

    const start = (
        <div>
            <p style={{
                color: "#00000080",
                fontWeight: "bold",
                fontSize: "22px",
                margin: "0px",
                marginBottom: "5PX",
            }}>
                Task Analytics by Approved and Reject
            </p>
        </div>
    );

    const end = (
        <div style={{ paddingRight: "15px" }}>
            <Image src={logo} height="60px" width="60px" />
        </div>
    );

    // Filter the tableData based on the selected month
    const filteredData = selectedMonth ? tableData.filter(item => {
        const [startDate] = item.week.split(' - ');
        const parsedDate = parse(startDate, 'dd/MM/yyyy', new Date());
        const monthYear = format(parsedDate, 'MMMM yyyy');
        return monthYear === selectedMonth;
    }) : tableData; // Show all data if no month is selected

    return (
        <div>
            <div className='card'>
                <Menubar start={start} end={end} />
            </div>
            <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 132px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
                <div style={{
                    margin: '20px',
                    padding: '20px',
                    border: '1px solid #ccc',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                        <Dropdown
                            value={selectedStatus}
                            options={statusOptions}
                            onChange={handleStatusChange}
                            placeholder="Select Status"
                        />
                        {selectedStatus && (
                            <Dropdown
                                value={selectedMonth}
                                options={months}
                                onChange={handleMonthChange}
                                placeholder="Select Month"
                                style={{ marginLeft: '10px' }}
                            />
                        )}
                        <div style={{ fontWeight: 'bold' }}>
                            Total {selectedStatus === 'approved' ? 'Approved' : 'Rejected'}: {totalCount}
                        </div>
                    </div>

                    <div>
                        <DataTable value={filteredData}
                            style={{ border: '1px solid #ddd', borderRadius: '8px' }} // Responsive height for DataTable
                            emptyMessage="No records found">
                            <Column field="week" header="Week" />
                            <Column field="totalTimesheetCount" header="No. of TImesheet" />
                            <Column field="status" header="Status" body={statusBodyTemplate} />
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TimesheetReport;
