// import React, {useState} from 'react';
// import { Dropdown } from 'primereact/dropdown';
// import { InputText } from 'primereact/inputtext';
// import { Card } from 'primereact/card';

// const BenchPool = ({
//   projectData,
//   searchTerm,
//   setSearchTerm,
//   filteredUser = [], // Default to an empty array if not provided
//   handleUserClick,
//   hierarchyData,
//   onHierarchyChange,
// }) => {
 
//   const [selectedKey, setSelectedKey] = useState(null); // Declare selectedKey state here

//   const handleDropdownChange = (e) => {
//     const key = e.value;
//     setSelectedKey(key); // Update the state with the selected value
//     console.log('Selected Key:', key);
//     onHierarchyChange(key);
//   };
//   // Filter users by name based on search term
//   const filteredUsersByName = Array.isArray(filteredUser)
//     ? filteredUser.filter(user =>
//       user.name.toLowerCase().includes(searchTerm.toLowerCase())
//     )
//     : [];

//   console.log("data", projectData);

//   return (
//     <div className="w-6/12 p-0 mr-2">
//       <div style={{textAlign:'center'}}>
//       {/* <h4 style={{color:'#495057'}}>Bench Pool</h4> */}
//       </div>
//       <Card className="w-full">
//         <Dropdown
//         value={selectedKey}
//           options={hierarchyData.map((item) => ({
//             label: item.designation,
//             value: item.hkey,
//           }))}
//           placeholder="Select a Designation"
//           className="w-full md:w-18rem mb-2"
//           onChange={handleDropdownChange}
//         />

//         <div
//           style={{
//             background: "#f9f9f9",
//             margin: "5px",
//             borderRadius: "5px",
//             boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.2)",
//             padding: "5px",
//             border: "1px solid #EBEBEB",
//           }}
//         >
//           <div className="flex mt-3 w-full">

//             <div
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 gap: "15px",
//                 width: "100%",
//               }}
//             >
//               <i className="pi pi-search" style={{ fontSize: "1rem" }}></i>
//               <InputText
//                 placeholder="Search User"
//                 style={{ borderRadius: "5px", width: "80%" }}
//                 onChange={(e) => setSearchTerm(e.target.value)}
//               />
//             </div>
//           </div>
//           <div style={{ maxHeight: '500px', overflowY: 'auto' }} className="mt-2">
//             {filteredUsersByName.length > 0 ? (
//               filteredUsersByName.map((user, index) => (
//                 <div
//                   key={user}
//                   onClick={() => handleUserClick(user)}
//                   style={{
//                     margin: "5px",
//                     background: "#FFFFFF",
//                     padding: "15px",
//                     borderRadius: "5px",
//                     boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.2)",
//                     marginTop: "10px",
//                     border: "1px solid #EBEBEB",
//                     cursor: "pointer",
//                   }}
//                 >
             
//                   <div style={{ fontWeight: "500", color: "#373D3F", fontSize:'16px' }}>
//                     {user.name}
//                   </div>
                
//                   <div
//                     style={{
//                       fontWeight: "bold",
//                       color: "#00000080",
//                       marginTop: "8px",
//                     }}
//                   >
//                     Allocation:{" "}
//                     <span style={{ color: "green" }}>
//                       100%
//                     </span>
//                   </div>
//                 </div>
//               ))
//             ) : (
//               <div className="text-center p-3">No users found</div>
//             )}
//           </div>
//         </div>
//       </Card>
//     </div>
//   );
// };

// export default BenchPool;


import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';

const BenchPool = ({
  projectData,
  searchTerm,
  setSearchTerm,
  filteredUser, // No default value here
  handleUserClick,
  hierarchyData,
  onHierarchyChange,
}) => {
  const [selectedKey, setSelectedKey] = useState('All'); // Set 'All' as the initial value
  const [allUsers, setAllUsers] = useState([]); // Ensure allUsers is an array

  const fetchAllUsers = async (retry = 3) => {
    try {
      const apiUrl = `${process.env.REACT_APP_SERVICE_URL}${process.env.REACT_APP_SERVICE_LAYER_ENDPOINT}/serviceLayerAllFilter/All`;
      console.log('API URL:', apiUrl); // Log the API URL to ensure it’s correct
      const response = await fetch(apiUrl);

      if (!response.ok) {
        if (response.status === 404 && retry > 0) {
          console.warn('404 error, retrying...', retry);
          return fetchAllUsers(retry - 1); // Retry the fetch if 404
        }
        throw new Error(`API call failed: ${response.status}`);
      }

      const data = await response.json();
      return Array.isArray(data) ? data : [];
    } catch (error) {
      console.error('Error fetching all users after retries:', error);
      return [];
    }
  };

  useEffect(() => {
    const fetchDataOnMount = async () => {
      try {
        if (selectedKey === 'All' || !selectedKey) {
          const fetchedUsers = await fetchAllUsers();
          setAllUsers(fetchedUsers);
          onHierarchyChange(fetchedUsers); // Pass all users to the parent
        }
      } catch (error) {
        console.error('Error fetching all users on mount:', error);
      }
    };

    // Fetch all users when the component mounts
    fetchDataOnMount();
  }, []); // Empty dependency array ensures it runs only once on mount

  const handleDropdownChange = async (e) => {
    const key = e.value;
    setSelectedKey(key);

    if (key === 'All') {
      const fetchedUsers = await fetchAllUsers();
      setAllUsers(fetchedUsers);
      onHierarchyChange(fetchedUsers); // Pass all users to the parent
    } else {
      onHierarchyChange(key);
    }
  };

  const filteredUsersByName = selectedKey === 'All'
  ? allUsers.filter(user => user.name && user.name.toLowerCase().includes(searchTerm.toLowerCase()))
  : Array.isArray(filteredUser)
    ? filteredUser.filter(user => user.name && user.name.toLowerCase().includes(searchTerm.toLowerCase()))
    : [];


  return (
    <div className="w-6/12 p-0 mr-2">
      <Card className="w-full">
        <Dropdown
          value={selectedKey} // The selectedKey state is now initialized with 'All'
          options={[
            { label: 'All', value: 'All' }, // Add "All" option
            ...hierarchyData.map((item) => ({
              label: item.designation,
              value: item.hkey,
            })),
          ]}
          placeholder="Select a Designation" // Placeholder won't be shown since "All" is selected by default
          className="w-full md:w-18rem mb-2"
          onChange={handleDropdownChange}
        />

        <div
          style={{
            background: "#f9f9f9",
            margin: "5px",
            borderRadius: "5px",
            boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.2)",
            padding: "5px",
            border: "1px solid #EBEBEB",
          }}
        >
          <div className="flex mt-3 w-full">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
                width: "100%",
              }}
            >
              <i className="pi pi-search" style={{ fontSize: "1rem" }}></i>
              <InputText
                placeholder="Search User"
                style={{ borderRadius: "5px", width: "80%" }}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div style={{ maxHeight: '500px', overflowY: 'auto' }} className="mt-2">
            {filteredUsersByName.length > 0 ? (
              filteredUsersByName.map((user) => (
                <div
                  key={user.empId} // Use empId as the key
                  onClick={() => handleUserClick(user)}
                  style={{
                    margin: "5px",
                    background: "#FFFFFF",
                    padding: "15px",
                    borderRadius: "5px",
                    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.2)",
                    marginTop: "10px",
                    border: "1px solid #EBEBEB",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ fontWeight: "500", color: "#373D3F", fontSize: '16px' }}>
                    {user.name}
                  </div>
                  <div
                    style={{
                      fontWeight: "bold",
                      color: "#00000080",
                      marginTop: "8px",
                      fontSize:"16px"
                    }}
                  >
                    Designation:{" "}
                    <span style={{ color: "green" }}>
                      {user.designation}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center p-3">No users found</div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default BenchPool;




