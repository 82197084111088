// import React, { useEffect, useRef, useState } from "react";
// import { Panel } from "primereact/panel";
// import { Chart } from "chart.js/auto";

// export default function RejectedTimesheetChart() {
//     const chartRef = useRef(null);
//     const [chartData, setChartData] = useState({
//         labels: [], // Project names will go here
//         datasets: [
//             {
//                 label: "Rejected Timesheets",
//                 data: [], // Dynamic data for rejected timesheets
//                 backgroundColor: "#FF6384", // Set different color for rejected timesheets
//             },
//         ],
//     });
//     const [tooltips, setTooltips] = useState([]); // Store tooltips for each project

//     useEffect(() => {
//         const fetchTimesheetData = async () => {
//             try {
//                 const response = await fetch(
//                     "http://192.168.1.20:5004/api/v1/admin/reports/getRejectedTimesheetsForEmployee/E04105"
//                 );
//                 const result = await response.json();

//                 const projectLabels = [];
//                 const rejectedData = [];
//                 const projectTooltips = [];

//                 // Process the rejected timesheets from the response
//                 result.rejectedTimesheets.forEach((project) => {
//                     projectLabels.push(project.projectName); // Use only project names as labels
//                     rejectedData.push(project.totalRejectedTimesheetCount); // Use total rejected timesheet count for each project

//                     // Generate tooltips by combining project and task names
//                     const tooltipText = `${project.projectName}: ${project.taskNames.flat().join(", ")}`;
//                     projectTooltips.push(tooltipText);
//                 });

//                 setChartData({
//                     labels: projectLabels,
//                     datasets: [
//                         {
//                             label: "Rejected Timesheets",
//                             data: rejectedData,
//                             backgroundColor: "#FF6384",
//                         },
//                     ],
//                 });

//                 setTooltips(projectTooltips);
//             } catch (error) {
//                 console.error("Error fetching data:", error);
//             }
//         };

//         fetchTimesheetData();
//     }, []);

//     useEffect(() => {
//         const ctx = chartRef.current.getContext("2d");

//         const myChart = new Chart(ctx, {
//             type: "bar", // Bar chart to show rejected timesheets
//             data: chartData,
//             options: {
//                 responsive: true,
//                 plugins: {
//                     tooltip: {
//                         callbacks: {
//                             // Custom tooltip handler to show project name and task names
//                             label: function (context) {
//                                 return tooltips[context.dataIndex];
//                             },
//                         },
//                     },
//                     legend: {
//                         position: "top",
//                     },
//                 },
//                 scales: {
//                     x: {
//                         beginAtZero: true,
//                     },
//                     y: {
//                         beginAtZero: true,
//                     },
//                 },
//             },
//         });

//         return () => {
//             myChart.destroy();
//         };
//     }, [chartData, tooltips]);

//     return (
//         <Panel
//             header="Rejected Timesheets by Project"
//             style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}
//         >
//             <canvas ref={chartRef} />
//         </Panel>
//     );
// }

// import React, { useState } from 'react';
// import { Dropdown } from 'primereact/dropdown';
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';

// const TimesheetReport = () => {
//   const [selectedStatus, setSelectedStatus] = useState(null);
//   const [tableData, setTableData] = useState([]);
//   const [totalCount, setTotalCount] = useState(0);

//   const statusOptions = [
//     { label: 'Approved', value: 'approved' },
//     { label: 'Rejected', value: 'rejected' }
//   ];

//   const handleStatusChange = (e) => {
//     setSelectedStatus(e.value);
//     const apiUrl = e.value === 'approved' 
//       ? 'http://192.168.1.20:5004/api/v1/admin/reports/getApprovedTimesheetsForEmployee/E04105'
//       : 'http://192.168.1.20:5004/api/v1/admin/reports/getRejectedTimesheetsForEmployee/E04105';

//     fetch(apiUrl)
//       .then(response => response.json())
//       .then(data => {
//         setTableData(data.rejectedTimesheets.rejectedTimesheets);
//         setTotalCount(data.rejectedTimesheets.TotalRejectedcount);
//       })
//       .catch(error => console.error("Error fetching data:", error));
//   };

//   // Function to style the status
//   const statusBodyTemplate = (rowData) => {
//     return (
//       <span style={{ color: rowData.status === 'rejected' ? 'red' : 'black' }}>
//         {rowData.status}
//       </span>
//     );
//   };

//   return (
//     <div style={{ padding: '20px', border: '1px solid #ccc', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
//       <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
//         <Dropdown
//           value={selectedStatus}
//           options={statusOptions}
//           onChange={handleStatusChange}
//           placeholder="Select Status"
//         />
//         <div style={{ fontWeight: 'bold' }}>
//           Total {selectedStatus === 'approved' ? 'Approved' : 'Rejected'}: {totalCount}
//         </div>
//       </div>

//       <DataTable value={tableData} style={{ border: '1px solid #ddd', borderRadius: '8px' }}>
//         {/* Center the table header and data */}
//         <Column field="week" header="Week" ></Column>
//         <Column field="totalTimesheetCount" header="Total Timesheet" ></Column>
//         <Column field="status" header="Status" body={statusBodyTemplate} ></Column>
//       </DataTable>
//     </div>
//   );
// };

// export default TimesheetReport;

import React, { useState, useEffect, useContext } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { format, parse } from 'date-fns'; // For date handling
import { UserContext } from "../../../../context/UserContext";
import { Panel } from 'primereact/panel';


const TimesheetReport = () => {
    const { user } = useContext(UserContext)
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [months, setMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);

    const statusOptions = [
        { label: 'Approved', value: 'approved' },
        { label: 'Rejected', value: 'rejected' }
    ];

    useEffect(() => {
        if (selectedStatus) {
            // Determine which API to call based on the selected status
            const apiUrl = selectedStatus === 'approved'
                ? `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getApprovedTimesheetForEmployee/${user.Data.empId}`
                : `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getRejectedTimesheetsForEmployee/${user.Data.empId}`;

            fetch(apiUrl)
                .then(response => response.json())
                .then(data => {
                    // Adjust for both approved and rejected API responses
                    const timesheets = selectedStatus === 'approved'
                        ? data.approvedTimesheets.approvedTimesheets
                        : data.rejectedTimesheets.rejectedTimesheets;

                    const totalCount = selectedStatus === 'approved'
                        ? data.approvedTimesheets.Totalapprovedcount
                        : data.rejectedTimesheets.TotalRejectedcount;

                    setTableData(timesheets);
                    setTotalCount(totalCount);

                    // Extract unique months and years from the weeks (parsed in 'dd/MM/yyyy' format)
                    const uniqueMonths = [...new Set(timesheets.map(item => {
                        const [startDate] = item.week.split(' - ');
                        const parsedDate = parse(startDate, 'dd/MM/yyyy', new Date());
                        return format(parsedDate, 'MMMM yyyy'); // Get month and year (e.g., September 2024)
                    }))];
                    const monthOptions = uniqueMonths.map(month => ({ label: month, value: month }));
                    setMonths(monthOptions);
                })
                .catch(error => console.error("Error fetching data:", error));
        }
    }, [selectedStatus]);

    const handleStatusChange = (e) => {
        setSelectedStatus(e.value);
        setSelectedMonth(null); // Reset selected month when status changes
    };

    const handleMonthChange = (e) => {
        setSelectedMonth(e.value);
    };

    // Function to style the status
    const statusBodyTemplate = (rowData) => {
        const color = rowData.status === 'rejected' ? 'red' : 'green'; // Red for rejected, green for approved
        return (
            <span style={{ color }}>
                {rowData.status}
            </span>
        );
    };

    return (
        <Panel header="Timesheet Analytics by Approved / Reject"
        style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}>
        <div
            style={{
                padding: '20px',
                border: '1px solid #ccc',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                height: '45vh', // Set height relative to viewport
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <Dropdown
                    value={selectedStatus}
                    options={statusOptions}
                    onChange={handleStatusChange}
                    placeholder="Select Status"
                />
                {selectedStatus && (
                    <Dropdown
                        value={selectedMonth}
                        options={months}
                        onChange={handleMonthChange}
                        placeholder="Select Month"
                        style={{ marginLeft: '10px' }}
                    />
                )}
                <div style={{ fontWeight: 'bold' }}>
                    Total {selectedStatus === 'approved' ? 'Approved' : 'Rejected'}: {totalCount}
                </div>
            </div>
    
            {/* Display the table only when a month is selected */}
            <div style={{ flexGrow: 1, overflowY: 'auto' }}>
                {selectedMonth && (
                    <DataTable value={tableData.filter(item => {
                        const [startDate] = item.week.split(' - ');
                        const parsedDate = parse(startDate, 'dd/MM/yyyy', new Date());
                        const monthYear = format(parsedDate, 'MMMM yyyy');
                        return monthYear === selectedMonth;
                    })} 
                        style={{ height: '50vh', border: '1px solid #ddd', borderRadius: '8px', overflowY: 'auto' }} // Responsive height for DataTable
                        emptyMessage="No records found">
                        <Column field="week" header="Week" style={{ textAlign: 'center' }}></Column>
                        <Column field="totalTimesheetCount" header="Total Timesheet Count" style={{ textAlign: 'center' }}></Column>
                        <Column field="status" header="Status" body={statusBodyTemplate} style={{ textAlign: 'center' }}></Column>
                    </DataTable>
                )}
            </div>
        </div>
    </Panel>
    

    );
};

export default TimesheetReport;



