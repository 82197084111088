
import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import { Image } from 'primereact/image';
import { Menubar } from 'primereact/menubar';
import logo from '../../../assets/IGS.png';
import { TbArrowBack } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

const NotOnBench = () => {
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  // Fetch the "Not On Bench" data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getNotOnBenchByDesignation`);
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching the data:", error);
      }
    };

    fetchData();
  }, []);

  // Initialize the chart
  useEffect(() => {
    if (data) {
      const chartDom = document.getElementById('not-on-bench-chart');
      const myChart = echarts.init(chartDom);

      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: (params) => {
            return `
              ${params[0].name}<br/>
              <strong>Not On Bench: </strong>${params[0].value}
            `;
          },
        },
        legend: {
          bottom: '10px',
          textStyle: {
            color: '#555',
            fontSize: 14,
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          axisLabel: {
            color: '#666',
            fontSize: 12,
            formatter: '{value}', // Display natural number counts
          },
          splitLine: {
            lineStyle: {
              color: '#f0f0f0',
            },
          },
        },
        yAxis: {
          type: 'category',
          data: data.map((item) => item.designation), // Map designations from the data
          axisLabel: {
            color: '#666',
            fontSize: 14,
            fontWeight: 'bold',
          },
        },
        series: [
          {
            name: 'Not On Bench',
            type: 'bar',
            data: data.map((item) => item.notOnBench), // Map notOnBench counts from the data
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
           
                { offset: 1, color: '#993300' },
                { offset: 0, color: '#000066' },
              ]),
            },
            label: {
              show: true,
              position: 'inside',
              formatter: '{c} Employee', // Display natural number counts
              color: '#fff',
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(0, 255, 0, 0.3)',
              },
            },
            animationEasing: 'elasticOut',
            animationDelay: (idx) => idx * 50,
          },
        ],
        backgroundColor: '#f5f5f5',
        animationDuration: 1500,
      };

      myChart.setOption(option);

      // Cleanup the chart on component unmount
      return () => {
        myChart.dispose();
      };
    }
  }, [data]);

  const start = (
    <div style={{ display: "flex", alignItems: 'center' }}>
      <button className="rounded-full m-2 hover:bg-gray-200" style={{ height: '40px', width: '40px' }} onClick={() => navigate(-1)}>
        <TbArrowBack />
      </button>
      <div style={{ color: "#00000080" }}>
        <h2>Not On Bench Status By Designation</h2>
      </div>
    </div>
  );

  const end = (
    <div style={{ paddingRight: '15px' }}>
      <Image src={logo} height="60px" width="60px" />
    </div>
  );

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        <Menubar start={start} end={end} />
      </div>
      <div id="not-on-bench-chart" style={{ width: '100%', height: '600px', backgroundColor: '#fff', borderRadius: '10px', padding: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}></div>
    </div>
  );
};

export default NotOnBench;
