
import React, { useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext"; // Make sure this path is correct
import styles from "./Login.module.css";
import logo from "../../assets/logo.png";
import abstract from "../../assets/abstract.png";
import { Toast } from "primereact/toast";

const Login = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authStatus, setAuthStatus] = useState(null);
  const [signIn, setSignIn] = useState(false);
  const { setUser, user } = useContext(UserContext); // Correctly placed useContext hook here
  const navigate = useNavigate();
  const toast = useRef(null); // Create a ref for Toast


  
  const handleLogin = async () => {
    try {
      // Convert email and password to appropriate types
      const parsedEmail = isNaN(email) ? email : Number(email);
      const parsedPassword = isNaN(password) ? password : Number(password);

      const response = await fetch(
        `${process.env.REACT_APP_ADMIN_API_SIGNIN}${process.env.REACT_APP_AUTH_API_ENDPOINT}/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: parsedEmail, password: parsedPassword }),
        }
      );

      const data = await response.json();

      if (response.ok && data.Status === "OK") {
        // Save user details in localStorage
        localStorage.setItem("user", JSON.stringify(data.Data));

        // Update the context with user data
        setUser(data);

        // Save user details in localStorage
        localStorage.setItem("user", JSON.stringify(data));

        // Show success message
        toast.current.show({ severity: "success", summary: "Success", detail: "Login successful!", life: 3000 });

        // Notify parent component of login success
        onLoginSuccess();

        // Navigate to the projects page
        navigate("/timesheets");
      } else {
        setAuthStatus("failure");
        // Show error message
        toast.current.show({ severity: "error", summary: "Error", detail: "Authentication failed. Please check your credentials.", life: 3000 });
      }
    } catch (error) {
      console.error("Login error:", error);
      setAuthStatus("failure");
      // Show error message
      toast.current.show({ severity: "error", summary: "Error", detail: "An error occurred during login.", life: 3000 });
    }
  };

  const handleSignIn = async () => {
    console.log("bob is here", user?.Data);
    
    try {
      // Accessing empId from user context data
      const empId = user?.Data?.empId;

      if (!empId) {
        console.error("Employee ID not found.");
        setAuthStatus("failure");
        // Show error message
        toast.current.show({ severity: "error", summary: "Error", detail: "Employee ID not found.", life: 3000 });
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_ADMIN_API_SIGNIN1}${process.env.REACT_APP_ADMIN_API_SIGNIN_ENDPOINT}/employee/change-password/${empId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ newPassword: password }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Password updated successfully:", data);
        setAuthStatus("success");
        // Show success message
        toast.current.show({ severity: "success", summary: "Success", detail: "Password updated successfully.", life: 3000 });
      } else {
        setAuthStatus("failure");
        console.error("Failed to update password");
        // Show error message
        toast.current.show({ severity: "error", summary: "Error", detail: "Failed to update password.", life: 3000 });
      }
    } catch (error) {
      console.error("Error during password update:", error);
      setAuthStatus("failure");
      // Show error message
      toast.current.show({ severity: "error", summary: "Error", detail: "An error occurred during password update.", life: 3000 });
    }
  };
 

  return (
    <div className={styles.container}>
      <Toast ref={toast} /> {/* Toast component placed here */}
      <div className={styles.leftPanel}>
        <div className={styles.text}>Where amazing things happen</div>
        <div className={styles.illustrations}>
          <div className={styles.illustrationItem}>
            <img src={abstract} alt="Graph" />
          </div>
        </div>
      </div>
      <div className={styles.rightPanel}>
        <div className={styles.logo}>
          <img src={logo} alt="Logo" />
          <h1>IGS TimeSheets</h1>
        </div>
        {!signIn && (
          <div className={styles.form}>
            <div className={styles.inputGroup}>
              <label>Email</label>
              <input
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className={styles.inputGroup}>
              <label>Password</label>
              <input
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className={styles.forgotPassword}>
              <a onClick={() => setSignIn(true)} style={{ cursor: "pointer" }}>
                Sign In
              </a>
            </div>
            <button className={styles.loginButton} onClick={handleLogin}>
              Login
            </button>
            {authStatus === "failure" && (
              <div className={styles.errorMessage}>
                Authentication failed. Please check your credentials.
              </div>
            )}
          </div>
        )}
        {signIn && (
          <div className={styles.form}>
            <div className={styles.inputGroup}>
              <label>Email</label>
              <input
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className={styles.inputGroup}>
              <label>New Password</label>
              <input
                type="password"
                placeholder="Enter New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className={styles.forgotPassword}>
              <a onClick={() => setSignIn(false)} style={{ cursor: "pointer" }}>
                Log In
              </a>
            </div>
            <button className={styles.loginButton} onClick={() => { handleSignIn() }}>
              Sign In
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
