import React, { useContext, useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { UserContext } from "../../context/UserContext";
 
const App = () => {
  const {user} = useContext(UserContext)
  const [timesheets, setTimesheets] = useState([]);
  const [filteredTimesheets, setFilteredTimesheets] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState("");
  const [weeks, setWeeks] = useState([]);
 
  // Logic to calculate or assign totalHours
 
  useEffect(() => {
    const fetchTimesheets = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/timesheets/approvedempId/${user.Data.empId}`
        );
        const data = await response.json();
        console.log(data, "bob is here for timesheet");
        
        // const firstTimesheet = data[0]?.timesheets || [];
        const firstTimesheet = data.reduce((acc, curr) => {
          return acc.concat(curr.timesheets || []);
        }, []);
        
 
        setTimesheets(firstTimesheet);
 
        const uniqueWeeks = [
          ...new Set(firstTimesheet.map((item) => item.week)),
        ];
        setWeeks(uniqueWeeks);
 
        if (uniqueWeeks.length > 0) {
          setSelectedWeek(uniqueWeeks[0]);
        }
      } catch (error) {
        console.error("Error fetching timesheets:", error);
      }
    };
 
    fetchTimesheets();
  }, []);
 
  useEffect(() => {
    if (selectedWeek) {
      const filtered = timesheets.filter((ts) => ts.week === selectedWeek);
      setFilteredTimesheets(filtered);
    } else {
      setFilteredTimesheets(timesheets);
    }
  }, [selectedWeek, timesheets]);
 
  const weekOptions = weeks.map((week) => ({
    label: week,
    value: week,
  }));
 
  return (
    <div>
      <div>
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <div
            style={{ color: "#00000080", fontWeight: "bold", fontSize: "20px" }}
          >
            Select Week:
          </div>
          <Dropdown
            value={selectedWeek}
            options={weekOptions}
            onChange={(e) => setSelectedWeek(e.value)}
            placeholder="Select a week"
          />
        </div>
      </div>
 
      <div style={{ marginTop: "30px" }}>
        <TimesheetTable timesheets={filteredTimesheets} />
      </div>
    </div>
  );
};
 
const TimesheetTable = ({ timesheets }) => {
  // Function to calculate total hours
  const calculateTotalHours = (hours) => {
    if (!hours) return 0;
    return Object.values(hours).reduce(
      (total, day) => total + (parseFloat(day?.time) || 0),
      0
    );
  };
 
  return (
    <div>
      {timesheets.length > 0 ? (
        <div>
          <div style={{ display: "flex", width: "100%", gap: "5px" }}>
            {/* Table Header */}
            <div
              style={{
                width: "14%",
                background: "#71759B",
                padding: "10px",
                color: "#FFFFFF",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Projects
            </div>
            <div
              style={{
                width: "14%",
                background: "#71759B",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                color: "#FFFFFF",
              }}
            >
              Sub Projects
            </div>
            <div
              style={{
                width: "14%",
                background: "#71759B",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                color: "#FFFFFF",
              }}
            >
              Tasks
            </div>
            <div
              style={{
                width: "7.25%",
                color: "#0069FFFF",
                background: "#F3F1ECFF",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Sun
            </div>
            <div
              style={{
                width: "7.25%",
                color: "#0069FFFF",
                background: "#F3F1ECFF",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Mon
            </div>
            <div
              style={{
                width: "7.25%",
                color: "#0069FFFF",
                background: "#F3F1ECFF",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Tue
            </div>
            <div
              style={{
                width: "7.25%",
                color: "#0069FFFF",
                background: "#F3F1ECFF",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Wed
            </div>
            <div
              style={{
                width: "7.25%",
                color: "#0069FFFF",
                background: "#F3F1ECFF",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Thur
            </div>
            <div
              style={{
                width: "7.25%",
                color: "#0069FFFF",
                background: "#F3F1ECFF",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Fri
            </div>
            <div
              style={{
                width: "7.25%",
                color: "#0069FFFF",
                background: "#F3F1ECFF",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Sat
            </div>
            <div
              style={{
                width: "7.25%",
                color: "#0069FFFF",
                background: "#F3F1ECFF",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Total
            </div>
          </div>
 
          <div>
            {timesheets.map((timesheet, index) => {
              const totalHours = calculateTotalHours(
                timesheet.entries[0]?.hours
              );
 
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    width: "100%",
                    gap: "5px",
                    marginTop: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "14%",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      border: "2px solid #EBEBEB",
                      fontSize: "14px",
                    }}
                  >
                    {timesheet.project.name}
                  </div>
                  <div
                    style={{
                      width: "14%",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      border: "2px solid #EBEBEB",
                      gap: "5px",
                      fontSize: "14px",
                    }}
                  >
                    {timesheet.subproject.name}
                  </div>
                  <div
                    style={{
                      width: "14%",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      border: "2px solid #EBEBEB",
                      fontSize: "14px",
                    }}
                  >
                    {timesheet.task.name}
                  </div>
                  <div
                    style={{
                      width: "7.25%",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      border: "2px solid #EBEBEB",
                    }}
                  >
                    {timesheet.entries[0]?.hours?.sun?.time || "-"}
                  </div>
                  <div
                    style={{
                      width: "7.25%",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      border: "2px solid #EBEBEB",
                    }}
                  >
                    {timesheet.entries[0].hours?.mon?.time || "-"}
                  </div>
                  <div
                    style={{
                      width: "7.25%",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      border: "2px solid #EBEBEB",
                    }}
                  >
                    {timesheet.entries[0].hours?.tue?.time || "-"}
                  </div>
                  <div
                    style={{
                      width: "7.25%",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      border: "2px solid #EBEBEB",
                    }}
                  >
                    {timesheet.entries[0].hours?.wed?.time || "-"}
                  </div>
                  <div
                    style={{
                      width: "7.25%",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      border: "2px solid #EBEBEB",
                    }}
                  >
                    {timesheet.entries[0].hours?.thu?.time || "-"}
                  </div>
                  <div
                    style={{
                      width: "7.25%",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      border: "2px solid #EBEBEB",
                    }}
                  >
                    {timesheet.entries[0].hours?.fri?.time || "-"}
                  </div>
                  <div
                    style={{
                      width: "7.25%",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      border: "2px solid #EBEBEB",
                    }}
                  >
                    {timesheet.entries[0].hours?.sat?.time || "-"}
                  </div>
                  <div
                    style={{
                      width: "7.25%",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      border: "2px solid #EBEBEB",
                    }}
                  >
                    {totalHours}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <p>No timesheets available.</p>
      )}
    </div>
  );
};
 
export default App;
