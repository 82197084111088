// // import React from 'react'
// // import { Image } from 'primereact/image';
// // import { Panel } from 'primereact/panel';
// // // import logo from '../'

// // export default function Reports() {
// //   return (
// //     <div>
// //       <div style={{ backgroundColor: '#f8f9fa', height: '95vh', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)' }}>
// //         <div style={{ display: 'flex', justifyContent: 'space-between', }} >
// //           <div style={{ marginLeft: '20px' }}>
// //             <p style={{ color: "#00000080", fontSize: "22px", fontWeight: "bold" }}>
// //               Reports
// //             </p>
// //           </div>
// //           <div style={{ paddingRight: '15px' }}>
// //             <Image src={''} height='80px' width='80px' />
// //           </div>
// //         </div>

// //         <div style={{ paddingTop: '10px', display: 'flex' }}>
// //           <div style={{ paddingLeft: '20px', paddingTop: '20px', paddingRight: '5px', width: '35%' }}>
// //             <Panel
// //               header="Bench List"
// //               style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)' }}
// //             >

// //             </Panel>
// //           </div>
// //         </div>

// //       </div>
// //     </div>
// //   )
// // }



// // import React, { useRef, useEffect } from 'react';
// // import { Image } from 'primereact/image';
// // import { Panel } from 'primereact/panel';
// // import { Chart } from 'chart.js/auto';
// // import EarmarkProject from "./ReportChart/EarmarkProject.js"
// // export default function Reports() {
// //   const chartRef = useRef(null);

// //   useEffect(() => {
// //     // Dummy data for the chart
// //     const data = {
// //       labels: ['Bench', 'On Project'],
// //       datasets: [
// //         {
// //           label: 'Employees',
// //           data: [5, 20], // Dummy data: 5 on bench, 20 on projects
// //           backgroundColor: ['#FF6384', '#36A2EB'],
// //           borderColor: ['#FF6384', '#36A2EB'],
// //           borderWidth: 1,
// //         },
// //       ],
// //     };

// //     // Options for the chart
// //     const options = {
// //       responsive: true,
// //       plugins: {
// //         legend: {
// //           position: 'top',
// //         },
// //       },
// //     };

// //     // Get the context of the canvas where we want to draw the chart
// //     const ctx = chartRef.current.getContext('2d');

// //     // Initialize the chart
// //     const myChart = new Chart(ctx, {
// //       type: 'bar',
// //       data: data,
// //       options: options,
// //     });

// //     // Cleanup the chart when the component unmounts
// //     return () => {
// //       myChart.destroy();
// //     };
// //   }, []);

// //   return (
// //     <div>
// //       <div style={{ backgroundColor: '#f8f9fa', height: '95vh', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)' }}>
// //         <div style={{ display: 'flex', justifyContent: 'space-between' }}>
// //           <div style={{ marginLeft: '20px' }}>
// //             <p style={{ color: '#00000080', fontSize: '22px', fontWeight: 'bold' }}>Reports</p>
// //           </div>
// //           <div style={{ paddingRight: '15px' }}>
// //             <Image src={''} height='80px' width='80px' />
// //           </div>
// //         </div>

// //         <div style={{ paddingTop: '10px', display: 'flex' }}>
// //           <div style={{ paddingLeft: '20px', paddingTop: '20px', paddingRight: '5px', width: '35%' }}>
// //             <Panel header="Bench List" style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)' }}>
// //               <canvas ref={chartRef} />
// //             </Panel>
// //           </div>
// //           <div style={{width:'30%'}}>
// //             <EarmarkProject />
// //         </div>
// //         </div>


// //       </div>
// //     </div>
// //   );
// // }



// import React from 'react';
// import { Image } from 'primereact/image';

// import EarmarkProject from './ReportChart/EarmarkProject.js';
// import RoleReport from './ProjectInRole/RoleReport.js';
// import { Menubar } from 'primereact/menubar';
// import logo from "../../assets/IGS.png"
// import BenchReport from './BenchReport/BenchReport.js';
// import HourAcrossProject from './HourAcrossProject/HourAcrossProject.js';

// export default function Reports() {

//   const start = (
//     <div>
//       <p style={{ color: "#00000080", fontWeight: "bold", fontSize: "22px", margin: "0px", marginBottom: '5PX' }}>
//         Reports
//       </p>
//     </div>
//   )
//   const end = (
//     <div style={{ paddingRight: '15px' }}>
//       <Image src={logo} height='60px' width='60px' />
//     </div>
//   )
//   return (
//     <div>
//       <div className="card" >
//         <Menubar start={start} end={end} />
//       </div>
//       <div style={{ backgroundColor: '#f8f9fa', height: '87vh', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
//         <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center',margin:'5px' }}>
//           <div style={{ width: '40%', boxSizing: 'border-box', paddingBottom: '20px' }}>
//             <BenchReport style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)' }} />
//           </div>
//           <div style={{ width: '39%', boxSizing: 'border-box', paddingBottom: '20px' }}>
//             <RoleReport style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)' }} />
//           </div>
        
//         </div>
//         <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center',margin:'5px' }}>
       
//         <div style={{ width: '40%', boxSizing: 'border-box', paddingBottom: '20px' }}>
//             <EarmarkProject style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)' }} />
//           </div>
         
//           <div style={{ width: '40%', boxSizing: 'border-box', paddingBottom: '20px' }}>
//             <HourAcrossProject style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)' }} />
//           </div>

//         </div>

//       </div>
//     </div>

//   );
// }



import React from "react";
import { Image } from "primereact/image";
import { Menubar } from "primereact/menubar";
import logo from "../../assets/IGS.png";


export default function Reports() {
  const start = (
    <div>
      <p
        style={{
          color: "#00000080",
          fontWeight: "bold",
          fontSize: "22px",
          margin: "0px",
          marginBottom: "5PX",
        }}
      >
        Reports
      </p>
    </div>
  );
  const end = (
    <div style={{ paddingRight: "15px" }}>
      <Image src={logo} height="60px" width="60px" />
    </div>
  );
  return (
    <div>
      <div className="card">
        <Menubar start={start} end={end} />
      </div>
      <div
        style={{
          backgroundColor: "#f8f9fa",
          height: "87vh",
          boxShadow: "0 10px 10px -5px rgba(0, 0, 0, 0.3)",
          border: "1px solid rgba(128, 128, 128, 0.211)",
          marginTop: "10px",
          overflow: "scroll",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            margin: "5px",
          }}
        >
          {/* <div
            style={{
              width: "40%",
              boxSizing: "border-box",
              paddingBottom: "20px",
            }}
          >
            <BenchReport style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }} />
          </div> */}
          <div
            style={{
              width: "40%",
              boxSizing: "border-box",
              paddingBottom: "20px",
            }}
          >
            {/* <BenchReport1
              style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}
            /> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            margin: "5px",
          }}
        >
          <div
            style={{
              width: "40%",
              boxSizing: "border-box",
              paddingBottom: "20px",
            }}
          >
            {/* <RoleReport style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }} /> */}
          </div>

          <div
            style={{
              width: "40%",
              boxSizing: "border-box",
              paddingBottom: "20px",
            }}
          >
            {/* <BillableEmployeeChart
              style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}
            /> */}
          </div>
        </div>
     
      </div>
    </div>
  );
}
