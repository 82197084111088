// import React, { useEffect, useRef, useState } from "react";
// import { Panel } from "primereact/panel";
// import { Chart } from "chart.js/auto";

// export default function TimesheetApprovalChart() {
//   const chartRef = useRef(null);
//   const [chartData, setChartData] = useState({
//     labels: [], // Task names will go here
//     datasets: [
//       {
//         label: "Approved Timesheets",
//         data: [], // Dynamic data for approved timesheets
//         backgroundColor: "#36A2EB",
//       },
//     ],
//   });

//   useEffect(() => {
//     const fetchTimesheetData = async () => {
//       try {
//         const response = await fetch(
//           "http://192.168.1.20:5004/api/v1/admin/reports/getApprovedTimesheetForEmployee/E04105"
//         );
//         const result = await response.json();

//         const taskLabels = [];
//         const approvedData = [];

//         // Process the approved timesheets from the response
//         result.approvedTimesheets.forEach((project) => {
//           const taskLabel = `${project.projectName} - ${project.taskName}`;
//           taskLabels.push(taskLabel);
//           approvedData.push(project.timesheetCount); // Number of approved timesheets per task
//         });

//         setChartData({
//           labels: taskLabels,
//           datasets: [
//             {
//               label: "Approved Timesheets",
//               data: approvedData,
//               backgroundColor: "#36A2EB",
//             },
//           ],
//         });
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchTimesheetData();
//   }, []);

//   useEffect(() => {
//     const ctx = chartRef.current.getContext("2d");

//     // Function to calculate font size based on the canvas width
//     const calculateFontSize = (width) => {
//       if (width > 1000) return 16; // Large screen
//       if (width > 600) return 12;  // Medium screen
//       return 8; // Small screen
//     };

//     const myChart = new Chart(ctx, {
//       type: "bar", // Bar chart to show approved timesheets
//       data: chartData,
//       options: {
//         responsive: true,
//         plugins: {
//           legend: {
//             position: "top",
//           },
//         },
//         scales: {
//           x: {
//             beginAtZero: true,
//             ticks: {
//               font: {
//                 size: calculateFontSize(chartRef.current.offsetWidth), // Adjust font size based on canvas width
//               },
//             },
//           },
//           y: {
//             beginAtZero: true,
//           },
//         },
//       },
//     });

//     return () => {
//       myChart.destroy();
//     };
//   }, [chartData]);

//   return (
//     <Panel
//       header="Approved Timesheets by Project and Task"
//       style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}
//     >
//       <canvas ref={chartRef} />
//     </Panel>
//   );
// }

import React, { useRef, useState, useEffect, useContext } from 'react';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { Panel } from 'primereact/panel';
import { Dropdown } from 'primereact/dropdown'; // Import PrimeReact Dropdown
import { UserContext } from "../../../../context/UserContext";

// Register Chart.js components
Chart.register(ArcElement, Tooltip, Legend);

const ReminderChart = () => {
  const { user } = useContext(UserContext)

  const chartRef = useRef(null);
  const [selectedWeek, setSelectedWeek] = useState('');
  const [weeks, setWeeks] = useState([]); // State to store the fetched data
  const [loading, setLoading] = useState(true); // State to manage loading status

  // Fetch data from API
  const fetchReminderData = async () => {
    try {
      const response = await fetch(
        // 'http://192.168.29.195:5004/api/v1/admin/reports/getRemindersByWeekForEmpId/E04105'
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getRemindersByWeekForEmpId/${user.Data.empId}`
      );
      const data = await response.json();
      setWeeks(data);
      setSelectedWeek(data[0]?.week || ''); // Set the first week by default
      setLoading(false); // Data fetched successfully
    } catch (error) {
      console.error('Error fetching reminder data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReminderData();
  }, []); // Fetch data on component mount

  // Prepare chart data based on the selected week
  const chartData = () => {
    const currentWeekData = weeks.find((w) => w.week === selectedWeek)?.data || [];

    return {
      // Use project names as labels
      labels: currentWeekData.map(reminder => reminder.project),
      datasets: [
        {
          label: 'Reminder Count',
          data: currentWeekData.map(reminder => reminder.reminderCount),
          backgroundColor: currentWeekData.map((_, index) =>
            `rgba(${(index * 50) % 255}, ${(index * 100) % 255}, 200, 0.5)`
          ),
          borderColor: currentWeekData.map((_, index) =>
            `rgba(${(index * 50) % 255}, ${(index * 100) % 255}, 200, 1)`
          ),
          borderWidth: 1,
        },
      ],
    };
  };

  // Inside the tooltip, show the task name and other relevant data as before.
  useEffect(() => {
    const ctx = chartRef.current?.getContext('2d');
    if (ctx) {
      // Destroy the existing chart instance if it exists
      if (ctx.chart) {
        ctx.chart.destroy();
      }

      // Create a new chart instance
      ctx.chart = new Chart(ctx, {
        type: 'doughnut',
        data: chartData(),
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            tooltip: {
              callbacks: {
                title: function (tooltipItems) {
                  const index = tooltipItems[0].dataIndex;
                  const reminder = weeks
                    .find((w) => w.week === selectedWeek)?.data[index] || {};
                  return `Project: ${reminder.project}`; // Project name as title
                },
                label: function (tooltipItem) {
                  const index = tooltipItem.dataIndex;
                  const reminder = weeks
                    .find((w) => w.week === selectedWeek)?.data[index] || {};
                  return [
                    `Task: ${reminder.task}`,
                    `Reminder Message: ${reminder.reminderMessage}`,
                    `Reminder Count: ${reminder.reminderCount}`,
                  ];
                },
              },
            },
          },
        },
      });
    }
  }, [selectedWeek, weeks]); // Update chart when selectedWeek or weeks change

  return (
    <Panel
      header="Timesheet Reminder"
      style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}
    >
      <div style={{ marginBottom: '20px' }}>
        <Dropdown
          value={selectedWeek}
          options={weeks.map((week) => ({ label: week.week, value: week.week }))} // Map weeks for Dropdown options
          onChange={(e) => setSelectedWeek(e.value)} // Use PrimeReact Dropdown event structure
          placeholder="Select Week"
        />
      </div>

      <div style={{ width: "300px", height: "300px", margin: "0 auto" }}>
        <canvas ref={chartRef} style={{ width: "100%", height: "100%" }}></canvas>
      </div>


    </Panel>
  );
};

export default ReminderChart;




