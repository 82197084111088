// import React, { useEffect, useRef } from 'react';
// import * as echarts from 'echarts';

// const MultiBarChart = () => {
//   const chartRef = useRef(null);
//   let chartInstance = useRef(null); // Local variable to hold the chart instance

//   useEffect(() => {
//     // Fetch data from the API
//     const fetchData = async () => {
//       try {
//         const response = await fetch('http://192.168.29.195:5004/api/v1/admin/reports/getProjectsByAllocation/25-50');
//         const data = await response.json();
//         renderChart(data);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();

//     // Resize chart on window resize
//     window.addEventListener('resize', handleResize);

//     return () => {
//       // Cleanup chart instance on unmount
//       if (chartInstance.current) {
//         chartInstance.current.dispose();
//       }
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   const renderChart = (data) => {
//     if (!chartInstance.current) {
//       chartInstance.current = echarts.init(chartRef.current);
//     }

//     // Extract project names and employee counts
//     const projectNames = data.map(item => item.project_name);
//     const employeeCounts = data.map(item => item.employee_count);

//     const option = {
//       title: {
//         text: 'Employee Count by Project',
//       },
//       tooltip: {
//         trigger: 'item',
//       },
//       xAxis: {
//         type: 'category',
//         data: projectNames,
//       },
//       yAxis: {
//         type: 'value',
//         name: 'Employee Count',
//       },
//       series: [
//         {
//           name: 'Employee Count',
//           type: 'bar',
//           data: employeeCounts,
//           label: {
//             show: true,
//             position: 'top',
//           },
//         },
//       ],
//     };

//     chartInstance.current.setOption(option);
//   };

//   const handleResize = () => {
//     if (chartInstance.current) {
//       chartInstance.current.resize();
//     }
//   };

//   return (
//     <div
//       ref={chartRef}
//       style={{ width: '100%', height: '400px' }}
//     />
//   );
// };

// export default MultiBarChart;

import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import 'primereact/resources/themes/saga-blue/theme.css';  // Theme
import 'primereact/resources/primereact.min.css';          // Core CSS
import 'primeicons/primeicons.css';
import { Image } from 'primereact/image';
import { Menubar } from 'primereact/menubar';
import logo from '../../../../assets/IGS.png';
import { TbArrowBack } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';                     // Icons

const ProjectAllocation = () => {
    const navigate = useNavigate();
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState('All');
    const [employees, setEmployees] = useState([]);
    const [paginatedEmployees, setPaginatedEmployees] = useState([]);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10); // Set rows per page

    // Fetch data from the API
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getProjectsByAllocation/25-50`)
            .then(response => response.json())
            .then(data => {
                setProjects(data);
                const allEmployees = data.flatMap(project =>
                    project.employees.map(employee => ({ ...employee, project: project.project }))
                );
                setEmployees(allEmployees);
                setPaginatedEmployees(allEmployees.slice(0, rows)); // Initial page data
            })
            .catch(error => console.error('Error fetching data:', error));
    }, [rows]);

    // Handle project selection change
    const handleProjectChange = (e) => {
        const selected = e.value;
        setSelectedProject(selected);

        if (selected === 'All') {
            // Show all employees if "All" is selected
            const allEmployees = projects.flatMap(project =>
                project.employees.map(employee => ({ ...employee, project: project.project }))
            );
            setEmployees(allEmployees);
            setPaginatedEmployees(allEmployees.slice(0, rows)); // Reset pagination
            setFirst(0); // Reset to first page
        } else {
            // Filter employees by the selected project
            const selectedProjectData = projects.find(project => project.project === selected);
            const projectEmployees = selectedProjectData ? selectedProjectData.employees.map(employee => ({ ...employee, project: selectedProjectData.project })) : [];
            setEmployees(projectEmployees);
            setPaginatedEmployees(projectEmployees.slice(0, rows)); // Reset pagination
            setFirst(0); // Reset to first page
        }
    };

    // Handle pagination change
    const onPageChange = (e) => {
        setFirst(e.first);
        const startIndex = e.first;
        const endIndex = startIndex + e.rows;
        setPaginatedEmployees(employees.slice(startIndex, endIndex)); // Paginate employees data
    };
    const start = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <button className="rounded-full m-2 hover:bg-gray-200" style={{ height: '40px', width: '40px' }} onClick={() => navigate(-1)}>
                <TbArrowBack />
            </button>
            <div style={{ color: '#00000080' }}>
                <h2>Allocation Details</h2>
            </div>
        </div>
    );

    const end = (
        <div style={{ paddingRight: '15px' }}>
            <Image src={logo} height="60px" width="60px" />
        </div>
    );
    return (
        <div>
            <div className="card">
                <Menubar start={start} end={end} />
            </div>
            <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 140px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
                <div style={{ margin: '20px' }}>
                    <div className="p-grid p-dir-col p-mt-5">
                        <div className="p-col-12 p-md-4">
                            {/* Dropdown for selecting the project */}
                            <Dropdown
                                value={selectedProject}
                                options={[{ label: 'All', value: 'All' }, ...projects.map(project => ({ label: project.project, value: project.project }))]}
                                onChange={handleProjectChange}
                                placeholder="Select a Project"
                                className="p-mb-4"
                                style={{ width: '12vw' }}
                            />
                        </div>

                        <div className="p-col-12 m-2">
                            <div >
                                <DataTable value={paginatedEmployees} responsiveLayout="scroll">
                                    <Column field="project" header="Project" />
                                    <Column field="employeeName" header="Employee Name" />
                                    <Column field="designation" header="Designation" />
                                    <Column field="allocation" header="Allocation" />
                                </DataTable>
                            </div>
                            {/* DataTable for displaying employee details including project name */}

                            <div>
                                <Paginator
                                    first={first}
                                    rows={rows}
                                    totalRecords={employees.length}
                                    onPageChange={onPageChange}
                                    className="p-mt-4"
                                />
                            </div>
                            {/* Paginator for handling pagination */}

                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
};

export default ProjectAllocation;
