import React from 'react'
import { TabPanel, TabView } from "primereact/tabview";
import TimeSheets from './TimeSheets';
import ApproveTimesheet from './ApproveTimesheet';
import RejectTimesheet from './RejectTimesheet';
import PendingReject from './PendingReject';
import { Image } from 'primereact/image';
import logo from "../../assets/IGS.png"
import { Menubar } from 'primereact/menubar';
import Dashboard from '../Users/dashboard/Dashboard';
import TimesheetSummary from "./TimeSheetSummary"

export default function TimesheetTab() {

  const start = (
    <div>
      <p style={{ color: "#00000080", fontWeight: "bold", fontSize: "22px", margin: "0px", marginBottom: '5PX' }}>
        TimeSheets
      </p>
    </div>
    )
    const end = (
    <div style={{ paddingRight: '15px' }}>
      <Image src={logo} height='60px' width='60px' />
    </div>
    )
  return (
    <div className="w-full" style={{ overflowY: 'scroll' }}>
      <div style={{ justifyContent: 'space-between', alignItems: 'center' }}>

        <div className="card">
      <Menubar  start={start} end={end} />
          
          <TabView activeIndex={2}>
          <TabPanel header="Dashboard">
              <Dashboard />
            </TabPanel>
            <TabPanel header="Approved">
              <ApproveTimesheet />
            </TabPanel>
            <TabPanel header="Pending">
              <PendingReject />
            </TabPanel>
            <TabPanel header="Rejected">
              <RejectTimesheet />
            </TabPanel>
          </TabView>
        </div>
      </div>

    </div>
  )
}