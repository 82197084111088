// import React, { useEffect, useContext } from 'react';
// import * as echarts from 'echarts';
// import { Image } from 'primereact/image';
// import logo from "../../../assets/IGS.png";
// import { Menubar } from 'primereact/menubar';
// import { UserContext } from '../../../context/UserContext';

// const RemindersChart = () => {
//     const { user } = useContext(UserContext);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await fetch(
//                     // 'http://192.168.29.195:5004/api/v1/admin/reports/getRemindersByWeekForEmpId/E04105'
//                     `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getRemindersByWeekForEmpId/${user.Data.empId}`

//                 );
//                 const data = await response.json();

//                 // Process data to prepare for chart
//                 const weeks = [];
//                 const projects = {};
//                 const seriesData = [];

//                 data.forEach(weekData => {
//                     if (!weeks.includes(weekData.week)) {
//                         weeks.push(weekData.week);
//                     }
//                     weekData.data.forEach(item => {
//                         if (!projects[item.project]) {
//                             projects[item.project] = [];
//                         }
//                         const weekIndex = weeks.indexOf(weekData.week);
//                         projects[item.project][weekIndex] = item.reminderCount;
//                     });
//                 });

//                 Object.keys(projects).forEach(project => {
//                     seriesData.push({
//                         name: project,
//                         type: 'bar',
//                         data: weeks.map((week, index) => projects[project][index] || 0),
//                         label: {
//                             show: true,
//                             position: 'inside', // Display labels inside the bars
//                             formatter: (params) => `${params.value}`,
//                             color: '#fff', // Color of the labels for better visibility inside the bars
//                             fontSize: 12,
//                             align: 'center',
//                             verticalAlign: 'middle',
//                         },
//                     });
//                 });

//                 // Initialize the chart with processed data
//                 const chartContainer = document.getElementById('chartContainer');
//                 if (chartContainer) {
//                     const myChart = echarts.init(chartContainer);

//                     myChart.setOption({
//                         tooltip: {
//                             trigger: 'axis',
//                             axisPointer: {
//                                 type: 'shadow',
//                             },
//                             formatter: (params) => {
//                                 let tooltip = '';
//                                 params.forEach(item => {
//                                     if (item.value > 0) {
//                                         tooltip += `${item.seriesName}: ${item.value} reminder<br/>`;
//                                     }
//                                 });
//                                 return tooltip || 'No data'; // Show 'No data' if all values are 0
//                             },
//                         },
//                         legend: {
//                             data: Object.keys(projects),
//                         },
//                         xAxis: {
//                             type: 'category',
//                             data: weeks,
//                             axisLabel: {
//                                 rotate: 0, // Horizontal labels
//                                 interval: 0,
//                                 align: 'center',
//                                 margin: 10,
//                             },
//                             axisTick: {
//                                 alignWithLabel: true,
//                             },
//                         },
//                         yAxis: {
//                             type: 'value',
//                         },
//                         series: seriesData,
//                     });

//                     // Dispose the chart on component unmount
//                     return () => {
//                         myChart.dispose();
//                     };
//                 }
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };

//         fetchData();
//     }, []); // Empty dependency array ensures this runs only once

//     const start = (
//         <div>
//             <p
//                 style={{
//                     color: "#00000080",
//                     fontWeight: "bold",
//                     fontSize: "22px",
//                     margin: "0px",
//                     marginBottom: "5PX",
//                 }}
//             >
//                 Task Analytics by Month and Project
//             </p>
//         </div>
//     );
//     const end = (
//         <div style={{ paddingRight: "15px" }}>
//             <Image src={logo} height="60px" width="60px" />
//         </div>
//     );

//     return (
//         <div>
//             <div className='card'>
//                 <Menubar start={start} end={end} />
//             </div>
//             <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 132px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
//                 <div style={{ height: 'calc(100vh - 260px)', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
//                     <div style={{ width: '98%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}>
//                         <div id="chartContainer" style={{ width: '100%', height: '100%', padding: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default RemindersChart;


import React, { useEffect, useState, useContext } from 'react';
import * as echarts from 'echarts';
import { Image } from 'primereact/image';
import logo from "../../../assets/IGS.png";
import { Menubar } from 'primereact/menubar';
import { UserContext } from '../../../context/UserContext';

const RemindersChart = () => {
    const { user } = useContext(UserContext);
    const [chartData, setChartData] = useState(null); // State to hold chart data

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/getRemindersByWeekForEmpId/${user.Data.empId}`
                );
                const data = await response.json();

                // Process data to prepare for chart
                const weeks = [];
                const projects = {};
                const seriesData = [];

                data.forEach(weekData => {
                    if (!weeks.includes(weekData.week)) {
                        weeks.push(weekData.week);
                    }
                    weekData.data.forEach(item => {
                        if (!projects[item.project]) {
                            projects[item.project] = [];
                        }
                        const weekIndex = weeks.indexOf(weekData.week);
                        projects[item.project][weekIndex] = item.reminderCount;
                    });
                });

                Object.keys(projects).forEach(project => {
                    seriesData.push({
                        name: project,
                        type: 'bar',
                        data: weeks.map((week, index) => projects[project][index] || 0),
                        label: {
                            show: true,
                            position: 'inside', // Display labels inside the bars
                            formatter: (params) => `${params.value}`,
                            color: '#fff', // Color of the labels for better visibility inside the bars
                            fontSize: 12,
                            align: 'center',
                            verticalAlign: 'middle',
                        },
                    });
                });

                setChartData({
                    weeks,
                    seriesData,
                    projects,
                });

                // Initialize the chart with processed data
                const chartContainer = document.getElementById('chartContainer');
                if (chartContainer) {
                    const myChart = echarts.init(chartContainer);

                    myChart.setOption({
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow',
                            },
                            formatter: (params) => {
                                let tooltip = '';
                                params.forEach(item => {
                                    if (item.value > 0) {
                                        tooltip += `${item.seriesName}: ${item.value} reminder<br/>`;
                                    }
                                });
                                return tooltip || 'No data'; // Show 'No data' if all values are 0
                            },
                        },
                        legend: {
                            data: Object.keys(projects),
                        },
                        xAxis: {
                            type: 'category',
                            data: weeks,
                            axisLabel: {
                                rotate: 0, // Horizontal labels
                                interval: 0,
                                align: 'center',
                                margin: 10,
                            },
                            axisTick: {
                                alignWithLabel: true,
                            },
                        },
                        yAxis: {
                            type: 'value',
                        },
                        series: seriesData,
                    });

                    // Dispose the chart on component unmount
                    return () => {
                        myChart.dispose();
                    };
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [user.Data.empId]); // Adding user.Data.empId as a dependency

    const start = (
        <div>
            <p
                style={{
                    color: "#00000080",
                    fontWeight: "bold",
                    fontSize: "22px",
                    margin: "0px",
                    marginBottom: "5PX",
                }}
            >
                Task Analytics by Month and Project
            </p>
        </div>
    );
    const end = (
        <div style={{ paddingRight: "15px" }}>
            <Image src={logo} height="60px" width="60px" />
        </div>
    );

    return (
        <div>
            <div className='card'>
                <Menubar start={start} end={end} />
            </div>
            <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 132px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>
                <div style={{ height: 'calc(100vh - 260px)', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                    <div style={{ width: '98%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}>
                        {chartData && chartData.seriesData.length > 0 ? (
                            <div id="chartContainer" style={{ width: '100%', height: '100%', padding: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
                        ) : (
                            <p style={{ fontSize: '24px', fontWeight: 'bold', color: '#00000080', textAlign: 'center', width: '100%' }}>No Reminder</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RemindersChart;
