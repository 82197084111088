import React, { useEffect, useState } from 'react';
import { getAllProjects } from '../../services/createProjectServices/Services';
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import styles from "./ProjectsList.module.css"
export default function Projects() {
    const [projects, setProjects] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        getAllProjects().then((data) => {
            if (data != null) {
                const sortedProjects = data.sort((a, b) => {
                    const currentDate = new Date();
                    const endDateA = new Date(a.project_details.end_date);
                    const endDateB = new Date(b.project_details.end_date);

                    if ((currentDate < endDateA && currentDate < endDateB) || (currentDate > endDateA && currentDate > endDateB)) {
                        return new Date(b.project_details.start_date) - new Date(a.project_details.start_date);
                    }
                    return currentDate > endDateA ? 1 : -1;
                });
                setProjects(sortedProjects);
            }
        });
    }, []);

    // Function to format date in 'dd/mm/yyyy' format
    function formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB');
    }

    // Determine status color based on project start and end dates
    function getStatusColor(startDate, endDate) {
        const currentDate = new Date();
        const projectStartDate = new Date(startDate);
        const projectEndDate = new Date(endDate);

        if (currentDate > projectEndDate) return '#FF0000'; // Red for expired projects
        const totalDuration = projectEndDate - projectStartDate;
        const remainingDuration = projectEndDate - currentDate;

        if (remainingDuration <= 0.2 * totalDuration) return '#FFBF00'; // Amber for projects close to ending
        return 'green'; // Green for active projects
    }

    const filteredProjects = projects.filter((project) => {
        const projectName = project?.project_details?.name?.toLowerCase() || '';
        const deliveryManagerName = project?.project_details?.delivery_manager?.name?.toLowerCase() || '';
        const endDate = new Date(project.project_details.end_date);
        const currentDate = new Date();

        const isExpired = getStatusColor(project.project_details.start_date, project.project_details.end_date) === '#FF0000';
        return (
            (projectName.includes(searchTerm.toLowerCase()) ||
                deliveryManagerName.includes(searchTerm.toLowerCase())) && isExpired && endDate < currentDate
        );
    });

    return (
        <div className={styles.container}>
            {/* Search and Add Project */}
            <main className={styles.main}>
                <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '30px', padding: '10px' }}>
                    <div>
                        <InputText
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search projects..."
                            style={{ width: '100%' }} />
                    </div>
                </header>


            {filteredProjects.length === 0 ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 300px)' }}>
                    <h1 style={{ textAlign: 'center', color: '#888' }}>No Project Found</h1>
                </div>
            ) : (
                <div className={styles.projectList}>
                    {filteredProjects.map((project, index) => (
                        <div key={index} className={styles.project}>
                            <div className={styles.projectNumber} >
                                {project.project_details.name}
                            </div>
                            <div className={styles.projectName}>
                                <span>Delivery Manager: {project.project_details.delivery_manager?.name || 'No delivery manager'}</span>
                            </div>
                            <div className={styles.projectName1}>
                                <span>Client: {project.project_details.client_name}</span>
                            </div>
                            <div className={styles.projectDates}>
                                <span>Start: {formatDate(project.project_details.start_date)}</span>
                            </div>
                            <div className={styles.projectDates}>
                                <span>End: {formatDate(project.project_details.end_date)}</span>
                            </div>
                            <div className={styles.projectUsers}>
                                Team: {project.project_members ? project.project_members.length : `0`}/{project.project_details.team_size}
                            </div>
                            <div className={styles.projectStatus}>
                                <div className={styles.statusIndicator} style={{ backgroundColor: getStatusColor(project.project_details.start_date, project.project_details.end_date) }}></div>
                            </div>
                            {/* <div>
                                <div style={{ backgroundColor: '#ebebeb' }}>
                                    <Button
                                        icon="pi pi-ellipsis-v"
                                        className={styles.icon}
                                        style={{ backgroundColor: '#ebebeb', border: 'none' }}
                                    />

                                </div>
                            </div> */}
                        </div>
                    ))}
                </div>
            )}
            </main>

        </div>
    );
}
