import React, { useContext, useState, useEffect, useRef } from "react";
import { UserContext } from "../../context/UserContext";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { format } from "date-fns";

export default function Buttons({
  rows,
  status,
  setStatus,
  setRows,
  user,
  isSubmitted,
  projectMap,
  subProjectMap,
  taskMap,
  selectedWeekStart,
  selectedWeekEnd,
  fetchTimesheetData,
  setIsSaved,
  isSaved,
  leaveSelections,
  setLeaveSelections,
  setDayTotals,
}) {
  const toast = useRef(null);
  //   const { user } = useContext(UserContext);

  const addNewRow = () => {
    const newRow = {
      id: new Date().getTime(),
      project: "",
      subProject: "",
      task: "",
      sun: leaveSelections[0]?.sun || "", // Use the existing leave selection for "sun"
      mon: leaveSelections[0]?.mon || "",
      tue: leaveSelections[0]?.tue || "",
      wed: leaveSelections[0]?.wed || "",
      thu: leaveSelections[0]?.thu || "",
      fri: leaveSelections[0]?.fri || "",
      sat: leaveSelections[0]?.sat || "",
    };

    setRows([...rows, newRow]); // Add the new row to the existing rows
    setLeaveSelections([
      ...leaveSelections,
      {
        sun: leaveSelections[0]?.sun || null,
        mon: leaveSelections[0]?.mon || null,
        tue: leaveSelections[0]?.tue || null,
        wed: leaveSelections[0]?.wed || null,
        thu: leaveSelections[0]?.thu || null,
        fri: leaveSelections[0]?.fri || null,
        sat: leaveSelections[0]?.sat || null,
      },
    ]); // Update the leave selections for the new row as well
  };

  // const handleSave = async () => {
  //     // Validation: Check if project, subProject, and task are selected for each row
  //     for (let row of rows) {
  //         if (!row.project) {
  //             toast.current.show({
  //                 severity: 'warn',
  //                 summary: 'Validation Error',
  //                 detail: 'Project is required',
  //                 life: 3000,
  //             });
  //             return; // Exit the function without saving
  //         }
  //         if (!row.subProject) {
  //             toast.current.show({
  //                 severity: 'warn',
  //                 summary: 'Validation Error',
  //                 detail: 'SubProject is required',
  //                 life: 3000,
  //             });
  //             return; // Exit the function without saving
  //         }
  //         if (!row.task) {
  //             toast.current.show({
  //                 severity: 'warn',
  //                 summary: 'Validation Error',
  //                 detail: 'Task is required',
  //                 life: 3000,
  //             });
  //             return; // Exit the function without saving
  //         }
  //     }

  //     // Calculate total hours for each row
  //     const updatedRows = rows.map(row => {
  //         // Calculate the total hours
  //         const total = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
  //             .reduce((sum, day) => sum + (parseFloat(row[day]) || 0), 0);

  //         return { ...row, total };
  //     });

  //     // Construct payload array for each row
  //     const payloadArray = updatedRows.map(row => {
  //         const timesheetData = {
  //             empId: user.Data.empId,
  //             employeeName: user.Data.name,
  //             email: user.Data.email,
  //             status: status || "pending",
  //             project: {
  //                 _id: row.project,
  //                 name: projectMap[row.project] || 'Unknown Project'
  //             },
  //             subproject: {
  //                 _id: row.subProject,
  //                 name: subProjectMap[row.subProject] || 'Unknown SubProject'
  //             },
  //             task: {
  //                 _id: row.task,
  //                 name: taskMap[row.task] || 'Unknown Task'
  //             },
  //             week: `${format(selectedWeekStart, "dd/MM/yyyy")} - ${format(selectedWeekEnd, "dd/MM/yyyy")}`,
  //             entries: [
  //                 {
  //                     hours: {
  //                         sun: { time: row.sun },
  //                         mon: { time: row.mon },
  //                         tue: { time: row.tue },
  //                         wed: { time: row.wed },
  //                         thu: { time: row.thu },
  //                         fri: { time: row.fri },
  //                         sat: { time: row.sat },
  //                     }
  //                 }
  //             ],
  //             totalHours: row.total || 0 // Include total hours in the payload
  //         };

  //         return {
  //             empId: user.Data.empId,
  //             projectId: row.project || 'defaultProjectId',
  //             subProjectId: row.subProject || 'defaultSubProjectId',
  //             taskDescription: row.task || 'defaultTaskId',
  //             timesheet: timesheetData
  //         };
  //     });

  //     try {
  //         const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/timesheet/${user.Data.empId}`, {
  //             method: 'POST',
  //             headers: {
  //                 'Content-Type': 'application/json',
  //             },
  //             body: JSON.stringify(payloadArray),
  //         });

  //         const responseBody = await response.json();
  //         if (response.ok) {
  //             toast.current.show({
  //                 severity: 'success',
  //                 summary: 'Success',
  //                 detail: 'Timesheet has been saved successfully',
  //                 life: 3000,
  //             });
  //             console.log('Timesheets posted successfully:', responseBody);
  //             setIsSaved(true); // Set save flag to true

  //             fetchTimesheetData();
  //         } else {
  //             toast.current.show({
  //                 severity: 'error',
  //                 summary: 'Error',
  //                 detail: 'Failed to save timesheet',
  //                 life: 3000,
  //             });
  //             console.error('Failed to post timesheets:', responseBody);
  //         }
  //     } catch (error) {
  //         toast.current.show({
  //             severity: 'error',
  //             summary: 'Error',
  //             detail: 'An error occurred while saving timesheet',
  //             life: 3000,
  //         });
  //         console.error('Error posting timesheets:', error);
  //     }
  // };

  const handleSave = async () => {
    // Validation: Check if project, subProject, and task are selected for each row
    for (let row of rows) {
      if (!row.project) {
        toast.current.show({
          severity: "warn",
          summary: "Validation Error",
          detail: "Project is required",
          life: 3000,
        });
        return; // Exit the function without saving
      }
      if (!row.subProject) {
        toast.current.show({
          severity: "warn",
          summary: "Validation Error",
          detail: "SubProject is required",
          life: 3000,
        });
        return; // Exit the function without saving
      }
      if (!row.task) {
        toast.current.show({
          severity: "warn",
          summary: "Validation Error",
          detail: "Task is required",
          life: 3000,
        });
        return; // Exit the function without saving
      }
    }

    // Validate that no day exceeds 8 hours for each row
    const updatedRows = rows.map((row) => {
      const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
      let total = 0; // Initialize total hours for the week

      for (let day of days) {
        let hours = row[day];

        // Check for special codes SH, CH, EH and add 4 hours for each occurrence
        if (hours === "SH" || hours === "CH" || hours === "EH") {
          total += 4;
        } else {
          // Add normal hours to the total if they don't exceed 8
          total += parseFloat(hours) || 0;
        }
      }

      // Return the updated row with the calculated total hours
      return { ...row, total };
    });

    // If any row contains invalid hours, prevent the save
    if (updatedRows.includes(null)) {
      return;
    }

    // Construct payload array for each row
    const payloadArray = updatedRows.map((row) => {
      const timesheetData = {
        empId: user.Data.empId,
        employeeName: user.Data.name,
        email: user.Data.email,
        status: status || "pending",
        project: {
          _id: row.project,
          name: projectMap[row.project] || "Unknown Project",
        },
        subproject: {
          _id: row.subProject,
          name: subProjectMap[row.subProject] || "Unknown SubProject",
        },
        task: {
          // _id: row.task,
          name: taskMap[row.task] || "Unknown Task",
        },
        week: `${format(selectedWeekStart, "dd/MM/yyyy")} - ${format(
          selectedWeekEnd,
          "dd/MM/yyyy"
        )}`,
        entries: [
          {
            hours: {
              sun: { time: row.sun },
              mon: { time: row.mon },
              tue: { time: row.tue },
              wed: { time: row.wed },
              thu: { time: row.thu },
              fri: { time: row.fri },
              sat: { time: row.sat },
            },
          },
        ],
        totalHours: row.total || 0, // Include total hours in the payload
      };

      return {
        empId: user.Data.empId,
        projectId: row.project || "defaultProjectId",
        subProjectId: row.subProject || "defaultSubProjectId",
        category_name: row.task || "defaultTaskId",
        timesheet: timesheetData,
      };
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/timesheet/${user.Data.empId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payloadArray),
        }
      );

      if (response.ok) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Timesheet has been saved successfully",
          life: 3000,
        });

        // Keep the rows intact after saving
        setRows(updatedRows);
        setIsSaved(true);
        setDayTotals({
          sun: 0,
          mon: 0,
          tue: 0,
          wed: 0,
          thu: 0,
          fri: 0,
          sat: 0,
        });

        // Fetch the updated timesheet data
        await fetchTimesheetData();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to save timesheet",
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error saving timesheet:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while saving the timesheet",
        life: 3000,
      });
    }
  };

  const handleSubmit = async () => {
    const submitPayload = rows.map((row) => ({
      projectId: row.project || "defaultProjectId",
      subProjectId: row.subProject || "defaultSubProjectId",
      taskId: row.task || "defaultTaskId",
      week: `${format(selectedWeekStart, "dd/MM/yyyy")} - ${format(
        selectedWeekEnd,
        "dd/MM/yyyy"
      )}`,
      daySum: row.total || 0, // Include the total hours for each row
    }));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/timesheet/status/${user.Data.empId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(submitPayload),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setStatus(data.status); // Update status from server response

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Timesheet sent for approval successfully",
          life: 3000,
        });

        fetchTimesheetData(); // Fetch updated timesheet data after submitting
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to send timesheet for approval",
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error updating timesheet status for row:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while sending timesheet for approval",
        life: 3000,
      });
    }
  };

  const handleSaveButtonClick = async () => {
    await fetchTimesheetData();
    handleSubmit(rows);
  };
  return (
    <div>
      <Toast ref={toast} />
      {!isSubmitted && (
        <div style={{ marginTop: "20px", display: "flex" }}>
          <div style={{ width: "70%" }}>
            <Button
              label="Add New"
              style={{ background: "#EFB034FF", border: "1px solid #EFB034FF" }}
              raised
              onClick={addNewRow}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "30%",
              justifyContent: "flex-end",
              gap: "15px",
              paddingRight: "20px",
            }}
          >
            <Button
              label="Submit"
              style={{ background: "#29295FFF", border: "1px solid #29295FFF" }}
              onClick={handleSaveButtonClick}
              raised
              disabled={!isSaved}
            />
            <Button
              label="Save"
              style={{ background: "#29295FFF", border: "1px solid #29295FFF" }}
              onClick={handleSave}
              raised
            />
          </div>
        </div>
      )}
    </div>
  );
}
