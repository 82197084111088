
// import React, { useContext } from "react";
// import logo from "../../assets/logo.png";
// import { NavLink, useNavigate } from "react-router-dom";
// import {
//   FaProjectDiagram,
//   FaClock,
//   FaFileAlt,
//   FaTasks,
//   FaUserSecret,
// } from "react-icons/fa";
// import { IoIosLogOut } from "react-icons/io";
// import { PiEar } from "react-icons/pi";
// import "./Sidebar.css";
// import { UserContext } from "../../context/UserContext";

// const Sidebar = ({ onLogout }) => {
//   const navigate = useNavigate();
//   const { user } = useContext(UserContext);
//   const showFullLayout = ["100", "101", "110", "111", 100, 101, 110, 111].includes(user.Data.hkey);

//   const handleLogout = () => {
//     onLogout();
//     navigate("/login");
//   };

//   return (
//     <div className="sidebar">
//       <div className="logo" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
//         <img alt="logo" src={logo} className="logo" />
//         <div>
//           <h1>TimeSheets</h1>
//         </div>
//       </div>
//       <div
//         style={{ backgroundColor: "black", height: "1px", marginBottom: "5px" }}
//       ></div>
//       <nav>
//         <ul>
//           <li>
//             <NavLink
//               to="/timesheets"
//               className={({ isActive }) => (isActive ? "active-link" : "")}
//             >
//               <FaClock />
//               <span>Time Sheets</span>
//             </NavLink>
//           </li>
//           <li>
//             <NavLink
//               to="/reports"
//               className={({ isActive }) => (isActive ? "active-link" : "")}
//             >
//               <FaFileAlt />
//               <span>Reports</span>
//             </NavLink>
//           </li>
//           {showFullLayout && (
//             <>
//               <li>
//                 <NavLink
//                   to="/projects"
//                   className={({ isActive }) => (isActive ? "active-link" : "")}
//                 >
//                   <FaProjectDiagram />
//                   <span>Projects</span>
//                 </NavLink>
//               </li>
//               <li>
//                 <NavLink
//                   to="/tasks"
//                   className={({ isActive }) => (isActive ? "active-link" : "")}
//                 >
//                   <FaTasks />
//                   <span>Sub Projects</span>
//                 </NavLink>
//               </li>
//               <li>
//                 <NavLink
//                   to="/createtasks"
//                   className={({ isActive }) => (isActive ? "active-link" : "")}
//                 >
//                   <FaFileAlt />
//                   <span>Master Tasks</span>
//                 </NavLink>
//               </li>
//               <li>
//                 <NavLink
//                   to="/earmark"
//                   className={({ isActive }) => (isActive ? "active-link" : "")}
//                 >
//                   <PiEar />
//                   <span>EarMarked Projects</span>
//                 </NavLink>
//               </li>
//             </>
//           )}
//         </ul>
//         <div style={{
//           position: "fixed",
//           bottom: "10%",
//           display: "flex",
//           gap: "10px",
//           cursor: "pointer",
//           alignItems: "center",
//         }}>
//           <FaUserSecret style={{ fontSize: "25px" }} />
//           <p style={{ fontWeight: "500" }}>{user.Data.name}</p>
//         </div>
//         <div
//           onClick={handleLogout} // Add click event handler
//           style={{
//             position: "fixed",
//             bottom: "5%",
//             display: "flex",
//             gap: "10px",
//             cursor: "pointer",
//             alignItems: "center",
//           }}
//         >
//           <IoIosLogOut style={{ fontSize: "25px" }} />
//           <div style={{ fontWeight: "500" }}>Log Out</div>
//         </div>
//       </nav>
//     </div>
//   );
// };

// export default Sidebar;


import React, { useContext, useState } from "react";
import logo from "../../assets/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import {
  FaProjectDiagram,
  FaClock,
  FaFileAlt,
  FaTasks,
  FaUserSecret,
} from "react-icons/fa";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import { IoIosLogOut } from "react-icons/io";
import { PiEar } from "react-icons/pi";
import "./Sidebar.css";
import { UserContext } from "../../context/UserContext";

const Sidebar = ({ onLogout }) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [reportsExpanded, setReportsExpanded] = useState(false);
  // Only show full layout for these hkeys
  const showFullLayout = ["100", "101", "110", "111", 100, 101, 110, 111].includes(user.Data.hkey);
  // Toggle report section visibility
  const toggleReports = () => {
    setReportsExpanded(!reportsExpanded);
  };

  const handleLogout = () => {
    onLogout();
    navigate("/login");
  };

  return (
    <div className="sidebar">
      <div className="logo" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
        <img alt="logo" src={logo} className="logo" />
        <div>
          <h1>TimeSheets</h1>
        </div>
      </div>
      <div
        style={{ backgroundColor: "black", height: "1px", marginBottom: "5px" }}
      ></div>
      <nav>
        <ul>
          <li>
            <NavLink
              to="/timesheets"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              <FaClock />
              <span>Time Sheets</span>
            </NavLink>
          </li>

          {/* Expandable Report section */}
          <li>
          <div onClick={toggleReports} className="report-link" style={{ display: 'flex', alignItems: 'center', gap:'20px',cursor: 'pointer' }}>
              <FaFileAlt />
              <span style={{ marginRight: '10px' }}>Reports</span> {/* Add space between text and icon */}
              {reportsExpanded ? <FaChevronUp style={{ fontSize: '16px' }} /> : <FaChevronDown style={{ fontSize: '16px' }} />}
            </div>
            {reportsExpanded && (
              <ul className="sub-links">
                {/* Ternary operator to conditionally show "Bench" and "Not on Bench" or "Report by Week" and "Report by Month" */}
                {showFullLayout ? (
                  <>
                    <li>
                      <NavLink
                        to="/reports/bench"
                        className={({ isActive }) => (isActive ? "active-link" : "")}
                      >
                        <span>Bench</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/reports/Attendence"
                        className={({ isActive }) => (isActive ? "active-link" : "")}
                      >
                        <span>Attendence Report</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/reports/billing"
                        className={({ isActive }) => (isActive ? "active-link" : "")}
                      >
                        <span>Billing Report</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/reports/allocation"
                        className={({ isActive }) => (isActive ? "active-link" : "")}
                      >
                        <span>Allocation</span>
                      </NavLink>
                    </li> 
                    <li>
                      <NavLink
                        to="/reports/utilization"
                        className={({ isActive }) => (isActive ? "active-link" : "")}
                      >
                        <span>Utilization</span>
                      </NavLink>
                    </li> 
                  
                  </>
                ) : (
                  <>
                    <li>
                      <NavLink
                        to="/reports/week"
                        className={({ isActive }) => (isActive ? "active-link" : "")}
                      >
                        <span>Weekly Report</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/reports/month"
                        className={({ isActive }) => (isActive ? "active-link" : "")}
                      >
                        <span>Monthly Report</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/reports/timesheetanalysis"
                        className={({ isActive }) => (isActive ? "active-link" : "")}
                      >
                        <span>Timesheet Analysis</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/reports/reportanalysis"
                        className={({ isActive }) => (isActive ? "active-link" : "")}
                      >
                        <span>Report Insights</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/reports/reminder"
                        className={({ isActive }) => (isActive ? "active-link" : "")}
                      >
                        <span>Reminder</span>
                      </NavLink>
                    </li>
                  </>
                )}
              </ul>
            )}
          </li>

          {/* Additional navigation links visible to users with specific hkeys */}
          {showFullLayout && (
            <>
              <li>
                <NavLink
                  to="/projects"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                >
                  <FaProjectDiagram />
                  <span>Projects</span>
                </NavLink>
              </li>
              
              <li>
                <NavLink
                  to="/mastertasks"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                >
                  <FaFileAlt />
                  <span>Master Tasks</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/leaves"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                >
                  <FaFileAlt />
                  <span>Leaves</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/earmark"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                >
                  <PiEar />
                  <span>EarMarked Projects</span>
                </NavLink>
              </li>
            </>
          )}
        </ul>
        <div style={{
          position: "fixed",
          bottom: "5%",
          display: "flex",
          gap: "5px",
          cursor: "pointer",
          alignItems: "center",
        }}>
          <FaUserSecret style={{ fontSize: "18px" }} />
          <p style={{ fontWeight: "500" }}>{user.Data.name}</p>
        </div>
        <div
          onClick={handleLogout}
          style={{
            position: "fixed",
            bottom: "3%",
            display: "flex",
            gap: "5px",
            cursor: "pointer",
            alignItems: "center",
          }}
        >
          <IoIosLogOut style={{ fontSize: "18px" }} />
          <div style={{ fontWeight: "500" }}>Log Out</div>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
