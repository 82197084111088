import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useContext, useState, useEffect, useRef } from "react";
import WeekCalendarHeader from "../../components/WeekCalendarHeader/WeekCalendarHeader";
import { getTimeSheet } from "../../services/createProjectServices/Services";
import { UserContext } from "../../context/UserContext";
import { Toast } from 'primereact/toast';

export default function TimeSheets() {
  const toast = useRef(null);
  const [rows, setRows] = useState([
    {
      id: new Date().getTime(),
      project: "",
      subProject: "",
      task: "",
      sun: "",  // Ensure these are initialized as strings
      mon: "",
      tue: "",
      wed: "",
      thu: "",
      fri: "",
      sat: "",
    },
  ]);
  const [projects, setProjects] = useState([]);
  const [subProjects, setSubProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [weekRange, setWeekRange] = useState({ start: new Date(), end: new Date() });
  const [daySum, setDaySum] = useState([])
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const { user } = useContext(UserContext);
  console.log(user, "bob is here");

  const [projectMap, setProjectMap] = useState({});
  const [subProjectMap, setSubProjectMap] = useState({});
  const [taskMap, setTaskMap] = useState({});

  function calculateWeeklySums(data) {
    // Initialize an object to store the sum of each day
    const daySums = {
      sun: 0,
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0
    };

    // Iterate over each row in the data
    // data.forEach(rows => {
    data.forEach(row => {
      // Sum the hours for each day
      daySums.sun += parseFloat(row.entries[0].hours.sun.time) || 0;
      daySums.mon += parseFloat(row.entries[0].hours.mon.time) || 0;
      daySums.tue += parseFloat(row.entries[0].hours.tue.time) || 0;
      daySums.wed += parseFloat(row.entries[0].hours.wed.time) || 0;
      daySums.thu += parseFloat(row.entries[0].hours.thu.time) || 0;
      daySums.fri += parseFloat(row.entries[0].hours.fri.time) || 0;
      daySums.sat += parseFloat(row.entries[0].hours.sat.time) || 0;
    });
    // });

    // Convert the day sums object to an array
    const weeklySumsArray = [
      { day: 'Sunday', total: daySums.sun },
      { day: 'Monday', total: daySums.mon },
      { day: 'Tuesday', total: daySums.tue },
      { day: 'Wednesday', total: daySums.wed },
      { day: 'Thursday', total: daySums.thu },
      { day: 'Friday', total: daySums.fri },
      { day: 'Saturday', total: daySums.sat },
    ];

    return weeklySumsArray;
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getTimeSheet(user.Data.empId);
        const data = response.projects;

        setStartDate(response.startDate);
        setEndDate(response.endDate);
        setStatus(response.status);

        const projectMap = {};
        const subProjectMap = {};
        const taskMap = {};

        data.forEach((project) => {
          projectMap[project.projectId] = project.projectName;
          project.subProjects.forEach((subProject) => {
            subProjectMap[subProject.subProjectId] = subProject.subProjectName;
            subProject.tasks.forEach((task) => {
              taskMap[task.task_id] = task.task_name;
            });
          });
        });

        setProjects(
          data.map((project) => ({
            label: project.projectName,
            value: project.projectId,
            subProjects: project.subProjects,
          }))
        );
        setProjectMap(projectMap);
        setSubProjectMap(subProjectMap);
        setTaskMap(taskMap);
      } catch (error) {
        console.error("Error fetching timesheet data:", error);
      }
    }

    fetchData();
  }, [user.Data.empId]);

  const handleProjectChange = (e, index) => {
    const selectedProject = projects.find(project => project.value === e.value);
    const updatedRows = [...rows];
    updatedRows[index].project = e.value;
    updatedRows[index].subProject = "";
    updatedRows[index].task = "";
    setRows(updatedRows);

    setSubProjects(selectedProject?.subProjects.map(subProject => ({
      label: subProject.subProjectName,
      value: subProject.subProjectId,
      tasks: subProject.tasks,
    })) || []);
  };

  const handleSubProjectChange = (e, index) => {
    const selectedSubProject = subProjects.find(subProject => subProject.value === e.value);
    const updatedRows = [...rows];
    updatedRows[index].subProject = e.value;
    updatedRows[index].task = "";
    setRows(updatedRows);

    setTasks(selectedSubProject?.tasks.map(task => ({
      label: task.task_name,
      value: task.task_id,
    })) || []);
  };

  const handleTaskChange = (e, index) => {
    const updatedRows = [...rows];
    updatedRows[index].task = e.value;
    setRows(updatedRows);
  };

  const handleInputChange = (e, day, index) => {
    const { value } = e.target;

    // Convert value to string if necessary
    if (!isNaN(value) && Number(value) >= 0) {
      const updatedRows = [...rows];
      updatedRows[index][day] = value.toString(); // Ensure this is a string
      setRows(updatedRows);
    } else {
      // Handle cases where value is not a valid number
      const updatedRows = [...rows];
      updatedRows[index][day] = value; // Directly set the value, assuming it's a string
      setRows(updatedRows);
    }
  };

  const addNewRow = () => {
    setRows([
      ...rows,
      {
        id: new Date().getTime(),
        project: "",
        subProject: "",
        task: "",
        sun: "",
        mon: "",
        tue: "",
        wed: "",
        thu: "",
        fri: "",
        sat: "",
      },
    ]);
  };
  const deleteRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleSubmit = async () => {
    // Function to format the week range
    const formatWeekRange = (start, end) => {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      return `${new Intl.DateTimeFormat('en-GB', options).format(start)} - ${new Intl.DateTimeFormat('en-GB', options).format(end)}`;
    };

    // Map project, subproject, and task data
    const projectMap = projects.reduce((map, project) => {
      map[project.value] = project.label;
      return map;
    }, {});

    const subProjectMap = subProjects.reduce((map, subProject) => {
      map[subProject.value] = subProject.label;
      return map;
    }, {});

    const taskMap = tasks.reduce((map, task) => {
      map[task.value] = task.label;
      return map;
    }, {});

    // Construct payload array for each row
    const payloadArray = rows.map(row => {
      const timesheetData = {
        empId: user.Data.empId,
        employeeName: user.Data.name,
        email: user.Data.email,
        status: user.Data.status != undefined ? user.Data.status : "pending",
        project: {
          _id: row.project,
          name: projectMap[row.project] || 'Unknown Project'
        },
        subproject: {
          _id: row.subProject,
          name: subProjectMap[row.subProject] || 'Unknown SubProject'
        },
        task: {
          _id: row.task,
          name: taskMap[row.task] || 'Unknown Task'
        },
        week: formatWeekRange(weekRange.start, weekRange.end),
        entries: [{
          hours: {
            sun: { time: row.sun },
            mon: { time: row.mon },
            tue: { time: row.tue },
            wed: { time: row.wed },
            thu: { time: row.thu },
            fri: { time: row.fri },
            sat: { time: row.sat },
          }
        }]
      };

      return {
        empId: user.Data.empId,
        projectId: row.project || 'defaultProjectId',
        subProjectId: row.subProject || 'defaultSubProjectId',
        taskId: row.task || 'defaultTaskId',
        timesheet: timesheetData
      };
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/timesheet/${user.Data.empId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payloadArray),
      });

      const responseBody = await response.json();
      if (response.ok) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Timesheet has been saved successfully',
          life: 3000,
        });
        console.log('Timesheets posted successfully:', responseBody);
        fetchTimesheetData(); // Update the data after successful post
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to save timesheet',
          life: 3000,
        });
        console.error('Failed to post timesheets:', responseBody);
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'An error occurred while saving timesheet',
        life: 3000,
      });
      console.error('Error posting timesheets:', error);
    }
  };

  const formatWeekRange = (start, end) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return `${new Intl.DateTimeFormat('en-GB', options).format(start)} - ${new Intl.DateTimeFormat('en-GB', options).format(end)}`;
  };

  const handleSave = async (rows) => {
    const submitPayload = [];

    for (const row of rows) {
      submitPayload.push({
        projectId: row.project || 'defaultProjectId',
        subProjectId: row.subProject || 'defaultSubProjectId',
        taskId: row.task || 'defaultTaskId',
        week: formatWeekRange(weekRange.start, weekRange.end),
      });
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/timesheet/status/${user.Data.empId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submitPayload),
      });

      if (response.ok) {
        const data = await response.json();
        setStatus(data.status); // Update status from server response

        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Timesheet sent for approval successfully',
          life: 3000,
        });
        fetchTimesheetData(); // Fetch updated timesheet data
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to send timesheet for approval',
          life: 3000,
        });
        console.error('Failed to update timesheet status for row:');
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'An error occurred while sending timesheet for approval',
        life: 3000,
      });
      console.error('Error updating timesheet status for row:');
    }
  };

  const handleSaveButtonClick = () => {
   
    handleSave(rows);  // Pass the entire rows array
  };

  const fetchTimesheetData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/Emptimesheet/${user.Data.empId}`
      );
      const data = await response.json();
      // Filter out entries where status is "approved"
      setStatus(data[0].status);
      const filteredData = data.filter(entry => entry.status !== "approved");
      setDaySum(calculateWeeklySums(filteredData));

      const formattedRows = filteredData.map((entry) => {
        const hours = entry.entries[0].hours || {};
        // Calculate total hours for the row
        const total = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"].reduce((sum, day) => {
          return sum + (parseFloat(hours[day]?.time) || 0); // Ensure time is treated as a number
        }, 0);

        return {
          project: entry.project._id,
          subProject: entry.subproject._id,
          task: entry.task._id,
          status: entry.status,
          sun: hours.sun?.time || "",
          mon: hours.mon?.time || "-",
          tue: hours.tue?.time || "-",
          wed: hours.wed?.time || "-",
          thu: hours.thu?.time || "-",
          fri: hours.fri?.time || "-",
          sat: hours.sat?.time || "-",
          total, // Add total hours to the row object
        };
      });

      // Log the formatted rows to ensure they're being processed
      console.log(formattedRows, "bob is here with formatted rows");
      setRows(formattedRows);
    } catch (error) {
      console.error("Error fetching timesheet data:", error);
    }
  };

  useEffect(() => {
    fetchTimesheetData();
  }, [user.Data.empId]);

  return (
    <div>
      <Toast ref={toast} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p style={{ color: " #00000080", fontSize: "24px", fontWeight: "bold" }}>
          Time Sheet
        </p>
      </div>

      <div
        style={{ color: " #00000080", fontSize: "18px", fontWeight: "bold", display: 'flex', justifyContent: 'space-between' }}
      >
        <div>
          Status: <span style={{ color: "#EFB034FF" }}>{status}</span>
        </div>
        <WeekCalendarHeader
          start_date={startDate}
          end_date={endDate}
          weekRange={weekRange}
          setWeekRange={setWeekRange}
        />
      </div>
      <div
        style={{
          marginTop: "20px",
          border: "1px solid #EBEBEB",
          padding: "15px",
          borderRadius: "5px",
          boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
        }}
      >
        <div>
          <div style={{ display: "flex", width: "100%", gap: "5px" }}>
            <div
              style={{
                width: "14%",
                background: "#71759B",
                padding: "10px",
                color: "#FFFFFF",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Projects
            </div>
            <div
              style={{
                width: "14%",
                background: "#71759B",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                color: "#FFFFFF",
              }}
            >
              Sub Projects
            </div>
            <div
              style={{
                width: "14%",
                background: "#71759B",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                color: "#FFFFFF",
              }}
            >
              Tasks
            </div>
            <div
              style={{
                width: "7.25%",
                color: "#0069FFFF",
                background: "#F3F1ECFF",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Sun
            </div>
            <div
              style={{
                width: "7.25%",
                color: "#0069FFFF",
                background: "#F3F1ECFF",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Mon
            </div>
            <div
              style={{
                width: "7.25%",
                color: "#0069FFFF",
                background: "#F3F1ECFF",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Tue
            </div>
            <div
              style={{
                width: "7.25%",
                color: "#0069FFFF",
                background: "#F3F1ECFF",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Wed
            </div>
            <div
              style={{
                width: "7.25%",
                color: "#0069FFFF",
                background: "#F3F1ECFF",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Thur
            </div>
            <div
              style={{
                width: "7.25%",
                color: "#0069FFFF",
                background: "#F3F1ECFF",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Fri
            </div>
            <div
              style={{
                width: "7.25%",
                color: "#0069FFFF",
                background: "#F3F1ECFF",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Sat
            </div>
            <div
              style={{
                width: "7.25%",
                color: "#0069FFFF",
                background: "#F3F1ECFF",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Total
            </div>
          </div>
          {rows.map((row, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                width: "100%",
                gap: "5px",
                marginTop: "10px",
              }}
            >
              {/* Project Dropdown with Delete Icon */}
              <div style={rowStyle}>
                <i
                  className="pi pi-trash"
                  style={{ color: "grey", cursor: "pointer" }}
                  onClick={() => deleteRow(row.id)}
                ></i>
                <Dropdown
                  placeholder="Project"
                  style={{ width: "80%" }}
                  value={row.project} // Project ID as a primitive value
                  options={projects.map((project) => ({
                    label: project.label,
                    value: project.value,
                  }))} // Options for the project dropdown
                  onChange={(e) => handleProjectChange(e, index)}
                />
              </div>

              {/* Sub Project Dropdown */}
              <div style={rowStyle}>
                <Dropdown
                  placeholder="Sub Project"
                  style={{ width: "100%" }}
                  value={row.subProject} // SubProject ID as a primitive value
                  options={
                    projects
                      .find((p) => p.value === row.project)?.subProjects
                      .map((subProject) => ({
                        label: subProject.subProjectName,
                        value: subProject.subProjectId,
                      })) || []
                  } // Filter and map subprojects for the selected project
                  onChange={(e) => handleSubProjectChange(e, index)}
                />
              </div>

              {/* Task Dropdown */}
              <div style={rowStyle}>
                <Dropdown
                  placeholder="Task"
                  style={{ width: "100%" }}
                  value={row.task} // Task ID as a primitive value
                  options={
                    projects
                      .find((p) => p.value === row.project)?.subProjects
                      .find((sp) => sp.subProjectId === row.subProject)?.tasks
                      .map((task) => ({
                        label: task.task_name,
                        value: task.task_id,
                      })) || []
                  } // Filter and map tasks for the selected subproject
                  onChange={(e) => handleTaskChange(e, index)}
                />
              </div>

              {/* Daily Input Fields */}
              {["sun", "mon", "tue", "wed", "thu", "fri", "sat"].map((day, i) => (
                <div key={i} style={inputStyle}>
                  <InputText
                    name={day}
                    value={row[day]} // Display the value from fetched data
                    onChange={(e) => handleInputChange(e, day, index)}
                    style={{
                      width: "80%",
                      borderRadius: "10px",
                      boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)",
                      textAlign: "center"
                    }}
                  />
                  {/* <InputText
                    name={day}
                    value={row[day]} // Display the value from fetched data
                    onChange={(e) => handleInputChange(e, day, index)}
                    style={{
                      width: "80%",
                      borderRadius: "10px",
                      boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)",
                      textAlign: "center",
                      backgroundColor: day === "sun" ? "#ebebeb" : "white", // Set Sunday background color
                      pointerEvents: day === "sun" ? "none" : "auto", // Make Sunday read-only
                    }}
                    readOnly={day === "sun"} // Make Sunday input read-only
                  /> */}
                  {/* <i className="pi pi-envelope" style={{ color: "grey" }}></i> */}
                </div>
              ))}

              {/* Total Column */}
              <div
                style={{
                  width: "7.25%",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#DEE1E6FF",
                  color: "#29295FFF",
                }}
              >
                {row.total} hours
              </div>
            </div>
          ))}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                marginTop: "10px",
                background: "#D0CEC7FF",
                width: "92.75%",
                borderRadius: "5px",
                padding: "10px",
                boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
                display: "flex",
              }}
            >
              <div
                style={{
                  fontSize: "22px",
                  color: "171A1FFF",
                  width: "45%",
                  fontWeight: "500",
                }}
              >
                Total
              </div>
              <div
                style={{
                  width: "7.85%",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#29295FFF",
                }}
              >
                {daySum[0]?.total}hr
              </div>
              <div
                style={{
                  width: "7.85%",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#29295FFF",
                }}
              >
                {daySum[1]?.total}hr
              </div>
              <div
                style={{
                  width: "7.85%",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#29295FFF",
                }}
              >
                {daySum[2]?.total}hr
              </div>
              <div
                style={{
                  width: "7.85%",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#29295FFF",
                }}
              >
                {daySum[3]?.total}hr
              </div>
              <div
                style={{
                  width: "7.85%",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#29295FFF",
                }}
              >
                {daySum[4]?.total}hr
              </div>
              <div
                style={{
                  width: "7.85%",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#29295FFF",
                }}
              >
                {daySum[5]?.total}hr
              </div>
              <div
                style={{
                  width: "7.85%",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#29295FFF",
                }}
              >
                {daySum[6]?.total}hr
              </div>
            </div>
            <div
              style={{
                width: "6.85%",
                background: "#DEE1E6FF",
                borderRadius: "5px",
                padding: "10px",
                boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
                marginTop: "10px",
                fontSize: "22px",
                fontWeight: "bold",
                color: "#29295FFF",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {daySum.reduce((accumulator, current) => {
                return accumulator + current.total;
              }, 0)}hr
            </div>
          </div>
        </div>
        <div style={{ marginTop: "20px", display: "flex" }}>
          <div style={{ width: "70%" }}>
            <Button
              // label="Add New"
              style={{ background: "#EFB034FF", border: "1px solid #EFB034FF" }}
              raised
              onClick={addNewRow}
            // disabled={buttonsDisabled}
            > <i
              className="pi pi-plus"
              style={{
                color: "#FFFFFF",
                fontSize: "20px",
              }}
            ></i></Button>
          </div>
          <div
            style={{
              display: "flex",
              width: "30%",
              justifyContent: "flex-end",
              gap: "15px",
              paddingRight: "20px",
            }}
          >
            <Button
              label="Submit"
              style={{ background: "#29295FFF", border: "1px solid #29295FFF" }}
              onClick={handleSaveButtonClick}
              // disabled={buttonsDisabled}
              raised
            />
            <Button
              label="Save"
              style={{ background: "#29295FFF", border: "1px solid #29295FFF" }}
              onClick={handleSubmit}
              // disabled={buttonsDisabled}
              raised
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const rowStyle = {
  width: "14%",
  color: "#FFFFFF",
  borderRadius: "5px",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
};

const inputStyle = {
  width: "7.25%",
  borderRadius: "5px",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  paddingTop: "8px",
  paddingBottom: "8px",
  border: "2px solid #EBEBEB",
};