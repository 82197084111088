// import React, { useEffect, useRef, useState } from "react";
// import { Panel } from "primereact/panel";
// import { Chart } from "chart.js/auto";

// export default function TimesheetChartByMonth() {
//   const chartRef = useRef(null);
//   const [chartData, setChartData] = useState({
//     labels: [], // Project names will go here
//     datasets: [
//       {
//         label: "Pending Timesheets",
//         data: [], // Dynamic data for pending timesheets
//         backgroundColor: "#FFCE56", // Yellow for pending
//       },
//       {
//         label: "Rejected Timesheets",
//         data: [], // Dynamic data for rejected timesheets
//         backgroundColor: "#FF6384", // Red for rejected
//       },
//       {
//         label: "Approved Timesheets",
//         data: [], // Dynamic data for approved timesheets
//         backgroundColor: "#36A2EB", // Blue for approved
//       },
//     ],
//   });
//   const [tooltips, setTooltips] = useState([]); // Store tooltips for each project

//   useEffect(() => {
//     const fetchTimesheetData = async () => {
//       try {
//         const response = await fetch(
//           "http://192.168.1.20:5004/api/v1/admin/reports/fetchEmployeeTimesheetsByMonth/E04105"
//         );
//         const result = await response.json();

//         const projectLabels = [];
//         const pendingData = [];
//         const rejectedData = [];
//         const approvedData = [];
//         const projectTooltips = [];

//         // Process the timesheets from the response
//         result.data.forEach((monthData) => {
//           // Add label based on the monthYear
//           projectLabels.push(monthData.monthYear);

//           // Populate data arrays for each type of timesheet
//           pendingData.push(monthData.pendingCount);
//           rejectedData.push(monthData.rejectedCount);
//           approvedData.push(monthData.approvedCount);

//           // Generate tooltips by combining project, sub-project, and task names
//           const tooltipText = `
//             Pending: ${monthData.pending.length} projects,
//             Rejected: ${monthData.rejected.length} projects,
//             Approved: ${monthData.approved.length} projects
//           `;
//           projectTooltips.push(tooltipText);
//         });

//         setChartData({
//           labels: projectLabels,
//           datasets: [
//             {
//               label: "Pending Timesheets",
//               data: pendingData,
//               backgroundColor: "#FFCE56",
//             },
//             {
//               label: "Rejected Timesheets",
//               data: rejectedData,
//               backgroundColor: "#FF6384",
//             },
//             {
//               label: "Approved Timesheets",
//               data: approvedData,
//               backgroundColor: "#36A2EB",
//             },
//           ],
//         });

//         setTooltips(projectTooltips);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchTimesheetData();
//   }, []);

//   useEffect(() => {
//     const ctx = chartRef.current.getContext("2d");

//     const myChart = new Chart(ctx, {
//       type: "bar", // Bar chart to show timesheets
//       data: chartData,
//       options: {
//         responsive: true,
//         plugins: {
//           tooltip: {
//             callbacks: {
//               label: function (context) {
//                 return tooltips[context.dataIndex];
//               },
//             },
//           },
//           legend: {
//             position: "top",
//           },
//         },
//         scales: {
//           x: {
//             beginAtZero: true,
//           },
//           y: {
//             beginAtZero: true,
//           },
//         },
//       },
//     });

//     return () => {
//       myChart.destroy();
//     };
//   }, [chartData, tooltips]);

//   return (
//     <Panel
//       header="Timesheets by Month"
//       style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}
//     >
//       <canvas ref={chartRef} />
//     </Panel>
//   );
// }


// import React, { useEffect, useRef, useState, useContext } from 'react';
// import { Chart } from 'chart.js/auto';
// import { Panel } from 'primereact/panel';
// import { Dropdown } from 'primereact/dropdown';
// import { UserContext } from "../../../../context/UserContext";


// const TimesheetPieChart = () => {
//   const { user } = useContext(UserContext)

//   const [selectedMonth, setSelectedMonth] = useState('September 2024');
//   const [months, setMonths] = useState([]);
//   const [chartData, setChartData] = useState(null);
//   const chartRef = useRef(null);

//   // Fetch month options
//   useEffect(() => {
//     // Replace with your actual API to fetch month options if available
//     setMonths([
//       { label: 'September 2024', value: 'September 2024' },
//       // Add more months here
//     ]);
//   }, []);

//   // Fetch data and update chart when selectedMonth changes
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(
//           // 'http://192.168.29.195:5004/api/v1/admin/reports/fetchEmployeeTimesheetsByMonth/E04105'
//           `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/fetchEmployeeTimesheetsByMonth/${user.Data.empId}`
//         );
//         const data = await response.json();
//         if (data.status === 200 && data.data.length > 0) {
//           const monthData = data.data.find(item => item.monthYear === selectedMonth);
//           if (monthData) {
//             const { submittedCount, pendingCount, rejectedCount, approvedCount } = monthData;
//             setChartData({
//               labels: ['Submitted', 'Pending', 'Rejected', 'Approved'],
//               datasets: [
//                 {
//                   label: 'Timesheet Status',
//                   data: [submittedCount, pendingCount, rejectedCount, approvedCount],
//                   backgroundColor: [
//                     'rgba(54, 162, 235, 0.6)', // Blue for Submitted
//                     'rgba(255, 206, 86, 0.6)',  // Yellow for Pending
//                     'rgba(255, 99, 132, 0.6)',  // Red for Rejected
//                     'rgba(75, 192, 192, 0.6)',  // Green for Approved
//                   ],
//                   borderColor: [
//                     'rgba(54, 162, 235, 1)',
//                     'rgba(255, 206, 86, 1)',
//                     'rgba(255, 99, 132, 1)',
//                     'rgba(75, 192, 192, 1)',
//                   ],
//                   borderWidth: 1,
//                 },
//               ],
//             });
//           } else {
//             setChartData(null);
//           }
//         }
//       } catch (error) {
//         console.error('Error fetching timesheet data:', error);
//         setChartData(null);
//       }
//     };

//     fetchData();

//     return () => {
//       if (chartRef.current) {
//         const chart = Chart.getChart(chartRef.current);
//         if (chart) chart.destroy();
//       }
//     };
//   }, [selectedMonth]);

//   useEffect(() => {
//     if (chartData) {
//       const ctx = chartRef.current.getContext('2d');
//       if (Chart.getChart(chartRef.current)) {
//         Chart.getChart(chartRef.current).destroy();
//       }

//       new Chart(ctx, {
//         type: 'pie',
//         data: chartData,
//         options: {
//           responsive: true,
//           plugins: {
//             legend: {
//               position: 'top',
//             },
//             tooltip: {
//               enabled: true,
//             },
//           },
//         },
//       });
//     }
//   }, [chartData]);

//   return (
//     <Panel
//       header="Timehseet Analytics by Month"
//       style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}
//     >
//       <div style={{ marginBottom: '20px' }}>
//         <Dropdown
//           value={selectedMonth}
//           options={months}
//           onChange={(e) => setSelectedMonth(e.value)}
//           placeholder="Select a month"
//         />
//       </div>
//       <div style={{ width: "300px", height: "300px", margin: "0 auto" }}>
//         <canvas ref={chartRef} style={{ width: "100%", height: "100%" }}></canvas>
//       </div>
//     </Panel>
//   );
// };

// export default TimesheetPieChart;

import React, { useState, useEffect, useRef } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Panel } from 'primereact/panel';
import { Chart } from 'chart.js/auto';

const TaskHoursPieChart = () => {
    const [data, setData] = useState(null); // To store fetched data
    const [selectedMonth, setSelectedMonth] = useState(null); // To store selected month
    const [weekProjectOptions, setWeekProjectOptions] = useState([]); // To store week + project options
    const [selectedWeekProject, setSelectedWeekProject] = useState(null); // To store selected week + project
    const [chartData, setChartData] = useState(null); // To store chart data
    const chartRef = useRef(null); // Reference for the chart instance

    // Fetch the data from the API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('http://192.168.29.195:5004/api/v1/admin/reports/getApprovedTimesheetForParticularEmployeeByMonth/E01550');
                const result = await response.json();
                const approvedTimesheets = result.approvedTimesheets?.approvedTimesheets || {};
                setData(approvedTimesheets); // Set the fetched data
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // Handle month selection and set up week + project options
    const handleMonthChange = (e) => {
        const month = e.value;
        setSelectedMonth(month);

        // Fetch total task hours for the selected month
        const totalTaskHours = data.totalTaskHours ? data.totalTaskHours[month] : null; // Safe access check
        if (totalTaskHours) {
            const taskLabels = Object.keys(totalTaskHours);
            const taskHours = Object.values(totalTaskHours);

            setChartData({
                labels: taskLabels,
                datasets: [
                    {
                        label: `Total Task Hours for ${month}`,
                        data: taskHours,
                        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#66BB6A', '#FF7043'],
                        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#66BB6A', '#FF7043'],
                    },
                ],
            });
        } else {
            console.warn(`No total task hours found for month: ${month}`);
        }

        // Prepare week + project options
        const selectedMonthData = data.groupedByMonth.find((item) => item.month === month);
        if (selectedMonthData) {
            const weekProjectData = [];
            selectedMonthData.weeks.forEach((weekItem) => {
                weekItem.projects.forEach((projectItem) => {
                    weekProjectData.push({
                        label: `${weekItem.week} - ${projectItem.project}`,
                        value: {
                            week: weekItem.week,
                            project: projectItem.project,
                            tasks: projectItem.tasks,
                        },
                    });
                });
            });
            setWeekProjectOptions(weekProjectData);
        } else {
            setWeekProjectOptions([]);
        }

        // Reset the selected week + project
        setSelectedWeekProject(null);
    };

    // Handle week + project selection and update chart data
    const handleWeekProjectChange = (e) => {
        const { week, project, tasks } = e.value;
        setSelectedWeekProject(e.value);

        // Prepare data for the pie chart
        const taskLabels = tasks.map((task) => task.task); // Task names
        const taskHours = tasks.map((task) => task.totalTaskHours); // Task hours

        setChartData({
            labels: taskLabels,
            datasets: [
                {
                    label: `Task Hours for ${project} (${week})`,
                    data: taskHours,
                    backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#66BB6A', '#FF7043'],
                    hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#66BB6A', '#FF7043'],
                },
            ],
        });
    };

    // Render the chart using the chart.js library
    useEffect(() => {
        if (chartData) {
            // Destroy the previous chart instance if it exists
            if (chartRef.current) {
                chartRef.current.destroy();
            }

            const ctx = document.getElementById('taskHoursChart').getContext('2d');
            chartRef.current = new Chart(ctx, {
                type: 'pie',
                data: chartData,
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                    },
                },
            });
        }
    }, [chartData]);

    // Clean up the chart when the component unmounts
    useEffect(() => {
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, []);

    // If no data is loaded, show a loading message
    if (!data) {
        return <div>Loading...</div>;
    }

    // Prepare months for the dropdown
    const months = Object.keys(data.totalTaskHours || {});

    return (
        <div>
            <Panel header="Task Hours by Month, Week, and Project">
                {/* Month Dropdown */}
                <Dropdown
                    value={selectedMonth}
                    options={months.map((month) => ({ label: month, value: month }))}
                    onChange={handleMonthChange}
                    placeholder="Select a month"
                    className="p-mb-3"
                />

                {/* Week + Project Dropdown */}
                {weekProjectOptions.length > 0 && (
                    <Dropdown
                        value={selectedWeekProject}
                        options={weekProjectOptions}
                        onChange={handleWeekProjectChange}
                        placeholder="Select a week + project"
                        className="p-mb-3"
                    />
                )}

                {/* Render the pie chart when a month or week + project is selected */}
                {chartData && (
                    <div style={{ width: '50%', margin: '0 auto' }}>
                        <canvas id="taskHoursChart"></canvas>
                    </div>
                )}
            </Panel>
        </div>
    );
};

export default TaskHoursPieChart;




