import React, { useState } from "react";

import ApprovedTimesheet from "./ApprovedTimesheet/ApprovedTimesheet"
import PendingTimesheet from "./PendingTimesheet/PendingTimesheet"
import RejectedTimesheet from "./RejectedTimesheet/RejectedTimesheet"
import { TabPanel, TabView } from "primereact/tabview";
import { Image } from "primereact/image";
import logo from "../../assets/IGS.png"
import { Menubar } from 'primereact/menubar';

const Users = () => {
  const start = (
    <div>
      <p style={{ color: "#00000080", fontWeight: "bold", fontSize: "22px", margin: "0px", marginBottom: '5PX' }}>
        TimeSheets
      </p>
    </div>
  )
  const end = (
    <div style={{ paddingRight: '15px' }}>
      <Image src={logo} height='60px' width='60px' />
    </div>
  )
  return (
    <div className="w-full" >
      <div className="card">
      <Menubar  start={start} end={end} />
      </div>
      <div className="card" >
        <TabView activeIndex={1}>
          <TabPanel header="Approved">
            <ApprovedTimesheet />
          </TabPanel>
          <TabPanel header="Pending">
            <PendingTimesheet />
          </TabPanel>
          <TabPanel header="Rejection">
            <RejectedTimesheet />
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default Users;