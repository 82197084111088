import React from 'react'

export default function TimesheetLayout() {
    return (
        <div style={{ display: "flex", width: "100%", gap: "5px" }}>
            <div
                style={{
                    width: "14%",
                    background: "#71759B",
                    padding: "10px",
                    color: "#FFFFFF",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                Projects
            </div>
            
            <div
                style={{
                    width: "14%",
                    background: "#71759B",
                    padding: "10px",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    color: "#FFFFFF",
                }}
            >
                Sub Projects
            </div>
            <div
                style={{
                    width: "14%",
                    background: "#71759B",
                    padding: "10px",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    color: "#FFFFFF",
                }}
            >
                Tasks
            </div>
            <div
                style={{
                    width: "7.25%",
                    color: "#0069FFFF",
                    background: "#F3F1ECFF",
                    padding: "10px",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                Sun
            </div>
            <div
                style={{
                    width: "7.25%",
                    color: "#0069FFFF",
                    background: "#F3F1ECFF",
                    padding: "10px",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                Mon
            </div>
            <div
                style={{
                    width: "7.25%",
                    color: "#0069FFFF",
                    background: "#F3F1ECFF",
                    padding: "10px",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                Tue
            </div>
            <div
                style={{
                    width: "7.25%",
                    color: "#0069FFFF",
                    background: "#F3F1ECFF",
                    padding: "10px",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                Wed
            </div>
            <div
                style={{
                    width: "7.25%",
                    color: "#0069FFFF",
                    background: "#F3F1ECFF",
                    padding: "10px",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                Thu
            </div>
            <div
                style={{
                    width: "7.25%",
                    color: "#0069FFFF",
                    background: "#F3F1ECFF",
                    padding: "10px",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                Fri
            </div>
            <div
                style={{
                    width: "7.25%",
                    color: "#0069FFFF",
                    background: "#F3F1ECFF",
                    padding: "10px",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                Sat
            </div>
            <div
                style={{
                    width: "7.25%",
                    color: "#0069FFFF",
                    background: "#F3F1ECFF",
                    padding: "10px",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                Total
            </div>
        </div>
    )
}
