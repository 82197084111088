import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Image } from "primereact/image";
import logo from "../../assets/IGS.png"
import { Menubar } from "primereact/menubar";
import { TbArrowBack } from "react-icons/tb";

export default function NewProject() {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const today = new Date();
  const [projectName, setProjectName] = useState("");
  const [cost, setCost] = useState("");
  const [projects, setProjects] = useState([]);
  const [projectToDelete, setProjectToDelete] = useState("");
  const [projectToEdit, setProjectToEdit] = useState({});
  const [newProjectName, setNewProjectName] = useState("");
  const [editStartDate, setEditStartDate] = useState(null); // New state for edit start date
  const [editEndDate, setEditEndDate] = useState(null); // New state for edit end date
  const [editCost, setEditCost] = useState("");
  const fetchProjects = () => {
    fetch(
      // "http://192.168.29.195:50010/api/v1/earmarkprojects/project-names"
      `${process.env.REACT_APP_API_EARMARK_URL}${process.env.REACT_APP_EARMARK_ENDPOINT}/earmark/project-names`

    )
      .then((response) => response.json())
      .then((data) => {
        setProjects(data);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const formatDate = (date) => {
    if (!date) return '';
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleAddProject = () => {
    const projectData = {
      project_name: projectName,
      startDate: formatDate(startDate), // Format start date
      endDate: formatDate(endDate),
      cost: cost,
    };

    fetch(`${process.env.REACT_APP_API_EARMARK_URL}${process.env.REACT_APP_EARMARK_ENDPOINT}/earmark/project-names`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(projectData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Project added:", data);
        setVisible(false);
        fetchProjects();
      })
      .catch((error) => {
        console.error("Error adding project:", error);
      });
    setStartDate(null);
    setEndDate(null);
    setProjectName("");
    setCost("");
  };

  const handleDeleteClick = (projectName) => {
    setProjectToDelete(projectName);
    setDeleteModal(true);
  };

  const handleDeleteProject = (projectName) => {
    const deleteData = {
      project_name: projectName,
    };

    fetch(`${process.env.REACT_APP_API_EARMARK_URL}${process.env.REACT_APP_EARMARK_ENDPOINT}/earmark/project-names`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(deleteData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Project deleted:", data);
        setDeleteModal(false);
        fetchProjects();
      })
      .catch((error) => {
        console.error("Error deleting project:", error);
      });
  };



  const handleEditClick = (project) => {
    setProjectToEdit(project);
    setNewProjectName(project.project_name);

    // Check if startDate and endDate are defined
    if (project.startDate) {
      const startDateObj = new Date(
        project.startDate.split("/").reverse().join("-") // Convert "dd/MM/yyyy" to "yyyy-MM-dd"
      );
      setEditStartDate(startDateObj);
    } else {
      setEditStartDate(null);
    }

    if (project.endDate) {
      const endDateObj = new Date(
        project.endDate.split("/").reverse().join("-") // Convert "dd/MM/yyyy" to "yyyy-MM-dd"
      );
      setEditEndDate(endDateObj);
    } else {
      setEditEndDate(null);
    }

    setEditCost(project.cost);
    setEditModal(true);
  };


  const handleEditProject = () => {
    const editData = {
      project_name: newProjectName,
      startDate: editStartDate.toLocaleDateString("en-GB"),
      endDate: editEndDate.toLocaleDateString("en-GB"),
      cost: editCost,
    };

    fetch(
      `${process.env.REACT_APP_API_EARMARK_URL}${process.env.REACT_APP_EARMARK_ENDPOINT}/earmark/project-names/${projectToEdit.project_name}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editData),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Project updated:", data);
        setEditModal(false);
        fetchProjects();
      })
      .catch((error) => {
        console.error("Error updating project:", error);
      });
  };
  const start = (
    <button className="rounded-full m-2 hover:bg-gray-200" style={{ height: '40px', width: '40px' }} onClick={() => navigate(-1)}>
      <TbArrowBack />
    </button>
  );
  const end = (
    <div style={{ paddingRight: '15px' }}>
      <Image src={logo} height="60px" width="60px" />
    </div>
  );
  return (
    <div>
       <Menubar start={start} end={end} />
      <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 132px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>

        <div style={{ display: 'flex', justifyContent: 'space-between', }}>

          <div>

            <div
              style={{
                color: "#00000080",
                fontSize: "24px",
                fontWeight: "500",
                margin: "20px",
              }}
            >
              Ear Mark Projects
            </div>
          </div>

        </div>

        <div
          style={{
            margin: "20px",
            border: "1px solid #EBEBEB",
            background: "#f9f9f9",
            borderRadius: "5px",
            boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.2)",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  width: "30%",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontWeight: "500",
                }}
              >
                Project Name
              </p>
              <p
                style={{
                  width: "14%",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontWeight: "500",
                }}
              >
                Start Date
              </p>
              <p
                style={{
                  width: "14%",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontWeight: "500",
                }}
              >
                End Date
              </p>
              <p
                style={{
                  width: "14%",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontWeight: "500",
                }}
              >
                Cost
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  width: "14%",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontWeight: "500",
                }}
              >
                Edit
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  width: "14%",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontWeight: "500",
                }}
              >
                Delete
              </p>
            </div>
          </div>
        </div>

        {projects.map((project, index) => (
          <div
            key={index}
            style={{
              margin: "20px",
              border: "1px solid #EBEBEB",
              borderRadius: "5px",
              boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.2)",
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: "bold",
                    color: "#00000080",
                  }}
                >
                  {project.project_name}
                </p>
                <p
                  style={{
                    width: "14%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: "bold",
                    color: "#00000080",
                  }}
                >
                  {project.startDate}
                </p>
                <p
                  style={{
                    width: "14%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: "bold",
                    color: "#00000080",
                  }}
                >
                  {project.endDate}
                </p>
                <p
                  style={{
                    width: "14%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: "bold",
                    color: "#00000080",
                  }}
                >
                  {project.cost}
                </p>
                <i
                  className="pi pi-pencil"
                  style={{
                    fontSize: "1rem",
                    width: "14%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    cursor: "pointer",
                    fontWeight: "bold",
                    color: "#00000080",
                  }}
                  onClick={() => handleEditClick(project)}
                ></i>
                <i
                  className="pi pi-trash"
                  style={{
                    fontSize: "1rem",
                    width: "14%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    cursor: "pointer",
                    fontWeight: "bold",
                    color: "#00000080",
                  }}
                  onClick={() => handleDeleteClick(project.project_name)}
                ></i>
              </div>
            </div>
          </div>
        ))}

        <div
          style={{
            position: "fixed",
            bottom: "calc(100vh - 90vh)",
            right: "4%",
            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
          }}
        >
          <Button
            style={{
              background: "#29295FFF",
              border: "1px solid #29295FFF",
            }}
            onClick={() => setVisible(true)}
          >
            <i
              className="pi pi-plus"
              style={{
                color: "#FFFFFF",
                fontSize: "20px",
              }}
            ></i>
          </Button>
        </div>
      </div>

      <Dialog
      header="Create New Ear Mark Project"
      visible={visible}
      style={{ width: "30vw" }}
      headerStyle={{ backgroundColor: "#d5d8dc", color: "Black" }}
      onHide={() => setVisible(false)}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h4>Project Name</h4>
          <InputText
            placeholder="Enter Project Name"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
          />
        </div>
        <div>
          <h4>Cost</h4>
          <InputText
            placeholder="Enter Cost"
            type="number"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h4>Start Date</h4>
          <Calendar
            placeholder="Select Start Date"
            value={startDate}
            onChange={(e) => setStartDate(e.value)}
            minDate={today} // Prevent selection of past dates
          />
        </div>
        <div>
          <h4>End Date</h4>
          <Calendar
            placeholder="Select End Date"
            value={endDate}
            onChange={(e) => setEndDate(e.value)}
            minDate={startDate || today} // Prevent selection before the start date
          />
        </div>
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          style={{
            background: "#29295FFF",
            border: "1px solid #29295FFF",
          }}
          onClick={handleAddProject}
        >
          Add
        </Button>
      </div>
    </Dialog>
      <Dialog
        visible={deleteModal}
        style={{ width: "35%" }}
        onHide={() => setDeleteModal(false)}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p style={{ fontWeight: "bold", fontSize: "18px" }}>
            Are you sure you want to delete the project: {projectToDelete}?
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            gap: "20px",
          }}
        >
          <Button
            style={{
              background: "#29295FFF",
              border: "1px solid #29295FFF",
              marginRight: "10px",
            }}
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            style={{
              background: "#d9534f",
              border: "1px solid #d9534f",
            }}
            onClick={() => handleDeleteProject(projectToDelete)}
          >
            Delete
          </Button>
        </div>
      </Dialog>
      <Dialog
        header="Edit Project"
        visible={editModal}
        onHide={() => setEditModal(false)}
        headerStyle={{ backgroundColor: "#d5d8dc", color: "Black" }}
        style={{ width: "25%" }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h4>Project Name</h4>
            <InputText
              id="newProjectName"
              value={newProjectName}
              onChange={(e) => setNewProjectName(e.target.value)}
              style={{ width: "80%" }}
            />
          </div>
          <div>
            <h4>Cost</h4>
            <InputText
              value={editCost}
              onChange={(e) => setEditCost(e.target.value)}
              style={{ width: "80%" }}
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h4>Start Date</h4>
            <Calendar
              value={editStartDate}
              onChange={(e) => setEditStartDate(e.value)}
              style={{ width: "80%" }}
              dateFormat="dd/mm/yy"
              minDate={today}
            />
          </div>
          <div>
            <h4>End Date</h4>
            <Calendar
              value={editEndDate}
              onChange={(e) => setEditEndDate(e.value)}
              style={{ width: "80%" }}
              dateFormat="dd/mm/yy"
              minDate={startDate || today} 
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            label="Save Changes"
            onClick={handleEditProject}
            style={{
              background: "#29295FFF",
              border: "1px solid #29295FFF",
              marginTop: "20px",
            }}
          />
        </div>
      </Dialog>
    </div>
  );
}