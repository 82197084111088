
// import React, { useEffect, useState } from 'react';
// import * as echarts from 'echarts';
// import { useNavigate } from 'react-router-dom'; // Assuming you are using React Router for navigation
// import { Image } from "primereact/image";
// import { Menubar } from "primereact/menubar";
// import logo from "../../../assets/IGS.png";

// const BenchStatusChart = () => {
//   const [data, setData] = useState(null);
//   const navigate = useNavigate(); // To navigate to the new page

//   // Fetch the data
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/bench-status`); // Replace with your actual API endpoint
//         const result = await response.json();
//         setData(result);
//       } catch (error) {
//         console.error("Error fetching the data:", error);
//       }
//     };
//     fetchData();
//   }, []);

//   // Initialize the chart
//   useEffect(() => {
//     if (data && data.onBench && data.notOnBench) {
//       const chartDom = document.getElementById('bench-status-chart');
//       const myChart = echarts.init(chartDom);

//       // Define gradient colors
//       const gradientColors = [
//         {
//           type: 'linear',
//           x: 0,
//           y: 0,
//           x2: 0,
//           y2: 1,
//           colorStops: [
//             { offset: 0, color: '#f1948a' }, // Start color (e.g., Coral)
//             { offset: 1, color: '#cd6155' }, // End color (e.g., Tomato)
//           ],
//         },
//         {
//           type: 'linear',
//           x: 0,
//           y: 0,
//           x2: 0,
//           y2: 1,
//           colorStops: [
//             { offset: 0, color: '#87cefa' }, // Start color (e.g., LightSkyBlue)
//             { offset: 1, color: '#4682b4' }, // End color (e.g., SteelBlue)
//           ],
//         },
//       ];

//       const option = {
//         tooltip: {
//           trigger: 'item',
//         },
//         series: [
//           {
//             name: 'Bench Status',
//             type: 'pie',
//             radius: '90%',
//             data: [
//               { value: data.onBench.count, name: 'On Bench', itemStyle: { color: gradientColors[0] } },
//               { value: data.notOnBench.count, name: 'Not On Bench', itemStyle: { color: gradientColors[1] } },
//             ],
//             emphasis: {
//               itemStyle: {
//                 shadowBlur: 10,
//                 shadowOffsetX: 0,
//                 shadowColor: 'rgba(0, 0, 0, 0.5)',
//               },
//             },
//             label: {
//               show: true,
//               position: 'outside',
//               formatter: '{b|{b}}',
//               color: '#000', // Adjust color for visibility
//               rich: {
//                 b: {
//                   fontSize: 18,  // Set font size
//                   fontWeight: 'bold',  // Set font weight
//                   color: '#000', // Color for label
//                 },
//               },
//             },
//             labelLine: {
//               show: true,
//               length: 10,
//               length2: 10,
//               smooth: true,
//             },
//             itemStyle: {
//               borderColor: '#fff',
//               borderWidth: 2,
//             },
//           },
//           {
//             name: 'Bench Status Counts',
//             type: 'pie',
//             radius: ['60%', '60%'],
//             data: [
//               { value: data.onBench.count, name: '' },
//               { value: data.notOnBench.count, name: '' },
//             ],
//             label: {
//               show: true,
//               position: 'inside',
//               formatter: '{c} employee',
//               color: (params) => {
//                 return params.value > 0 ? '#fff' : '#000'; // Dynamic color
//               },
//               fontSize: '16px',   // Set font size
//               fontWeight: '400',  // Set font weight
//             },
//             emphasis: {
//               itemStyle: {
//                 shadowBlur: 10,
//                 shadowOffsetX: 0,
//                 shadowColor: 'rgba(0, 0, 0, 0.5)',
//               },
//             },
//           },
//         ],
//       };
//       myChart.setOption(option);
//       // Handle click event
//       myChart.on('click', function (params) {
//         if (params.name === 'On Bench') {
//           navigate('/report/benchlist'); // Navigate to the Bench component
//         } else if (params.name === 'Not On Bench') {
//           navigate('/reports/notonbench'); // Navigate to the Not On Bench component
//         }
//       });

//       // Cleanup the chart on component unmount
//       return () => {
//         myChart.dispose();
//       };
//     }
//   }, [data, navigate]);

//   const start = (
//     <div>
//       <p
//         style={{
//           color: "#00000080",
//           fontWeight: "bold",
//           fontSize: "22px",
//           margin: "0px",
//           marginBottom: "5PX",
//         }}
//       >
//         Reports
//       </p>
//     </div>
//   );
//   const end = (
//     <div style={{ paddingRight: "15px" }}>
//       <Image src={logo} height="60px" width="60px" />
//     </div>
//   );

//   return (
//     <div>
//       <div className="card">
//         <Menubar start={start} end={end} />
//       </div>
//       <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', height: 'calc(100vh - 180px)', backgroundColor: 'white', border: '1px solid rgba(128, 128, 128, 0.211)' }}>
//         <div style={{ width: '100%' }}>
//           <h2 style={{ textAlign: 'center', marginBottom: '40px' }}>Employee Bench Status Chart</h2>
//           {data && data.onBench && data.notOnBench ? (
//             <div id="bench-status-chart" style={{ width: '100%', height: '500px' }}></div>
//           ) : (
//             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px', fontSize: '24px', fontWeight: 'bold', color: 'gray' }}>
//               No Bench Report
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BenchStatusChart;

// import React, { useEffect, useState } from 'react';
// import * as echarts from 'echarts';
// import { useNavigate } from 'react-router-dom';
// import { Image } from 'primereact/image';
// import { ProgressSpinner } from 'primereact/progressspinner'; // Import the ProgressSpinner
// import logo from '../../../assets/IGS.png';
// import { Menubar } from "primereact/menubar";
// const BenchStatusChart = () => {
//   const [data, setData] = useState(null);
//   const [loading, setLoading] = useState(true); // Add loading state
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_BASE_URL}:${process.env.REACT_APP_ADMIN_API_ENDPOINT}/bench-status`
//         ); // Replace with your actual API endpoint
//         const result = await response.json();
//         setData(result);
//         setLoading(false); // Set loading to false once data is fetched
//       } catch (error) {
//         console.error('Error fetching the data:', error);
//         setLoading(false); // In case of error, stop the spinner
//       }
//     };

//     const timeoutId = setTimeout(() => {
//       fetchData();
//     }, 100); // Set a delay of 200ms

//     return () => clearTimeout(timeoutId); // Cleanup the timeout
//   }, []);

//   useEffect(() => {
//     if (data && data.onBench && data.notOnBench) {
//       const chartDom = document.getElementById('bench-status-chart');
//       const myChart = echarts.init(chartDom);

//       const gradientColors = [
//         {
//           type: 'linear',
//           x: 0,
//           y: 0,
//           x2: 0,
//           y2: 1,
//           colorStops: [
//             { offset: 0, color: '#f1948a' },
//             { offset: 1, color: '#cd6155' },
//           ],
//         },
//         {
//           type: 'linear',
//           x: 0,
//           y: 0,
//           x2: 0,
//           y2: 1,
//           colorStops: [
//             { offset: 0, color: '#87cefa' },
//             { offset: 1, color: '#4682b4' },
//           ],
//         },
//       ];

//       const option = {
//         tooltip: { trigger: 'item' },
//         series: [
//           {
//             name: 'Bench Status',
//             type: 'pie',
//             radius: '90%',
//             data: [
//               { value: data.onBench.count, name: 'On Bench', itemStyle: { color: gradientColors[0] } },
//               { value: data.notOnBench.count, name: 'Not On Bench', itemStyle: { color: gradientColors[1] } },
//             ],
//             emphasis: {
//               itemStyle: {
//                 shadowBlur: 10,
//                 shadowOffsetX: 0,
//                 shadowColor: 'rgba(0, 0, 0, 0.5)',
//               },
//             },
//             label: {
//               show: true,
//               position: 'outside',
//               formatter: '{b|{b}}',
//               color: '#000',
//               rich: { b: { fontSize: 18, fontWeight: 'bold', color: '#000' } },
//             },
//             labelLine: {
//               show: true,
//               length: 10,
//               length2: 10,
//               smooth: true,
//             },
//             itemStyle: {
//               borderColor: '#fff',
//               borderWidth: 2,
//             },
//           },
//           {
//             name: 'Bench Status Counts',
//             type: 'pie',
//             radius: ['60%', '60%'],
//             data: [
//               { value: data.onBench.count, name: '' },
//               { value: data.notOnBench.count, name: '' },
//             ],
//             label: {
//               show: true,
//               position: 'inside',
//               formatter: '{c} employee',
//               color: (params) => (params.value > 0 ? '#fff' : '#000'),
//               fontSize: '16px',
//               fontWeight: '400',
//             },
//             emphasis: {
//               itemStyle: {
//                 shadowBlur: 10,
//                 shadowOffsetX: 0,
//                 shadowColor: 'rgba(0, 0, 0, 0.5)',
//               },
//             },
//           },
//         ],
//       };
//       myChart.setOption(option);

//       myChart.on('click', function (params) {
//         if (params.name === 'On Bench') {
//           navigate('/report/benchlist');
//         } else if (params.name === 'Not On Bench') {
//           navigate('/reports/notonbench');
//         }
//       });

//       return () => {
//         myChart.dispose();
//       };
//     }
//   }, [data, navigate]);

//   const start = (
//     <div>
//       <p style={{ color: '#00000080', fontWeight: 'bold', fontSize: '22px', margin: '0px', marginBottom: '5PX' }}>
//         Bench Report
//       </p>
//     </div>
//   );

//   const end = (
//     <div style={{ paddingRight: '15px' }}>
//       <Image src={logo} height="60px" width="60px" />
//     </div>
//   );

//   return (
//     <div>
//       <div className="card">
//         <Menubar start={start} end={end} />
//       </div>
//       <div
//         style={{
//           display: 'flex',
//           marginTop: '20px',
//           height: 'calc(100vh - 180px)',
//           justifyContent: 'center',
//           backgroundColor: 'white',
//           overflow: 'hidden',
//           border: '1px solid rgba(128, 128, 128, 0.211)',
//         }}
//       >
//         <div style={{ width: '100%' }}>
//           <h3 style={{ textAlign: 'center', marginBottom: '40px' }}>Employee Bench Status Chart</h3>
//           {loading ? ( // Show spinner when loading
//             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
//               <ProgressSpinner style={{ width: '70px', height: '70px' }} strokeWidth="5" fill="var(--surface-ground)" animationDuration=".5s" />
//             </div>
//           ) : data && data.onBench && data.notOnBench ? (
//             <div id="bench-status-chart" style={{ width: '100%', height: '500px' }}></div>
//           ) : (
//             <div
//               style={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 height: '500px',
//                 fontSize: '24px',
//                 fontWeight: 'bold',
//                 color: 'gray',
//               }}
//             >
//               No Bench Report
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BenchStatusChart;

import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Image } from "primereact/image";
import { Menubar } from "primereact/menubar";
import logo from "../../../assets/IGS.png";

const BenchStatusTable = () => {
  const [benchData, setBenchData] = useState([]);
  const [selectedBenchStatus, setSelectedBenchStatus] = useState('all'); // Default "All" selection
  const [filteredData, setFilteredData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(''); // Global search state
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const rowsPerPage = 9; // Number of rows per page

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/bench-status`
      );
      const data = await response.json();
      setBenchData(data);

      // Default to "All" selection when data is loaded
      handleBenchStatusChange({ value: 'all' }, data);
    };
    fetchData();
  }, []);

  // Options for bench status dropdown including "All"
  const benchOptions = [
    { label: 'All', value: 'all' },
    { label: 'On Bench', value: 'onBench' },
    { label: 'Not on Bench', value: 'notOnBench' },
  ];

  // Handle dropdown change
  const handleBenchStatusChange = (e, data = benchData) => {
    setSelectedBenchStatus(e.value);
    setCurrentPage(0); // Reset to the first page when the dropdown changes

    if (e.value === 'onBench') {
      setFilteredData(data.onBench.employees);
      setTotalRecords(data.onBench.employees.length);
    } else if (e.value === 'notOnBench') {
      setFilteredData(data.notOnBench.employees);
      setTotalRecords(data.notOnBench.employees.length);
    } else if (e.value === 'all') {
      // Combine both lists but place "Not on Bench" employees first
      const allEmployees = [
        ...data.notOnBench.employees, // Display "Not on Bench" employees first
        ...data.onBench.employees,    // Followed by "On Bench" employees
      ];
      setFilteredData(allEmployees);
      setTotalRecords(allEmployees.length);
    }
  };


  // Handle page change
  const onPageChange = (event) => {
    setCurrentPage(event.page);
  };

  // Handle global search
  const onGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
  };

  // Apply global search filter
  const globalFilteredData = filteredData.filter((employee) => {
    const searchValue = globalFilter.toLowerCase();
    return (
      employee.empId.toLowerCase().includes(searchValue) ||
      employee.name.toLowerCase().includes(searchValue) ||
      employee.designation.toLowerCase().includes(searchValue)
    );
  });

  // Calculate the data to display for the current page
  const paginatedData = globalFilteredData.slice(
    currentPage * rowsPerPage,
    (currentPage + 1) * rowsPerPage
  );

  // Dynamic style for bench status column
  const benchStatusTemplate = (rowData) => {
    const isOnBench = benchData.onBench.employees.some((emp) => emp._id === rowData._id);
    const color = isOnBench ? '#4a0202' : '#145a32'; // On bench: #4a0202, Not on bench: #145a32
    const statusText = isOnBench ? 'On Bench' : 'Not on Bench';

    return (
      <span style={{ color: color, fontWeight: 'bold' }}>
        {statusText}
      </span>
    );
  };

  // Count of employees on bench and not on bench
  const benchCount = benchData?.onBench?.employees?.length || 0;
  const notOnBenchCount = benchData?.notOnBench?.employees?.length || 0;
  const start = (
    <div>
      <p
        style={{
          color: "#00000080",
          fontWeight: "bold",
          fontSize: "22px",
          margin: "0px",
          marginBottom: "5PX",
        }}
      >
        Reports
      </p>
    </div>
  );
  const end = (
    <div style={{ paddingRight: "15px" }}>
      <Image src={logo} height="60px" width="60px" />
    </div>
  );

  return (
    <div>

      <div className="card">
        <Menubar start={start} end={end} />
      </div>

      {/* Flex container for dropdown and global search */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1em', alignItems: 'center' }}>
        {/* Left section for dropdown and global search */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          {/* Dropdown to select bench status */}
          <Dropdown
            value={selectedBenchStatus}
            options={benchOptions}
            onChange={(e) => handleBenchStatusChange(e)}
            className="w-full md:w-10rem mb-2"
          />

          {/* Global search input */}
          <span className="p-input-icon-left">
            <InputText
              value={globalFilter}
              onChange={onGlobalFilterChange}
              placeholder="Search employees..."
              className='mb-2'
            />
          </span>
        </div>

        {/* Right section for Bench and Not On Bench counts */}
        <div style={{ display: 'flex', gap: '30px', alignItems: 'flex-end' }}>
          <div style={{
            fontWeight: 'bold',
            color: '#4a0202',
            backgroundColor: '#f0f0f0', // Gray background
            border: '1px solid #d9d9d9', // Border
            boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.1)', // Border shadow
            padding: '10px', // Padding for spacing inside the box
            borderRadius: '5px' // Rounded corners
          }}>
            Bench: {benchCount}
          </div>

          <div style={{
            fontWeight: 'bold',
            color: '#145a32',
            backgroundColor: '#f0f0f0', // Gray background
            border: '1px solid #d9d9d9', // Border
            boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.1)', // Border shadow
            padding: '10px', // Padding for spacing inside the box
            borderRadius: '5px' // Rounded corners
          }}>
            Not On Bench: {notOnBenchCount}
          </div>
        </div>

      </div>

      {/* Table to display employee data */}
      {globalFilteredData.length > 0 && (
        <>
          <DataTable value={paginatedData} responsiveLayout="scroll">
            <Column field="empId" header="Employee ID" />
            <Column field="name" header="Name" />
            <Column field="designation" header="Designation" />
            <Column header="Bench Status" body={benchStatusTemplate} />
          </DataTable>

          {/* Paginator for pagination */}
          <Paginator
            first={currentPage * rowsPerPage}
            rows={rowsPerPage}
            totalRecords={globalFilteredData.length}
            onPageChange={onPageChange}
          />
        </>
      )}
    </div>
  );
};

export default BenchStatusTable;

