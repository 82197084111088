import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Image } from "primereact/image";
import logo from "../../assets/IGS.png";
import { Menubar } from "primereact/menubar";
import { Dialog } from "primereact/dialog";
import { Paginator } from "primereact/paginator";
import { Toast } from "primereact/toast";

const TestManagementTable = () => {
  const toast = useRef(null);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [newCategory, setNewCategory] = useState("");
  const [newRow, setNewRow] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [categoryDialogVisible, setCategoryDialogVisible] = useState(false);
  const [taskDialogVisible, setTaskDialogVisible] = useState(false);
  const [categoryError, setCategoryError] = useState("");
  const [taskError, setTaskError] = useState(["", "", "", "", ""]);
  const [importFile, setImportFile] = useState(null);
  // Pagination state
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10); // Default number of rows per page

  const [categories, setCategories] = useState([]); // State to hold the fetched categories


  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/masterTask/getMasterTask`
        );
        const data = await response.json();
        if (data && data.categories) {
          setCategories(data.categories);
        } else {
          setCategories([]); // Set an empty array if no categories exist
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
        setCategories([]); // Handle the error and set categories to an empty array
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    setFilteredData(getDataToDisplay());
  }, [selectedCategory, searchQuery, categories]);
  const columns = [
    { field: "ToDS_Ref", header: "ToDS Ref" },
    { field: "Type", header: "Type" },
    { field: "Task_Description", header: "Task Description" },
    { field: "Test_Units", header: "Test Units" },
    { field: "Unit_of_Measure", header: "Unit of Measure" },
  ];

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.value);
  };

  const uniqueCategoryNames = [
    ...new Set(categories.map((cat) => cat.category_name)),
  ];
  const categoryOptions = [
    { label: "All", value: "All" },
    ...uniqueCategoryNames.map((catName) => ({
      label: catName,
      value: catName,
    })),
  ];

  const handleInputChange = (e, index) => {
    const updatedRow = [...newRow];
    updatedRow[index] = e.target.value;
    setNewRow(updatedRow);
  };

  const handleFileSelect = async (e) => {
    const file = e.target.files[0]; // Get the selected file
    if (!file) {
      console.error("No file selected for import.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file); // Append the file to formData

    try {
      // Send the POST request to import the file
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/task/uploadMasterTask`,
        {
          method: "POST",
          body: formData, // Send formData with the file
        }
      );

      if (response.ok) {
        // Display success message in Toast
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "File imported successfully.",
          life: 3000,
        });

        // Fetch the updated data after successful import
        const updatedResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/masterTask/getMasterTask`
        );
        if (updatedResponse.ok) {
          const updatedData = await updatedResponse.json();
          // Update your categories state with the new data
          setCategories(updatedData.categories);
        } else {
          console.error(
            "Failed to fetch updated categories:",
            updatedResponse.statusText
          );
        }
      } else {
        console.error("Failed to import file:", response.statusText);
      }
    } catch (error) {
      console.error("Error during file import:", error);
    }
  };

  // Function to programmatically trigger file input click
  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Reset task errors
    setTaskError(["", "", "", "", ""]);

    // Validate newRow fields
    let hasError = false;
    const newErrors = [...taskError];

    newRow.forEach((value, index) => {
      if (!value) {
        newErrors[index] = "Field is required";
        hasError = true;
      }
    });

    if (hasError) {
      setTaskError(newErrors);
      return; // Stop further processing
    }

    const categoryIndex = categories.findIndex(
      (cat) => cat.category_name === selectedCategory
    );
    if (categoryIndex !== -1) {
      const newTask = {
        ToDS_Ref: newRow[0],
        Type: newRow[1],
        Task_Description: newRow[2],
        Test_Units: parseInt(newRow[3], 10),
        Unit_of_Measure: newRow[4],
        Category: selectedCategory, // Use the selected category
      };

      const taskData = {
        categories: [
          {
            category_name: selectedCategory,
            data: [newTask],
          },
        ],
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/task/createMasterTask`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(taskData),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to add task");
        }

        const result = await response.json();
        console.log(result);

        const updatedCategories = [...categories];
        updatedCategories[categoryIndex].data.push(newTask);
        setCategories(updatedCategories);

        setNewRow([]);
        setTaskDialogVisible(false);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  const handleNewCategorySubmit = async (e) => {
    if (!newCategory.trim()) {
      setCategoryError("Category name is required");
      return;
    }
    setCategoryError("");
    e.preventDefault();
    if (newCategory) {
      try {
        // Send the POST request to create the new category
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/task/createMasterTask`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              categories: [
                {
                  category_name: newCategory,
                },
              ],
            }),
          }
        );

        if (response.ok) {
          // Fetch the updated categories after successful creation
          const updatedResponse = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/masterTask/getMasterTask`
          );
          if (updatedResponse.ok) {
            const updatedData = await updatedResponse.json();
            // Update your categories state with the new data
            setCategories(updatedData.categories);
          } else {
            console.error(
              "Failed to fetch updated categories:",
              updatedResponse.statusText
            );
          }

          // Reset the category input and close the dialog
          setNewCategory("");
          setCategoryDialogVisible(false);
        } else {
          const errorData = await response.json();
          console.error(
            "Failed to create category:",
            response.statusText,
            errorData
          );
        }
      } catch (error) {
        console.error("Error while adding category:", error);
      }
    }
  };
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const getDataToDisplay = () => {
    let dataToDisplay = [];

    if (categories.length === 0) {
      return []; // Return empty array if no categories are available
    }

    if (selectedCategory === "All") {
      // If 'All' is selected, show all the data from all categories
      dataToDisplay = categories.flatMap((cat) =>
        (cat.data || []).map((row) => ({ ...row, category: cat.category_name }))
      );
    } else {
      // Filter for the selected category
      dataToDisplay = categories
        .filter((cat) => cat.category_name === selectedCategory)
        .flatMap((cat) =>
          (cat.data || []).map((row) => ({
            ...row,
            category: selectedCategory,
          }))
        );
    }

    // Apply search query filtering if there is a search term
    if (searchQuery) {
      return dataToDisplay.filter((row) =>
        Object.values(row).some((value) =>
          value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }

    return dataToDisplay;
  };

  const start = (
    <div>
      <p
        style={{
          color: "#00000080",
          fontWeight: "bold",
          fontSize: "22px",
          margin: "0px",
          marginBottom: "5PX",
        }}
      >
        Master Tasks
      </p>
    </div>
  );

  const end = (
    <div style={{ paddingRight: "15px" }}>
      <Image src={logo} height="60px" width="60px" />
    </div>
  );

  // Paginate data to display for the current page
  // const paginatedData = filteredData.slice(first, first + rows);
  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const paginatedData = getDataToDisplay().slice(first, first + rows);
  return (
    <div>
         <Toast ref={toast} />
      <div className="card">
        <Menubar start={start} end={end} />
      </div>
      <div
        style={{
          backgroundColor: "#f8f9fa",
          height: "calc(100vh - 140px)",
          boxShadow: "0 10px 10px -5px rgba(0, 0, 0, 0.3)",
          border: "1px solid rgba(128, 128, 128, 0.211)",
          marginTop: "10px",
          overflow: "scroll",
          position: "relative",
        }}
      >
        <div className="table-container" style={{ margin: "20px" }}>
          <div className="controls">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                className="dropdown-search"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "30px",
                  padding: "10px",
                  alignItems: "center",
                }}
              >
                <div>
                  <Dropdown
                    value={selectedCategory}
                    options={categoryOptions}
                    onChange={handleCategoryChange}
                    placeholder="Select a Category"
                    className="w-full md:w-14rem"
                  />
                </div>
                <div>
                  <InputText
                    value={searchQuery}
                    onChange={handleSearch}
                    placeholder="Search..."
                    className="w-full md:w-14rem"
                  />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "30px",
                  padding: "10px",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    background: "#29295FFF",
                    border: "1px solid #29295FFF",
                  }}
                  label="Import"
                  onClick={triggerFileInput} // Trigger file input click
                />

                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }} // Hide the file input element
                  accept=".xlsx,.xls"
                  onChange={handleFileSelect} // Handle file selection and upload
                />

                {selectedCategory === "All" && (
                  <Button
                    style={{
                      background: "#29295FFF",
                      border: "1px solid #29295FFF",
                    }}
                    label="Add Category"
                    onClick={() => setCategoryDialogVisible(true)}
                  />
                )}
                <div>
                  {selectedCategory !== "All" && (
                    <Button
                      style={{
                        background: "#29295FFF",
                        border: "1px solid #29295FFF",
                      }}
                      label="Add Task"
                      onClick={() => setTaskDialogVisible(true)}
                    />
                  )}
                </div>
              </div>
            </div>

            {/* Table Rendering */}
            {categories.length === 0 ? (
              <div>No Master Task Available</div>
            ) : (
              selectedCategory && (
                <div className="table-content">
                  <DataTable
                    value={paginatedData}
                    rows={rows} // Rows per page
                    first={first} // Starting index for pagination
                    totalRecords={getDataToDisplay().length} // Total records for paginator
                    onPage={onPageChange} // Handler for page change
                    responsiveLayout="scroll"
                    style={{ width: "100%" }}
                  >
                    {" "}
                    <Column
                      field="category"
                      header="Category"
                      style={{ border: "1px solid #ccc" }}
                    />
                    {columns.map((col, index) => (
                      <Column
                        key={index}
                        field={col.field}
                        header={col.header}
                        style={{ border: "1px solid #ccc" }}
                      />
                    ))}
                  </DataTable>
                </div>
              )
            )}
          </div>

          {/* Pagination outside the table */}
          <div>
            <Paginator
              first={first}
              rows={10}
              totalRecords={filteredData.length}
              onPageChange={(e) => {
                setFirst(e.first);
                setRows(e.rows);
              }}
              template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
              className="paginator-bottom-center"
              style={{ backgroundColor: "#f8f9fa" }}
            />
          </div>
        </div>
      </div>

      {/* Add Category Dialog */}
      <Dialog
        header="Add New Category"
        visible={categoryDialogVisible}
        style={{ width: "30vw" }}
        onHide={() => setCategoryDialogVisible(false)}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <div className="p-field">
            <InputText
              id="new-category"
              placeholder="Enter Category Name"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              required
              style={{ width: "17vw" }}
            />
            <div>
              {categoryError && (
                <small className="p-error">{categoryError}</small>
              )}
            </div>
          </div>
          <div className="p-field">
            <Button
              style={{ backgroundColor: "#182452" }}
              label="Add"
              onClick={handleNewCategorySubmit}
            />
          </div>
        </div>
      </Dialog>

      {/* Add Task Dialog */}
      <Dialog
        header="Add New Task"
        visible={taskDialogVisible}
        style={{ width: "50vw" }}
        onHide={() => setTaskDialogVisible(false)}
      >
        <form onSubmit={handleFormSubmit}>
          {/* Input Fields */}
          {columns.map((col, index) => (
            <div
              key={index}
              className="p-field"
              style={{ marginBottom: "15px" }}
            >
              <label htmlFor={`input-${index}`} style={{ fontWeight: 400 }}>
                {col.header}
              </label>
              <InputText
                id={`input-${index}`} // ID creation
                type={col.header === "Test Units" ? "number" : "text"} // Change type based on the column
                value={newRow[index] || ""}
                onChange={(e) => handleInputChange(e, index)}
                required
                placeholder={`Enter ${col.header}`} // Fixed placeholder creation
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "10px",
                  width: "100%",
                  transition: "border-color 0.3s, box-shadow 0.3s",
                }}
                onFocus={(e) => {
                  e.target.style.borderColor = "#007bff";
                  e.target.style.boxShadow = "0 0 5px rgba(0, 123, 255, 0.5)";
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#ccc";
                  e.target.style.boxShadow = "none";
                }}
                min="0" // Optional: Set minimum value
                step="1" // Optional: Set step for increment
              />
              {taskError[index] && (
                <small className="p-error">{taskError[index]}</small>
              )}
            </div>
          ))}

          {/* Button Container */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "15px",
            }}
          >
            <Button
              type="submit"
              label="Add Task"
              className="p-mt-2"
              style={{ backgroundColor: "#182452" }}
            />
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default TestManagementTable;
