

import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { Image } from "primereact/image";
import logo from "../../assets/IGS.png"
import { TbArrowBack } from "react-icons/tb";
import { Menubar } from "primereact/menubar";

export default function AddNew() {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [employeesData, setEmployeesData] = useState([]);
  const navigate = useNavigate();
  const toast = useRef(null);
  const [projectStartDate, setProjectStartDate] = useState("");
  const [projectEndDate, setProjectEndDate] = useState("");
  const [projectCost, setProjectCost] = useState("");
  const [progressions, setProgressions] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [employees, setEmployees] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const today = new Date();

  useEffect(() => {
    // Fetch data from the first API
    fetch(
      `${process.env.REACT_APP_SERVICE_URL}${process.env.REACT_APP_SERVICE_LAYER_ENDPOINT}/serviceLayerHierarchy`
    )
      .then((response) => response.json())
      .then((data) => {
        const extractedProgressions = [];

        // Extract progressions from the data
        data.forEach((item) => {
          item.Hierarchy_Progression.forEach((hierarchy) => {
            if (
              hierarchy.Progression &&
              typeof hierarchy.Progression === "object"
            ) {
              if (Array.isArray(hierarchy.Progression)) {
                hierarchy.Progression.forEach((progress) => {
                  // Extracting nested progressions for Management types
                  Object.entries(progress).forEach(([key, value]) => {
                    if (key !== "name")
                      extractedProgressions.push({ id: key, name: value });
                  });
                });
              } else {
                // Extracting Engineering progressions
                Object.entries(hierarchy.Progression).forEach(
                  ([key, value]) => {
                    extractedProgressions.push({ id: key, name: value });
                  }
                );
              }
            }
          });
        });

        setProgressions(extractedProgressions);

        // Set default selected value to the first item
        if (extractedProgressions.length > 0) {
          setSelectedValue(extractedProgressions[0].id);
          // Automatically trigger the fetch for the first selection
          handleProgressionChange({
            target: { value: extractedProgressions[0].id },
          });
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleProgressionChange = (event) => {
    const selectedId = event.target.value;
    setSelectedValue(selectedId);
    // Call the second API with the selected id
    fetch(
      `${process.env.REACT_APP_SERVICE_URL}${process.env.REACT_APP_SERVICE_LAYER_ENDPOINT}/serviceLayerAllFilter/${selectedId}`
    )
      .then((response) => response.json())
      .then((data) => {
        // Map through the data to extract name and designation
        const mappedEmployees = data.map((employee) => ({
          name: employee.name,
          designation: employee.designation,
        }));
        setEmployees(mappedEmployees);
      })
      .catch((error) => console.error("Error fetching filtered data:", error));
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_EARMARK_URL}${process.env.REACT_APP_EARMARK_ENDPOINT}/earmark/project-names`)
      .then((response) => response.json())
      .then((data) => {
        setProjects(data);

        if (data.length > 0) {
          const defaultProject = data[0];
          setSelectedProject(defaultProject);
          // Set the project details immediately
          setProjectStartDate(defaultProject.startDate);
          setProjectEndDate(defaultProject.endDate);
          setProjectCost(defaultProject.cost);
        }
      })
      .catch((error) => console.error("Error fetching projects:", error));
  }, []);

  useEffect(() => {
    fetch(
      // "http://192.168.29.195:50010/api/v1/earmarkprojects/employees"
      `${process.env.REACT_APP_API_EARMARK_URL}${process.env.REACT_APP_EARMARK_ENDPOINT}/earmark/employees`
    )
      .then((response) => response.json())
      .then((data) => {
        // Map through the projects to extract employee data
        const updatedEmployeeData = data.map((project) => {
          // Assuming project has nested employee groups
          const employees = project.employees.flatMap((group) =>
            group.employees.map((employee) => ({
              name: employee.name,
              role: employee.role,
              startDate: employee.startDate,
              endDate: employee.endDate,
              salary: employee.salary,
              margin: employee.margin,
            }))
          );

          return {
            project_name: project.project_name,
            employees: employees,
          };
        });

        setEmployeesData(updatedEmployeeData);
      })
      .catch((error) => console.error("Error fetching employees:", error));
  }, []);

  useEffect(() => {
    if (selectedProject) {
      const projectEmployees = employeesData.find(
        (empData) => empData.project_name === selectedProject.project_name
      );

      const parseDate = (dateStr) => {
        const [day, month, year] = dateStr.split("/").map(Number);
        return new Date(year, month - 1, day);
      };

      if (projectEmployees) {
        const users = projectEmployees.employees.map((employee) => {
          const parsedStartDate = parseDate(employee.startDate);
          const parsedEndDate = parseDate(employee.endDate);

          return {
            key: employee.name,
            data: {
              name: employee.name,
              role: employee.role,
              startDate: isNaN(parsedStartDate.getTime())
                ? new Date()
                : parsedStartDate,
              endDate: isNaN(parsedEndDate.getTime())
                ? new Date()
                : parsedEndDate,
              salary: employee.salary,
              costSalMargin: employee.margin,
            },
          };
        });
        setSelectedUsers(users);
      } else {
        setSelectedUsers([]);
      }
    }
  }, [selectedProject, employeesData]);

  const handleUserClick = (user) => {
    const clickedUser = employees.find((employee) => employee.name === user);

    if (clickedUser) {
      const userAlreadySelected = selectedUsers.find(
        (selectedUser) => selectedUser.key === user
      );

      if (!userAlreadySelected) {
        setSelectedUsers((prevUsers) => [
          ...prevUsers,
          {
            key: clickedUser.name, // Use the clicked user's name as the key
            data: {
              name: clickedUser.name,
              role: clickedUser.designation, // Set the user's designation as the role
              startDate: null,
              endDate: null,
              salary: "",
              costSalMargin: "",
            },
          },
        ]);
      }
    }
  };

  const calculateMargin = (cost, salary) => {
    if (cost && salary) {
      return ((cost - salary) / cost).toFixed(2); // Convert to a decimal and round to 2 decimal places
    }
    return 0;
  };

  const handleInputChange = (e, key, field) => {
    const updatedUsers = selectedUsers.map((node) => {
      if (node.key === key) {
        const updatedField = { ...node.data, [field]: e.target.value };

        // Calculate margin if both cost and salary are present
        const updatedMargin = calculateMargin(
          projectCost, // Assuming projectCost is available globally
          parseFloat(updatedField.salary)
        );

        return {
          ...node,
          data: {
            ...updatedField,
            costSalMargin: updatedMargin, // Update margin automatically
          },
        };
      }
      return node;
    });

    setSelectedUsers(updatedUsers);
  };

  const handleProjectChange = (e) => {
    const selected = projects.find((project) => project._id === e.value._id);
    setSelectedProject(selected);

    if (selected) {
      setProjectStartDate(selected.startDate);
      setProjectEndDate(selected.endDate);
      setProjectCost(selected.cost);
    }
  };

  const handleSubmit = () => {
    const employees = selectedUsers.map((user) => ({
      name: user.data.name,
      role: user.data.role,
      startDate: user.data.startDate
        ? user.data.startDate.toLocaleDateString()
        : null,
      endDate: user.data.endDate
        ? user.data.endDate.toLocaleDateString()
        : null,
      salary: user.data.salary,
      margin: user.data.costSalMargin,
    }));
  
    const requestBody = {
      project_name: selectedProject ? selectedProject.project_name : "",
      employees,
    };
  
    fetch(
      `${process.env.REACT_APP_API_EARMARK_URL}${process.env.REACT_APP_EARMARK_ENDPOINT}/earmark/createOrUpdateEmployees`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        // Show success toast first
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Added Successfully",
          life: 1000, // Toast will show for 3 seconds
        });
  
        // Delay navigation using setTimeout
        setTimeout(() => {
          navigate('/earmark'); // Navigate after 3 seconds
        }, 1000); // 3000ms delay to match the toast life
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  
  const handleDelete = (key) => {
    setSelectedUsers(selectedUsers.filter((user) => user.key !== key));
  };
  const start = (
    <button className="rounded-full m-2 hover:bg-gray-200" style={{ height: '40px', width: '40px' }} onClick={() => navigate(-1)}>
      <TbArrowBack />
    </button>
  );
  const end = (
    <div style={{ paddingRight: '15px' }}>
      <Image src={logo} height="60px" width="60px" />
    </div>
  );
  return (
    <div>
      <Menubar start={start} end={end} />
      <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 132px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', marginTop: '10px', overflow: 'scroll', position: 'relative' }}>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>

          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "10px",
              }}
            >
              <div
                style={{ fontWeight: "bold", color: "#373D3F", fontSize: "24px" }}
              >
                Earmark for the Upcoming Project
              </div>

            </div>
          </div>
    
        </div>

        <div>
          <div style={{ padding: "10px" }}>
            <div style={{ display: "flex", marginTop: "25px" }}>
              <div style={{ width: "20%" }}>
                <div
                  style={{
                    fontWeight: "bold",
                    color: "#373D3F",
                    fontSize: "20px",
                  }}
                >
                  Bench Pool
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Dropdown
                      value={selectedValue}
                      options={progressions.map((progression) => ({
                        label: progression.name,
                        value: progression.id,
                      }))}
                      onChange={handleProgressionChange}
                      placeholder="Select a Progression"
                    />
                  </div>
                  <div
                    style={{
                      background: "#f9f9f9",
                      margin: "10px",
                      borderRadius: "5px",
                      boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.2)",
                      padding: "10px",
                      border: "1px solid #EBEBEB",
                      overflowY: "scroll",
                      height: "350px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                        width: "100%",
                      }}
                    >
                      <i
                        className="pi pi-search"
                        style={{ fontSize: "1rem" }}
                      ></i>
                      <InputText
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search User"
                        style={{ borderRadius: "5px", width: "80%" }}
                      />
                    </div>

                    {employees
                      .filter((employee) =>
                        employee.name
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                      )
                      .map((employee, index) => (
                        <div
                          style={{
                            margin: "5px",
                            background: "#FFFFFF",
                            padding: "15px",
                            borderRadius: "5px",
                            boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.2)",
                            marginTop: "10px",
                            border: "1px solid #EBEBEB",
                            cursor: "pointer",
                          }}
                          key={index}
                          onClick={() => handleUserClick(employee.name)}
                        >
                          <div style={{ fontWeight: "bold", color: "#373D3F" }}>
                            {employee.name}
                          </div>
                        </div>
                      ))}

                  </div>
                </div>
              </div>
              <Divider layout="vertical" />
              <div style={{ width: "80%" }}>
                <div>
                  <div
                    style={{
                      fontWeight: "bold",
                      color: "#373D3F",
                      fontSize: "20px",
                    }}
                  >
                    Project Details
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      paddingRight: "10px",
                    }}
                  >
                    <Dropdown
                      value={selectedProject}
                      options={projects}
                      onChange={handleProjectChange}
                      optionLabel="project_name"
                      placeholder="Select a Project"
                      style={{ width: "20%" }}
                    />
                    <div style={{ fontWeight: "bold", color: "#00000080" }}>
                      Start Date: {projectStartDate}
                    </div>
                    <div style={{ fontWeight: "bold", color: "#00000080" }}>
                      End Date: {projectEndDate}
                    </div>
                    <div style={{ fontWeight: "bold", color: "#00000080" }}>
                      Cost: {projectCost}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    borderRadius: "5px",
                    overflow: "hidden",
                    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
                    marginTop: "15px",
                  }}
                >

                  <DataTable value={selectedUsers} dataKey="key">
                    <Column
                      field="name"
                      header="Employee Name"
                      body={(rowData) => <div>{rowData.data.name}</div>}
                      style={{ width: "20%" }}
                    />
                    <Column
                      field="role"
                      header="Role"
                      body={(rowData) => <div>{rowData.data.role}</div>}
                      style={{ width: "20%" }}
                    />
                    <Column
                      field="startDate"
                      header="Start Date"
                      style={{ width: "15%" }}
                      body={(rowData) => (
                        <Calendar
                          value={rowData.data.startDate}
                          onChange={(e) =>
                            handleInputChange(e, rowData.key, "startDate")
                          }
                          dateFormat="dd/mm/yy"
                          style={{ width: "100%" }}
                          minDate={today}
                        />
                      )}
                    />
                    <Column
                      field="endDate"
                      header="End Date"
                      style={{ width: "15%" }}
                      body={(rowData) => (
                        <Calendar
                          value={rowData.data.endDate}
                          onChange={(e) =>
                            handleInputChange(e, rowData.key, "endDate")
                          }
                          dateFormat="dd/mm/yy"
                          style={{ width: "100%" }}
                          minDate={ today} 
                        />
                      )}
                    />
                    <Column
                      field="salary"
                      header="Salary"
                      style={{ width: "15%" }}
                      body={(rowData) => (
                        <InputText
                          value={rowData.data.salary}
                          onChange={(e) =>
                            handleInputChange(e, rowData.key, "salary")
                          }
                          style={{ width: "100%" }}
                        />
                      )}
                    />
                    <Column
                      field="costSalMargin"
                      header="Margin"
                      style={{ width: "15%" }}
                      body={(rowData) => (
                        <InputText
                          value={rowData.data.costSalMargin}
                          onChange={(e) =>
                            handleInputChange(e, rowData.key, "costSalMargin")
                          }
                          style={{ width: "100%" }}
                        />
                      )}
                    />
                    {/* Add a Delete button column */}
                    <Column
                      header="Delete"
                      body={(rowData) => (
                        <Button
                          className="p-button-rounded p-button-text"
                          style={{ color: "#00000080", fontSize: "20px" }}
                          icon="pi pi-trash"
                          onClick={() => handleDelete(rowData.key)}
                        />
                      )}
                      style={{ width: "10%" }}
                    />
                  </DataTable>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    paddingRight: "30px",
                  }}
                >
                  <Button
                    label="Submit"
                    style={{
                      background: "#29295FFF",
                      border: "1px solid #29295FFF",
                      marginTop: "15px",
                    }}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast ref={toast} />
    </div>
  );
}

