import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import { useNavigate } from 'react-router-dom'; // Assuming you are using React Router for navigation
import { Image } from "primereact/image";
import { Menubar } from "primereact/menubar";
import logo from "../../../../assets/IGS.png";
import { ProgressSpinner } from 'primereact/progressspinner';

const BenchStatusChart = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // To navigate to the new page

  // Fetch the data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/reports/billableAndNonBillable`);
        const result = await response.json();
        setData(result);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error('Error fetching the data:', error);
        setLoading(false); // In case of error, stop the spinner
      }
    };
    // fetchData();
    const timeoutId = setTimeout(() => {
      fetchData();
    }, 200); // Set a delay of 200ms

    return () => clearTimeout(timeoutId); // Cleanup the timeout
  }, []);

  // Initialize the chart
  useEffect(() => {
    if (data && data.report) {
      const chartDom = document.getElementById('bench-billable-chart');
      const myChart = echarts.init(chartDom);

      // Define the data for the chart
      const billableCount = data.report.billableCount;
      const nonBillableCount = data.report.nonBillableCount;

      const option = {
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            name: 'Employee Status',
            type: 'pie',
            radius: '90%',
            data: [
              { value: billableCount, name: 'Billable', itemStyle: { color: '#87cefa' } },
              { value: nonBillableCount, name: 'Non-Billable', itemStyle: { color: '#cd6155' } },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
            label: {
              show: true,
              position: 'outside',
              formatter: '{b|{b}} {c|}', // Include both {b} and "Hour" with rich text
              color: '#000', // Default color for visibility
              rich: {
                b: {
                  fontSize: 18,  // Set font size for status name
                  fontWeight: 'bold',  // Set font weight for status name
                  color: '#000', // Color for status name
                },
                c: {
                  fontSize: 18,  // Set font size for "Hour"
                  fontWeight: 'bold',  // Set font weight for "Hour"
                  color: '#000', // Color for "Hour"
                },
              },
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 10,
              smooth: true,
            },
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 2,
            },
          },
          {
            name: 'Employee Counts',
            type: 'pie',
            radius: ['60%', '60%'],
            data: [
              { value: billableCount, name: `${billableCount} employee` },
              { value: nonBillableCount, name: `${nonBillableCount} employee` },
            ],
            label: {
              show: true,
              position: 'inside',
              formatter: '{c} Employee', // Show the count inside the slice
              color: '#000', // Dynamic color for visibility
              fontSize: '16px',   // Set font size
              fontWeight: '400',  // Set font weight
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
      

      myChart.setOption(option);
      // Handle click event
      myChart.on('click', function (params) {
        if (params.name === 'Billable') {
          navigate('/billable/report'); // Navigate to the Billable report component
        } else if (params.name === 'Non-Billable') {
          navigate('/nonbillable/report'); // Navigate to the Non-Billable report component
        }
      });

      // Cleanup the chart on component unmount
      return () => {
        myChart.dispose();
      };
    }
  }, [data, navigate]);

  const start = (
    <div>
      <p
        style={{
          color: "#00000080",
          fontWeight: "bold",
          fontSize: "22px",
          margin: "0px",
          marginBottom: "5PX",
        }}
      >
       Utilization Report
      </p>
    </div>
  );

  const end = (
    <div style={{ paddingRight: "15px" }}>
      <Image src={logo} height="60px" width="60px" />
    </div>
  );

  return (
    <div>
       <div className="card">
        <Menubar start={start} end={end} />
      </div>
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', height: 'calc(100vh - 180px)', backgroundColor: 'white', overflow: 'hidden', border: '1px solid rgba(128, 128, 128, 0.211)' }}>
      <div style={{ width: '100%' }}>
        <h3 style={{ textAlign: 'center', marginBottom: '40px' }}>Employee Billable Status Chart</h3>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
          </div>
        ) : data && data.report ? (
          <div id="bench-billable-chart" style={{ width: '100%', height: '500px' }}></div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px', fontSize: '24px', fontWeight: 'bold', color: 'gray' }}>
            No Report Available
          </div>
        )}
      </div>
    </div>
  </div>
  );
};

export default BenchStatusChart;
