import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getTimeSheet } from '../../../services/createProjectServices/Services'; // Assuming this is the service to fetch timesheet data

export default function Dashboard() {
    const { user } = useContext(UserContext);  
    const [timeSheetData, setTimeSheetData] = useState([]);

    // Function to format date to dd/mm/yyyy
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getTimeSheet(user.Data.empId); // Fetch data using empId
                const data = response.projects; // Extract projects from the response
                setTimeSheetData(data); // Set the projects data in the state
            } catch (error) {
                console.error('Error fetching timesheet data:', error);
            }
        }

        if (user?.Data?.empId) {
            fetchData();
        }
    }, [user?.Data?.empId]); // Re-run when empId changes

    return (
        <Panel
            header={
                <span className="text-md font-bold">
                    <span style={{ marginRight: '60px' }}>Name: {user?.Data?.name}</span>
                    <span style={{ marginRight: '60px' }}>EmpId: {user?.Data?.empId}</span>
                    <span style={{ marginRight: '60px' }}>Designation: {user?.Data?.designation}</span>
                </span>
            }
        >
            {/* PrimeReact DataTable to display project data */}
            <DataTable value={timeSheetData} responsiveLayout="scroll">
                <Column field="projectName" header="Project Name" />
                {/* Use formatDate function to format start_Date and end_Date */}
                <Column 
                    field="start_Date" 
                    header="Start Date" 
                    body={(rowData) => formatDate(rowData.start_Date)} 
                />
                <Column 
                    field="end_Date" 
                    header="End Date" 
                    body={(rowData) => formatDate(rowData.end_Date)} 
                />
            </DataTable>
        </Panel>
    );
}
