// DownloadExcel.js
import React from 'react';
import * as XLSX from 'xlsx';
import { FaFileExcel } from 'react-icons/fa'; // Importing Excel icon
import { FaDownload } from "react-icons/fa";
const DownloadExcel = ({ data, fileName, sheetName }) => {
  const handleDownload = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  return (
    <button onClick={handleDownload} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
      <FaDownload size={20} color="green" />
    </button>
  );
};

export default DownloadExcel;
