import React, { useState, useEffect, useRef } from "react";
import DownloadExcel from "./DownloadExcel"; // Import the DownloadExcel component
import "./LeaveList.css";
import * as XLSX from "xlsx";
import { Image } from "primereact/image";
import logo from "../../assets/IGS.png";
import { Menubar } from "primereact/menubar";
import { Toast } from "primereact/toast";
import { FaTrash } from "react-icons/fa";
import { ProgressSpinner } from "primereact/progressspinner";
const Leaves = () => {
  const [leaves, setLeaves] = useState([]); // Leave types
  const toast = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newLeave, setNewLeave] = useState("");
  const [code, setCode] = useState(""); // For leave code
  const [halfDayCode, setHalfDayCode] = useState(""); // For half-day code
  const [remarks, setRemarks] = useState(""); // For remarks
  const [leaveData, setLeaveData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchLeaveData = () => {
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/leaveList`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // Check if the response has content
        return response.text().then((text) => (text ? JSON.parse(text) : {}));
      })
      .then((data) => {
        if (Object.keys(data).length === 0) {
          setLeaves([]); // Empty array if no leaves
        } else {
          setLeaves(data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  // Call the fetch function on component mount
  useEffect(() => {
    fetchLeaveData();
  }, []);
  // Handle Delete Leave
  const handleDelete = (leave) => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/leave/delete`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          leaveType: leave.leaveType,
          code: leave.code,
          halfDayCode: leave.halfDayCode,
          remarks: leave.remarks,
          symbol: leave.symbol,
        }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete leave.");
        }
        // Remove the deleted leave from the state
        setLeaves((prevLeaves) =>
          prevLeaves.filter((l) => l.code !== leave.code)
        );
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: `Leave ${leave.leaveType} deleted successfully`,
          life: 3000,
        });
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.message,
          life: 3000,
        });
      });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching leave data: {error.message}</p>;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setNewLeave("");
    setCode("");
    setHalfDayCode("");
    setRemarks("");
  };

  const addNewLeave = () => {
    if (newLeave.trim() && code.trim()) {
      // Generate symbol from leave type (first letter of each word)
      const symbol = newLeave
        .split(" ") // Split the leave type into words
        .map((word) => word.charAt(0).toUpperCase()) // Take the first letter of each word and capitalize it
        .join(""); // Join the letters to form the symbol

      const leaveData = {
        leaveType: newLeave,
        symbol, // Automatically generated symbol
        code: code.toUpperCase(), // Convert code to uppercase
        halfDayCode: halfDayCode.trim() ? halfDayCode.toUpperCase() : null, // Convert halfDayCode to uppercase if it exists
        remarks: remarks.trim() ? remarks : null, // Optional field
      };

      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/leave/add`;

      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(leaveData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to add leave");
          }
          return response.json();
        })
        .then((data) => {
          // Show success toast
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Leave added successfully",
            life: 3000, // Display for 3 seconds
          });

          // Delay closing the modal using setTimeout
          setTimeout(() => {
            closeModal(); // Close the modal after 1 second
          }, 1000);

          // Fetch updated leave data after adding new leave
          fetchLeaveData();
        })
        .catch((error) => {
          console.error("Error:", error);

          // Show error toast if there's an issue with the API call
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to add leave. Please try again.",
            life: 3000, // Display for 3 seconds
          });
        });
    } else {
      // Show an error toast if required fields are not filled
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Leave type and code are required.",
        life: 3000,
      });
    }
  };

  

  const start = (
    <div>
      <p
        style={{
          color: "#00000080",
          fontWeight: "bold",
          fontSize: "22px",
          margin: "0px",
          marginBottom: "5PX",
        }}
      >
        Leaves
      </p>
    </div>
  );
  const end = (
    <div style={{ paddingRight: "15px" }}>
      <Image src={logo} height="60px" width="60px" />
    </div>
  );

  return (
    <div>
      <Toast ref={toast} />
      <div className="card">
        <Menubar start={start} end={end} />
      </div>
      <div
        style={{
          backgroundColor: "#f8f9fa",
          height: "calc(100vh - 140px)",
          boxShadow: "0 10px 10px -5px rgba(0, 0, 0, 0.3)",
          border: "1px solid rgba(128, 128, 128, 0.211)",
          marginTop: "10px",
          overflow: "scroll",
          position: "relative",
        }}
      >
        <div className="container">
          {/* Simple Leave Section */}
          <div className="leave-section">
            <div className="header">
              <h2 className="title">Leaves</h2>
              <div className="actions">
                {/* <button className="add-btn" onClick={openModal}>+ Leave</button> */}
                <label className="add-btn">
                  + Leave
                  <input
                    type="button"
                    onClick={openModal}
                    style={{ display: "none" }}
                  />
                </label>
                <DownloadExcel
                  data={leaves}
                  fileName="LeaveList"
                  sheetName="Leaves"
                />
              </div>
            </div>
            <table className="leave-table1" style={{ padding: "10px" }}>
        <thead>
          <tr>
            <th>Leave Type</th>
            <th>Code</th>
            <th>Half Day Code</th>
            <th>Remarks</th>
            <th>Action</th> {/* Added the new Action column */}
          </tr>
        </thead>
        <tbody>
          {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 300px)' }}>
        <ProgressSpinner />
      </div>
          ) : error ? (
            <tr>
              <td colSpan="5">Error fetching leave data: {error.message}</td>
            </tr>
          ) : leaves.length === 0 ? (
            <tr>
              <td colSpan="5">No leave available</td>
            </tr>
          ) : (
            leaves.map((leave, index) => (
              <tr key={index}>
                <td>{leave.leaveType}</td>
                <td>{leave.code}</td>
                <td>{leave.halfDayCode || "-"}</td>
                <td>{leave.remarks || "-"}</td>
                <td>
                  <FaTrash
                  size={25}
                    style={{ cursor: "pointer", color: "#ff4d4d" }}
                    onClick={() => handleDelete(leave)} // Pass the leave object to handleDelete
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
          </div>

          {/* Modal for Adding New Leave */}
          {isModalOpen && (
            <div className="modal">
              <div className="modal-content">
                <h3>Add New Leave</h3>
                <input
                  type="text"
                  required
                  value={newLeave}
                  onChange={(e) => setNewLeave(e.target.value)}
                  placeholder="Enter leave type"
                />
                <div className="flex gap-4">
                  <input
                    type="text"
                    required
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    placeholder="Enter Code"
                    className="flex-1"
                  />
                  <input
                    type="text"
                    value={halfDayCode}
                    onChange={(e) => setHalfDayCode(e.target.value)}
                    placeholder="Enter Half Day Code"
                    className="flex-1"
                  />
                </div>
                <input
                  type="text"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  placeholder="Enter Remarks"
                />
                <div className="modal-buttons">
                  <button onClick={addNewLeave}>Add</button>
                  <button onClick={closeModal}>Cancel</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Leaves;

// import React, { useState } from 'react';
// import './LeaveList.css';

// const LeaveList = () => {
//   // State for the list of leave types
//   const [leaves, setLeaves] = useState([
//     'Maternity Leave',
//     'Paternity Leave',
//     'Medical Leave',
//     'Sick Leave',
//     'Casual Leave (CL)',
//     'Paid Time Off (PTO)',
//     'Vacation Leave / Annual Leave',
//     'Bereavement Leave',
//     'Marriage Leave',
//     'Compensatory Off (Comp Off)',
//     'Unpaid Leave',
//     'Earned Leave (EL) / Privilege Leave (PL)',
//     'Study Leave',
//     'Adoption Leave',
//     'Sabbatical Leave'
//   ]);

//   // State to control modal visibility
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   // State to store the new leave being added
//   const [newLeave, setNewLeave] = useState('');

//   // Function to open the modal
//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   // Function to close the modal
//   const closeModal = () => {
//     setIsModalOpen(false);
//     setNewLeave(''); // Reset the new leave input
//   };

//   // Function to handle adding a new leave
//   const addNewLeave = () => {
//     if (newLeave.trim() !== '') {
//       setLeaves([...leaves, newLeave]); // Add new leave to the list
//       closeModal(); // Close the modal after adding
//     }
//   };

//   return (
//     <div className="container">
//       {/* Simple Leave Section */}
//       <div className="leave-section">
//         <div className="header">
//           <h2 className="title">Leaves</h2>
//           <button className="add-btn" onClick={openModal}>+</button>
//         </div>
//         <table className="leave-table">
//           <thead>
//             <tr>
//               <th>Leave Type</th>
//             </tr>
//           </thead>
//           <tbody>
//             {leaves.map((leave, index) => (
//               <tr key={index}>
//                 <td>{leave}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>

//       {/* National Leave Section (unchanged) */}
//       <div className="leave-section">
//         <div className="header">
//           <h2 className="title">National Holidays</h2>
//           <button className="import-btn">Import</button>
//         </div>
//         <table className="leave-table">
//           <thead>
//             <tr>
//               <th>Holiday</th>
//               <th>Date</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr><td>Republic Day</td><td>26/01/2024</td></tr>
//             <tr><td>Makar Sakranti</td><td>14/01/2024</td></tr>
//             <tr><td>Maha ShivRatri</td><td>26/02/2024</td></tr>
//             <tr><td>Holi</td><td>14/03/2024</td></tr>
//             <tr><td>Good Friday</td><td>29/03/2024</td></tr>
//             <tr><td>Eid al-Fitr</td><td>10/04/2024</td></tr>
//             <tr><td>Labor Day</td><td>01/05/2024</td></tr>
//             <tr><td>Eid al-Adha</td><td>17/06/2024</td></tr>
//             <tr><td>Independence Day</td><td>15/08/2024</td></tr>
//             <tr><td>Raksha Bandhan</td><td>19/08/2024</td></tr>
//             <tr><td>Janmashtami</td><td>26/08/2024</td></tr>
//             <tr><td>Gandhi Jayanti</td><td>02/10/2024</td></tr>
//             <tr><td>Dussehra</td><td>12/10/2024</td></tr>
//             <tr><td>Diwali</td><td>01/11/2024</td></tr>
//             <tr><td>Guru Nanak Jayanti</td><td>15/11/2024</td></tr>
//             <tr><td>Christmas</td><td>25/12/2024</td></tr>
//           </tbody>
//         </table>
//       </div>

//       {/* Modal for Adding New Leave */}
//       {isModalOpen && (
//         <div className="modal">
//           <div className="modal-content">
//             <h3>Add New Leave</h3>
//             <input
//               type="text"
//               value={newLeave}
//               onChange={(e) => setNewLeave(e.target.value)}
//               placeholder="Enter leave type"
//             />
//             <div className="modal-buttons">
//               <button onClick={addNewLeave}>Add</button>
//               <button onClick={closeModal}>Cancel</button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default LeaveList;

// import React from 'react';
// import './LeaveList.css';

// const LeaveList = () => {
//   return (
//     <div className="container">
//       {/* Simple Leave Section */}
//       <div className="leave-section">
//         <div className="header">
//           <h2 className="title">Leaves</h2>
//           <button className="add-btn">+</button>
//         </div>
//         <table className="leave-table">
//           <thead>
//             <tr>
//               <th>Leave Type</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr><td>Maternity Leave</td></tr>
//             <tr><td>Paternity Leave</td></tr>
//             <tr><td>Medical Leave</td></tr>
//             <tr><td>Sick Leave</td></tr>
//             <tr><td>Casual Leave (CL)</td></tr>
//             <tr><td>Paid Time Off (PTO)</td></tr>
//             <tr><td>Vacation Leave / Annual Leave</td></tr>
//             <tr><td>Bereavement Leave</td></tr>
//             <tr><td>Marriage Leave</td></tr>
//             <tr><td>Compensatory Off (Comp Off)</td></tr>
//             <tr><td>Unpaid Leave</td></tr>
//             <tr><td>Earned Leave (EL) / Privilege Leave (PL)</td></tr>
//             <tr><td>Study Leave</td></tr>
//             <tr><td>Adoption Leave</td></tr>
//             <tr><td>Sabbatical Leave</td></tr>
//           </tbody>
//         </table>
//       </div>

//       {/* National Leave Section */}
//       <div className="leave-section">
//         <div className="header">
//           <h2 className="title">National Holidays</h2>
//           <button className="import-btn">Import</button>
//         </div>
//         <table className="leave-table">
//           <thead>
//             <tr>
//               <th>Holiday</th>
//               <th>Date</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr><td>Republic Day</td><td>26/01/2024</td></tr>
//             <tr><td>Makar Sakranti</td><td>14/01/2024</td></tr>
//             <tr><td>Maha ShivRatri</td><td>26/02/2024</td></tr>
//             <tr><td>Holi</td><td>14/03/2024</td></tr>
//             <tr><td>Good Friday</td><td>29/03/2024</td></tr>
//             <tr><td>Eid al-Fitr</td><td>10/04/2024</td></tr>
//             <tr><td>Labor Day</td><td>01/05/2024</td></tr>
//             <tr><td>Eid al-Adha</td><td>17/06/2024</td></tr>
//             <tr><td>Independence Day</td><td>15/08/2024</td></tr>
//             <tr><td>Raksha Bandhan</td><td>19/08/2024</td></tr>
//             <tr><td>Janmashtami</td><td>26/08/2024</td></tr>
//             <tr><td>Gandhi Jayanti</td><td>02/10/2024</td></tr>
//             <tr><td>Dussehra</td><td>12/10/2024</td></tr>
//             <tr><td>Diwali</td><td>01/11/2024</td></tr>
//             <tr><td>Guru Nanak Jayanti</td><td>15/11/2024</td></tr>
//             <tr><td>Christmas</td><td>25/12/2024</td></tr>
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default LeaveList;

// import React from 'react';
// import './LeaveList.css';

// const LeaveList = () => {
//   return (
//     <div className="container">
//       <div className="leave-section">
//         <h2>Simple Leave</h2>
//         <table className="leave-table">
//           <thead>
//             <tr>
//               <th>Leave Type</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr><td>Casual Leave (CL)</td></tr>
//             <tr><td>Sick Leave (SL)</td></tr>
//             <tr><td>Paid Time Off (PTO)</td></tr>
//             <tr><td>Vacation Leave / Annual Leave</td></tr>
//             <tr><td>Maternity Leave</td></tr>
//             <tr><td>Paternity Leave</td></tr>
//             <tr><td>Bereavement Leave</td></tr>
//             <tr><td>Marriage Leave</td></tr>
//             <tr><td>Compensatory Off (Comp Off)</td></tr>
//             <tr><td>Unpaid Leave</td></tr>
//             <tr><td>Earned Leave (EL) / Privilege Leave (PL)</td></tr>
//             <tr><td>Study Leave</td></tr>
//             <tr><td>Adoption Leave</td></tr>
//             <tr><td>Sabbatical Leave</td></tr>
//           </tbody>
//         </table>
//       </div>

//       <div className="leave-section">
//         <h2>National Leave</h2>
//         <table className="leave-table">
//           <thead>
//             <tr>
//               <th>Holiday</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr><td>Republic Day (January 26)</td></tr>
//             <tr><td>Independence Day (August 15)</td></tr>
//             <tr><td>Gandhi Jayanti (October 2)</td></tr>
//             <tr><td>Labor Day (May 1)</td></tr>
//             <tr><td>Diwali</td></tr>
//             <tr><td>Holi</td></tr>
//             <tr><td>Eid ul-Fitr</td></tr>
//             <tr><td>Christmas (December 25)</td></tr>
//             <tr><td>Good Friday</td></tr>
//             <tr><td>Dussehra</td></tr>
//             <tr><td>Makar Sankranti / Pongal</td></tr>
//             <tr><td>Raksha Bandhan</td></tr>
//             <tr><td>Muharram</td></tr>
//             <tr><td>Janmashtami</td></tr>
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default LeaveList;
