export const fetchHierarchyOptions = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SERVICE_URL}${process.env.REACT_APP_SERVICE_LAYER_ENDPOINT}/hierarchy/flattened`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        const data = await response.json()
        return data
    } catch (error) {
        console.log(error)
    }
};

export const fetchDeliveryManagers = async () => {
    try {
        // server = 'http://172.20.10.2:5002/api/v1/admin/roles/get/111'
        // igs server = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/roles/get/111`
        const response = await fetch(`${process.env.REACT_APP_SERVICE_URL}${process.env.REACT_APP_SERVICE_LAYER_ENDPOINT}/getRoles/110-111`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        const data = await response.json()
        return data
    } catch (error) {
        console.log(error)
    }
    
};


export const fetchProjectManagers = async () => {
    try {
        // igs server ip = 'http://172.20.10.2:5002/api/v1/admin/roles/get/61-105'
        // igs deployed server ip = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/roles/get/61-105`
        const response = await fetch(`${process.env.REACT_APP_SERVICE_URL}${process.env.REACT_APP_SERVICE_LAYER_ENDPOINT}/getRoles/105`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        const data = await response.json()
        return data
    } catch (error) {
        console.log(error)
    }

};

export const getAllProjects = async () => {
  
    try {
        // sir's ip = http://172.20.10.2:5002/api/v1/admin/project/get/all
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/project/get/all`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
            }
        })
        const data = await response.json()
        return data
    } catch (error) {
        console.log(error)
    }
};

export const updateProject = async (projectData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}:${process.env.REACT_APP_ADMIN_API_ENDPOINT}/project/edit`, {
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            },
            body: projectData
        })
        return await response.json()
    } catch (error) {
        console.log(error)
    }
}

export const addSubProject =  async (subProjectData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/subproject/addSubProject`, {
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            },
            body: JSON.stringify(subProjectData)
        })
        return await response.json()
    } catch (error) {
        console.log(error)
    }
}


export const getAllSubProjects = async (project_id) => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/subproject/getAllMix`;
  console.log(project_id, "bob id");
  
      const response = await fetch(`${url}?project_id=${project_id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching subprojects:', error);
      throw error; // Rethrow the error so that the caller can handle it
    }
  };
  

  export const updateSubProject = async (subProjectData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/subproject/updateSubProject`, {
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            },
            body: JSON.stringify(subProjectData)
        })
        return await response.json()
    } catch (error) {
        console.log(error)
    }
}

// export const deleteSubProject = async (projectId, subProjectId) => {
//     try {
//         const response = await fetch(`http://192.168.29.195:5004/api/v1/admin/subproject`, {
//             method: 'DELETE',
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify({ project_id: projectId, sub_project_id: subProjectId })
//         })
//         return await response.json()
//     } catch (error) {
//         console.log(error)
//     }
// }

// export const deleteSubProject = async (projectId, subProjectId) => {
//     try {
//         const response = await fetch(`http://192.168.29.195:5004/api/v1/admin/subProject/deleteSubProject/${subProjectId}`, { // Pass subProjectId in the URL
//             method: 'DELETE',
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify({ project_id: projectId }) // Only pass projectId in the body
//         });
//         return await response.json();
//     } catch (error) {
//         console.log(error);
//     }
// };

export const deleteSubProject = async (projectId, subProjectId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/subProject/deleteSubProject/${subProjectId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ project_id: projectId }),
        });

        // Check if the response status is in the successful range
        if (!response.ok) {
            throw new Error(`Failed to delete subproject: ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error occurred while deleting subproject:', error);
        throw error; // rethrow the error after logging
    }
};


export const createSubProjectTask = async (subProjectData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/subProject/createSubProjectTask`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(subProjectData)
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`HTTP error! Status: ${response.status}. Response: ${errorText}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error:', error);
        throw error; // Re-throw to handle it in the caller
    }
};

export const createEmployee = async (employeeData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/employee/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(employeeData)
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`HTTP error! Status: ${response.status}. Response: ${errorText}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error:', error);
        throw error; // Re-throw to handle it in the caller
    }
};



export const getTimeSheet = async (empId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/timesheet/${empId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  