// import React, { useState } from "react";

// const AddCategory = ({ projectName, subProjectName, onCategoryAdded }) => {
//   const [categoryName, setCategoryName] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const handleAddCategory = async () => {
//     if (!categoryName) {
//       setError("Category name is required");
//       return;
//     }

//     setLoading(true);
//     setError(null); // Clear previous errors

//     const categoryData = {
//       project_name: projectName,
//       sub_project_name: subProjectName,
//       categories: [
//         {
//           category_name: categoryName,
//         },
//       ],
//     };

//     try {
//       const response = await fetch(
//         "http://192.168.29.195:5004/api/v1/admin/subProject/createSubProjectTask",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(categoryData), // Convert categoryData to JSON string
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to add category. Please try again.");
//       }

//       const data = await response.json();
//       console.log("Category added successfully:", data);

//       // Update the category in the parent component (ProjectDataTable)
//       onCategoryAdded(categoryName);

//       // Optionally, you can clear the input and provide feedback
//       setCategoryName("");
//     } catch (error) {
//       setError(error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div>
//       <h3>Add Category</h3>
//       <input
//         type="text"
//         value={categoryName}
//         placeholder="Enter category name"
//         onChange={(e) => setCategoryName(e.target.value)}
//         disabled={loading}
//       />
//       <button onClick={handleAddCategory} disabled={loading}>
//         {loading ? "Adding..." : "Add Category"}
//       </button>
//       {error && <p style={{ color: "red" }}>{error}</p>}
//     </div>
//   );
// };

// export default AddCategory;



import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

const AddCategory = ({ projectName, subProjectName, onCategoryAdded, visible, onHide }) => {
  const [categoryName, setCategoryName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const toastRef = React.useRef(null); 

  const handleAddCategory = async () => {
    if (!categoryName) {
      setError("Category name is required");
      return;
    }

    setLoading(true);
    setError(null); // Clear previous errors

    const categoryData = {
      project_name: projectName,
      sub_project_name: subProjectName,
      categories: [
        {
          category_name: categoryName,
        },
      ],
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/subProject/createSubProjectTask`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(categoryData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add category. Please try again.");
      }

      const data = await response.json();
      console.log("Category added successfully:", data);

      onCategoryAdded(categoryName);
      setCategoryName("");
      toastRef.current.show({ severity: 'success', summary: 'Success', detail: 'Category added successfully', life: 1000 });
      setTimeout(() => {
        onHide();
      }, 1000);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Toast ref={toastRef} /> 
    <Dialog header="Add Category" visible={visible} onHide={onHide} style={{ width: '30vw' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>

        <div className="p-field">
          <InputText
            value={categoryName}
            placeholder="Enter category name"
            onChange={(e) => setCategoryName(e.target.value)}
            disabled={loading}
            style={{ width: '17vw' }}
          />
        </div>
        <div>
          {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
        <div className="p-mt-3">
          <Button
            style={{ backgroundColor: '#182452' }}
            label={loading ? "Adding..." : "Add Category"}
            onClick={handleAddCategory}
            disabled={loading}
          />
        </div>
      </div>
    </Dialog>
    </>
  );
};

export default AddCategory;
