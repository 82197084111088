import React from 'react'
import { Image } from "primereact/image";
import { Menubar } from "primereact/menubar";
import logo from "../../../assets/IGS.png";
import Billable from "../admin/Billable/Billable"
import BenchReport from "../admin/Bench"
export default function Organization() {

    const start = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ color: '#00000080' }}>
                <h2>Organization Report</h2>
            </div>
        </div>
    );

    const end = (
        <div style={{ paddingRight: '15px' }}>
            <Image src={logo} height="60px" width="60px" />
        </div>
    );
    return (
        <div>
            <div style={{ marginBottom: '20px' }}>
                <Menubar start={start} end={end} />
            </div>
            <div style={{ backgroundColor: '#f8f9fa', height: 'calc(100vh - 150px)', boxShadow: '0 10px 10px -5px rgba(0, 0, 0, 0.3)', border: '1px solid rgba(128, 128, 128, 0.211)', overflow: 'scroll', position: 'relative' }}>
                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'10px', gap:'10px'}}>
                 
                    <div style={{width:'50%'}}>
                        <BenchReport />
                    </div>
                    <div  style={{width:'50%', }} >
                        <Billable />
                    </div>
                </div>
            </div>
        </div>
    )
}
