// import React, { useState } from "react";
// import { Dialog } from "primereact/dialog";
// import { Button } from "primereact/button";
// import { InputText } from "primereact/inputtext";
// import { Toast } from "primereact/toast";


// const AddData = ({ projectName, subProjectName, categoryName, onDataAdded, visible, onHide }) => {
//   const [newData, setNewData] = useState({
//     ToDS_Ref: "",
//     Type: "",
//     Task_Description: "",
//     Test_Units: "",
//     Unit_of_Measure: "",
//   });
//   const toastRef = React.useRef(null); 


//   const handleAddData = () => {
//     const data = {
//       project_name: projectName,
//       sub_project_name: subProjectName,
//       categories: [
//         {
//           category_name: categoryName,
//           data: [
//             {
//               ToDS_Ref: newData.ToDS_Ref,
//               Type: newData.Type,
//               Task_Description: newData.Task_Description,
//               Test_Units: newData.Test_Units,
//               Unit_of_Measure: newData.Unit_of_Measure,
//               Category: categoryName,
//             },
//           ],
//         },
//       ],
//     };

//     fetch('http://192.168.29.195:5004/api/v1/admin/subProject/createSubProjectTask', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(data),
//     })
//       .then(response => response.json())
//       .then((responseData) => {
//         onDataAdded(responseData);
//         setNewData({
//           ToDS_Ref: "",
//           Type: "",
//           Task_Description: "",
//           Test_Units: "",
//           Unit_of_Measure: "",
//         });
//         onHide(); // Close the dialog
//       })
//       .catch((error) => {
//         console.error('Error adding data:', error);
//         alert('Failed to add data');
//       });
//   };

//   return (
//     <Dialog header="Add New Task" style={{ width: '40vw' }} visible={visible} onHide={onHide} footer={
//       <div>
//         <Button  style={{ backgroundColor: '#182452' }} label="Add Task" icon="pi pi-check" onClick={handleAddData} />
//       </div>
//     }>
//       <div >
//         <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
//           <div className="p-field">
//             <InputText
//               value={newData.ToDS_Ref}
//               onChange={(e) => setNewData({ ...newData, ToDS_Ref: e.target.value })}
//               placeholder="ToDS Ref"
//               style={{ width: '17vw' }}
//             />
//           </div>
//           <div className="p-field">
//             <InputText
//               value={newData.Type}
//               onChange={(e) => setNewData({ ...newData, Type: e.target.value })}
//               placeholder="Type"
//               style={{ width: '17vw' }}
//             />
//           </div>
//         </div>
//         <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
//           <div className="p-field">
//             <InputText
//               type="number"
//               value={newData.Test_Units}
//               min="0"
//               onChange={(e) => setNewData({ ...newData, Test_Units: e.target.value })}
//               placeholder="Test Units"
//               style={{ width: '17vw' }}
//             />
//           </div>
//           <div className="p-field">
//             <InputText
//               value={newData.Unit_of_Measure}
//               onChange={(e) => setNewData({ ...newData, Unit_of_Measure: e.target.value })}
//               placeholder="Unit of Measure"
//               style={{ width: '17vw' }}
//             />
//           </div>
//         </div>

//         <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '20px' }}>
//         <div className="p-field">
//           <InputText
//             value={newData.Task_Description}
//             onChange={(e) => setNewData({ ...newData, Task_Description: e.target.value })}
//             placeholder="Task Description"
//             style={{ width: '35.5vw' }}
//           />
//         </div>
//         </div>
        

//       </div>
//     </Dialog>
//   );
// };

// export default AddData;



import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";

const AddData = ({ projectName, subProjectName, categoryName, onDataAdded, visible, onHide }) => {
  const [newData, setNewData] = useState({
    ToDS_Ref: "",
    Type: "",
    Task_Description: "",
    Test_Units: "",
    Unit_of_Measure: "",
  });
  const [errors, setErrors] = useState({});
  const toastRef = React.useRef(null); 

  const showSuccess = () => {
    toastRef.current.show({ severity: 'success', summary: 'Success', detail: 'Task added successfully!', life: 1000 });
  };

  const validateFields = () => {
    const newErrors = {};
    if (!newData.ToDS_Ref) newErrors.ToDS_Ref = "ToDS Ref is required.";
    if (!newData.Test_Units) newErrors.Test_Units = "Test Units are required.";
    if (!newData.Type) newErrors.Type = "Type is required.";
    if (!newData.Task_Description) newErrors.Task_Description = "Task Description is required.";
    if (!newData.Unit_of_Measure) newErrors.Unit_of_Measure = "Unit of Measure is required.";
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleAddData = () => {
    if (!validateFields()) return; // Stop if validation fails

    const data = {
      project_name: projectName,
      sub_project_name: subProjectName,
      categories: [
        {
          category_name: categoryName,
          data: [
            {
              ToDS_Ref: newData.ToDS_Ref,
              Type: newData.Type,
              Task_Description: newData.Task_Description,
              Test_Units: newData.Test_Units,
              Unit_of_Measure: newData.Unit_of_Measure,
              Category: categoryName,
            },
          ],
        },
      ],
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/subProject/createSubProjectTask`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then((responseData) => {
        onDataAdded(responseData);
        showSuccess(); // Show toast message
        setNewData({
          ToDS_Ref: "",
          Type: "",
          Task_Description: "",
          Test_Units: "",
          Unit_of_Measure: "",
        });
        setErrors({}); // Clear errors
        setTimeout(() => {
          onHide(); // Close the dialog after a delay
        }, 1000); // Adjust the delay as needed
      })
      .catch((error) => {
        console.error('Error adding data:', error);
        alert('Failed to add data');
      });
  };

  return (
    <>
      <Toast ref={toastRef} />
      <Dialog header="Add New Task" style={{ width: '40vw' }} visible={visible} onHide={onHide} footer={
        <div>
          <Button style={{ backgroundColor: '#182452' }} label="Add Task" icon="pi pi-check" onClick={handleAddData} />
        </div>
      }>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
            <div className="p-field">
              <InputText
                value={newData.ToDS_Ref}
                onChange={(e) => setNewData({ ...newData, ToDS_Ref: e.target.value })}
                placeholder="ToDS Ref"
                style={{ width: '17vw' }}
                className={errors.ToDS_Ref ? 'p-invalid' : ''}
              />
              {errors.ToDS_Ref && <small className="p-error">{errors.ToDS_Ref}</small>}
            </div>
            <div className="p-field">
              <InputText
                value={newData.Type}
                onChange={(e) => setNewData({ ...newData, Type: e.target.value })}
                placeholder="Type"
                style={{ width: '17vw' }}
                className={errors.Type ? 'p-invalid' : ''}
              />
              {errors.Type && <small className="p-error">{errors.Type}</small>}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
            <div className="p-field">
              <InputText
                type="number"
                value={newData.Test_Units}
                min="0"
                onChange={(e) => setNewData({ ...newData, Test_Units: e.target.value })}
                placeholder="Test Units"
                style={{ width: '17vw' }}
                className={errors.Test_Units ? 'p-invalid' : ''}
              />
              {errors.Test_Units && <small className="p-error">{errors.Test_Units}</small>}
            </div>
            <div className="p-field">
              <InputText
                value={newData.Unit_of_Measure}
                onChange={(e) => setNewData({ ...newData, Unit_of_Measure: e.target.value })}
                placeholder="Unit of Measure"
                style={{ width: '17vw' }}
                className={errors.Unit_of_Measure ? 'p-invalid' : ''}
              />
              {errors.Unit_of_Measure && <small className="p-error">{errors.Unit_of_Measure}</small>}
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '20px' }}>
            <div className="p-field">
              <InputText
                value={newData.Task_Description}
                onChange={(e) => setNewData({ ...newData, Task_Description: e.target.value })}
                placeholder="Task Description"
                style={{ width: '35.5vw' }}
                className={errors.Task_Description ? 'p-invalid' : ''}
              />
              {errors.Task_Description && <small className="p-error">{errors.Task_Description}</small>}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AddData;
